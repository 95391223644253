/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Json: `scalar.Json` as const,
	_RelationSide: "enum" as const,
	_OnDeleteBehaviour: "enum" as const,
	_OrderByDirection: "enum" as const,
	Query:{
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getContentReferenceLinkableItem:{
			by:"ContentReferenceLinkableItemUniqueWhere",
			filter:"ContentReferenceLinkableItemWhere"
		},
		listContentReferenceLinkableItem:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		},
		paginateContentReferenceLinkableItem:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterLink:{
			by:"FooterLinkUniqueWhere",
			filter:"FooterLinkWhere"
		},
		listFooterLink:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		paginateFooterLink:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getSeoDefault:{
			by:"SeoDefaultUniqueWhere",
			filter:"SeoDefaultWhere"
		},
		listSeoDefault:{
			filter:"SeoDefaultWhere",
			orderBy:"SeoDefaultOrderBy"
		},
		paginateSeoDefault:{
			filter:"SeoDefaultWhere",
			orderBy:"SeoDefaultOrderBy"
		},
		getSeoDefaultLocale:{
			by:"SeoDefaultLocaleUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		listSeoDefaultLocale:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		paginateSeoDefaultLocale:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		getBusiness:{
			by:"BusinessUniqueWhere",
			filter:"BusinessWhere"
		},
		listBusiness:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		paginateBusiness:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		getBusinessPage:{
			by:"BusinessPageUniqueWhere",
			filter:"BusinessPageWhere"
		},
		listBusinessPage:{
			filter:"BusinessPageWhere",
			orderBy:"BusinessPageOrderBy"
		},
		paginateBusinessPage:{
			filter:"BusinessPageWhere",
			orderBy:"BusinessPageOrderBy"
		},
		getBusinessPageLocale:{
			by:"BusinessPageLocaleUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		listBusinessPageLocale:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		paginateBusinessPageLocale:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		getIcon:{
			by:"IconUniqueWhere",
			filter:"IconWhere"
		},
		listIcon:{
			filter:"IconWhere",
			orderBy:"IconOrderBy"
		},
		paginateIcon:{
			filter:"IconWhere",
			orderBy:"IconOrderBy"
		},
		getJobPage:{
			by:"JobPageUniqueWhere",
			filter:"JobPageWhere"
		},
		listJobPage:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		},
		paginateJobPage:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		},
		getJobPageLocale:{
			by:"JobPageLocaleUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		listJobPageLocale:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		paginateJobPageLocale:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		getKeyInfoItem:{
			by:"KeyInfoItemUniqueWhere",
			filter:"KeyInfoItemWhere"
		},
		listKeyInfoItem:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		},
		paginateKeyInfoItem:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		},
		getKeyInfoList:{
			by:"KeyInfoListUniqueWhere",
			filter:"KeyInfoListWhere"
		},
		listKeyInfoList:{
			filter:"KeyInfoListWhere",
			orderBy:"KeyInfoListOrderBy"
		},
		paginateKeyInfoList:{
			filter:"KeyInfoListWhere",
			orderBy:"KeyInfoListOrderBy"
		},
		getContractType:{
			by:"ContractTypeUniqueWhere",
			filter:"ContractTypeWhere"
		},
		listContractType:{
			filter:"ContractTypeWhere",
			orderBy:"ContractTypeOrderBy"
		},
		paginateContractType:{
			filter:"ContractTypeWhere",
			orderBy:"ContractTypeOrderBy"
		},
		getContractTypeLocale:{
			by:"ContractTypeLocaleUniqueWhere",
			filter:"ContractTypeLocaleWhere"
		},
		listContractTypeLocale:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		paginateContractTypeLocale:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		getJobCity:{
			by:"JobCityUniqueWhere",
			filter:"JobCityWhere"
		},
		listJobCity:{
			filter:"JobCityWhere",
			orderBy:"JobCityOrderBy"
		},
		paginateJobCity:{
			filter:"JobCityWhere",
			orderBy:"JobCityOrderBy"
		},
		getJobCityLocale:{
			by:"JobCityLocaleUniqueWhere",
			filter:"JobCityLocaleWhere"
		},
		listJobCityLocale:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		paginateJobCityLocale:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		getJobPosition:{
			by:"JobPositionUniqueWhere",
			filter:"JobPositionWhere"
		},
		listJobPosition:{
			filter:"JobPositionWhere",
			orderBy:"JobPositionOrderBy"
		},
		paginateJobPosition:{
			filter:"JobPositionWhere",
			orderBy:"JobPositionOrderBy"
		},
		getJobPositionLocale:{
			by:"JobPositionLocaleUniqueWhere",
			filter:"JobPositionLocaleWhere"
		},
		listJobPositionLocale:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		paginateJobPositionLocale:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getHomePage:{
			by:"HomePageUniqueWhere",
			filter:"HomePageWhere"
		},
		listHomePage:{
			filter:"HomePageWhere",
			orderBy:"HomePageOrderBy"
		},
		paginateHomePage:{
			filter:"HomePageWhere",
			orderBy:"HomePageOrderBy"
		},
		getHomePageLocale:{
			by:"HomePageLocaleUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		listHomePageLocale:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		paginateHomePageLocale:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getMediumItem:{
			by:"MediumItemUniqueWhere",
			filter:"MediumItemWhere"
		},
		listMediumItem:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		},
		paginateMediumItem:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		},
		getMediumList:{
			by:"MediumListUniqueWhere",
			filter:"MediumListWhere"
		},
		listMediumList:{
			filter:"MediumListWhere",
			orderBy:"MediumListOrderBy"
		},
		paginateMediumList:{
			filter:"MediumListWhere",
			orderBy:"MediumListOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getCarousel:{
			by:"CarouselUniqueWhere",
			filter:"CarouselWhere"
		},
		listCarousel:{
			filter:"CarouselWhere",
			orderBy:"CarouselOrderBy"
		},
		paginateCarousel:{
			filter:"CarouselWhere",
			orderBy:"CarouselOrderBy"
		},
		getCarouselItem:{
			by:"CarouselItemUniqueWhere",
			filter:"CarouselItemWhere"
		},
		listCarouselItem:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		},
		paginateCarouselItem:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		},
		getTheme:{
			by:"ThemeUniqueWhere",
			filter:"ThemeWhere"
		},
		listTheme:{
			filter:"ThemeWhere",
			orderBy:"ThemeOrderBy"
		},
		paginateTheme:{
			filter:"ThemeWhere",
			orderBy:"ThemeOrderBy"
		},
		getBonusBanner:{
			by:"BonusBannerUniqueWhere",
			filter:"BonusBannerWhere"
		},
		listBonusBanner:{
			filter:"BonusBannerWhere",
			orderBy:"BonusBannerOrderBy"
		},
		paginateBonusBanner:{
			filter:"BonusBannerWhere",
			orderBy:"BonusBannerOrderBy"
		},
		getBonusBannerLocale:{
			by:"BonusBannerLocaleUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		listBonusBannerLocale:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		paginateBonusBannerLocale:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		getTile:{
			by:"TileUniqueWhere",
			filter:"TileWhere"
		},
		listTile:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		},
		paginateTile:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		},
		getSideBySide:{
			by:"SideBySideUniqueWhere",
			filter:"SideBySideWhere"
		},
		listSideBySide:{
			filter:"SideBySideWhere",
			orderBy:"SideBySideOrderBy"
		},
		paginateSideBySide:{
			filter:"SideBySideWhere",
			orderBy:"SideBySideOrderBy"
		},
		getSideBySideItem:{
			by:"SideBySideItemUniqueWhere",
			filter:"SideBySideItemWhere"
		},
		listSideBySideItem:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		},
		paginateSideBySideItem:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		},
		getHomePageLokal:{
			by:"HomePageLokalUniqueWhere",
			filter:"HomePageLokalWhere"
		},
		listHomePageLokal:{
			filter:"HomePageLokalWhere",
			orderBy:"HomePageLokalOrderBy"
		},
		paginateHomePageLokal:{
			filter:"HomePageLokalWhere",
			orderBy:"HomePageLokalOrderBy"
		},
		getHomePageLokalLocale:{
			by:"HomePageLokalLocaleUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		listHomePageLokalLocale:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		paginateHomePageLokalLocale:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		getSiteSwitcher:{
			by:"SiteSwitcherUniqueWhere",
			filter:"SiteSwitcherWhere"
		},
		listSiteSwitcher:{
			filter:"SiteSwitcherWhere",
			orderBy:"SiteSwitcherOrderBy"
		},
		paginateSiteSwitcher:{
			filter:"SiteSwitcherWhere",
			orderBy:"SiteSwitcherOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getJobsForm:{
			by:"JobsFormUniqueWhere",
			filter:"JobsFormWhere"
		},
		listJobsForm:{
			filter:"JobsFormWhere",
			orderBy:"JobsFormOrderBy"
		},
		paginateJobsForm:{
			filter:"JobsFormWhere",
			orderBy:"JobsFormOrderBy"
		},
		getJobsFormLocale:{
			by:"JobsFormLocaleUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		listJobsFormLocale:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		paginateJobsFormLocale:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		getImageTextItem:{
			by:"ImageTextItemUniqueWhere",
			filter:"ImageTextItemWhere"
		},
		listImageTextItem:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		},
		paginateImageTextItem:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		},
		getImageTextList:{
			by:"ImageTextListUniqueWhere",
			filter:"ImageTextListWhere"
		},
		listImageTextList:{
			filter:"ImageTextListWhere",
			orderBy:"ImageTextListOrderBy"
		},
		paginateImageTextList:{
			filter:"ImageTextListWhere",
			orderBy:"ImageTextListOrderBy"
		},
		getJobsFilter:{
			by:"JobsFilterUniqueWhere",
			filter:"JobsFilterWhere"
		},
		listJobsFilter:{
			filter:"JobsFilterWhere",
			orderBy:"JobsFilterOrderBy"
		},
		paginateJobsFilter:{
			filter:"JobsFilterWhere",
			orderBy:"JobsFilterOrderBy"
		},
		getJobsFilterLocale:{
			by:"JobsFilterLocaleUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		listJobsFilterLocale:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		paginateJobsFilterLocale:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		getFile:{
			by:"FileUniqueWhere",
			filter:"FileWhere"
		},
		listFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		paginateFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		validateCreateFile:{
			data:"FileCreateInput"
		},
		validateCreateJobFormSubscription:{
			data:"JobFormSubscriptionCreateInput"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getJobFormSubscriptionStatus:{
			by:"JobFormSubscriptionStatusUniqueWhere",
			filter:"JobFormSubscriptionStatusWhere"
		},
		listJobFormSubscriptionStatus:{
			filter:"JobFormSubscriptionStatusWhere",
			orderBy:"JobFormSubscriptionStatusOrderBy"
		},
		paginateJobFormSubscriptionStatus:{
			filter:"JobFormSubscriptionStatusWhere",
			orderBy:"JobFormSubscriptionStatusOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getStickyBar:{
			by:"StickyBarUniqueWhere",
			filter:"StickyBarWhere"
		},
		listStickyBar:{
			filter:"StickyBarWhere",
			orderBy:"StickyBarOrderBy"
		},
		paginateStickyBar:{
			filter:"StickyBarWhere",
			orderBy:"StickyBarOrderBy"
		},
		validateCreateStickyBar:{
			data:"StickyBarCreateInput"
		},
		getRecommendationForm:{
			by:"RecommendationFormUniqueWhere",
			filter:"RecommendationFormWhere"
		},
		listRecommendationForm:{
			filter:"RecommendationFormWhere",
			orderBy:"RecommendationFormOrderBy"
		},
		paginateRecommendationForm:{
			filter:"RecommendationFormWhere",
			orderBy:"RecommendationFormOrderBy"
		},
		getRecommendationFormLocale:{
			by:"RecommendationFormLocaleUniqueWhere",
			filter:"RecommendationFormLocaleWhere"
		},
		listRecommendationFormLocale:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		paginateRecommendationFormLocale:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		validateCreateRecommendationFormSubscription:{
			data:"RecommendationFormSubscriptionCreateInput"
		},
		getInfoBox:{
			by:"InfoBoxUniqueWhere",
			filter:"InfoBoxWhere"
		},
		listInfoBox:{
			filter:"InfoBoxWhere",
			orderBy:"InfoBoxOrderBy"
		},
		paginateInfoBox:{
			filter:"InfoBoxWhere",
			orderBy:"InfoBoxOrderBy"
		},
		validateCreateInfoBox:{
			data:"InfoBoxCreateInput"
		},
		getInfoBoxLocale:{
			by:"InfoBoxLocaleUniqueWhere",
			filter:"InfoBoxLocaleWhere"
		},
		listInfoBoxLocale:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		},
		paginateInfoBoxLocale:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		}
	},
	General:{
		locales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		localesByLocale:{
			by:"GeneralLocalesByLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		localesBySeo:{
			by:"GeneralLocalesBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		paginateLocales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		}
	},
	UUID: `scalar.UUID` as const,
	One: "enum" as const,
	GeneralLocale:{
		root:{
			filter:"GeneralWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	GeneralWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"GeneralLocaleWhere",
		and:"GeneralWhere",
		or:"GeneralWhere",
		not:"GeneralWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	GeneralLocaleWhere:{
		id:"UUIDCondition",
		root:"GeneralWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		and:"GeneralLocaleWhere",
		or:"GeneralLocaleWhere",
		not:"GeneralLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		label:"StringCondition",
		footers:"FooterLocaleWhere",
		genericPages:"GenericPageLocaleWhere",
		headers:"HeaderLocaleWhere",
		seoDefaults:"SeoDefaultLocaleWhere",
		businessPages:"BusinessPageLocaleWhere",
		jobPages:"JobPageLocaleWhere",
		contractTypes:"ContractTypeLocaleWhere",
		jobCities:"JobCityLocaleWhere",
		jobPositions:"JobPositionLocaleWhere",
		homePages:"HomePageLocaleWhere",
		bonusBanners:"BonusBannerLocaleWhere",
		homePageLokals:"HomePageLokalLocaleWhere",
		jobsForms:"JobsFormLocaleWhere",
		jobsFilters:"JobsFilterLocaleWhere",
		general:"GeneralLocaleWhere",
		recommendationForms:"RecommendationFormLocaleWhere",
		infoBoxes:"InfoBoxLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	FooterLocaleWhere:{
		id:"UUIDCondition",
		email:"StringCondition",
		address:"StringCondition",
		ico:"StringCondition",
		dic:"StringCondition",
		copyright:"StringCondition",
		note:"StringCondition",
		noteSecond:"StringCondition",
		root:"FooterWhere",
		locale:"LocaleWhere",
		linksFirstColumn:"FooterLinkWhere",
		linksSecondColumn:"FooterLinkWhere",
		termsAndConditions:"LinkWhere",
		form:"StringCondition",
		bannerTitle:"StringCondition",
		bannerSubtitle:"StringCondition",
		bannerImage:"ImageWhere",
		bannerLink:"LinkWhere",
		jobsForm:"JobsFormLocaleWhere",
		copyrightLink:"LinkWhere",
		bannerSecondBtn:"StringCondition",
		recommendationForm:"RecommendationFormLocaleWhere",
		bannerSecondLink:"LinkWhere",
		instagramLink:"LinkWhere",
		and:"FooterLocaleWhere",
		or:"FooterLocaleWhere",
		not:"FooterLocaleWhere"
	},
	FooterWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"FooterLocaleWhere",
		and:"FooterWhere",
		or:"FooterWhere",
		not:"FooterWhere"
	},
	FooterLinkWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		link:"LinkWhere",
		and:"FooterLinkWhere",
		or:"FooterLinkWhere",
		not:"FooterLinkWhere"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	LinkWhere:{
		id:"UUIDCondition",
		type:"LinkTypeCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		anchorLink:"StringCondition",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: "enum" as const,
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		redirect:"RedirectWhere",
		genericPage:"GenericPageLocaleWhere",
		businessPage:"BusinessPageLocaleWhere",
		jobPage:"JobPageLocaleWhere",
		homePage:"HomePageLocaleWhere",
		homePageLokal:"HomePageLokalLocaleWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	GenericPageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"GenericPageWhere",
		locale:"LocaleWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		text:"StringCondition",
		logo:"ImageWhere",
		image:"ImageWhere",
		and:"GenericPageLocaleWhere",
		or:"GenericPageLocaleWhere",
		not:"GenericPageLocaleWhere"
	},
	GenericPageWhere:{
		id:"UUIDCondition",
		locales:"GenericPageLocaleWhere",
		theme:"ThemeWhere",
		and:"GenericPageWhere",
		or:"GenericPageWhere",
		not:"GenericPageWhere"
	},
	ThemeWhere:{
		id:"UUIDCondition",
		backgroundColor:"StringCondition",
		textColor:"StringCondition",
		name:"StringCondition",
		buttonBackgroundColor:"StringCondition",
		buttonTextColor:"StringCondition",
		buttonBackgroundHoverColor:"StringCondition",
		buttonTextHoverColor:"StringCondition",
		buttonBorderColor:"StringCondition",
		buttonBorderHoverColor:"StringCondition",
		headerMenuColor:"StringCondition",
		and:"ThemeWhere",
		or:"ThemeWhere",
		not:"ThemeWhere"
	},
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		primaryText:"StringCondition",
		block:"ContentBlockWhere",
		image:"ImageWhere",
		linkables:"ContentReferenceLinkableItemWhere",
		secondaryText:"StringCondition",
		link:"LinkWhere",
		media:"MediumListWhere",
		textList:"TextListWhere",
		carousel:"CarouselWhere",
		bonusBanner:"BonusBannerLocaleWhere",
		sideBySide:"SideBySideWhere",
		gallery:"GalleryWhere",
		showBoolean:"BooleanCondition",
		isRed:"BooleanCondition",
		video:"VideoWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: "enum" as const,
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		fileName:"StringCondition",
		focalPointX:"FloatCondition",
		focalPointY:"FloatCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	ContentReferenceLinkableItemWhere:{
		id:"UUIDCondition",
		item:"LinkableWhere",
		reference:"ContentReferenceWhere",
		and:"ContentReferenceLinkableItemWhere",
		or:"ContentReferenceLinkableItemWhere",
		not:"ContentReferenceLinkableItemWhere"
	},
	MediumListWhere:{
		id:"UUIDCondition",
		items:"MediumItemWhere",
		and:"MediumListWhere",
		or:"MediumListWhere",
		not:"MediumListWhere"
	},
	MediumItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"MediumListWhere",
		medium:"MediumWhere",
		and:"MediumItemWhere",
		or:"MediumItemWhere",
		not:"MediumItemWhere"
	},
	MediumWhere:{
		id:"UUIDCondition",
		type:"MediumTypeCondition",
		image:"ImageWhere",
		video:"VideoWhere",
		and:"MediumWhere",
		or:"MediumWhere",
		not:"MediumWhere"
	},
	MediumTypeCondition:{
		and:"MediumTypeCondition",
		or:"MediumTypeCondition",
		not:"MediumTypeCondition",
		eq:"MediumType",
		notEq:"MediumType",
		in:"MediumType",
		notIn:"MediumType",
		lt:"MediumType",
		lte:"MediumType",
		gt:"MediumType",
		gte:"MediumType"
	},
	MediumType: "enum" as const,
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		videoInfo:"StringCondition",
		duration:"FloatCondition",
		poster:"ImageWhere",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	TextListWhere:{
		id:"UUIDCondition",
		items:"TextItemWhere",
		and:"TextListWhere",
		or:"TextListWhere",
		not:"TextListWhere"
	},
	TextItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"TextListWhere",
		title:"StringCondition",
		and:"TextItemWhere",
		or:"TextItemWhere",
		not:"TextItemWhere"
	},
	CarouselWhere:{
		id:"UUIDCondition",
		items:"CarouselItemWhere",
		background:"BackgroundCondition",
		squareImages:"BooleanCondition",
		and:"CarouselWhere",
		or:"CarouselWhere",
		not:"CarouselWhere"
	},
	CarouselItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"CarouselWhere",
		image:"ImageWhere",
		name:"StringCondition",
		title:"StringCondition",
		linkUrl:"LinkWhere",
		and:"CarouselItemWhere",
		or:"CarouselItemWhere",
		not:"CarouselItemWhere"
	},
	BackgroundCondition:{
		and:"BackgroundCondition",
		or:"BackgroundCondition",
		not:"BackgroundCondition",
		eq:"Background",
		notEq:"Background",
		in:"Background",
		notIn:"Background",
		lt:"Background",
		lte:"Background",
		gt:"Background",
		gte:"Background"
	},
	Background: "enum" as const,
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	BonusBannerLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		text:"StringCondition",
		root:"BonusBannerWhere",
		locale:"LocaleWhere",
		tiles:"TileWhere",
		logo:"ImageWhere",
		bannerName:"StringCondition",
		and:"BonusBannerLocaleWhere",
		or:"BonusBannerLocaleWhere",
		not:"BonusBannerLocaleWhere"
	},
	BonusBannerWhere:{
		id:"UUIDCondition",
		locales:"BonusBannerLocaleWhere",
		and:"BonusBannerWhere",
		or:"BonusBannerWhere",
		not:"BonusBannerWhere"
	},
	TileWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		bonusBanner:"BonusBannerLocaleWhere",
		image:"ImageWhere",
		lightBoxTitle:"StringCondition",
		lightBoxText:"StringCondition",
		ligthBoxMedia:"MediumListWhere",
		lightBoxSectionTitle:"StringCondition",
		title:"StringCondition",
		lightBoxLinkLabel:"StringCondition",
		lightBoxForm:"JobsFormLocaleWhere",
		and:"TileWhere",
		or:"TileWhere",
		not:"TileWhere"
	},
	JobsFormLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		jobText:"StringCondition",
		genericText:"StringCondition",
		nameLabel:"StringCondition",
		emailLabel:"StringCondition",
		telefonLabel:"StringCondition",
		fileTitleLabel:"StringCondition",
		fileFormatLabel:"StringCondition",
		formTextLabel:"StringCondition",
		submitLabel:"StringCondition",
		root:"JobsFormWhere",
		locale:"LocaleWhere",
		imageTextList:"ImageTextListWhere",
		minimalize:"StringCondition",
		logo:"ImageWhere",
		successTitle:"StringCondition",
		successText:"StringCondition",
		successBtnLabel:"StringCondition",
		successImage:"ImageWhere",
		successOtherJobsTitle:"StringCondition",
		successOtherJobsCategoryTitle:"StringCondition",
		successOtherJobsButtonTitle:"StringCondition",
		successOtherJobsSelectionTitle:"StringCondition",
		successOtherJobsSelectionYesButton:"StringCondition",
		successOtherJobsSelectionNoButton:"StringCondition",
		fileRemoveBtnLabel:"StringCondition",
		gdprLabel:"StringCondition",
		and:"JobsFormLocaleWhere",
		or:"JobsFormLocaleWhere",
		not:"JobsFormLocaleWhere"
	},
	JobsFormWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"JobsFormLocaleWhere",
		fileIlustration:"ImageWhere",
		and:"JobsFormWhere",
		or:"JobsFormWhere",
		not:"JobsFormWhere"
	},
	ImageTextListWhere:{
		id:"UUIDCondition",
		items:"ImageTextItemWhere",
		and:"ImageTextListWhere",
		or:"ImageTextListWhere",
		not:"ImageTextListWhere"
	},
	ImageTextItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		list:"ImageTextListWhere",
		image:"ImageWhere",
		and:"ImageTextItemWhere",
		or:"ImageTextItemWhere",
		not:"ImageTextItemWhere"
	},
	SideBySideWhere:{
		id:"UUIDCondition",
		items:"SideBySideItemWhere",
		background:"BackgroundCondition",
		alignment:"ContentAlignmentCondition",
		mediumSize:"MediumSizeCondition",
		and:"SideBySideWhere",
		or:"SideBySideWhere",
		not:"SideBySideWhere"
	},
	SideBySideItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		text:"StringCondition",
		list:"SideBySideWhere",
		media:"MediumListWhere",
		formButtonLabel:"StringCondition",
		type:"SideBySideItemTypeCondition",
		link:"LinkWhere",
		jobsForm:"JobsFormLocaleWhere",
		and:"SideBySideItemWhere",
		or:"SideBySideItemWhere",
		not:"SideBySideItemWhere"
	},
	SideBySideItemTypeCondition:{
		and:"SideBySideItemTypeCondition",
		or:"SideBySideItemTypeCondition",
		not:"SideBySideItemTypeCondition",
		eq:"SideBySideItemType",
		notEq:"SideBySideItemType",
		in:"SideBySideItemType",
		notIn:"SideBySideItemType",
		lt:"SideBySideItemType",
		lte:"SideBySideItemType",
		gt:"SideBySideItemType",
		gte:"SideBySideItemType"
	},
	SideBySideItemType: "enum" as const,
	ContentAlignmentCondition:{
		and:"ContentAlignmentCondition",
		or:"ContentAlignmentCondition",
		not:"ContentAlignmentCondition",
		eq:"ContentAlignment",
		notEq:"ContentAlignment",
		in:"ContentAlignment",
		notIn:"ContentAlignment",
		lt:"ContentAlignment",
		lte:"ContentAlignment",
		gt:"ContentAlignment",
		gte:"ContentAlignment"
	},
	ContentAlignment: "enum" as const,
	MediumSizeCondition:{
		and:"MediumSizeCondition",
		or:"MediumSizeCondition",
		not:"MediumSizeCondition",
		eq:"MediumSize",
		notEq:"MediumSize",
		in:"MediumSize",
		notIn:"MediumSize",
		lt:"MediumSize",
		lte:"MediumSize",
		gt:"MediumSize",
		gte:"MediumSize"
	},
	MediumSize: "enum" as const,
	GalleryWhere:{
		id:"UUIDCondition",
		items:"GalleryItemWhere",
		and:"GalleryWhere",
		or:"GalleryWhere",
		not:"GalleryWhere"
	},
	GalleryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"GalleryWhere",
		image:"ImageWhere",
		and:"GalleryItemWhere",
		or:"GalleryItemWhere",
		not:"GalleryItemWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		noIndex:"BooleanCondition",
		noFollow:"BooleanCondition",
		ogImage:"ImageWhere",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	BusinessPageLocaleWhere:{
		id:"UUIDCondition",
		root:"BusinessPageWhere",
		locale:"LocaleWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		subtitle:"StringCondition",
		text:"StringCondition",
		name:"StringCondition",
		image:"ImageWhere",
		stickyBar:"StickyBarWhere",
		infoBox:"InfoBoxWhere",
		and:"BusinessPageLocaleWhere",
		or:"BusinessPageLocaleWhere",
		not:"BusinessPageLocaleWhere"
	},
	BusinessPageWhere:{
		id:"UUIDCondition",
		locales:"BusinessPageLocaleWhere",
		theme:"ThemeWhere",
		isLokal:"BooleanCondition",
		and:"BusinessPageWhere",
		or:"BusinessPageWhere",
		not:"BusinessPageWhere"
	},
	StickyBarWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkWhere",
		formButtonLabel:"StringCondition",
		jobsForm:"JobsFormLocaleWhere",
		and:"StickyBarWhere",
		or:"StickyBarWhere",
		not:"StickyBarWhere"
	},
	InfoBoxWhere:{
		id:"UUIDCondition",
		logoSize:"LogoSizeCondition",
		logo:"ImageWhere",
		locales:"InfoBoxLocaleWhere",
		and:"InfoBoxWhere",
		or:"InfoBoxWhere",
		not:"InfoBoxWhere"
	},
	LogoSizeCondition:{
		and:"LogoSizeCondition",
		or:"LogoSizeCondition",
		not:"LogoSizeCondition",
		eq:"LogoSize",
		notEq:"LogoSize",
		in:"LogoSize",
		notIn:"LogoSize",
		lt:"LogoSize",
		lte:"LogoSize",
		gt:"LogoSize",
		gte:"LogoSize"
	},
	LogoSize: "enum" as const,
	InfoBoxLocaleWhere:{
		id:"UUIDCondition",
		address:"StringCondition",
		web:"StringCondition",
		instagram:"StringCondition",
		facebook:"StringCondition",
		root:"InfoBoxWhere",
		locale:"LocaleWhere",
		openingHours:"TextListWhere",
		addressLink:"StringCondition",
		and:"InfoBoxLocaleWhere",
		or:"InfoBoxLocaleWhere",
		not:"InfoBoxLocaleWhere"
	},
	JobPageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		text:"StringCondition",
		root:"JobPageWhere",
		locale:"LocaleWhere",
		image:"ImageWhere",
		keyInfo:"KeyInfoListWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		email:"StringCondition",
		stickyBar:"StickyBarWhere",
		mobileImage:"ImageWhere",
		and:"JobPageLocaleWhere",
		or:"JobPageLocaleWhere",
		not:"JobPageLocaleWhere"
	},
	JobPageWhere:{
		id:"UUIDCondition",
		locales:"JobPageLocaleWhere",
		theme:"ThemeWhere",
		contractType:"ContractTypeWhere",
		jobCity:"JobCityWhere",
		business:"BusinessPageWhere",
		isActive:"BooleanCondition",
		jobPosition:"JobPositionWhere",
		publishedAt:"DateTimeCondition",
		order:"IntCondition",
		isSyncedToTabidoo:"BooleanCondition",
		and:"JobPageWhere",
		or:"JobPageWhere",
		not:"JobPageWhere"
	},
	ContractTypeWhere:{
		id:"UUIDCondition",
		locales:"ContractTypeLocaleWhere",
		and:"ContractTypeWhere",
		or:"ContractTypeWhere",
		not:"ContractTypeWhere"
	},
	ContractTypeLocaleWhere:{
		id:"UUIDCondition",
		type:"StringCondition",
		root:"ContractTypeWhere",
		locale:"LocaleWhere",
		slug:"StringCondition",
		and:"ContractTypeLocaleWhere",
		or:"ContractTypeLocaleWhere",
		not:"ContractTypeLocaleWhere"
	},
	JobCityWhere:{
		id:"UUIDCondition",
		locales:"JobCityLocaleWhere",
		and:"JobCityWhere",
		or:"JobCityWhere",
		not:"JobCityWhere"
	},
	JobCityLocaleWhere:{
		id:"UUIDCondition",
		root:"JobCityWhere",
		locale:"LocaleWhere",
		name:"StringCondition",
		slug:"StringCondition",
		and:"JobCityLocaleWhere",
		or:"JobCityLocaleWhere",
		not:"JobCityLocaleWhere"
	},
	JobPositionWhere:{
		id:"UUIDCondition",
		locales:"JobPositionLocaleWhere",
		jobs:"JobPageWhere",
		and:"JobPositionWhere",
		or:"JobPositionWhere",
		not:"JobPositionWhere"
	},
	JobPositionLocaleWhere:{
		id:"UUIDCondition",
		type:"StringCondition",
		root:"JobPositionWhere",
		locale:"LocaleWhere",
		slug:"StringCondition",
		and:"JobPositionLocaleWhere",
		or:"JobPositionLocaleWhere",
		not:"JobPositionLocaleWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	DateTime: `scalar.DateTime` as const,
	KeyInfoListWhere:{
		id:"UUIDCondition",
		items:"KeyInfoItemWhere",
		and:"KeyInfoListWhere",
		or:"KeyInfoListWhere",
		not:"KeyInfoListWhere"
	},
	KeyInfoItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		keyInfo:"StringCondition",
		list:"KeyInfoListWhere",
		icon:"IconWhere",
		and:"KeyInfoItemWhere",
		or:"KeyInfoItemWhere",
		not:"KeyInfoItemWhere"
	},
	IconWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		icon:"ImageWhere",
		and:"IconWhere",
		or:"IconWhere",
		not:"IconWhere"
	},
	HomePageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"HomePageWhere",
		locale:"LocaleWhere",
		button:"LinkWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		logo:"ImageWhere",
		siteSwitcher:"SiteSwitcherWhere",
		gallery:"GalleryWhere",
		stickyBar:"StickyBarWhere",
		text:"StringCondition",
		ilustration:"GalleryWhere",
		and:"HomePageLocaleWhere",
		or:"HomePageLocaleWhere",
		not:"HomePageLocaleWhere"
	},
	HomePageWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"HomePageLocaleWhere",
		theme:"ThemeWhere",
		and:"HomePageWhere",
		or:"HomePageWhere",
		not:"HomePageWhere"
	},
	SiteSwitcherWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkWhere",
		theme:"ThemeWhere",
		and:"SiteSwitcherWhere",
		or:"SiteSwitcherWhere",
		not:"SiteSwitcherWhere"
	},
	HomePageLokalLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"HomePageLokalWhere",
		locale:"LocaleWhere",
		logo:"ImageWhere",
		button:"LinkWhere",
		content:"ContentWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		siteSwitcher:"SiteSwitcherWhere",
		gallery:"GalleryWhere",
		stickyBar:"StickyBarWhere",
		text:"StringCondition",
		ilustration:"GalleryWhere",
		and:"HomePageLokalLocaleWhere",
		or:"HomePageLokalLocaleWhere",
		not:"HomePageLokalLocaleWhere"
	},
	HomePageLokalWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"HomePageLokalLocaleWhere",
		theme:"ThemeWhere",
		and:"HomePageLokalWhere",
		or:"HomePageLokalWhere",
		not:"HomePageLokalWhere"
	},
	RecommendationFormLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		subtitle:"StringCondition",
		yourEmailLabel:"StringCondition",
		theirEmailLabel:"StringCondition",
		yourName:"StringCondition",
		sendBtnLabel:"StringCondition",
		successTitle:"StringCondition",
		successSubtitle:"StringCondition",
		successButtonClose:"StringCondition",
		root:"RecommendationFormWhere",
		locale:"LocaleWhere",
		and:"RecommendationFormLocaleWhere",
		or:"RecommendationFormLocaleWhere",
		not:"RecommendationFormLocaleWhere"
	},
	RecommendationFormWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"RecommendationFormLocaleWhere",
		successImage:"ImageWhere",
		and:"RecommendationFormWhere",
		or:"RecommendationFormWhere",
		not:"RecommendationFormWhere"
	},
	HeaderLocaleWhere:{
		id:"UUIDCondition",
		root:"HeaderWhere",
		locale:"LocaleWhere",
		menuLabel:"StringCondition",
		menuTitle:"StringCondition",
		menuText:"StringCondition",
		business:"BusinessWhere",
		lokalEnterprise:"BusinessWhere",
		businessSectionTitle:"StringCondition",
		lokalSectionTitle:"StringCondition",
		businessLink:"LinkWhere",
		lokalLink:"LinkWhere",
		and:"HeaderLocaleWhere",
		or:"HeaderLocaleWhere",
		not:"HeaderLocaleWhere"
	},
	HeaderWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"HeaderLocaleWhere",
		logo:"ImageWhere",
		logoMenu:"ImageWhere",
		and:"HeaderWhere",
		or:"HeaderWhere",
		not:"HeaderWhere"
	},
	BusinessWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		logo:"ImageWhere",
		link:"LinkWhere",
		and:"BusinessWhere",
		or:"BusinessWhere",
		not:"BusinessWhere"
	},
	SeoDefaultLocaleWhere:{
		id:"UUIDCondition",
		root:"SeoDefaultWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		and:"SeoDefaultLocaleWhere",
		or:"SeoDefaultLocaleWhere",
		not:"SeoDefaultLocaleWhere"
	},
	SeoDefaultWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"SeoDefaultLocaleWhere",
		and:"SeoDefaultWhere",
		or:"SeoDefaultWhere",
		not:"SeoDefaultWhere"
	},
	JobsFilterLocaleWhere:{
		id:"UUIDCondition",
		jobPositionLabel:"StringCondition",
		jobContractLabel:"StringCondition",
		jobCityLabel:"StringCondition",
		root:"JobsFilterWhere",
		locale:"LocaleWhere",
		bannerTitle:"StringCondition",
		bannerFormLabel:"StringCondition",
		bannerJobsForm:"JobsFormLocaleWhere",
		bannerImage:"ImageWhere",
		noJobTitle:"StringCondition",
		noJobBtnLabel:"StringCondition",
		noJobImage:"ImageWhere",
		noJobTitleForSpecificBusiness:"StringCondition",
		deactivatedJob:"StringCondition",
		and:"JobsFilterLocaleWhere",
		or:"JobsFilterLocaleWhere",
		not:"JobsFilterLocaleWhere"
	},
	JobsFilterWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"JobsFilterLocaleWhere",
		and:"JobsFilterWhere",
		or:"JobsFilterWhere",
		not:"JobsFilterWhere"
	},
	Locale:{
		footers:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		genericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		headers:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		seoDefaults:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		businessPages:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		jobPages:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		contractTypes:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		jobCities:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		jobPositions:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		homePages:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		bonusBanners:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		homePageLokals:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		jobsForms:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		jobsFilters:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		general:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		recommendationForms:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		infoBoxes:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		},
		footersByRoot:{
			by:"LocaleFootersByRootUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByTermsAndConditions:{
			by:"LocaleFootersByTermsAndConditionsUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByBannerImage:{
			by:"LocaleFootersByBannerImageUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByBannerLink:{
			by:"LocaleFootersByBannerLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByJobsForm:{
			by:"LocaleFootersByJobsFormUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByCopyrightLink:{
			by:"LocaleFootersByCopyrightLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByRecommendationForm:{
			by:"LocaleFootersByRecommendationFormUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByBannerSecondLink:{
			by:"LocaleFootersByBannerSecondLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByInstagramLink:{
			by:"LocaleFootersByInstagramLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		genericPagesByRoot:{
			by:"LocaleGenericPagesByRootUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByContent:{
			by:"LocaleGenericPagesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesBySeo:{
			by:"LocaleGenericPagesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByLink:{
			by:"LocaleGenericPagesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByLogo:{
			by:"LocaleGenericPagesByLogoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByImage:{
			by:"LocaleGenericPagesByImageUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		headersByRoot:{
			by:"LocaleHeadersByRootUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		headersByBusinessLink:{
			by:"LocaleHeadersByBusinessLinkUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		headersByLokalLink:{
			by:"LocaleHeadersByLokalLinkUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		seoDefaultsByRoot:{
			by:"LocaleSeoDefaultsByRootUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		seoDefaultsBySeo:{
			by:"LocaleSeoDefaultsBySeoUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		businessPagesByRoot:{
			by:"LocaleBusinessPagesByRootUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		businessPagesByContent:{
			by:"LocaleBusinessPagesByContentUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		businessPagesBySeo:{
			by:"LocaleBusinessPagesBySeoUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		businessPagesByLink:{
			by:"LocaleBusinessPagesByLinkUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		businessPagesByImage:{
			by:"LocaleBusinessPagesByImageUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		jobPagesByRoot:{
			by:"LocaleJobPagesByRootUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesByImage:{
			by:"LocaleJobPagesByImageUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesByKeyInfo:{
			by:"LocaleJobPagesByKeyInfoUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesByContent:{
			by:"LocaleJobPagesByContentUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesBySeo:{
			by:"LocaleJobPagesBySeoUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesByLink:{
			by:"LocaleJobPagesByLinkUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		jobPagesByMobileImage:{
			by:"LocaleJobPagesByMobileImageUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		contractTypesByRoot:{
			by:"LocaleContractTypesByRootUniqueWhere",
			filter:"ContractTypeLocaleWhere"
		},
		contractTypesBySlug:{
			by:"LocaleContractTypesBySlugUniqueWhere",
			filter:"ContractTypeLocaleWhere"
		},
		jobCitiesByRoot:{
			by:"LocaleJobCitiesByRootUniqueWhere",
			filter:"JobCityLocaleWhere"
		},
		jobCitiesBySlug:{
			by:"LocaleJobCitiesBySlugUniqueWhere",
			filter:"JobCityLocaleWhere"
		},
		jobPositionsByRoot:{
			by:"LocaleJobPositionsByRootUniqueWhere",
			filter:"JobPositionLocaleWhere"
		},
		jobPositionsBySlug:{
			by:"LocaleJobPositionsBySlugUniqueWhere",
			filter:"JobPositionLocaleWhere"
		},
		homePagesByRoot:{
			by:"LocaleHomePagesByRootUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesByButton:{
			by:"LocaleHomePagesByButtonUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesByContent:{
			by:"LocaleHomePagesByContentUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesBySeo:{
			by:"LocaleHomePagesBySeoUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesByLink:{
			by:"LocaleHomePagesByLinkUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesByLogo:{
			by:"LocaleHomePagesByLogoUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesBySiteSwitcher:{
			by:"LocaleHomePagesBySiteSwitcherUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		homePagesByIlustration:{
			by:"LocaleHomePagesByIlustrationUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		bonusBannersByRoot:{
			by:"LocaleBonusBannersByRootUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		bonusBannersByTiles:{
			by:"LocaleBonusBannersByTilesUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		bonusBannersByLogo:{
			by:"LocaleBonusBannersByLogoUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		homePageLokalsByRoot:{
			by:"LocaleHomePageLokalsByRootUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsByLogo:{
			by:"LocaleHomePageLokalsByLogoUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsByButton:{
			by:"LocaleHomePageLokalsByButtonUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsByContent:{
			by:"LocaleHomePageLokalsByContentUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsBySeo:{
			by:"LocaleHomePageLokalsBySeoUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsByLink:{
			by:"LocaleHomePageLokalsByLinkUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsBySiteSwitcher:{
			by:"LocaleHomePageLokalsBySiteSwitcherUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		homePageLokalsByIlustration:{
			by:"LocaleHomePageLokalsByIlustrationUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		jobsFormsByRoot:{
			by:"LocaleJobsFormsByRootUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		jobsFormsByImageTextList:{
			by:"LocaleJobsFormsByImageTextListUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		jobsFormsByLogo:{
			by:"LocaleJobsFormsByLogoUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		jobsFormsBySuccessImage:{
			by:"LocaleJobsFormsBySuccessImageUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		jobsFiltersByRoot:{
			by:"LocaleJobsFiltersByRootUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		jobsFiltersByBannerJobsForm:{
			by:"LocaleJobsFiltersByBannerJobsFormUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		jobsFiltersByBannerImage:{
			by:"LocaleJobsFiltersByBannerImageUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		jobsFiltersByNoJobImage:{
			by:"LocaleJobsFiltersByNoJobImageUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		generalByRoot:{
			by:"LocaleGeneralByRootUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		generalBySeo:{
			by:"LocaleGeneralBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		recommendationFormsByRoot:{
			by:"LocaleRecommendationFormsByRootUniqueWhere",
			filter:"RecommendationFormLocaleWhere"
		},
		infoBoxesByRoot:{
			by:"LocaleInfoBoxesByRootUniqueWhere",
			filter:"InfoBoxLocaleWhere"
		},
		paginateFooters:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateGenericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateHeaders:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateSeoDefaults:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		paginateBusinessPages:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		paginateJobPages:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		paginateContractTypes:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		paginateJobCities:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		paginateJobPositions:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		paginateHomePages:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		paginateBonusBanners:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		paginateHomePageLokals:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		paginateJobsForms:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		paginateJobsFilters:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateRecommendationForms:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		paginateInfoBoxes:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		}
	},
	FooterLocale:{
		root:{
			filter:"FooterWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		linksFirstColumn:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		linksSecondColumn:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		termsAndConditions:{
			filter:"LinkWhere"
		},
		bannerImage:{
			filter:"ImageWhere"
		},
		bannerLink:{
			filter:"LinkWhere"
		},
		jobsForm:{
			filter:"JobsFormLocaleWhere"
		},
		copyrightLink:{
			filter:"LinkWhere"
		},
		recommendationForm:{
			filter:"RecommendationFormLocaleWhere"
		},
		bannerSecondLink:{
			filter:"LinkWhere"
		},
		instagramLink:{
			filter:"LinkWhere"
		},
		paginateLinksFirstColumn:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		paginateLinksSecondColumn:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		}
	},
	Footer:{
		locales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		localesByLocale:{
			by:"FooterLocalesByLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByTermsAndConditions:{
			by:"FooterLocalesByTermsAndConditionsUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByBannerImage:{
			by:"FooterLocalesByBannerImageUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByBannerLink:{
			by:"FooterLocalesByBannerLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByJobsForm:{
			by:"FooterLocalesByJobsFormUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByCopyrightLink:{
			by:"FooterLocalesByCopyrightLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByRecommendationForm:{
			by:"FooterLocalesByRecommendationFormUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByBannerSecondLink:{
			by:"FooterLocalesByBannerSecondLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByInstagramLink:{
			by:"FooterLocalesByInstagramLinkUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		paginateLocales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		}
	},
	FooterLocaleOrderBy:{
		id:"OrderDirection",
		email:"OrderDirection",
		address:"OrderDirection",
		ico:"OrderDirection",
		dic:"OrderDirection",
		copyright:"OrderDirection",
		note:"OrderDirection",
		noteSecond:"OrderDirection",
		root:"FooterOrderBy",
		locale:"LocaleOrderBy",
		termsAndConditions:"LinkOrderBy",
		form:"OrderDirection",
		bannerTitle:"OrderDirection",
		bannerSubtitle:"OrderDirection",
		bannerImage:"ImageOrderBy",
		bannerLink:"LinkOrderBy",
		jobsForm:"JobsFormLocaleOrderBy",
		copyrightLink:"LinkOrderBy",
		bannerSecondBtn:"OrderDirection",
		recommendationForm:"RecommendationFormLocaleOrderBy",
		bannerSecondLink:"LinkOrderBy",
		instagramLink:"LinkOrderBy"
	},
	OrderDirection: "enum" as const,
	FooterOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		label:"OrderDirection"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy",
		anchorLink:"OrderDirection"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		redirect:"RedirectOrderBy",
		genericPage:"GenericPageLocaleOrderBy",
		businessPage:"BusinessPageLocaleOrderBy",
		jobPage:"JobPageLocaleOrderBy",
		homePage:"HomePageLocaleOrderBy",
		homePageLokal:"HomePageLokalLocaleOrderBy"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkOrderBy"
	},
	GenericPageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"GenericPageOrderBy",
		locale:"LocaleOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		text:"OrderDirection",
		logo:"ImageOrderBy",
		image:"ImageOrderBy"
	},
	GenericPageOrderBy:{
		id:"OrderDirection",
		theme:"ThemeOrderBy"
	},
	ThemeOrderBy:{
		id:"OrderDirection",
		backgroundColor:"OrderDirection",
		textColor:"OrderDirection",
		name:"OrderDirection",
		buttonBackgroundColor:"OrderDirection",
		buttonTextColor:"OrderDirection",
		buttonBackgroundHoverColor:"OrderDirection",
		buttonTextHoverColor:"OrderDirection",
		buttonBorderColor:"OrderDirection",
		buttonBorderHoverColor:"OrderDirection",
		headerMenuColor:"OrderDirection"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		noIndex:"OrderDirection",
		noFollow:"OrderDirection",
		ogImage:"ImageOrderBy"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection",
		fileName:"OrderDirection",
		focalPointX:"OrderDirection",
		focalPointY:"OrderDirection"
	},
	BusinessPageLocaleOrderBy:{
		id:"OrderDirection",
		root:"BusinessPageOrderBy",
		locale:"LocaleOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		subtitle:"OrderDirection",
		text:"OrderDirection",
		name:"OrderDirection",
		image:"ImageOrderBy",
		stickyBar:"StickyBarOrderBy",
		infoBox:"InfoBoxOrderBy"
	},
	BusinessPageOrderBy:{
		id:"OrderDirection",
		theme:"ThemeOrderBy",
		isLokal:"OrderDirection"
	},
	StickyBarOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkOrderBy",
		formButtonLabel:"OrderDirection",
		jobsForm:"JobsFormLocaleOrderBy"
	},
	JobsFormLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		jobText:"OrderDirection",
		genericText:"OrderDirection",
		nameLabel:"OrderDirection",
		emailLabel:"OrderDirection",
		telefonLabel:"OrderDirection",
		fileTitleLabel:"OrderDirection",
		fileFormatLabel:"OrderDirection",
		formTextLabel:"OrderDirection",
		submitLabel:"OrderDirection",
		root:"JobsFormOrderBy",
		locale:"LocaleOrderBy",
		imageTextList:"ImageTextListOrderBy",
		minimalize:"OrderDirection",
		logo:"ImageOrderBy",
		successTitle:"OrderDirection",
		successText:"OrderDirection",
		successBtnLabel:"OrderDirection",
		successImage:"ImageOrderBy",
		successOtherJobsTitle:"OrderDirection",
		successOtherJobsCategoryTitle:"OrderDirection",
		successOtherJobsButtonTitle:"OrderDirection",
		successOtherJobsSelectionTitle:"OrderDirection",
		successOtherJobsSelectionYesButton:"OrderDirection",
		successOtherJobsSelectionNoButton:"OrderDirection",
		fileRemoveBtnLabel:"OrderDirection",
		gdprLabel:"OrderDirection"
	},
	JobsFormOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		fileIlustration:"ImageOrderBy"
	},
	ImageTextListOrderBy:{
		id:"OrderDirection"
	},
	InfoBoxOrderBy:{
		id:"OrderDirection",
		logoSize:"OrderDirection",
		logo:"ImageOrderBy"
	},
	JobPageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		text:"OrderDirection",
		root:"JobPageOrderBy",
		locale:"LocaleOrderBy",
		image:"ImageOrderBy",
		keyInfo:"KeyInfoListOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		email:"OrderDirection",
		stickyBar:"StickyBarOrderBy",
		mobileImage:"ImageOrderBy"
	},
	JobPageOrderBy:{
		id:"OrderDirection",
		theme:"ThemeOrderBy",
		contractType:"ContractTypeOrderBy",
		jobCity:"JobCityOrderBy",
		business:"BusinessPageOrderBy",
		isActive:"OrderDirection",
		jobPosition:"JobPositionOrderBy",
		publishedAt:"OrderDirection",
		order:"OrderDirection",
		isSyncedToTabidoo:"OrderDirection"
	},
	ContractTypeOrderBy:{
		id:"OrderDirection"
	},
	JobCityOrderBy:{
		id:"OrderDirection"
	},
	JobPositionOrderBy:{
		id:"OrderDirection"
	},
	KeyInfoListOrderBy:{
		id:"OrderDirection"
	},
	HomePageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"HomePageOrderBy",
		locale:"LocaleOrderBy",
		button:"LinkOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		logo:"ImageOrderBy",
		siteSwitcher:"SiteSwitcherOrderBy",
		gallery:"GalleryOrderBy",
		stickyBar:"StickyBarOrderBy",
		text:"OrderDirection",
		ilustration:"GalleryOrderBy"
	},
	HomePageOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		theme:"ThemeOrderBy"
	},
	SiteSwitcherOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkOrderBy",
		theme:"ThemeOrderBy"
	},
	GalleryOrderBy:{
		id:"OrderDirection"
	},
	HomePageLokalLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"HomePageLokalOrderBy",
		locale:"LocaleOrderBy",
		logo:"ImageOrderBy",
		button:"LinkOrderBy",
		content:"ContentOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		siteSwitcher:"SiteSwitcherOrderBy",
		gallery:"GalleryOrderBy",
		stickyBar:"StickyBarOrderBy",
		text:"OrderDirection",
		ilustration:"GalleryOrderBy"
	},
	HomePageLokalOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		theme:"ThemeOrderBy"
	},
	RecommendationFormLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		subtitle:"OrderDirection",
		yourEmailLabel:"OrderDirection",
		theirEmailLabel:"OrderDirection",
		yourName:"OrderDirection",
		sendBtnLabel:"OrderDirection",
		successTitle:"OrderDirection",
		successSubtitle:"OrderDirection",
		successButtonClose:"OrderDirection",
		root:"RecommendationFormOrderBy",
		locale:"LocaleOrderBy"
	},
	RecommendationFormOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		successImage:"ImageOrderBy"
	},
	FooterLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		footers:"FooterLocaleUniqueWhere",
		genericPages:"GenericPageLocaleUniqueWhere",
		headers:"HeaderLocaleUniqueWhere",
		seoDefaults:"SeoDefaultLocaleUniqueWhere",
		businessPages:"BusinessPageLocaleUniqueWhere",
		jobPages:"JobPageLocaleUniqueWhere",
		contractTypes:"ContractTypeLocaleUniqueWhere",
		jobCities:"JobCityLocaleUniqueWhere",
		jobPositions:"JobPositionLocaleUniqueWhere",
		homePages:"HomePageLocaleUniqueWhere",
		bonusBanners:"BonusBannerLocaleUniqueWhere",
		homePageLokals:"HomePageLokalLocaleUniqueWhere",
		jobsForms:"JobsFormLocaleUniqueWhere",
		jobsFilters:"JobsFilterLocaleUniqueWhere",
		general:"GeneralLocaleUniqueWhere",
		recommendationForms:"RecommendationFormLocaleUniqueWhere",
		infoBoxes:"InfoBoxLocaleUniqueWhere"
	},
	FooterLocaleUniqueWhere:{
		id:"UUID",
		root:"FooterUniqueWhere",
		locale:"LocaleUniqueWhere",
		termsAndConditions:"LinkUniqueWhere",
		bannerImage:"ImageUniqueWhere",
		bannerLink:"LinkUniqueWhere",
		jobsForm:"JobsFormLocaleUniqueWhere",
		copyrightLink:"LinkUniqueWhere",
		recommendationForm:"RecommendationFormLocaleUniqueWhere",
		bannerSecondLink:"LinkUniqueWhere",
		instagramLink:"LinkUniqueWhere"
	},
	FooterUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"FooterLocaleUniqueWhere"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	ImageUniqueWhere:{
		id:"UUID"
	},
	JobsFormLocaleUniqueWhere:{
		id:"UUID",
		root:"JobsFormUniqueWhere",
		locale:"LocaleUniqueWhere",
		imageTextList:"ImageTextListUniqueWhere",
		logo:"ImageUniqueWhere",
		successImage:"ImageUniqueWhere"
	},
	JobsFormUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"JobsFormLocaleUniqueWhere",
		fileIlustration:"ImageUniqueWhere"
	},
	ImageTextListUniqueWhere:{
		id:"UUID",
		items:"ImageTextItemUniqueWhere"
	},
	ImageTextItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	RecommendationFormLocaleUniqueWhere:{
		id:"UUID",
		root:"RecommendationFormUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	RecommendationFormUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"RecommendationFormLocaleUniqueWhere",
		successImage:"ImageUniqueWhere"
	},
	GenericPageLocaleUniqueWhere:{
		id:"UUID",
		root:"GenericPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		logo:"ImageUniqueWhere",
		image:"ImageUniqueWhere"
	},
	GenericPageUniqueWhere:{
		id:"UUID",
		locales:"GenericPageLocaleUniqueWhere"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		linkables:"ContentReferenceLinkableItemUniqueWhere",
		link:"LinkUniqueWhere",
		media:"MediumListUniqueWhere"
	},
	ContentReferenceLinkableItemUniqueWhere:{
		id:"UUID",
		item:"LinkableUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		redirect:"RedirectUniqueWhere",
		genericPage:"GenericPageLocaleUniqueWhere",
		businessPage:"BusinessPageLocaleUniqueWhere",
		jobPage:"JobPageLocaleUniqueWhere",
		homePage:"HomePageLocaleUniqueWhere",
		homePageLokal:"HomePageLokalLocaleUniqueWhere"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere",
		target:"LinkUniqueWhere"
	},
	BusinessPageLocaleUniqueWhere:{
		id:"UUID",
		root:"BusinessPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		image:"ImageUniqueWhere"
	},
	BusinessPageUniqueWhere:{
		id:"UUID",
		locales:"BusinessPageLocaleUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	JobPageLocaleUniqueWhere:{
		id:"UUID",
		root:"JobPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		image:"ImageUniqueWhere",
		keyInfo:"KeyInfoListUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		mobileImage:"ImageUniqueWhere"
	},
	JobPageUniqueWhere:{
		id:"UUID",
		locales:"JobPageLocaleUniqueWhere"
	},
	KeyInfoListUniqueWhere:{
		id:"UUID",
		items:"KeyInfoItemUniqueWhere"
	},
	KeyInfoItemUniqueWhere:{
		id:"UUID"
	},
	HomePageLocaleUniqueWhere:{
		id:"UUID",
		root:"HomePageUniqueWhere",
		locale:"LocaleUniqueWhere",
		button:"LinkUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		logo:"ImageUniqueWhere",
		siteSwitcher:"SiteSwitcherUniqueWhere",
		ilustration:"GalleryUniqueWhere"
	},
	HomePageUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"HomePageLocaleUniqueWhere",
		theme:"ThemeUniqueWhere"
	},
	ThemeUniqueWhere:{
		id:"UUID"
	},
	SiteSwitcherUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere",
		theme:"ThemeUniqueWhere"
	},
	GalleryUniqueWhere:{
		id:"UUID",
		items:"GalleryItemUniqueWhere"
	},
	GalleryItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	HomePageLokalLocaleUniqueWhere:{
		id:"UUID",
		root:"HomePageLokalUniqueWhere",
		locale:"LocaleUniqueWhere",
		logo:"ImageUniqueWhere",
		button:"LinkUniqueWhere",
		content:"ContentUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		siteSwitcher:"SiteSwitcherUniqueWhere",
		ilustration:"GalleryUniqueWhere"
	},
	HomePageLokalUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"HomePageLokalLocaleUniqueWhere",
		theme:"ThemeUniqueWhere"
	},
	MediumListUniqueWhere:{
		id:"UUID",
		items:"MediumItemUniqueWhere"
	},
	MediumItemUniqueWhere:{
		id:"UUID"
	},
	HeaderLocaleUniqueWhere:{
		id:"UUID",
		root:"HeaderUniqueWhere",
		locale:"LocaleUniqueWhere",
		businessLink:"LinkUniqueWhere",
		lokalLink:"LinkUniqueWhere"
	},
	HeaderUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"HeaderLocaleUniqueWhere",
		logo:"ImageUniqueWhere",
		logoMenu:"ImageUniqueWhere"
	},
	SeoDefaultLocaleUniqueWhere:{
		id:"UUID",
		root:"SeoDefaultUniqueWhere",
		locale:"LocaleUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	SeoDefaultUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"SeoDefaultLocaleUniqueWhere"
	},
	ContractTypeLocaleUniqueWhere:{
		id:"UUID",
		root:"ContractTypeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ContractTypeUniqueWhere:{
		id:"UUID",
		locales:"ContractTypeLocaleUniqueWhere"
	},
	JobCityLocaleUniqueWhere:{
		id:"UUID",
		root:"JobCityUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	JobCityUniqueWhere:{
		id:"UUID",
		locales:"JobCityLocaleUniqueWhere"
	},
	JobPositionLocaleUniqueWhere:{
		id:"UUID",
		root:"JobPositionUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	JobPositionUniqueWhere:{
		id:"UUID",
		locales:"JobPositionLocaleUniqueWhere",
		jobs:"JobPageUniqueWhere"
	},
	BonusBannerLocaleUniqueWhere:{
		id:"UUID",
		root:"BonusBannerUniqueWhere",
		locale:"LocaleUniqueWhere",
		tiles:"TileUniqueWhere",
		logo:"ImageUniqueWhere"
	},
	BonusBannerUniqueWhere:{
		id:"UUID",
		locales:"BonusBannerLocaleUniqueWhere"
	},
	TileUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere",
		ligthBoxMedia:"MediumListUniqueWhere"
	},
	JobsFilterLocaleUniqueWhere:{
		id:"UUID",
		root:"JobsFilterUniqueWhere",
		locale:"LocaleUniqueWhere",
		bannerJobsForm:"JobsFormLocaleUniqueWhere",
		bannerImage:"ImageUniqueWhere",
		noJobImage:"ImageUniqueWhere"
	},
	JobsFilterUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"JobsFilterLocaleUniqueWhere"
	},
	GeneralLocaleUniqueWhere:{
		id:"UUID",
		root:"GeneralUniqueWhere",
		locale:"LocaleUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	GeneralUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"GeneralLocaleUniqueWhere"
	},
	InfoBoxLocaleUniqueWhere:{
		id:"UUID",
		root:"InfoBoxUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	InfoBoxUniqueWhere:{
		id:"UUID",
		logo:"ImageUniqueWhere",
		locales:"InfoBoxLocaleUniqueWhere"
	},
	FooterLocalesByTermsAndConditionsUniqueWhere:{
		termsAndConditions:"LinkUniqueWhere"
	},
	FooterLocalesByBannerImageUniqueWhere:{
		bannerImage:"ImageUniqueWhere"
	},
	FooterLocalesByBannerLinkUniqueWhere:{
		bannerLink:"LinkUniqueWhere"
	},
	FooterLocalesByJobsFormUniqueWhere:{
		jobsForm:"JobsFormLocaleUniqueWhere"
	},
	FooterLocalesByCopyrightLinkUniqueWhere:{
		copyrightLink:"LinkUniqueWhere"
	},
	FooterLocalesByRecommendationFormUniqueWhere:{
		recommendationForm:"RecommendationFormLocaleUniqueWhere"
	},
	FooterLocalesByBannerSecondLinkUniqueWhere:{
		bannerSecondLink:"LinkUniqueWhere"
	},
	FooterLocalesByInstagramLinkUniqueWhere:{
		instagramLink:"LinkUniqueWhere"
	},
	FooterLink:{
		link:{
			filter:"LinkWhere"
		}
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		}
	},
	Linkable:{
		redirect:{
			filter:"RedirectWhere"
		},
		genericPage:{
			filter:"GenericPageLocaleWhere"
		},
		businessPage:{
			filter:"BusinessPageLocaleWhere"
		},
		jobPage:{
			filter:"JobPageLocaleWhere"
		},
		homePage:{
			filter:"HomePageLocaleWhere"
		},
		homePageLokal:{
			filter:"HomePageLokalLocaleWhere"
		}
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkWhere"
		}
	},
	GenericPageLocale:{
		root:{
			filter:"GenericPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		logo:{
			filter:"ImageWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	GenericPage:{
		locales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		theme:{
			filter:"ThemeWhere"
		},
		localesByLocale:{
			by:"GenericPageLocalesByLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByContent:{
			by:"GenericPageLocalesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesBySeo:{
			by:"GenericPageLocalesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByLink:{
			by:"GenericPageLocalesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByLogo:{
			by:"GenericPageLocalesByLogoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByImage:{
			by:"GenericPageLocalesByImageUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		paginateLocales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		}
	},
	GenericPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GenericPageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	GenericPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	GenericPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	GenericPageLocalesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	GenericPageLocalesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByLinkables:{
			by:"ContentBlockReferencesByLinkablesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByLink:{
			by:"ContentBlockReferencesByLinkUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByMedia:{
			by:"ContentBlockReferencesByMediaUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		linkables:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		},
		link:{
			filter:"LinkWhere"
		},
		media:{
			filter:"MediumListWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		carousel:{
			filter:"CarouselWhere"
		},
		bonusBanner:{
			filter:"BonusBannerLocaleWhere"
		},
		sideBySide:{
			filter:"SideBySideWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		video:{
			filter:"VideoWhere"
		},
		linkablesByItem:{
			by:"ContentReferenceLinkablesByItemUniqueWhere",
			filter:"ContentReferenceLinkableItemWhere"
		},
		paginateLinkables:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		}
	},
	ContentReferenceLinkableItem:{
		item:{
			filter:"LinkableWhere"
		},
		reference:{
			filter:"ContentReferenceWhere"
		}
	},
	ContentReferenceLinkableItemOrderBy:{
		id:"OrderDirection",
		item:"LinkableOrderBy",
		reference:"ContentReferenceOrderBy"
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		primaryText:"OrderDirection",
		block:"ContentBlockOrderBy",
		image:"ImageOrderBy",
		secondaryText:"OrderDirection",
		link:"LinkOrderBy",
		media:"MediumListOrderBy",
		textList:"TextListOrderBy",
		carousel:"CarouselOrderBy",
		bonusBanner:"BonusBannerLocaleOrderBy",
		sideBySide:"SideBySideOrderBy",
		gallery:"GalleryOrderBy",
		showBoolean:"OrderDirection",
		isRed:"OrderDirection",
		video:"VideoOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	MediumListOrderBy:{
		id:"OrderDirection"
	},
	TextListOrderBy:{
		id:"OrderDirection"
	},
	CarouselOrderBy:{
		id:"OrderDirection",
		background:"OrderDirection",
		squareImages:"OrderDirection"
	},
	BonusBannerLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		text:"OrderDirection",
		root:"BonusBannerOrderBy",
		locale:"LocaleOrderBy",
		logo:"ImageOrderBy",
		bannerName:"OrderDirection"
	},
	BonusBannerOrderBy:{
		id:"OrderDirection"
	},
	SideBySideOrderBy:{
		id:"OrderDirection",
		background:"OrderDirection",
		alignment:"OrderDirection",
		mediumSize:"OrderDirection"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		videoInfo:"OrderDirection",
		duration:"OrderDirection",
		poster:"ImageOrderBy"
	},
	MediumList:{
		items:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		},
		paginateItems:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		}
	},
	MediumItem:{
		list:{
			filter:"MediumListWhere"
		},
		medium:{
			filter:"MediumWhere"
		}
	},
	Medium:{
		image:{
			filter:"ImageWhere"
		},
		video:{
			filter:"VideoWhere"
		}
	},
	Video:{
		poster:{
			filter:"ImageWhere"
		}
	},
	MediumItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"MediumListOrderBy",
		medium:"MediumOrderBy"
	},
	MediumOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		image:"ImageOrderBy",
		video:"VideoOrderBy"
	},
	TextList:{
		items:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateItems:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		}
	},
	TextItem:{
		list:{
			filter:"TextListWhere"
		}
	},
	TextItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"TextListOrderBy",
		title:"OrderDirection"
	},
	Carousel:{
		items:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		},
		itemsByImage:{
			by:"CarouselItemsByImageUniqueWhere",
			filter:"CarouselItemWhere"
		},
		itemsByLinkUrl:{
			by:"CarouselItemsByLinkUrlUniqueWhere",
			filter:"CarouselItemWhere"
		},
		paginateItems:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		}
	},
	CarouselItem:{
		list:{
			filter:"CarouselWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		linkUrl:{
			filter:"LinkWhere"
		}
	},
	CarouselItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"CarouselOrderBy",
		image:"ImageOrderBy",
		name:"OrderDirection",
		title:"OrderDirection",
		linkUrl:"LinkOrderBy"
	},
	CarouselItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	CarouselItemsByLinkUrlUniqueWhere:{
		linkUrl:"LinkUniqueWhere"
	},
	BonusBannerLocale:{
		root:{
			filter:"BonusBannerWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		tiles:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		},
		logo:{
			filter:"ImageWhere"
		},
		tilesByImage:{
			by:"BonusBannerLocaleTilesByImageUniqueWhere",
			filter:"TileWhere"
		},
		tilesByLigthBoxMedia:{
			by:"BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere",
			filter:"TileWhere"
		},
		paginateTiles:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		}
	},
	BonusBanner:{
		locales:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		localesByLocale:{
			by:"BonusBannerLocalesByLocaleUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		localesByTiles:{
			by:"BonusBannerLocalesByTilesUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		localesByLogo:{
			by:"BonusBannerLocalesByLogoUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		paginateLocales:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		}
	},
	BonusBannerLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BonusBannerLocalesByTilesUniqueWhere:{
		tiles:"TileUniqueWhere"
	},
	BonusBannerLocalesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	Tile:{
		bonusBanner:{
			filter:"BonusBannerLocaleWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		ligthBoxMedia:{
			filter:"MediumListWhere"
		},
		lightBoxForm:{
			filter:"JobsFormLocaleWhere"
		}
	},
	JobsFormLocale:{
		root:{
			filter:"JobsFormWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		imageTextList:{
			filter:"ImageTextListWhere"
		},
		logo:{
			filter:"ImageWhere"
		},
		successImage:{
			filter:"ImageWhere"
		}
	},
	JobsForm:{
		locales:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		fileIlustration:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"JobsFormLocalesByLocaleUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		localesByImageTextList:{
			by:"JobsFormLocalesByImageTextListUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		localesByLogo:{
			by:"JobsFormLocalesByLogoUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		localesBySuccessImage:{
			by:"JobsFormLocalesBySuccessImageUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		paginateLocales:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		}
	},
	JobsFormLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobsFormLocalesByImageTextListUniqueWhere:{
		imageTextList:"ImageTextListUniqueWhere"
	},
	JobsFormLocalesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	JobsFormLocalesBySuccessImageUniqueWhere:{
		successImage:"ImageUniqueWhere"
	},
	ImageTextList:{
		items:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		},
		itemsByImage:{
			by:"ImageTextListItemsByImageUniqueWhere",
			filter:"ImageTextItemWhere"
		},
		paginateItems:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		}
	},
	ImageTextItem:{
		list:{
			filter:"ImageTextListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageTextItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		list:"ImageTextListOrderBy",
		image:"ImageOrderBy"
	},
	ImageTextListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	TileOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		bonusBanner:"BonusBannerLocaleOrderBy",
		image:"ImageOrderBy",
		lightBoxTitle:"OrderDirection",
		lightBoxText:"OrderDirection",
		ligthBoxMedia:"MediumListOrderBy",
		lightBoxSectionTitle:"OrderDirection",
		title:"OrderDirection",
		lightBoxLinkLabel:"OrderDirection",
		lightBoxForm:"JobsFormLocaleOrderBy"
	},
	BonusBannerLocaleTilesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere:{
		ligthBoxMedia:"MediumListUniqueWhere"
	},
	SideBySide:{
		items:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		},
		itemsByMedia:{
			by:"SideBySideItemsByMediaUniqueWhere",
			filter:"SideBySideItemWhere"
		},
		paginateItems:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		}
	},
	SideBySideItem:{
		list:{
			filter:"SideBySideWhere"
		},
		media:{
			filter:"MediumListWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		jobsForm:{
			filter:"JobsFormLocaleWhere"
		}
	},
	SideBySideItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		text:"OrderDirection",
		list:"SideBySideOrderBy",
		media:"MediumListOrderBy",
		formButtonLabel:"OrderDirection",
		type:"OrderDirection",
		link:"LinkOrderBy",
		jobsForm:"JobsFormLocaleOrderBy"
	},
	SideBySideItemsByMediaUniqueWhere:{
		media:"MediumListUniqueWhere"
	},
	Gallery:{
		items:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		itemsByImage:{
			by:"GalleryItemsByImageUniqueWhere",
			filter:"GalleryItemWhere"
		},
		paginateItems:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		}
	},
	GalleryItem:{
		list:{
			filter:"GalleryWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	GalleryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"GalleryOrderBy",
		image:"ImageOrderBy"
	},
	GalleryItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	ContentReferenceLinkablesByItemUniqueWhere:{
		item:"LinkableUniqueWhere"
	},
	ContentBlockReferencesByLinkablesUniqueWhere:{
		linkables:"ContentReferenceLinkableItemUniqueWhere"
	},
	ContentBlockReferencesByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	ContentBlockReferencesByMediaUniqueWhere:{
		media:"MediumListUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	BusinessPageLocale:{
		root:{
			filter:"BusinessPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		stickyBar:{
			filter:"StickyBarWhere"
		},
		infoBox:{
			filter:"InfoBoxWhere"
		}
	},
	BusinessPage:{
		locales:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		theme:{
			filter:"ThemeWhere"
		},
		localesByLocale:{
			by:"BusinessPageLocalesByLocaleUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		localesByContent:{
			by:"BusinessPageLocalesByContentUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		localesBySeo:{
			by:"BusinessPageLocalesBySeoUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		localesByLink:{
			by:"BusinessPageLocalesByLinkUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		localesByImage:{
			by:"BusinessPageLocalesByImageUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		paginateLocales:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		}
	},
	BusinessPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BusinessPageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	BusinessPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	BusinessPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	BusinessPageLocalesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	StickyBar:{
		link:{
			filter:"LinkWhere"
		},
		jobsForm:{
			filter:"JobsFormLocaleWhere"
		}
	},
	InfoBox:{
		logo:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		},
		localesByLocale:{
			by:"InfoBoxLocalesByLocaleUniqueWhere",
			filter:"InfoBoxLocaleWhere"
		},
		paginateLocales:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		}
	},
	InfoBoxLocale:{
		root:{
			filter:"InfoBoxWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		openingHours:{
			filter:"TextListWhere"
		}
	},
	InfoBoxLocaleOrderBy:{
		id:"OrderDirection",
		address:"OrderDirection",
		web:"OrderDirection",
		instagram:"OrderDirection",
		facebook:"OrderDirection",
		root:"InfoBoxOrderBy",
		locale:"LocaleOrderBy",
		openingHours:"TextListOrderBy",
		addressLink:"OrderDirection"
	},
	InfoBoxLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobPageLocale:{
		root:{
			filter:"JobPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		keyInfo:{
			filter:"KeyInfoListWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		stickyBar:{
			filter:"StickyBarWhere"
		},
		mobileImage:{
			filter:"ImageWhere"
		}
	},
	JobPage:{
		locales:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		theme:{
			filter:"ThemeWhere"
		},
		contractType:{
			filter:"ContractTypeWhere"
		},
		jobCity:{
			filter:"JobCityWhere"
		},
		business:{
			filter:"BusinessPageWhere"
		},
		jobPosition:{
			filter:"JobPositionWhere"
		},
		localesByLocale:{
			by:"JobPageLocalesByLocaleUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesByImage:{
			by:"JobPageLocalesByImageUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesByKeyInfo:{
			by:"JobPageLocalesByKeyInfoUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesByContent:{
			by:"JobPageLocalesByContentUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesBySeo:{
			by:"JobPageLocalesBySeoUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesByLink:{
			by:"JobPageLocalesByLinkUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		localesByMobileImage:{
			by:"JobPageLocalesByMobileImageUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		paginateLocales:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		}
	},
	ContractType:{
		locales:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		localesByLocale:{
			by:"ContractTypeLocalesByLocaleUniqueWhere",
			filter:"ContractTypeLocaleWhere"
		},
		paginateLocales:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		}
	},
	ContractTypeLocale:{
		root:{
			filter:"ContractTypeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ContractTypeLocaleOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		root:"ContractTypeOrderBy",
		locale:"LocaleOrderBy",
		slug:"OrderDirection"
	},
	ContractTypeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobCity:{
		locales:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		localesByLocale:{
			by:"JobCityLocalesByLocaleUniqueWhere",
			filter:"JobCityLocaleWhere"
		},
		paginateLocales:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		}
	},
	JobCityLocale:{
		root:{
			filter:"JobCityWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	JobCityLocaleOrderBy:{
		id:"OrderDirection",
		root:"JobCityOrderBy",
		locale:"LocaleOrderBy",
		name:"OrderDirection",
		slug:"OrderDirection"
	},
	JobCityLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobPosition:{
		locales:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		jobs:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		},
		localesByLocale:{
			by:"JobPositionLocalesByLocaleUniqueWhere",
			filter:"JobPositionLocaleWhere"
		},
		jobsByLocales:{
			by:"JobPositionJobsByLocalesUniqueWhere",
			filter:"JobPageWhere"
		},
		paginateLocales:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		paginateJobs:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		}
	},
	JobPositionLocale:{
		root:{
			filter:"JobPositionWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	JobPositionLocaleOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		root:"JobPositionOrderBy",
		locale:"LocaleOrderBy",
		slug:"OrderDirection"
	},
	JobPositionLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobPositionJobsByLocalesUniqueWhere:{
		locales:"JobPageLocaleUniqueWhere"
	},
	JobPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobPageLocalesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	JobPageLocalesByKeyInfoUniqueWhere:{
		keyInfo:"KeyInfoListUniqueWhere"
	},
	JobPageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	JobPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	JobPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	JobPageLocalesByMobileImageUniqueWhere:{
		mobileImage:"ImageUniqueWhere"
	},
	KeyInfoList:{
		items:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		},
		paginateItems:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		}
	},
	KeyInfoItem:{
		list:{
			filter:"KeyInfoListWhere"
		},
		icon:{
			filter:"IconWhere"
		}
	},
	Icon:{
		icon:{
			filter:"ImageWhere"
		}
	},
	KeyInfoItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		keyInfo:"OrderDirection",
		list:"KeyInfoListOrderBy",
		icon:"IconOrderBy"
	},
	IconOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		icon:"ImageOrderBy"
	},
	HomePageLocale:{
		root:{
			filter:"HomePageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		button:{
			filter:"LinkWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		logo:{
			filter:"ImageWhere"
		},
		siteSwitcher:{
			filter:"SiteSwitcherWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		stickyBar:{
			filter:"StickyBarWhere"
		},
		ilustration:{
			filter:"GalleryWhere"
		}
	},
	HomePage:{
		locales:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		theme:{
			filter:"ThemeWhere"
		},
		localesByLocale:{
			by:"HomePageLocalesByLocaleUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesByButton:{
			by:"HomePageLocalesByButtonUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesByContent:{
			by:"HomePageLocalesByContentUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesBySeo:{
			by:"HomePageLocalesBySeoUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesByLink:{
			by:"HomePageLocalesByLinkUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesByLogo:{
			by:"HomePageLocalesByLogoUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesBySiteSwitcher:{
			by:"HomePageLocalesBySiteSwitcherUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		localesByIlustration:{
			by:"HomePageLocalesByIlustrationUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		paginateLocales:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		}
	},
	HomePageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	HomePageLocalesByButtonUniqueWhere:{
		button:"LinkUniqueWhere"
	},
	HomePageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	HomePageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	HomePageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	HomePageLocalesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	HomePageLocalesBySiteSwitcherUniqueWhere:{
		siteSwitcher:"SiteSwitcherUniqueWhere"
	},
	HomePageLocalesByIlustrationUniqueWhere:{
		ilustration:"GalleryUniqueWhere"
	},
	SiteSwitcher:{
		link:{
			filter:"LinkWhere"
		},
		theme:{
			filter:"ThemeWhere"
		}
	},
	HomePageLokalLocale:{
		root:{
			filter:"HomePageLokalWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		logo:{
			filter:"ImageWhere"
		},
		button:{
			filter:"LinkWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		siteSwitcher:{
			filter:"SiteSwitcherWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		stickyBar:{
			filter:"StickyBarWhere"
		},
		ilustration:{
			filter:"GalleryWhere"
		}
	},
	HomePageLokal:{
		locales:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		theme:{
			filter:"ThemeWhere"
		},
		localesByLocale:{
			by:"HomePageLokalLocalesByLocaleUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesByLogo:{
			by:"HomePageLokalLocalesByLogoUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesByButton:{
			by:"HomePageLokalLocalesByButtonUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesByContent:{
			by:"HomePageLokalLocalesByContentUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesBySeo:{
			by:"HomePageLokalLocalesBySeoUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesByLink:{
			by:"HomePageLokalLocalesByLinkUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesBySiteSwitcher:{
			by:"HomePageLokalLocalesBySiteSwitcherUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		localesByIlustration:{
			by:"HomePageLokalLocalesByIlustrationUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		paginateLocales:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		}
	},
	HomePageLokalLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	HomePageLokalLocalesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	HomePageLokalLocalesByButtonUniqueWhere:{
		button:"LinkUniqueWhere"
	},
	HomePageLokalLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	HomePageLokalLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	HomePageLokalLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	HomePageLokalLocalesBySiteSwitcherUniqueWhere:{
		siteSwitcher:"SiteSwitcherUniqueWhere"
	},
	HomePageLokalLocalesByIlustrationUniqueWhere:{
		ilustration:"GalleryUniqueWhere"
	},
	FooterLinkOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		link:"LinkOrderBy"
	},
	RecommendationFormLocale:{
		root:{
			filter:"RecommendationFormWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	RecommendationForm:{
		locales:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		successImage:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"RecommendationFormLocalesByLocaleUniqueWhere",
			filter:"RecommendationFormLocaleWhere"
		},
		paginateLocales:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		}
	},
	RecommendationFormLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	HeaderLocale:{
		root:{
			filter:"HeaderWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		business:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		lokalEnterprise:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		businessLink:{
			filter:"LinkWhere"
		},
		lokalLink:{
			filter:"LinkWhere"
		},
		paginateBusiness:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		paginateLokalEnterprise:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		}
	},
	Header:{
		locales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		logo:{
			filter:"ImageWhere"
		},
		logoMenu:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"HeaderLocalesByLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		localesByBusinessLink:{
			by:"HeaderLocalesByBusinessLinkUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		localesByLokalLink:{
			by:"HeaderLocalesByLokalLinkUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		paginateLocales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		}
	},
	HeaderLocaleOrderBy:{
		id:"OrderDirection",
		root:"HeaderOrderBy",
		locale:"LocaleOrderBy",
		menuLabel:"OrderDirection",
		menuTitle:"OrderDirection",
		menuText:"OrderDirection",
		businessSectionTitle:"OrderDirection",
		lokalSectionTitle:"OrderDirection",
		businessLink:"LinkOrderBy",
		lokalLink:"LinkOrderBy"
	},
	HeaderOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		logo:"ImageOrderBy",
		logoMenu:"ImageOrderBy"
	},
	HeaderLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	HeaderLocalesByBusinessLinkUniqueWhere:{
		businessLink:"LinkUniqueWhere"
	},
	HeaderLocalesByLokalLinkUniqueWhere:{
		lokalLink:"LinkUniqueWhere"
	},
	Business:{
		logo:{
			filter:"ImageWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	BusinessOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		logo:"ImageOrderBy",
		link:"LinkOrderBy"
	},
	SeoDefaultLocale:{
		root:{
			filter:"SeoDefaultWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	SeoDefault:{
		locales:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		localesByLocale:{
			by:"SeoDefaultLocalesByLocaleUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		localesBySeo:{
			by:"SeoDefaultLocalesBySeoUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		paginateLocales:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		}
	},
	SeoDefaultLocaleOrderBy:{
		id:"OrderDirection",
		root:"SeoDefaultOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy"
	},
	SeoDefaultOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	SeoDefaultLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SeoDefaultLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	JobsFilterLocale:{
		root:{
			filter:"JobsFilterWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		bannerJobsForm:{
			filter:"JobsFormLocaleWhere"
		},
		bannerImage:{
			filter:"ImageWhere"
		},
		noJobImage:{
			filter:"ImageWhere"
		}
	},
	JobsFilter:{
		locales:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		localesByLocale:{
			by:"JobsFilterLocalesByLocaleUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		localesByBannerJobsForm:{
			by:"JobsFilterLocalesByBannerJobsFormUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		localesByBannerImage:{
			by:"JobsFilterLocalesByBannerImageUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		localesByNoJobImage:{
			by:"JobsFilterLocalesByNoJobImageUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		paginateLocales:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		}
	},
	JobsFilterLocaleOrderBy:{
		id:"OrderDirection",
		jobPositionLabel:"OrderDirection",
		jobContractLabel:"OrderDirection",
		jobCityLabel:"OrderDirection",
		root:"JobsFilterOrderBy",
		locale:"LocaleOrderBy",
		bannerTitle:"OrderDirection",
		bannerFormLabel:"OrderDirection",
		bannerJobsForm:"JobsFormLocaleOrderBy",
		bannerImage:"ImageOrderBy",
		noJobTitle:"OrderDirection",
		noJobBtnLabel:"OrderDirection",
		noJobImage:"ImageOrderBy",
		noJobTitleForSpecificBusiness:"OrderDirection",
		deactivatedJob:"OrderDirection"
	},
	JobsFilterOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	JobsFilterLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	JobsFilterLocalesByBannerJobsFormUniqueWhere:{
		bannerJobsForm:"JobsFormLocaleUniqueWhere"
	},
	JobsFilterLocalesByBannerImageUniqueWhere:{
		bannerImage:"ImageUniqueWhere"
	},
	JobsFilterLocalesByNoJobImageUniqueWhere:{
		noJobImage:"ImageUniqueWhere"
	},
	GeneralLocaleOrderBy:{
		id:"OrderDirection",
		root:"GeneralOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy"
	},
	GeneralOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	LocaleFootersByRootUniqueWhere:{
		root:"FooterUniqueWhere"
	},
	LocaleFootersByTermsAndConditionsUniqueWhere:{
		termsAndConditions:"LinkUniqueWhere"
	},
	LocaleFootersByBannerImageUniqueWhere:{
		bannerImage:"ImageUniqueWhere"
	},
	LocaleFootersByBannerLinkUniqueWhere:{
		bannerLink:"LinkUniqueWhere"
	},
	LocaleFootersByJobsFormUniqueWhere:{
		jobsForm:"JobsFormLocaleUniqueWhere"
	},
	LocaleFootersByCopyrightLinkUniqueWhere:{
		copyrightLink:"LinkUniqueWhere"
	},
	LocaleFootersByRecommendationFormUniqueWhere:{
		recommendationForm:"RecommendationFormLocaleUniqueWhere"
	},
	LocaleFootersByBannerSecondLinkUniqueWhere:{
		bannerSecondLink:"LinkUniqueWhere"
	},
	LocaleFootersByInstagramLinkUniqueWhere:{
		instagramLink:"LinkUniqueWhere"
	},
	LocaleGenericPagesByRootUniqueWhere:{
		root:"GenericPageUniqueWhere"
	},
	LocaleGenericPagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleGenericPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleGenericPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleGenericPagesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	LocaleGenericPagesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	LocaleHeadersByRootUniqueWhere:{
		root:"HeaderUniqueWhere"
	},
	LocaleHeadersByBusinessLinkUniqueWhere:{
		businessLink:"LinkUniqueWhere"
	},
	LocaleHeadersByLokalLinkUniqueWhere:{
		lokalLink:"LinkUniqueWhere"
	},
	LocaleSeoDefaultsByRootUniqueWhere:{
		root:"SeoDefaultUniqueWhere"
	},
	LocaleSeoDefaultsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleBusinessPagesByRootUniqueWhere:{
		root:"BusinessPageUniqueWhere"
	},
	LocaleBusinessPagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleBusinessPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleBusinessPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleBusinessPagesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	LocaleJobPagesByRootUniqueWhere:{
		root:"JobPageUniqueWhere"
	},
	LocaleJobPagesByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	LocaleJobPagesByKeyInfoUniqueWhere:{
		keyInfo:"KeyInfoListUniqueWhere"
	},
	LocaleJobPagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleJobPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleJobPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleJobPagesByMobileImageUniqueWhere:{
		mobileImage:"ImageUniqueWhere"
	},
	LocaleContractTypesByRootUniqueWhere:{
		root:"ContractTypeUniqueWhere"
	},
	LocaleContractTypesBySlugUniqueWhere:{

	},
	LocaleJobCitiesByRootUniqueWhere:{
		root:"JobCityUniqueWhere"
	},
	LocaleJobCitiesBySlugUniqueWhere:{

	},
	LocaleJobPositionsByRootUniqueWhere:{
		root:"JobPositionUniqueWhere"
	},
	LocaleJobPositionsBySlugUniqueWhere:{

	},
	LocaleHomePagesByRootUniqueWhere:{
		root:"HomePageUniqueWhere"
	},
	LocaleHomePagesByButtonUniqueWhere:{
		button:"LinkUniqueWhere"
	},
	LocaleHomePagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleHomePagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleHomePagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleHomePagesByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	LocaleHomePagesBySiteSwitcherUniqueWhere:{
		siteSwitcher:"SiteSwitcherUniqueWhere"
	},
	LocaleHomePagesByIlustrationUniqueWhere:{
		ilustration:"GalleryUniqueWhere"
	},
	LocaleBonusBannersByRootUniqueWhere:{
		root:"BonusBannerUniqueWhere"
	},
	LocaleBonusBannersByTilesUniqueWhere:{
		tiles:"TileUniqueWhere"
	},
	LocaleBonusBannersByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	LocaleHomePageLokalsByRootUniqueWhere:{
		root:"HomePageLokalUniqueWhere"
	},
	LocaleHomePageLokalsByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	LocaleHomePageLokalsByButtonUniqueWhere:{
		button:"LinkUniqueWhere"
	},
	LocaleHomePageLokalsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleHomePageLokalsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleHomePageLokalsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleHomePageLokalsBySiteSwitcherUniqueWhere:{
		siteSwitcher:"SiteSwitcherUniqueWhere"
	},
	LocaleHomePageLokalsByIlustrationUniqueWhere:{
		ilustration:"GalleryUniqueWhere"
	},
	LocaleJobsFormsByRootUniqueWhere:{
		root:"JobsFormUniqueWhere"
	},
	LocaleJobsFormsByImageTextListUniqueWhere:{
		imageTextList:"ImageTextListUniqueWhere"
	},
	LocaleJobsFormsByLogoUniqueWhere:{
		logo:"ImageUniqueWhere"
	},
	LocaleJobsFormsBySuccessImageUniqueWhere:{
		successImage:"ImageUniqueWhere"
	},
	LocaleJobsFiltersByRootUniqueWhere:{
		root:"JobsFilterUniqueWhere"
	},
	LocaleJobsFiltersByBannerJobsFormUniqueWhere:{
		bannerJobsForm:"JobsFormLocaleUniqueWhere"
	},
	LocaleJobsFiltersByBannerImageUniqueWhere:{
		bannerImage:"ImageUniqueWhere"
	},
	LocaleJobsFiltersByNoJobImageUniqueWhere:{
		noJobImage:"ImageUniqueWhere"
	},
	LocaleGeneralByRootUniqueWhere:{
		root:"GeneralUniqueWhere"
	},
	LocaleGeneralBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleRecommendationFormsByRootUniqueWhere:{
		root:"RecommendationFormUniqueWhere"
	},
	LocaleInfoBoxesByRootUniqueWhere:{
		root:"InfoBoxUniqueWhere"
	},
	GeneralLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GeneralLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	FooterLinkUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	BusinessUniqueWhere:{
		id:"UUID",
		logo:"ImageUniqueWhere",
		link:"LinkUniqueWhere"
	},
	IconUniqueWhere:{
		id:"UUID",
		icon:"ImageUniqueWhere"
	},
	MediumUniqueWhere:{
		id:"UUID"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	TextItemUniqueWhere:{
		id:"UUID"
	},
	TextListUniqueWhere:{
		id:"UUID",
		items:"TextItemUniqueWhere"
	},
	CarouselUniqueWhere:{
		id:"UUID",
		items:"CarouselItemUniqueWhere"
	},
	CarouselItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere",
		linkUrl:"LinkUniqueWhere"
	},
	SideBySideUniqueWhere:{
		id:"UUID",
		items:"SideBySideItemUniqueWhere"
	},
	SideBySideItemUniqueWhere:{
		id:"UUID",
		media:"MediumListUniqueWhere"
	},
	FileUniqueWhere:{
		id:"UUID"
	},
	FileWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		size:"IntCondition",
		type:"StringCondition",
		and:"FileWhere",
		or:"FileWhere",
		not:"FileWhere"
	},
	FileOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection"
	},
	FileCreateInput:{

	},
	JobFormSubscriptionCreateInput:{
		createdAt:"DateTime",
		file:"JobFormSubscriptionCreateFileEntityRelationInput",
		job:"JobFormSubscriptionCreateJobEntityRelationInput",
		status:"JobFormSubscriptionCreateStatusEntityRelationInput"
	},
	JobFormSubscriptionCreateFileEntityRelationInput:{
		connect:"FileUniqueWhere",
		create:"FileCreateInput",
		connectOrCreate:"JobFormSubscriptionConnectOrCreateFileRelationInput"
	},
	JobFormSubscriptionConnectOrCreateFileRelationInput:{
		connect:"FileUniqueWhere",
		create:"FileCreateInput"
	},
	JobFormSubscriptionCreateJobEntityRelationInput:{
		connect:"JobPageLocaleUniqueWhere"
	},
	JobFormSubscriptionCreateStatusEntityRelationInput:{
		connect:"JobFormSubscriptionStatusUniqueWhere"
	},
	JobFormSubscriptionStatusUniqueWhere:{
		id:"UUID"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"ImageListItemsByImageUniqueWhere",
			filter:"ImageListItemWhere"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ImageListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	JobFormSubscriptionStatusWhere:{
		id:"UUIDCondition",
		statusTitle:"StringCondition",
		statusColor:"StringCondition",
		and:"JobFormSubscriptionStatusWhere",
		or:"JobFormSubscriptionStatusWhere",
		not:"JobFormSubscriptionStatusWhere"
	},
	JobFormSubscriptionStatusOrderBy:{
		id:"OrderDirection",
		statusTitle:"OrderDirection",
		statusColor:"OrderDirection"
	},
	StickyBarUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	StickyBarCreateInput:{
		link:"StickyBarCreateLinkEntityRelationInput",
		jobsForm:"StickyBarCreateJobsFormEntityRelationInput"
	},
	StickyBarCreateLinkEntityRelationInput:{
		connect:"LinkUniqueWhere"
	},
	StickyBarCreateJobsFormEntityRelationInput:{
		connect:"JobsFormLocaleUniqueWhere"
	},
	RecommendationFormSubscriptionCreateInput:{
		createdAt:"DateTime"
	},
	InfoBoxCreateInput:{
		logoSize:"LogoSize",
		logo:"InfoBoxCreateLogoEntityRelationInput"
	},
	InfoBoxCreateLogoEntityRelationInput:{
		connect:"ImageUniqueWhere"
	},
	QueryTransaction:{
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getContentReferenceLinkableItem:{
			by:"ContentReferenceLinkableItemUniqueWhere",
			filter:"ContentReferenceLinkableItemWhere"
		},
		listContentReferenceLinkableItem:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		},
		paginateContentReferenceLinkableItem:{
			filter:"ContentReferenceLinkableItemWhere",
			orderBy:"ContentReferenceLinkableItemOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterLink:{
			by:"FooterLinkUniqueWhere",
			filter:"FooterLinkWhere"
		},
		listFooterLink:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		paginateFooterLink:{
			filter:"FooterLinkWhere",
			orderBy:"FooterLinkOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getSeoDefault:{
			by:"SeoDefaultUniqueWhere",
			filter:"SeoDefaultWhere"
		},
		listSeoDefault:{
			filter:"SeoDefaultWhere",
			orderBy:"SeoDefaultOrderBy"
		},
		paginateSeoDefault:{
			filter:"SeoDefaultWhere",
			orderBy:"SeoDefaultOrderBy"
		},
		getSeoDefaultLocale:{
			by:"SeoDefaultLocaleUniqueWhere",
			filter:"SeoDefaultLocaleWhere"
		},
		listSeoDefaultLocale:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		paginateSeoDefaultLocale:{
			filter:"SeoDefaultLocaleWhere",
			orderBy:"SeoDefaultLocaleOrderBy"
		},
		getBusiness:{
			by:"BusinessUniqueWhere",
			filter:"BusinessWhere"
		},
		listBusiness:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		paginateBusiness:{
			filter:"BusinessWhere",
			orderBy:"BusinessOrderBy"
		},
		getBusinessPage:{
			by:"BusinessPageUniqueWhere",
			filter:"BusinessPageWhere"
		},
		listBusinessPage:{
			filter:"BusinessPageWhere",
			orderBy:"BusinessPageOrderBy"
		},
		paginateBusinessPage:{
			filter:"BusinessPageWhere",
			orderBy:"BusinessPageOrderBy"
		},
		getBusinessPageLocale:{
			by:"BusinessPageLocaleUniqueWhere",
			filter:"BusinessPageLocaleWhere"
		},
		listBusinessPageLocale:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		paginateBusinessPageLocale:{
			filter:"BusinessPageLocaleWhere",
			orderBy:"BusinessPageLocaleOrderBy"
		},
		getIcon:{
			by:"IconUniqueWhere",
			filter:"IconWhere"
		},
		listIcon:{
			filter:"IconWhere",
			orderBy:"IconOrderBy"
		},
		paginateIcon:{
			filter:"IconWhere",
			orderBy:"IconOrderBy"
		},
		getJobPage:{
			by:"JobPageUniqueWhere",
			filter:"JobPageWhere"
		},
		listJobPage:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		},
		paginateJobPage:{
			filter:"JobPageWhere",
			orderBy:"JobPageOrderBy"
		},
		getJobPageLocale:{
			by:"JobPageLocaleUniqueWhere",
			filter:"JobPageLocaleWhere"
		},
		listJobPageLocale:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		paginateJobPageLocale:{
			filter:"JobPageLocaleWhere",
			orderBy:"JobPageLocaleOrderBy"
		},
		getKeyInfoItem:{
			by:"KeyInfoItemUniqueWhere",
			filter:"KeyInfoItemWhere"
		},
		listKeyInfoItem:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		},
		paginateKeyInfoItem:{
			filter:"KeyInfoItemWhere",
			orderBy:"KeyInfoItemOrderBy"
		},
		getKeyInfoList:{
			by:"KeyInfoListUniqueWhere",
			filter:"KeyInfoListWhere"
		},
		listKeyInfoList:{
			filter:"KeyInfoListWhere",
			orderBy:"KeyInfoListOrderBy"
		},
		paginateKeyInfoList:{
			filter:"KeyInfoListWhere",
			orderBy:"KeyInfoListOrderBy"
		},
		getContractType:{
			by:"ContractTypeUniqueWhere",
			filter:"ContractTypeWhere"
		},
		listContractType:{
			filter:"ContractTypeWhere",
			orderBy:"ContractTypeOrderBy"
		},
		paginateContractType:{
			filter:"ContractTypeWhere",
			orderBy:"ContractTypeOrderBy"
		},
		getContractTypeLocale:{
			by:"ContractTypeLocaleUniqueWhere",
			filter:"ContractTypeLocaleWhere"
		},
		listContractTypeLocale:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		paginateContractTypeLocale:{
			filter:"ContractTypeLocaleWhere",
			orderBy:"ContractTypeLocaleOrderBy"
		},
		getJobCity:{
			by:"JobCityUniqueWhere",
			filter:"JobCityWhere"
		},
		listJobCity:{
			filter:"JobCityWhere",
			orderBy:"JobCityOrderBy"
		},
		paginateJobCity:{
			filter:"JobCityWhere",
			orderBy:"JobCityOrderBy"
		},
		getJobCityLocale:{
			by:"JobCityLocaleUniqueWhere",
			filter:"JobCityLocaleWhere"
		},
		listJobCityLocale:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		paginateJobCityLocale:{
			filter:"JobCityLocaleWhere",
			orderBy:"JobCityLocaleOrderBy"
		},
		getJobPosition:{
			by:"JobPositionUniqueWhere",
			filter:"JobPositionWhere"
		},
		listJobPosition:{
			filter:"JobPositionWhere",
			orderBy:"JobPositionOrderBy"
		},
		paginateJobPosition:{
			filter:"JobPositionWhere",
			orderBy:"JobPositionOrderBy"
		},
		getJobPositionLocale:{
			by:"JobPositionLocaleUniqueWhere",
			filter:"JobPositionLocaleWhere"
		},
		listJobPositionLocale:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		paginateJobPositionLocale:{
			filter:"JobPositionLocaleWhere",
			orderBy:"JobPositionLocaleOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getHomePage:{
			by:"HomePageUniqueWhere",
			filter:"HomePageWhere"
		},
		listHomePage:{
			filter:"HomePageWhere",
			orderBy:"HomePageOrderBy"
		},
		paginateHomePage:{
			filter:"HomePageWhere",
			orderBy:"HomePageOrderBy"
		},
		getHomePageLocale:{
			by:"HomePageLocaleUniqueWhere",
			filter:"HomePageLocaleWhere"
		},
		listHomePageLocale:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		paginateHomePageLocale:{
			filter:"HomePageLocaleWhere",
			orderBy:"HomePageLocaleOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getMediumItem:{
			by:"MediumItemUniqueWhere",
			filter:"MediumItemWhere"
		},
		listMediumItem:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		},
		paginateMediumItem:{
			filter:"MediumItemWhere",
			orderBy:"MediumItemOrderBy"
		},
		getMediumList:{
			by:"MediumListUniqueWhere",
			filter:"MediumListWhere"
		},
		listMediumList:{
			filter:"MediumListWhere",
			orderBy:"MediumListOrderBy"
		},
		paginateMediumList:{
			filter:"MediumListWhere",
			orderBy:"MediumListOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getCarousel:{
			by:"CarouselUniqueWhere",
			filter:"CarouselWhere"
		},
		listCarousel:{
			filter:"CarouselWhere",
			orderBy:"CarouselOrderBy"
		},
		paginateCarousel:{
			filter:"CarouselWhere",
			orderBy:"CarouselOrderBy"
		},
		getCarouselItem:{
			by:"CarouselItemUniqueWhere",
			filter:"CarouselItemWhere"
		},
		listCarouselItem:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		},
		paginateCarouselItem:{
			filter:"CarouselItemWhere",
			orderBy:"CarouselItemOrderBy"
		},
		getTheme:{
			by:"ThemeUniqueWhere",
			filter:"ThemeWhere"
		},
		listTheme:{
			filter:"ThemeWhere",
			orderBy:"ThemeOrderBy"
		},
		paginateTheme:{
			filter:"ThemeWhere",
			orderBy:"ThemeOrderBy"
		},
		getBonusBanner:{
			by:"BonusBannerUniqueWhere",
			filter:"BonusBannerWhere"
		},
		listBonusBanner:{
			filter:"BonusBannerWhere",
			orderBy:"BonusBannerOrderBy"
		},
		paginateBonusBanner:{
			filter:"BonusBannerWhere",
			orderBy:"BonusBannerOrderBy"
		},
		getBonusBannerLocale:{
			by:"BonusBannerLocaleUniqueWhere",
			filter:"BonusBannerLocaleWhere"
		},
		listBonusBannerLocale:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		paginateBonusBannerLocale:{
			filter:"BonusBannerLocaleWhere",
			orderBy:"BonusBannerLocaleOrderBy"
		},
		getTile:{
			by:"TileUniqueWhere",
			filter:"TileWhere"
		},
		listTile:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		},
		paginateTile:{
			filter:"TileWhere",
			orderBy:"TileOrderBy"
		},
		getSideBySide:{
			by:"SideBySideUniqueWhere",
			filter:"SideBySideWhere"
		},
		listSideBySide:{
			filter:"SideBySideWhere",
			orderBy:"SideBySideOrderBy"
		},
		paginateSideBySide:{
			filter:"SideBySideWhere",
			orderBy:"SideBySideOrderBy"
		},
		getSideBySideItem:{
			by:"SideBySideItemUniqueWhere",
			filter:"SideBySideItemWhere"
		},
		listSideBySideItem:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		},
		paginateSideBySideItem:{
			filter:"SideBySideItemWhere",
			orderBy:"SideBySideItemOrderBy"
		},
		getHomePageLokal:{
			by:"HomePageLokalUniqueWhere",
			filter:"HomePageLokalWhere"
		},
		listHomePageLokal:{
			filter:"HomePageLokalWhere",
			orderBy:"HomePageLokalOrderBy"
		},
		paginateHomePageLokal:{
			filter:"HomePageLokalWhere",
			orderBy:"HomePageLokalOrderBy"
		},
		getHomePageLokalLocale:{
			by:"HomePageLokalLocaleUniqueWhere",
			filter:"HomePageLokalLocaleWhere"
		},
		listHomePageLokalLocale:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		paginateHomePageLokalLocale:{
			filter:"HomePageLokalLocaleWhere",
			orderBy:"HomePageLokalLocaleOrderBy"
		},
		getSiteSwitcher:{
			by:"SiteSwitcherUniqueWhere",
			filter:"SiteSwitcherWhere"
		},
		listSiteSwitcher:{
			filter:"SiteSwitcherWhere",
			orderBy:"SiteSwitcherOrderBy"
		},
		paginateSiteSwitcher:{
			filter:"SiteSwitcherWhere",
			orderBy:"SiteSwitcherOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getJobsForm:{
			by:"JobsFormUniqueWhere",
			filter:"JobsFormWhere"
		},
		listJobsForm:{
			filter:"JobsFormWhere",
			orderBy:"JobsFormOrderBy"
		},
		paginateJobsForm:{
			filter:"JobsFormWhere",
			orderBy:"JobsFormOrderBy"
		},
		getJobsFormLocale:{
			by:"JobsFormLocaleUniqueWhere",
			filter:"JobsFormLocaleWhere"
		},
		listJobsFormLocale:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		paginateJobsFormLocale:{
			filter:"JobsFormLocaleWhere",
			orderBy:"JobsFormLocaleOrderBy"
		},
		getImageTextItem:{
			by:"ImageTextItemUniqueWhere",
			filter:"ImageTextItemWhere"
		},
		listImageTextItem:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		},
		paginateImageTextItem:{
			filter:"ImageTextItemWhere",
			orderBy:"ImageTextItemOrderBy"
		},
		getImageTextList:{
			by:"ImageTextListUniqueWhere",
			filter:"ImageTextListWhere"
		},
		listImageTextList:{
			filter:"ImageTextListWhere",
			orderBy:"ImageTextListOrderBy"
		},
		paginateImageTextList:{
			filter:"ImageTextListWhere",
			orderBy:"ImageTextListOrderBy"
		},
		getJobsFilter:{
			by:"JobsFilterUniqueWhere",
			filter:"JobsFilterWhere"
		},
		listJobsFilter:{
			filter:"JobsFilterWhere",
			orderBy:"JobsFilterOrderBy"
		},
		paginateJobsFilter:{
			filter:"JobsFilterWhere",
			orderBy:"JobsFilterOrderBy"
		},
		getJobsFilterLocale:{
			by:"JobsFilterLocaleUniqueWhere",
			filter:"JobsFilterLocaleWhere"
		},
		listJobsFilterLocale:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		paginateJobsFilterLocale:{
			filter:"JobsFilterLocaleWhere",
			orderBy:"JobsFilterLocaleOrderBy"
		},
		getFile:{
			by:"FileUniqueWhere",
			filter:"FileWhere"
		},
		listFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		paginateFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		validateCreateFile:{
			data:"FileCreateInput"
		},
		validateCreateJobFormSubscription:{
			data:"JobFormSubscriptionCreateInput"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getJobFormSubscriptionStatus:{
			by:"JobFormSubscriptionStatusUniqueWhere",
			filter:"JobFormSubscriptionStatusWhere"
		},
		listJobFormSubscriptionStatus:{
			filter:"JobFormSubscriptionStatusWhere",
			orderBy:"JobFormSubscriptionStatusOrderBy"
		},
		paginateJobFormSubscriptionStatus:{
			filter:"JobFormSubscriptionStatusWhere",
			orderBy:"JobFormSubscriptionStatusOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getStickyBar:{
			by:"StickyBarUniqueWhere",
			filter:"StickyBarWhere"
		},
		listStickyBar:{
			filter:"StickyBarWhere",
			orderBy:"StickyBarOrderBy"
		},
		paginateStickyBar:{
			filter:"StickyBarWhere",
			orderBy:"StickyBarOrderBy"
		},
		validateCreateStickyBar:{
			data:"StickyBarCreateInput"
		},
		getRecommendationForm:{
			by:"RecommendationFormUniqueWhere",
			filter:"RecommendationFormWhere"
		},
		listRecommendationForm:{
			filter:"RecommendationFormWhere",
			orderBy:"RecommendationFormOrderBy"
		},
		paginateRecommendationForm:{
			filter:"RecommendationFormWhere",
			orderBy:"RecommendationFormOrderBy"
		},
		getRecommendationFormLocale:{
			by:"RecommendationFormLocaleUniqueWhere",
			filter:"RecommendationFormLocaleWhere"
		},
		listRecommendationFormLocale:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		paginateRecommendationFormLocale:{
			filter:"RecommendationFormLocaleWhere",
			orderBy:"RecommendationFormLocaleOrderBy"
		},
		validateCreateRecommendationFormSubscription:{
			data:"RecommendationFormSubscriptionCreateInput"
		},
		getInfoBox:{
			by:"InfoBoxUniqueWhere",
			filter:"InfoBoxWhere"
		},
		listInfoBox:{
			filter:"InfoBoxWhere",
			orderBy:"InfoBoxOrderBy"
		},
		paginateInfoBox:{
			filter:"InfoBoxWhere",
			orderBy:"InfoBoxOrderBy"
		},
		validateCreateInfoBox:{
			data:"InfoBoxCreateInput"
		},
		getInfoBoxLocale:{
			by:"InfoBoxLocaleUniqueWhere",
			filter:"InfoBoxLocaleWhere"
		},
		listInfoBoxLocale:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		},
		paginateInfoBoxLocale:{
			filter:"InfoBoxLocaleWhere",
			orderBy:"InfoBoxLocaleOrderBy"
		}
	},
	Mutation:{
		generateUploadUrl:{
			input:"S3GenerateSignedUploadInput"
		},
		generateReadUrl:{

		},
		createFile:{
			data:"FileCreateInput"
		},
		createJobFormSubscription:{
			data:"JobFormSubscriptionCreateInput"
		},
		createStickyBar:{
			data:"StickyBarCreateInput"
		},
		createRecommendationFormSubscription:{
			data:"RecommendationFormSubscriptionCreateInput"
		},
		createInfoBox:{
			data:"InfoBoxCreateInput"
		},
		transaction:{
			options:"MutationTransactionOptions"
		}
	},
	S3GenerateSignedUploadInput:{
		contentDisposition:"S33ContentDisposition",
		acl:"S3Acl"
	},
	S33ContentDisposition: "enum" as const,
	S3Acl: "enum" as const,
	_MutationErrorType: "enum" as const,
	MutationTransaction:{
		generateUploadUrl:{
			input:"S3GenerateSignedUploadInput"
		},
		generateReadUrl:{

		},
		createFile:{
			data:"FileCreateInput"
		},
		createJobFormSubscription:{
			data:"JobFormSubscriptionCreateInput"
		},
		createStickyBar:{
			data:"StickyBarCreateInput"
		},
		createRecommendationFormSubscription:{
			data:"RecommendationFormSubscriptionCreateInput"
		},
		createInfoBox:{
			data:"InfoBoxCreateInput"
		}
	},
	MutationTransactionOptions:{

	}
}

export const ReturnTypes: Record<string,any> = {
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_RuleMessage:{
		text:"String"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	Json: `scalar.Json` as const,
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	Query:{
		schema:"_Schema",
		s3DummyQuery:"String",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getContentReferenceLinkableItem:"ContentReferenceLinkableItem",
		listContentReferenceLinkableItem:"ContentReferenceLinkableItem",
		paginateContentReferenceLinkableItem:"ContentReferenceLinkableItemConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterLink:"FooterLink",
		listFooterLink:"FooterLink",
		paginateFooterLink:"FooterLinkConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getSeoDefault:"SeoDefault",
		listSeoDefault:"SeoDefault",
		paginateSeoDefault:"SeoDefaultConnection",
		getSeoDefaultLocale:"SeoDefaultLocale",
		listSeoDefaultLocale:"SeoDefaultLocale",
		paginateSeoDefaultLocale:"SeoDefaultLocaleConnection",
		getBusiness:"Business",
		listBusiness:"Business",
		paginateBusiness:"BusinessConnection",
		getBusinessPage:"BusinessPage",
		listBusinessPage:"BusinessPage",
		paginateBusinessPage:"BusinessPageConnection",
		getBusinessPageLocale:"BusinessPageLocale",
		listBusinessPageLocale:"BusinessPageLocale",
		paginateBusinessPageLocale:"BusinessPageLocaleConnection",
		getIcon:"Icon",
		listIcon:"Icon",
		paginateIcon:"IconConnection",
		getJobPage:"JobPage",
		listJobPage:"JobPage",
		paginateJobPage:"JobPageConnection",
		getJobPageLocale:"JobPageLocale",
		listJobPageLocale:"JobPageLocale",
		paginateJobPageLocale:"JobPageLocaleConnection",
		getKeyInfoItem:"KeyInfoItem",
		listKeyInfoItem:"KeyInfoItem",
		paginateKeyInfoItem:"KeyInfoItemConnection",
		getKeyInfoList:"KeyInfoList",
		listKeyInfoList:"KeyInfoList",
		paginateKeyInfoList:"KeyInfoListConnection",
		getContractType:"ContractType",
		listContractType:"ContractType",
		paginateContractType:"ContractTypeConnection",
		getContractTypeLocale:"ContractTypeLocale",
		listContractTypeLocale:"ContractTypeLocale",
		paginateContractTypeLocale:"ContractTypeLocaleConnection",
		getJobCity:"JobCity",
		listJobCity:"JobCity",
		paginateJobCity:"JobCityConnection",
		getJobCityLocale:"JobCityLocale",
		listJobCityLocale:"JobCityLocale",
		paginateJobCityLocale:"JobCityLocaleConnection",
		getJobPosition:"JobPosition",
		listJobPosition:"JobPosition",
		paginateJobPosition:"JobPositionConnection",
		getJobPositionLocale:"JobPositionLocale",
		listJobPositionLocale:"JobPositionLocale",
		paginateJobPositionLocale:"JobPositionLocaleConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getHomePage:"HomePage",
		listHomePage:"HomePage",
		paginateHomePage:"HomePageConnection",
		getHomePageLocale:"HomePageLocale",
		listHomePageLocale:"HomePageLocale",
		paginateHomePageLocale:"HomePageLocaleConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getMediumItem:"MediumItem",
		listMediumItem:"MediumItem",
		paginateMediumItem:"MediumItemConnection",
		getMediumList:"MediumList",
		listMediumList:"MediumList",
		paginateMediumList:"MediumListConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getCarousel:"Carousel",
		listCarousel:"Carousel",
		paginateCarousel:"CarouselConnection",
		getCarouselItem:"CarouselItem",
		listCarouselItem:"CarouselItem",
		paginateCarouselItem:"CarouselItemConnection",
		getTheme:"Theme",
		listTheme:"Theme",
		paginateTheme:"ThemeConnection",
		getBonusBanner:"BonusBanner",
		listBonusBanner:"BonusBanner",
		paginateBonusBanner:"BonusBannerConnection",
		getBonusBannerLocale:"BonusBannerLocale",
		listBonusBannerLocale:"BonusBannerLocale",
		paginateBonusBannerLocale:"BonusBannerLocaleConnection",
		getTile:"Tile",
		listTile:"Tile",
		paginateTile:"TileConnection",
		getSideBySide:"SideBySide",
		listSideBySide:"SideBySide",
		paginateSideBySide:"SideBySideConnection",
		getSideBySideItem:"SideBySideItem",
		listSideBySideItem:"SideBySideItem",
		paginateSideBySideItem:"SideBySideItemConnection",
		getHomePageLokal:"HomePageLokal",
		listHomePageLokal:"HomePageLokal",
		paginateHomePageLokal:"HomePageLokalConnection",
		getHomePageLokalLocale:"HomePageLokalLocale",
		listHomePageLokalLocale:"HomePageLokalLocale",
		paginateHomePageLokalLocale:"HomePageLokalLocaleConnection",
		getSiteSwitcher:"SiteSwitcher",
		listSiteSwitcher:"SiteSwitcher",
		paginateSiteSwitcher:"SiteSwitcherConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getJobsForm:"JobsForm",
		listJobsForm:"JobsForm",
		paginateJobsForm:"JobsFormConnection",
		getJobsFormLocale:"JobsFormLocale",
		listJobsFormLocale:"JobsFormLocale",
		paginateJobsFormLocale:"JobsFormLocaleConnection",
		getImageTextItem:"ImageTextItem",
		listImageTextItem:"ImageTextItem",
		paginateImageTextItem:"ImageTextItemConnection",
		getImageTextList:"ImageTextList",
		listImageTextList:"ImageTextList",
		paginateImageTextList:"ImageTextListConnection",
		getJobsFilter:"JobsFilter",
		listJobsFilter:"JobsFilter",
		paginateJobsFilter:"JobsFilterConnection",
		getJobsFilterLocale:"JobsFilterLocale",
		listJobsFilterLocale:"JobsFilterLocale",
		paginateJobsFilterLocale:"JobsFilterLocaleConnection",
		getFile:"File",
		listFile:"File",
		paginateFile:"FileConnection",
		validateCreateFile:"_ValidationResult",
		validateCreateJobFormSubscription:"_ValidationResult",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getJobFormSubscriptionStatus:"JobFormSubscriptionStatus",
		listJobFormSubscriptionStatus:"JobFormSubscriptionStatus",
		paginateJobFormSubscriptionStatus:"JobFormSubscriptionStatusConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getStickyBar:"StickyBar",
		listStickyBar:"StickyBar",
		paginateStickyBar:"StickyBarConnection",
		validateCreateStickyBar:"_ValidationResult",
		getRecommendationForm:"RecommendationForm",
		listRecommendationForm:"RecommendationForm",
		paginateRecommendationForm:"RecommendationFormConnection",
		getRecommendationFormLocale:"RecommendationFormLocale",
		listRecommendationFormLocale:"RecommendationFormLocale",
		paginateRecommendationFormLocale:"RecommendationFormLocaleConnection",
		validateCreateRecommendationFormSubscription:"_ValidationResult",
		getInfoBox:"InfoBox",
		listInfoBox:"InfoBox",
		paginateInfoBox:"InfoBoxConnection",
		validateCreateInfoBox:"_ValidationResult",
		getInfoBoxLocale:"InfoBoxLocale",
		listInfoBoxLocale:"InfoBoxLocale",
		paginateInfoBoxLocale:"InfoBoxLocaleConnection",
		transaction:"QueryTransaction",
		_info:"Info"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Enum:{
		name:"String",
		values:"String"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	General:{
		_meta:"GeneralMeta",
		id:"UUID",
		unique:"One",
		locales:"GeneralLocale",
		localesByLocale:"GeneralLocale",
		localesBySeo:"GeneralLocale",
		paginateLocales:"GeneralLocaleConnection"
	},
	GeneralMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	UUID: `scalar.UUID` as const,
	GeneralLocale:{
		_meta:"GeneralLocaleMeta",
		id:"UUID",
		root:"General",
		locale:"Locale",
		seo:"Seo"
	},
	GeneralLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta"
	},
	DateTime: `scalar.DateTime` as const,
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		code:"String",
		label:"String",
		footers:"FooterLocale",
		genericPages:"GenericPageLocale",
		headers:"HeaderLocale",
		seoDefaults:"SeoDefaultLocale",
		businessPages:"BusinessPageLocale",
		jobPages:"JobPageLocale",
		contractTypes:"ContractTypeLocale",
		jobCities:"JobCityLocale",
		jobPositions:"JobPositionLocale",
		homePages:"HomePageLocale",
		bonusBanners:"BonusBannerLocale",
		homePageLokals:"HomePageLokalLocale",
		jobsForms:"JobsFormLocale",
		jobsFilters:"JobsFilterLocale",
		general:"GeneralLocale",
		recommendationForms:"RecommendationFormLocale",
		infoBoxes:"InfoBoxLocale",
		footersByRoot:"FooterLocale",
		footersByTermsAndConditions:"FooterLocale",
		footersByBannerImage:"FooterLocale",
		footersByBannerLink:"FooterLocale",
		footersByJobsForm:"FooterLocale",
		footersByCopyrightLink:"FooterLocale",
		footersByRecommendationForm:"FooterLocale",
		footersByBannerSecondLink:"FooterLocale",
		footersByInstagramLink:"FooterLocale",
		genericPagesByRoot:"GenericPageLocale",
		genericPagesByContent:"GenericPageLocale",
		genericPagesBySeo:"GenericPageLocale",
		genericPagesByLink:"GenericPageLocale",
		genericPagesByLogo:"GenericPageLocale",
		genericPagesByImage:"GenericPageLocale",
		headersByRoot:"HeaderLocale",
		headersByBusinessLink:"HeaderLocale",
		headersByLokalLink:"HeaderLocale",
		seoDefaultsByRoot:"SeoDefaultLocale",
		seoDefaultsBySeo:"SeoDefaultLocale",
		businessPagesByRoot:"BusinessPageLocale",
		businessPagesByContent:"BusinessPageLocale",
		businessPagesBySeo:"BusinessPageLocale",
		businessPagesByLink:"BusinessPageLocale",
		businessPagesByImage:"BusinessPageLocale",
		jobPagesByRoot:"JobPageLocale",
		jobPagesByImage:"JobPageLocale",
		jobPagesByKeyInfo:"JobPageLocale",
		jobPagesByContent:"JobPageLocale",
		jobPagesBySeo:"JobPageLocale",
		jobPagesByLink:"JobPageLocale",
		jobPagesByMobileImage:"JobPageLocale",
		contractTypesByRoot:"ContractTypeLocale",
		contractTypesBySlug:"ContractTypeLocale",
		jobCitiesByRoot:"JobCityLocale",
		jobCitiesBySlug:"JobCityLocale",
		jobPositionsByRoot:"JobPositionLocale",
		jobPositionsBySlug:"JobPositionLocale",
		homePagesByRoot:"HomePageLocale",
		homePagesByButton:"HomePageLocale",
		homePagesByContent:"HomePageLocale",
		homePagesBySeo:"HomePageLocale",
		homePagesByLink:"HomePageLocale",
		homePagesByLogo:"HomePageLocale",
		homePagesBySiteSwitcher:"HomePageLocale",
		homePagesByIlustration:"HomePageLocale",
		bonusBannersByRoot:"BonusBannerLocale",
		bonusBannersByTiles:"BonusBannerLocale",
		bonusBannersByLogo:"BonusBannerLocale",
		homePageLokalsByRoot:"HomePageLokalLocale",
		homePageLokalsByLogo:"HomePageLokalLocale",
		homePageLokalsByButton:"HomePageLokalLocale",
		homePageLokalsByContent:"HomePageLokalLocale",
		homePageLokalsBySeo:"HomePageLokalLocale",
		homePageLokalsByLink:"HomePageLokalLocale",
		homePageLokalsBySiteSwitcher:"HomePageLokalLocale",
		homePageLokalsByIlustration:"HomePageLokalLocale",
		jobsFormsByRoot:"JobsFormLocale",
		jobsFormsByImageTextList:"JobsFormLocale",
		jobsFormsByLogo:"JobsFormLocale",
		jobsFormsBySuccessImage:"JobsFormLocale",
		jobsFiltersByRoot:"JobsFilterLocale",
		jobsFiltersByBannerJobsForm:"JobsFilterLocale",
		jobsFiltersByBannerImage:"JobsFilterLocale",
		jobsFiltersByNoJobImage:"JobsFilterLocale",
		generalByRoot:"GeneralLocale",
		generalBySeo:"GeneralLocale",
		recommendationFormsByRoot:"RecommendationFormLocale",
		infoBoxesByRoot:"InfoBoxLocale",
		paginateFooters:"FooterLocaleConnection",
		paginateGenericPages:"GenericPageLocaleConnection",
		paginateHeaders:"HeaderLocaleConnection",
		paginateSeoDefaults:"SeoDefaultLocaleConnection",
		paginateBusinessPages:"BusinessPageLocaleConnection",
		paginateJobPages:"JobPageLocaleConnection",
		paginateContractTypes:"ContractTypeLocaleConnection",
		paginateJobCities:"JobCityLocaleConnection",
		paginateJobPositions:"JobPositionLocaleConnection",
		paginateHomePages:"HomePageLocaleConnection",
		paginateBonusBanners:"BonusBannerLocaleConnection",
		paginateHomePageLokals:"HomePageLokalLocaleConnection",
		paginateJobsForms:"JobsFormLocaleConnection",
		paginateJobsFilters:"JobsFilterLocaleConnection",
		paginateGeneral:"GeneralLocaleConnection",
		paginateRecommendationForms:"RecommendationFormLocaleConnection",
		paginateInfoBoxes:"InfoBoxLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		label:"FieldMeta",
		footers:"FieldMeta",
		genericPages:"FieldMeta",
		headers:"FieldMeta",
		seoDefaults:"FieldMeta",
		businessPages:"FieldMeta",
		jobPages:"FieldMeta",
		contractTypes:"FieldMeta",
		jobCities:"FieldMeta",
		jobPositions:"FieldMeta",
		homePages:"FieldMeta",
		bonusBanners:"FieldMeta",
		homePageLokals:"FieldMeta",
		jobsForms:"FieldMeta",
		jobsFilters:"FieldMeta",
		general:"FieldMeta",
		recommendationForms:"FieldMeta",
		infoBoxes:"FieldMeta"
	},
	FooterLocale:{
		_meta:"FooterLocaleMeta",
		id:"UUID",
		email:"String",
		address:"String",
		ico:"String",
		dic:"String",
		copyright:"String",
		note:"String",
		noteSecond:"String",
		root:"Footer",
		locale:"Locale",
		linksFirstColumn:"FooterLink",
		linksSecondColumn:"FooterLink",
		termsAndConditions:"Link",
		form:"String",
		bannerTitle:"String",
		bannerSubtitle:"String",
		bannerImage:"Image",
		bannerLink:"Link",
		jobsForm:"JobsFormLocale",
		copyrightLink:"Link",
		bannerSecondBtn:"String",
		recommendationForm:"RecommendationFormLocale",
		bannerSecondLink:"Link",
		instagramLink:"Link",
		paginateLinksFirstColumn:"FooterLinkConnection",
		paginateLinksSecondColumn:"FooterLinkConnection"
	},
	FooterLocaleMeta:{
		id:"FieldMeta",
		email:"FieldMeta",
		address:"FieldMeta",
		ico:"FieldMeta",
		dic:"FieldMeta",
		copyright:"FieldMeta",
		note:"FieldMeta",
		noteSecond:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		linksFirstColumn:"FieldMeta",
		linksSecondColumn:"FieldMeta",
		termsAndConditions:"FieldMeta",
		form:"FieldMeta",
		bannerTitle:"FieldMeta",
		bannerSubtitle:"FieldMeta",
		bannerImage:"FieldMeta",
		bannerLink:"FieldMeta",
		jobsForm:"FieldMeta",
		copyrightLink:"FieldMeta",
		bannerSecondBtn:"FieldMeta",
		recommendationForm:"FieldMeta",
		bannerSecondLink:"FieldMeta",
		instagramLink:"FieldMeta"
	},
	Footer:{
		_meta:"FooterMeta",
		id:"UUID",
		unique:"One",
		locales:"FooterLocale",
		localesByLocale:"FooterLocale",
		localesByTermsAndConditions:"FooterLocale",
		localesByBannerImage:"FooterLocale",
		localesByBannerLink:"FooterLocale",
		localesByJobsForm:"FooterLocale",
		localesByCopyrightLink:"FooterLocale",
		localesByRecommendationForm:"FooterLocale",
		localesByBannerSecondLink:"FooterLocale",
		localesByInstagramLink:"FooterLocale",
		paginateLocales:"FooterLocaleConnection"
	},
	FooterMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	FooterLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"FooterLocaleEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	FooterLocaleEdge:{
		node:"FooterLocale"
	},
	FooterLink:{
		_meta:"FooterLinkMeta",
		id:"UUID",
		order:"Int",
		link:"Link"
	},
	FooterLinkMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		link:"FieldMeta"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable",
		anchorLink:"String"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta",
		anchorLink:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		redirect:"Redirect",
		genericPage:"GenericPageLocale",
		businessPage:"BusinessPageLocale",
		jobPage:"JobPageLocale",
		homePage:"HomePageLocale",
		homePageLokal:"HomePageLokalLocale"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		redirect:"FieldMeta",
		genericPage:"FieldMeta",
		businessPage:"FieldMeta",
		jobPage:"FieldMeta",
		homePage:"FieldMeta",
		homePageLokal:"FieldMeta"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Link"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	GenericPageLocale:{
		_meta:"GenericPageLocaleMeta",
		id:"UUID",
		title:"String",
		root:"GenericPage",
		locale:"Locale",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		text:"String",
		logo:"Image",
		image:"Image"
	},
	GenericPageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		text:"FieldMeta",
		logo:"FieldMeta",
		image:"FieldMeta"
	},
	GenericPage:{
		_meta:"GenericPageMeta",
		id:"UUID",
		locales:"GenericPageLocale",
		theme:"Theme",
		localesByLocale:"GenericPageLocale",
		localesByContent:"GenericPageLocale",
		localesBySeo:"GenericPageLocale",
		localesByLink:"GenericPageLocale",
		localesByLogo:"GenericPageLocale",
		localesByImage:"GenericPageLocale",
		paginateLocales:"GenericPageLocaleConnection"
	},
	GenericPageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		theme:"FieldMeta"
	},
	Theme:{
		_meta:"ThemeMeta",
		id:"UUID",
		backgroundColor:"String",
		textColor:"String",
		name:"String",
		buttonBackgroundColor:"String",
		buttonTextColor:"String",
		buttonBackgroundHoverColor:"String",
		buttonTextHoverColor:"String",
		buttonBorderColor:"String",
		buttonBorderHoverColor:"String",
		headerMenuColor:"String"
	},
	ThemeMeta:{
		id:"FieldMeta",
		backgroundColor:"FieldMeta",
		textColor:"FieldMeta",
		name:"FieldMeta",
		buttonBackgroundColor:"FieldMeta",
		buttonTextColor:"FieldMeta",
		buttonBackgroundHoverColor:"FieldMeta",
		buttonTextHoverColor:"FieldMeta",
		buttonBorderColor:"FieldMeta",
		buttonBorderHoverColor:"FieldMeta",
		headerMenuColor:"FieldMeta"
	},
	GenericPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageLocaleEdge"
	},
	GenericPageLocaleEdge:{
		node:"GenericPageLocale"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByLinkables:"ContentReference",
		referencesByLink:"ContentReference",
		referencesByMedia:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		block:"ContentBlock",
		image:"Image",
		linkables:"ContentReferenceLinkableItem",
		secondaryText:"String",
		link:"Link",
		media:"MediumList",
		textList:"TextList",
		carousel:"Carousel",
		bonusBanner:"BonusBannerLocale",
		sideBySide:"SideBySide",
		gallery:"Gallery",
		showBoolean:"Boolean",
		isRed:"Boolean",
		video:"Video",
		linkablesByItem:"ContentReferenceLinkableItem",
		paginateLinkables:"ContentReferenceLinkableItemConnection"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		block:"FieldMeta",
		image:"FieldMeta",
		linkables:"FieldMeta",
		secondaryText:"FieldMeta",
		link:"FieldMeta",
		media:"FieldMeta",
		textList:"FieldMeta",
		carousel:"FieldMeta",
		bonusBanner:"FieldMeta",
		sideBySide:"FieldMeta",
		gallery:"FieldMeta",
		showBoolean:"FieldMeta",
		isRed:"FieldMeta",
		video:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String",
		fileName:"String",
		focalPointX:"Float",
		focalPointY:"Float"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta",
		fileName:"FieldMeta",
		focalPointX:"FieldMeta",
		focalPointY:"FieldMeta"
	},
	ContentReferenceLinkableItem:{
		_meta:"ContentReferenceLinkableItemMeta",
		id:"UUID",
		item:"Linkable",
		reference:"ContentReference"
	},
	ContentReferenceLinkableItemMeta:{
		id:"FieldMeta",
		item:"FieldMeta",
		reference:"FieldMeta"
	},
	MediumList:{
		_meta:"MediumListMeta",
		id:"UUID",
		items:"MediumItem",
		paginateItems:"MediumItemConnection"
	},
	MediumListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	MediumItem:{
		_meta:"MediumItemMeta",
		id:"UUID",
		order:"Int",
		list:"MediumList",
		medium:"Medium"
	},
	MediumItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta"
	},
	Medium:{
		_meta:"MediumMeta",
		id:"UUID",
		type:"MediumType",
		image:"Image",
		video:"Video"
	},
	MediumMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		image:"FieldMeta",
		video:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		videoInfo:"String",
		duration:"Float",
		poster:"Image"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		videoInfo:"FieldMeta",
		duration:"FieldMeta",
		poster:"FieldMeta"
	},
	MediumItemConnection:{
		pageInfo:"PageInfo",
		edges:"MediumItemEdge"
	},
	MediumItemEdge:{
		node:"MediumItem"
	},
	TextList:{
		_meta:"TextListMeta",
		id:"UUID",
		items:"TextItem",
		paginateItems:"TextItemConnection"
	},
	TextListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TextItem:{
		_meta:"TextItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"TextList",
		title:"String"
	},
	TextItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		title:"FieldMeta"
	},
	TextItemConnection:{
		pageInfo:"PageInfo",
		edges:"TextItemEdge"
	},
	TextItemEdge:{
		node:"TextItem"
	},
	Carousel:{
		_meta:"CarouselMeta",
		id:"UUID",
		items:"CarouselItem",
		background:"Background",
		squareImages:"Boolean",
		itemsByImage:"CarouselItem",
		itemsByLinkUrl:"CarouselItem",
		paginateItems:"CarouselItemConnection"
	},
	CarouselMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		background:"FieldMeta",
		squareImages:"FieldMeta"
	},
	CarouselItem:{
		_meta:"CarouselItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"Carousel",
		image:"Image",
		name:"String",
		title:"String",
		linkUrl:"Link"
	},
	CarouselItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta",
		name:"FieldMeta",
		title:"FieldMeta",
		linkUrl:"FieldMeta"
	},
	CarouselItemConnection:{
		pageInfo:"PageInfo",
		edges:"CarouselItemEdge"
	},
	CarouselItemEdge:{
		node:"CarouselItem"
	},
	BonusBannerLocale:{
		_meta:"BonusBannerLocaleMeta",
		id:"UUID",
		title:"String",
		text:"String",
		root:"BonusBanner",
		locale:"Locale",
		tiles:"Tile",
		logo:"Image",
		bannerName:"String",
		tilesByImage:"Tile",
		tilesByLigthBoxMedia:"Tile",
		paginateTiles:"TileConnection"
	},
	BonusBannerLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		tiles:"FieldMeta",
		logo:"FieldMeta",
		bannerName:"FieldMeta"
	},
	BonusBanner:{
		_meta:"BonusBannerMeta",
		id:"UUID",
		locales:"BonusBannerLocale",
		localesByLocale:"BonusBannerLocale",
		localesByTiles:"BonusBannerLocale",
		localesByLogo:"BonusBannerLocale",
		paginateLocales:"BonusBannerLocaleConnection"
	},
	BonusBannerMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	BonusBannerLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BonusBannerLocaleEdge"
	},
	BonusBannerLocaleEdge:{
		node:"BonusBannerLocale"
	},
	Tile:{
		_meta:"TileMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		bonusBanner:"BonusBannerLocale",
		image:"Image",
		lightBoxTitle:"String",
		lightBoxText:"String",
		ligthBoxMedia:"MediumList",
		lightBoxSectionTitle:"String",
		title:"String",
		lightBoxLinkLabel:"String",
		lightBoxForm:"JobsFormLocale"
	},
	TileMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		bonusBanner:"FieldMeta",
		image:"FieldMeta",
		lightBoxTitle:"FieldMeta",
		lightBoxText:"FieldMeta",
		ligthBoxMedia:"FieldMeta",
		lightBoxSectionTitle:"FieldMeta",
		title:"FieldMeta",
		lightBoxLinkLabel:"FieldMeta",
		lightBoxForm:"FieldMeta"
	},
	JobsFormLocale:{
		_meta:"JobsFormLocaleMeta",
		id:"UUID",
		title:"String",
		jobText:"String",
		genericText:"String",
		nameLabel:"String",
		emailLabel:"String",
		telefonLabel:"String",
		fileTitleLabel:"String",
		fileFormatLabel:"String",
		formTextLabel:"String",
		submitLabel:"String",
		root:"JobsForm",
		locale:"Locale",
		imageTextList:"ImageTextList",
		minimalize:"String",
		logo:"Image",
		successTitle:"String",
		successText:"String",
		successBtnLabel:"String",
		successImage:"Image",
		successOtherJobsTitle:"String",
		successOtherJobsCategoryTitle:"String",
		successOtherJobsButtonTitle:"String",
		successOtherJobsSelectionTitle:"String",
		successOtherJobsSelectionYesButton:"String",
		successOtherJobsSelectionNoButton:"String",
		fileRemoveBtnLabel:"String",
		gdprLabel:"String"
	},
	JobsFormLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		jobText:"FieldMeta",
		genericText:"FieldMeta",
		nameLabel:"FieldMeta",
		emailLabel:"FieldMeta",
		telefonLabel:"FieldMeta",
		fileTitleLabel:"FieldMeta",
		fileFormatLabel:"FieldMeta",
		formTextLabel:"FieldMeta",
		submitLabel:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		imageTextList:"FieldMeta",
		minimalize:"FieldMeta",
		logo:"FieldMeta",
		successTitle:"FieldMeta",
		successText:"FieldMeta",
		successBtnLabel:"FieldMeta",
		successImage:"FieldMeta",
		successOtherJobsTitle:"FieldMeta",
		successOtherJobsCategoryTitle:"FieldMeta",
		successOtherJobsButtonTitle:"FieldMeta",
		successOtherJobsSelectionTitle:"FieldMeta",
		successOtherJobsSelectionYesButton:"FieldMeta",
		successOtherJobsSelectionNoButton:"FieldMeta",
		fileRemoveBtnLabel:"FieldMeta",
		gdprLabel:"FieldMeta"
	},
	JobsForm:{
		_meta:"JobsFormMeta",
		id:"UUID",
		unique:"One",
		locales:"JobsFormLocale",
		fileIlustration:"Image",
		localesByLocale:"JobsFormLocale",
		localesByImageTextList:"JobsFormLocale",
		localesByLogo:"JobsFormLocale",
		localesBySuccessImage:"JobsFormLocale",
		paginateLocales:"JobsFormLocaleConnection"
	},
	JobsFormMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		fileIlustration:"FieldMeta"
	},
	JobsFormLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"JobsFormLocaleEdge"
	},
	JobsFormLocaleEdge:{
		node:"JobsFormLocale"
	},
	ImageTextList:{
		_meta:"ImageTextListMeta",
		id:"UUID",
		items:"ImageTextItem",
		itemsByImage:"ImageTextItem",
		paginateItems:"ImageTextItemConnection"
	},
	ImageTextListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageTextItem:{
		_meta:"ImageTextItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		list:"ImageTextList",
		image:"Image"
	},
	ImageTextItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageTextItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageTextItemEdge"
	},
	ImageTextItemEdge:{
		node:"ImageTextItem"
	},
	TileConnection:{
		pageInfo:"PageInfo",
		edges:"TileEdge"
	},
	TileEdge:{
		node:"Tile"
	},
	SideBySide:{
		_meta:"SideBySideMeta",
		id:"UUID",
		items:"SideBySideItem",
		background:"Background",
		alignment:"ContentAlignment",
		mediumSize:"MediumSize",
		itemsByMedia:"SideBySideItem",
		paginateItems:"SideBySideItemConnection"
	},
	SideBySideMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		background:"FieldMeta",
		alignment:"FieldMeta",
		mediumSize:"FieldMeta"
	},
	SideBySideItem:{
		_meta:"SideBySideItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		text:"String",
		list:"SideBySide",
		media:"MediumList",
		formButtonLabel:"String",
		type:"SideBySideItemType",
		link:"Link",
		jobsForm:"JobsFormLocale"
	},
	SideBySideItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		media:"FieldMeta",
		formButtonLabel:"FieldMeta",
		type:"FieldMeta",
		link:"FieldMeta",
		jobsForm:"FieldMeta"
	},
	SideBySideItemConnection:{
		pageInfo:"PageInfo",
		edges:"SideBySideItemEdge"
	},
	SideBySideItemEdge:{
		node:"SideBySideItem"
	},
	Gallery:{
		_meta:"GalleryMeta",
		id:"UUID",
		items:"GalleryItem",
		itemsByImage:"GalleryItem",
		paginateItems:"GalleryItemConnection"
	},
	GalleryMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GalleryItem:{
		_meta:"GalleryItemMeta",
		id:"UUID",
		order:"Int",
		list:"Gallery",
		image:"Image"
	},
	GalleryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	GalleryItemConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryItemEdge"
	},
	GalleryItemEdge:{
		node:"GalleryItem"
	},
	ContentReferenceLinkableItemConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceLinkableItemEdge"
	},
	ContentReferenceLinkableItemEdge:{
		node:"ContentReferenceLinkableItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		noIndex:"Boolean",
		noFollow:"Boolean",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		noIndex:"FieldMeta",
		noFollow:"FieldMeta",
		ogImage:"FieldMeta"
	},
	BusinessPageLocale:{
		_meta:"BusinessPageLocaleMeta",
		id:"UUID",
		root:"BusinessPage",
		locale:"Locale",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		subtitle:"String",
		text:"String",
		name:"String",
		image:"Image",
		stickyBar:"StickyBar",
		infoBox:"InfoBox"
	},
	BusinessPageLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		subtitle:"FieldMeta",
		text:"FieldMeta",
		name:"FieldMeta",
		image:"FieldMeta",
		stickyBar:"FieldMeta",
		infoBox:"FieldMeta"
	},
	BusinessPage:{
		_meta:"BusinessPageMeta",
		id:"UUID",
		locales:"BusinessPageLocale",
		theme:"Theme",
		isLokal:"Boolean",
		localesByLocale:"BusinessPageLocale",
		localesByContent:"BusinessPageLocale",
		localesBySeo:"BusinessPageLocale",
		localesByLink:"BusinessPageLocale",
		localesByImage:"BusinessPageLocale",
		paginateLocales:"BusinessPageLocaleConnection"
	},
	BusinessPageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		theme:"FieldMeta",
		isLokal:"FieldMeta"
	},
	BusinessPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessPageLocaleEdge"
	},
	BusinessPageLocaleEdge:{
		node:"BusinessPageLocale"
	},
	StickyBar:{
		_meta:"StickyBarMeta",
		id:"UUID",
		title:"String",
		link:"Link",
		formButtonLabel:"String",
		jobsForm:"JobsFormLocale"
	},
	StickyBarMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		formButtonLabel:"FieldMeta",
		jobsForm:"FieldMeta"
	},
	InfoBox:{
		_meta:"InfoBoxMeta",
		id:"UUID",
		logoSize:"LogoSize",
		logo:"Image",
		locales:"InfoBoxLocale",
		localesByLocale:"InfoBoxLocale",
		paginateLocales:"InfoBoxLocaleConnection"
	},
	InfoBoxMeta:{
		id:"FieldMeta",
		logoSize:"FieldMeta",
		logo:"FieldMeta",
		locales:"FieldMeta"
	},
	InfoBoxLocale:{
		_meta:"InfoBoxLocaleMeta",
		id:"UUID",
		address:"String",
		web:"String",
		instagram:"String",
		facebook:"String",
		root:"InfoBox",
		locale:"Locale",
		openingHours:"TextList",
		addressLink:"String"
	},
	InfoBoxLocaleMeta:{
		id:"FieldMeta",
		address:"FieldMeta",
		web:"FieldMeta",
		instagram:"FieldMeta",
		facebook:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		openingHours:"FieldMeta",
		addressLink:"FieldMeta"
	},
	InfoBoxLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"InfoBoxLocaleEdge"
	},
	InfoBoxLocaleEdge:{
		node:"InfoBoxLocale"
	},
	JobPageLocale:{
		_meta:"JobPageLocaleMeta",
		id:"UUID",
		title:"String",
		text:"String",
		root:"JobPage",
		locale:"Locale",
		image:"Image",
		keyInfo:"KeyInfoList",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		email:"String",
		stickyBar:"StickyBar",
		mobileImage:"Image"
	},
	JobPageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		image:"FieldMeta",
		keyInfo:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		email:"FieldMeta",
		stickyBar:"FieldMeta",
		mobileImage:"FieldMeta"
	},
	JobPage:{
		_meta:"JobPageMeta",
		id:"UUID",
		locales:"JobPageLocale",
		theme:"Theme",
		contractType:"ContractType",
		jobCity:"JobCity",
		business:"BusinessPage",
		isActive:"Boolean",
		jobPosition:"JobPosition",
		publishedAt:"DateTime",
		order:"Int",
		isSyncedToTabidoo:"Boolean",
		localesByLocale:"JobPageLocale",
		localesByImage:"JobPageLocale",
		localesByKeyInfo:"JobPageLocale",
		localesByContent:"JobPageLocale",
		localesBySeo:"JobPageLocale",
		localesByLink:"JobPageLocale",
		localesByMobileImage:"JobPageLocale",
		paginateLocales:"JobPageLocaleConnection"
	},
	JobPageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		theme:"FieldMeta",
		contractType:"FieldMeta",
		jobCity:"FieldMeta",
		business:"FieldMeta",
		isActive:"FieldMeta",
		jobPosition:"FieldMeta",
		publishedAt:"FieldMeta",
		order:"FieldMeta",
		isSyncedToTabidoo:"FieldMeta"
	},
	ContractType:{
		_meta:"ContractTypeMeta",
		id:"UUID",
		locales:"ContractTypeLocale",
		localesByLocale:"ContractTypeLocale",
		paginateLocales:"ContractTypeLocaleConnection"
	},
	ContractTypeMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	ContractTypeLocale:{
		_meta:"ContractTypeLocaleMeta",
		id:"UUID",
		type:"String",
		root:"ContractType",
		locale:"Locale",
		slug:"String"
	},
	ContractTypeLocaleMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		slug:"FieldMeta"
	},
	ContractTypeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ContractTypeLocaleEdge"
	},
	ContractTypeLocaleEdge:{
		node:"ContractTypeLocale"
	},
	JobCity:{
		_meta:"JobCityMeta",
		id:"UUID",
		locales:"JobCityLocale",
		localesByLocale:"JobCityLocale",
		paginateLocales:"JobCityLocaleConnection"
	},
	JobCityMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	JobCityLocale:{
		_meta:"JobCityLocaleMeta",
		id:"UUID",
		root:"JobCity",
		locale:"Locale",
		name:"String",
		slug:"String"
	},
	JobCityLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		name:"FieldMeta",
		slug:"FieldMeta"
	},
	JobCityLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"JobCityLocaleEdge"
	},
	JobCityLocaleEdge:{
		node:"JobCityLocale"
	},
	JobPosition:{
		_meta:"JobPositionMeta",
		id:"UUID",
		locales:"JobPositionLocale",
		jobs:"JobPage",
		localesByLocale:"JobPositionLocale",
		jobsByLocales:"JobPage",
		paginateLocales:"JobPositionLocaleConnection",
		paginateJobs:"JobPageConnection"
	},
	JobPositionMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		jobs:"FieldMeta"
	},
	JobPositionLocale:{
		_meta:"JobPositionLocaleMeta",
		id:"UUID",
		type:"String",
		root:"JobPosition",
		locale:"Locale",
		slug:"String"
	},
	JobPositionLocaleMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		slug:"FieldMeta"
	},
	JobPositionLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"JobPositionLocaleEdge"
	},
	JobPositionLocaleEdge:{
		node:"JobPositionLocale"
	},
	JobPageConnection:{
		pageInfo:"PageInfo",
		edges:"JobPageEdge"
	},
	JobPageEdge:{
		node:"JobPage"
	},
	JobPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"JobPageLocaleEdge"
	},
	JobPageLocaleEdge:{
		node:"JobPageLocale"
	},
	KeyInfoList:{
		_meta:"KeyInfoListMeta",
		id:"UUID",
		items:"KeyInfoItem",
		paginateItems:"KeyInfoItemConnection"
	},
	KeyInfoListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	KeyInfoItem:{
		_meta:"KeyInfoItemMeta",
		id:"UUID",
		order:"Int",
		keyInfo:"String",
		list:"KeyInfoList",
		icon:"Icon"
	},
	KeyInfoItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		keyInfo:"FieldMeta",
		list:"FieldMeta",
		icon:"FieldMeta"
	},
	Icon:{
		_meta:"IconMeta",
		id:"UUID",
		name:"String",
		icon:"Image"
	},
	IconMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		icon:"FieldMeta"
	},
	KeyInfoItemConnection:{
		pageInfo:"PageInfo",
		edges:"KeyInfoItemEdge"
	},
	KeyInfoItemEdge:{
		node:"KeyInfoItem"
	},
	HomePageLocale:{
		_meta:"HomePageLocaleMeta",
		id:"UUID",
		title:"String",
		root:"HomePage",
		locale:"Locale",
		button:"Link",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		logo:"Image",
		siteSwitcher:"SiteSwitcher",
		gallery:"Gallery",
		stickyBar:"StickyBar",
		text:"String",
		ilustration:"Gallery"
	},
	HomePageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		button:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		logo:"FieldMeta",
		siteSwitcher:"FieldMeta",
		gallery:"FieldMeta",
		stickyBar:"FieldMeta",
		text:"FieldMeta",
		ilustration:"FieldMeta"
	},
	HomePage:{
		_meta:"HomePageMeta",
		id:"UUID",
		unique:"One",
		locales:"HomePageLocale",
		theme:"Theme",
		localesByLocale:"HomePageLocale",
		localesByButton:"HomePageLocale",
		localesByContent:"HomePageLocale",
		localesBySeo:"HomePageLocale",
		localesByLink:"HomePageLocale",
		localesByLogo:"HomePageLocale",
		localesBySiteSwitcher:"HomePageLocale",
		localesByIlustration:"HomePageLocale",
		paginateLocales:"HomePageLocaleConnection"
	},
	HomePageMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		theme:"FieldMeta"
	},
	HomePageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"HomePageLocaleEdge"
	},
	HomePageLocaleEdge:{
		node:"HomePageLocale"
	},
	SiteSwitcher:{
		_meta:"SiteSwitcherMeta",
		id:"UUID",
		title:"String",
		link:"Link",
		theme:"Theme"
	},
	SiteSwitcherMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		theme:"FieldMeta"
	},
	HomePageLokalLocale:{
		_meta:"HomePageLokalLocaleMeta",
		id:"UUID",
		title:"String",
		root:"HomePageLokal",
		locale:"Locale",
		logo:"Image",
		button:"Link",
		content:"Content",
		seo:"Seo",
		link:"Linkable",
		siteSwitcher:"SiteSwitcher",
		gallery:"Gallery",
		stickyBar:"StickyBar",
		text:"String",
		ilustration:"Gallery"
	},
	HomePageLokalLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		logo:"FieldMeta",
		button:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		siteSwitcher:"FieldMeta",
		gallery:"FieldMeta",
		stickyBar:"FieldMeta",
		text:"FieldMeta",
		ilustration:"FieldMeta"
	},
	HomePageLokal:{
		_meta:"HomePageLokalMeta",
		id:"UUID",
		unique:"One",
		locales:"HomePageLokalLocale",
		theme:"Theme",
		localesByLocale:"HomePageLokalLocale",
		localesByLogo:"HomePageLokalLocale",
		localesByButton:"HomePageLokalLocale",
		localesByContent:"HomePageLokalLocale",
		localesBySeo:"HomePageLokalLocale",
		localesByLink:"HomePageLokalLocale",
		localesBySiteSwitcher:"HomePageLokalLocale",
		localesByIlustration:"HomePageLokalLocale",
		paginateLocales:"HomePageLokalLocaleConnection"
	},
	HomePageLokalMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		theme:"FieldMeta"
	},
	HomePageLokalLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"HomePageLokalLocaleEdge"
	},
	HomePageLokalLocaleEdge:{
		node:"HomePageLokalLocale"
	},
	RecommendationFormLocale:{
		_meta:"RecommendationFormLocaleMeta",
		id:"UUID",
		title:"String",
		subtitle:"String",
		yourEmailLabel:"String",
		theirEmailLabel:"String",
		yourName:"String",
		sendBtnLabel:"String",
		successTitle:"String",
		successSubtitle:"String",
		successButtonClose:"String",
		root:"RecommendationForm",
		locale:"Locale"
	},
	RecommendationFormLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		subtitle:"FieldMeta",
		yourEmailLabel:"FieldMeta",
		theirEmailLabel:"FieldMeta",
		yourName:"FieldMeta",
		sendBtnLabel:"FieldMeta",
		successTitle:"FieldMeta",
		successSubtitle:"FieldMeta",
		successButtonClose:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	RecommendationForm:{
		_meta:"RecommendationFormMeta",
		id:"UUID",
		unique:"One",
		locales:"RecommendationFormLocale",
		successImage:"Image",
		localesByLocale:"RecommendationFormLocale",
		paginateLocales:"RecommendationFormLocaleConnection"
	},
	RecommendationFormMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		successImage:"FieldMeta"
	},
	RecommendationFormLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"RecommendationFormLocaleEdge"
	},
	RecommendationFormLocaleEdge:{
		node:"RecommendationFormLocale"
	},
	FooterLinkConnection:{
		pageInfo:"PageInfo",
		edges:"FooterLinkEdge"
	},
	FooterLinkEdge:{
		node:"FooterLink"
	},
	HeaderLocale:{
		_meta:"HeaderLocaleMeta",
		id:"UUID",
		root:"Header",
		locale:"Locale",
		menuLabel:"String",
		menuTitle:"String",
		menuText:"String",
		business:"Business",
		lokalEnterprise:"Business",
		businessSectionTitle:"String",
		lokalSectionTitle:"String",
		businessLink:"Link",
		lokalLink:"Link",
		paginateBusiness:"BusinessConnection",
		paginateLokalEnterprise:"BusinessConnection"
	},
	HeaderLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		menuLabel:"FieldMeta",
		menuTitle:"FieldMeta",
		menuText:"FieldMeta",
		business:"FieldMeta",
		lokalEnterprise:"FieldMeta",
		businessSectionTitle:"FieldMeta",
		lokalSectionTitle:"FieldMeta",
		businessLink:"FieldMeta",
		lokalLink:"FieldMeta"
	},
	Header:{
		_meta:"HeaderMeta",
		id:"UUID",
		unique:"One",
		locales:"HeaderLocale",
		logo:"Image",
		logoMenu:"Image",
		localesByLocale:"HeaderLocale",
		localesByBusinessLink:"HeaderLocale",
		localesByLokalLink:"HeaderLocale",
		paginateLocales:"HeaderLocaleConnection"
	},
	HeaderMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		logo:"FieldMeta",
		logoMenu:"FieldMeta"
	},
	HeaderLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderLocaleEdge"
	},
	HeaderLocaleEdge:{
		node:"HeaderLocale"
	},
	Business:{
		_meta:"BusinessMeta",
		id:"UUID",
		order:"Int",
		logo:"Image",
		link:"Link"
	},
	BusinessMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		logo:"FieldMeta",
		link:"FieldMeta"
	},
	BusinessConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessEdge"
	},
	BusinessEdge:{
		node:"Business"
	},
	SeoDefaultLocale:{
		_meta:"SeoDefaultLocaleMeta",
		id:"UUID",
		root:"SeoDefault",
		locale:"Locale",
		seo:"Seo"
	},
	SeoDefaultLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta"
	},
	SeoDefault:{
		_meta:"SeoDefaultMeta",
		id:"UUID",
		unique:"One",
		locales:"SeoDefaultLocale",
		localesByLocale:"SeoDefaultLocale",
		localesBySeo:"SeoDefaultLocale",
		paginateLocales:"SeoDefaultLocaleConnection"
	},
	SeoDefaultMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	SeoDefaultLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"SeoDefaultLocaleEdge"
	},
	SeoDefaultLocaleEdge:{
		node:"SeoDefaultLocale"
	},
	JobsFilterLocale:{
		_meta:"JobsFilterLocaleMeta",
		id:"UUID",
		jobPositionLabel:"String",
		jobContractLabel:"String",
		jobCityLabel:"String",
		root:"JobsFilter",
		locale:"Locale",
		bannerTitle:"String",
		bannerFormLabel:"String",
		bannerJobsForm:"JobsFormLocale",
		bannerImage:"Image",
		noJobTitle:"String",
		noJobBtnLabel:"String",
		noJobImage:"Image",
		noJobTitleForSpecificBusiness:"String",
		deactivatedJob:"String"
	},
	JobsFilterLocaleMeta:{
		id:"FieldMeta",
		jobPositionLabel:"FieldMeta",
		jobContractLabel:"FieldMeta",
		jobCityLabel:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		bannerTitle:"FieldMeta",
		bannerFormLabel:"FieldMeta",
		bannerJobsForm:"FieldMeta",
		bannerImage:"FieldMeta",
		noJobTitle:"FieldMeta",
		noJobBtnLabel:"FieldMeta",
		noJobImage:"FieldMeta",
		noJobTitleForSpecificBusiness:"FieldMeta",
		deactivatedJob:"FieldMeta"
	},
	JobsFilter:{
		_meta:"JobsFilterMeta",
		id:"UUID",
		unique:"One",
		locales:"JobsFilterLocale",
		localesByLocale:"JobsFilterLocale",
		localesByBannerJobsForm:"JobsFilterLocale",
		localesByBannerImage:"JobsFilterLocale",
		localesByNoJobImage:"JobsFilterLocale",
		paginateLocales:"JobsFilterLocaleConnection"
	},
	JobsFilterMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	JobsFilterLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"JobsFilterLocaleEdge"
	},
	JobsFilterLocaleEdge:{
		node:"JobsFilterLocale"
	},
	GeneralLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralLocaleEdge"
	},
	GeneralLocaleEdge:{
		node:"GeneralLocale"
	},
	GeneralConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralEdge"
	},
	GeneralEdge:{
		node:"General"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	GenericPageConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageEdge"
	},
	GenericPageEdge:{
		node:"GenericPage"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	FooterConnection:{
		pageInfo:"PageInfo",
		edges:"FooterEdge"
	},
	FooterEdge:{
		node:"Footer"
	},
	HeaderConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderEdge"
	},
	HeaderEdge:{
		node:"Header"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	SeoDefaultConnection:{
		pageInfo:"PageInfo",
		edges:"SeoDefaultEdge"
	},
	SeoDefaultEdge:{
		node:"SeoDefault"
	},
	BusinessPageConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessPageEdge"
	},
	BusinessPageEdge:{
		node:"BusinessPage"
	},
	IconConnection:{
		pageInfo:"PageInfo",
		edges:"IconEdge"
	},
	IconEdge:{
		node:"Icon"
	},
	KeyInfoListConnection:{
		pageInfo:"PageInfo",
		edges:"KeyInfoListEdge"
	},
	KeyInfoListEdge:{
		node:"KeyInfoList"
	},
	ContractTypeConnection:{
		pageInfo:"PageInfo",
		edges:"ContractTypeEdge"
	},
	ContractTypeEdge:{
		node:"ContractType"
	},
	JobCityConnection:{
		pageInfo:"PageInfo",
		edges:"JobCityEdge"
	},
	JobCityEdge:{
		node:"JobCity"
	},
	JobPositionConnection:{
		pageInfo:"PageInfo",
		edges:"JobPositionEdge"
	},
	JobPositionEdge:{
		node:"JobPosition"
	},
	GalleryConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryEdge"
	},
	GalleryEdge:{
		node:"Gallery"
	},
	HomePageConnection:{
		pageInfo:"PageInfo",
		edges:"HomePageEdge"
	},
	HomePageEdge:{
		node:"HomePage"
	},
	MediumConnection:{
		pageInfo:"PageInfo",
		edges:"MediumEdge"
	},
	MediumEdge:{
		node:"Medium"
	},
	MediumListConnection:{
		pageInfo:"PageInfo",
		edges:"MediumListEdge"
	},
	MediumListEdge:{
		node:"MediumList"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	TextListConnection:{
		pageInfo:"PageInfo",
		edges:"TextListEdge"
	},
	TextListEdge:{
		node:"TextList"
	},
	CarouselConnection:{
		pageInfo:"PageInfo",
		edges:"CarouselEdge"
	},
	CarouselEdge:{
		node:"Carousel"
	},
	ThemeConnection:{
		pageInfo:"PageInfo",
		edges:"ThemeEdge"
	},
	ThemeEdge:{
		node:"Theme"
	},
	BonusBannerConnection:{
		pageInfo:"PageInfo",
		edges:"BonusBannerEdge"
	},
	BonusBannerEdge:{
		node:"BonusBanner"
	},
	SideBySideConnection:{
		pageInfo:"PageInfo",
		edges:"SideBySideEdge"
	},
	SideBySideEdge:{
		node:"SideBySide"
	},
	HomePageLokalConnection:{
		pageInfo:"PageInfo",
		edges:"HomePageLokalEdge"
	},
	HomePageLokalEdge:{
		node:"HomePageLokal"
	},
	SiteSwitcherConnection:{
		pageInfo:"PageInfo",
		edges:"SiteSwitcherEdge"
	},
	SiteSwitcherEdge:{
		node:"SiteSwitcher"
	},
	JobsFormConnection:{
		pageInfo:"PageInfo",
		edges:"JobsFormEdge"
	},
	JobsFormEdge:{
		node:"JobsForm"
	},
	ImageTextListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageTextListEdge"
	},
	ImageTextListEdge:{
		node:"ImageTextList"
	},
	JobsFilterConnection:{
		pageInfo:"PageInfo",
		edges:"JobsFilterEdge"
	},
	JobsFilterEdge:{
		node:"JobsFilter"
	},
	File:{
		_meta:"FileMeta",
		id:"UUID",
		url:"String",
		size:"Int",
		type:"String"
	},
	FileMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta"
	},
	FileConnection:{
		pageInfo:"PageInfo",
		edges:"FileEdge"
	},
	FileEdge:{
		node:"File"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		itemsByImage:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ImageList",
		image:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	JobFormSubscriptionStatus:{
		_meta:"JobFormSubscriptionStatusMeta",
		id:"UUID",
		statusTitle:"String",
		statusColor:"String"
	},
	JobFormSubscriptionStatusMeta:{
		id:"FieldMeta",
		statusTitle:"FieldMeta",
		statusColor:"FieldMeta"
	},
	JobFormSubscriptionStatusConnection:{
		pageInfo:"PageInfo",
		edges:"JobFormSubscriptionStatusEdge"
	},
	JobFormSubscriptionStatusEdge:{
		node:"JobFormSubscriptionStatus"
	},
	StickyBarConnection:{
		pageInfo:"PageInfo",
		edges:"StickyBarEdge"
	},
	StickyBarEdge:{
		node:"StickyBar"
	},
	RecommendationFormConnection:{
		pageInfo:"PageInfo",
		edges:"RecommendationFormEdge"
	},
	RecommendationFormEdge:{
		node:"RecommendationForm"
	},
	InfoBoxConnection:{
		pageInfo:"PageInfo",
		edges:"InfoBoxEdge"
	},
	InfoBoxEdge:{
		node:"InfoBox"
	},
	QueryTransaction:{
		schema:"_Schema",
		s3DummyQuery:"String",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getContentReferenceLinkableItem:"ContentReferenceLinkableItem",
		listContentReferenceLinkableItem:"ContentReferenceLinkableItem",
		paginateContentReferenceLinkableItem:"ContentReferenceLinkableItemConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterLink:"FooterLink",
		listFooterLink:"FooterLink",
		paginateFooterLink:"FooterLinkConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getSeoDefault:"SeoDefault",
		listSeoDefault:"SeoDefault",
		paginateSeoDefault:"SeoDefaultConnection",
		getSeoDefaultLocale:"SeoDefaultLocale",
		listSeoDefaultLocale:"SeoDefaultLocale",
		paginateSeoDefaultLocale:"SeoDefaultLocaleConnection",
		getBusiness:"Business",
		listBusiness:"Business",
		paginateBusiness:"BusinessConnection",
		getBusinessPage:"BusinessPage",
		listBusinessPage:"BusinessPage",
		paginateBusinessPage:"BusinessPageConnection",
		getBusinessPageLocale:"BusinessPageLocale",
		listBusinessPageLocale:"BusinessPageLocale",
		paginateBusinessPageLocale:"BusinessPageLocaleConnection",
		getIcon:"Icon",
		listIcon:"Icon",
		paginateIcon:"IconConnection",
		getJobPage:"JobPage",
		listJobPage:"JobPage",
		paginateJobPage:"JobPageConnection",
		getJobPageLocale:"JobPageLocale",
		listJobPageLocale:"JobPageLocale",
		paginateJobPageLocale:"JobPageLocaleConnection",
		getKeyInfoItem:"KeyInfoItem",
		listKeyInfoItem:"KeyInfoItem",
		paginateKeyInfoItem:"KeyInfoItemConnection",
		getKeyInfoList:"KeyInfoList",
		listKeyInfoList:"KeyInfoList",
		paginateKeyInfoList:"KeyInfoListConnection",
		getContractType:"ContractType",
		listContractType:"ContractType",
		paginateContractType:"ContractTypeConnection",
		getContractTypeLocale:"ContractTypeLocale",
		listContractTypeLocale:"ContractTypeLocale",
		paginateContractTypeLocale:"ContractTypeLocaleConnection",
		getJobCity:"JobCity",
		listJobCity:"JobCity",
		paginateJobCity:"JobCityConnection",
		getJobCityLocale:"JobCityLocale",
		listJobCityLocale:"JobCityLocale",
		paginateJobCityLocale:"JobCityLocaleConnection",
		getJobPosition:"JobPosition",
		listJobPosition:"JobPosition",
		paginateJobPosition:"JobPositionConnection",
		getJobPositionLocale:"JobPositionLocale",
		listJobPositionLocale:"JobPositionLocale",
		paginateJobPositionLocale:"JobPositionLocaleConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getHomePage:"HomePage",
		listHomePage:"HomePage",
		paginateHomePage:"HomePageConnection",
		getHomePageLocale:"HomePageLocale",
		listHomePageLocale:"HomePageLocale",
		paginateHomePageLocale:"HomePageLocaleConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getMediumItem:"MediumItem",
		listMediumItem:"MediumItem",
		paginateMediumItem:"MediumItemConnection",
		getMediumList:"MediumList",
		listMediumList:"MediumList",
		paginateMediumList:"MediumListConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getCarousel:"Carousel",
		listCarousel:"Carousel",
		paginateCarousel:"CarouselConnection",
		getCarouselItem:"CarouselItem",
		listCarouselItem:"CarouselItem",
		paginateCarouselItem:"CarouselItemConnection",
		getTheme:"Theme",
		listTheme:"Theme",
		paginateTheme:"ThemeConnection",
		getBonusBanner:"BonusBanner",
		listBonusBanner:"BonusBanner",
		paginateBonusBanner:"BonusBannerConnection",
		getBonusBannerLocale:"BonusBannerLocale",
		listBonusBannerLocale:"BonusBannerLocale",
		paginateBonusBannerLocale:"BonusBannerLocaleConnection",
		getTile:"Tile",
		listTile:"Tile",
		paginateTile:"TileConnection",
		getSideBySide:"SideBySide",
		listSideBySide:"SideBySide",
		paginateSideBySide:"SideBySideConnection",
		getSideBySideItem:"SideBySideItem",
		listSideBySideItem:"SideBySideItem",
		paginateSideBySideItem:"SideBySideItemConnection",
		getHomePageLokal:"HomePageLokal",
		listHomePageLokal:"HomePageLokal",
		paginateHomePageLokal:"HomePageLokalConnection",
		getHomePageLokalLocale:"HomePageLokalLocale",
		listHomePageLokalLocale:"HomePageLokalLocale",
		paginateHomePageLokalLocale:"HomePageLokalLocaleConnection",
		getSiteSwitcher:"SiteSwitcher",
		listSiteSwitcher:"SiteSwitcher",
		paginateSiteSwitcher:"SiteSwitcherConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getJobsForm:"JobsForm",
		listJobsForm:"JobsForm",
		paginateJobsForm:"JobsFormConnection",
		getJobsFormLocale:"JobsFormLocale",
		listJobsFormLocale:"JobsFormLocale",
		paginateJobsFormLocale:"JobsFormLocaleConnection",
		getImageTextItem:"ImageTextItem",
		listImageTextItem:"ImageTextItem",
		paginateImageTextItem:"ImageTextItemConnection",
		getImageTextList:"ImageTextList",
		listImageTextList:"ImageTextList",
		paginateImageTextList:"ImageTextListConnection",
		getJobsFilter:"JobsFilter",
		listJobsFilter:"JobsFilter",
		paginateJobsFilter:"JobsFilterConnection",
		getJobsFilterLocale:"JobsFilterLocale",
		listJobsFilterLocale:"JobsFilterLocale",
		paginateJobsFilterLocale:"JobsFilterLocaleConnection",
		getFile:"File",
		listFile:"File",
		paginateFile:"FileConnection",
		validateCreateFile:"_ValidationResult",
		validateCreateJobFormSubscription:"_ValidationResult",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getJobFormSubscriptionStatus:"JobFormSubscriptionStatus",
		listJobFormSubscriptionStatus:"JobFormSubscriptionStatus",
		paginateJobFormSubscriptionStatus:"JobFormSubscriptionStatusConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getStickyBar:"StickyBar",
		listStickyBar:"StickyBar",
		paginateStickyBar:"StickyBarConnection",
		validateCreateStickyBar:"_ValidationResult",
		getRecommendationForm:"RecommendationForm",
		listRecommendationForm:"RecommendationForm",
		paginateRecommendationForm:"RecommendationFormConnection",
		getRecommendationFormLocale:"RecommendationFormLocale",
		listRecommendationFormLocale:"RecommendationFormLocale",
		paginateRecommendationFormLocale:"RecommendationFormLocaleConnection",
		validateCreateRecommendationFormSubscription:"_ValidationResult",
		getInfoBox:"InfoBox",
		listInfoBox:"InfoBox",
		paginateInfoBox:"InfoBoxConnection",
		validateCreateInfoBox:"_ValidationResult",
		getInfoBoxLocale:"InfoBoxLocale",
		listInfoBoxLocale:"InfoBoxLocale",
		paginateInfoBoxLocale:"InfoBoxLocaleConnection"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		generateUploadUrl:"S3SignedUpload",
		generateReadUrl:"S3SignedRead",
		createFile:"FileCreateResult",
		createJobFormSubscription:"JobFormSubscriptionCreateResult",
		createStickyBar:"StickyBarCreateResult",
		createRecommendationFormSubscription:"RecommendationFormSubscriptionCreateResult",
		createInfoBox:"InfoBoxCreateResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	S3SignedUpload:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String",
		publicUrl:"String"
	},
	S3Header:{
		key:"String",
		value:"String"
	},
	S3SignedRead:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String"
	},
	FileCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"File",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on FileCreateResult": "FileCreateResult",
		"...on JobFormSubscriptionCreateResult": "JobFormSubscriptionCreateResult",
		"...on StickyBarCreateResult": "StickyBarCreateResult",
		"...on RecommendationFormSubscriptionCreateResult": "RecommendationFormSubscriptionCreateResult",
		"...on InfoBoxCreateResult": "InfoBoxCreateResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	JobFormSubscriptionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult"
	},
	StickyBarCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"StickyBar",
		validation:"_ValidationResult"
	},
	RecommendationFormSubscriptionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult"
	},
	InfoBoxCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"InfoBox",
		validation:"_ValidationResult"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		generateUploadUrl:"S3SignedUpload",
		generateReadUrl:"S3SignedRead",
		createFile:"FileCreateResult",
		createJobFormSubscription:"JobFormSubscriptionCreateResult",
		createStickyBar:"StickyBarCreateResult",
		createRecommendationFormSubscription:"RecommendationFormSubscriptionCreateResult",
		createInfoBox:"InfoBoxCreateResult",
		query:"Query"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = GraphQLTypes["_Field"] | GraphQLTypes["MutationResult"]
export type ScalarCoders = {
	Json?: ScalarResolver;
	UUID?: ScalarResolver;
	DateTime?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["_Argument"] | GraphQLTypes["_PathFragment"]

export type ValueTypes = {
    ["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: AliasType<{
	schema?:ValueTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getContentReferenceLinkableItem?: [{	by: ValueTypes["ContentReferenceLinkableItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
listContentReferenceLinkableItem?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
paginateContentReferenceLinkableItem?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItemConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterLink?: [{	by: ValueTypes["FooterLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
listFooterLink?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
paginateFooterLink?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLinkConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getSeoDefault?: [{	by: ValueTypes["SeoDefaultUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefault"]],
listSeoDefault?: [{	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultConnection"]],
getSeoDefaultLocale?: [{	by: ValueTypes["SeoDefaultLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
listSeoDefaultLocale?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
paginateSeoDefaultLocale?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocaleConnection"]],
getBusiness?: [{	by: ValueTypes["BusinessUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>},ValueTypes["Business"]],
listBusiness?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Business"]],
paginateBusiness?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessConnection"]],
getBusinessPage?: [{	by: ValueTypes["BusinessPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
listBusinessPage?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
paginateBusinessPage?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageConnection"]],
getBusinessPageLocale?: [{	by: ValueTypes["BusinessPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
listBusinessPageLocale?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
paginateBusinessPageLocale?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocaleConnection"]],
getIcon?: [{	by: ValueTypes["IconUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>},ValueTypes["Icon"]],
listIcon?: [{	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["IconOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Icon"]],
paginateIcon?: [{	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["IconOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["IconConnection"]],
getJobPage?: [{	by: ValueTypes["JobPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
listJobPage?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
paginateJobPage?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageConnection"]],
getJobPageLocale?: [{	by: ValueTypes["JobPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
listJobPageLocale?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
paginateJobPageLocale?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocaleConnection"]],
getKeyInfoItem?: [{	by: ValueTypes["KeyInfoItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItem"]],
listKeyInfoItem?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItem"]],
paginateKeyInfoItem?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItemConnection"]],
getKeyInfoList?: [{	by: ValueTypes["KeyInfoListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
listKeyInfoList?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
paginateKeyInfoList?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoListConnection"]],
getContractType?: [{	by: ValueTypes["ContractTypeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
listContractType?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
paginateContractType?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeConnection"]],
getContractTypeLocale?: [{	by: ValueTypes["ContractTypeLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
listContractTypeLocale?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
paginateContractTypeLocale?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocaleConnection"]],
getJobCity?: [{	by: ValueTypes["JobCityUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
listJobCity?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
paginateJobCity?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityConnection"]],
getJobCityLocale?: [{	by: ValueTypes["JobCityLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
listJobCityLocale?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
paginateJobCityLocale?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocaleConnection"]],
getJobPosition?: [{	by: ValueTypes["JobPositionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
listJobPosition?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
paginateJobPosition?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionConnection"]],
getJobPositionLocale?: [{	by: ValueTypes["JobPositionLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
listJobPositionLocale?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
paginateJobPositionLocale?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocaleConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryConnection"]],
getHomePage?: [{	by: ValueTypes["HomePageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePage"]],
listHomePage?: [{	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePage"]],
paginateHomePage?: [{	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageConnection"]],
getHomePageLocale?: [{	by: ValueTypes["HomePageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
listHomePageLocale?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
paginateHomePageLocale?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocaleConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumConnection"]],
getMediumItem?: [{	by: ValueTypes["MediumItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumItem"]],
listMediumItem?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItem"]],
paginateMediumItem?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItemConnection"]],
getMediumList?: [{	by: ValueTypes["MediumListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
listMediumList?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
paginateMediumList?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumListConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TextListConnection"]],
getCarousel?: [{	by: ValueTypes["CarouselUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
listCarousel?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
paginateCarousel?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselConnection"]],
getCarouselItem?: [{	by: ValueTypes["CarouselItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
listCarouselItem?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
paginateCarouselItem?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItemConnection"]],
getTheme?: [{	by: ValueTypes["ThemeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
listTheme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThemeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
paginateTheme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThemeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ThemeConnection"]],
getBonusBanner?: [{	by: ValueTypes["BonusBannerUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBanner"]],
listBonusBanner?: [{	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBanner"]],
paginateBonusBanner?: [{	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerConnection"]],
getBonusBannerLocale?: [{	by: ValueTypes["BonusBannerLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
listBonusBannerLocale?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
paginateBonusBannerLocale?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocaleConnection"]],
getTile?: [{	by: ValueTypes["TileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
listTile?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
paginateTile?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TileConnection"]],
getSideBySide?: [{	by: ValueTypes["SideBySideUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
listSideBySide?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
paginateSideBySide?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideConnection"]],
getSideBySideItem?: [{	by: ValueTypes["SideBySideItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
listSideBySideItem?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
paginateSideBySideItem?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItemConnection"]],
getHomePageLokal?: [{	by: ValueTypes["HomePageLokalUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokal"]],
listHomePageLokal?: [{	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokal"]],
paginateHomePageLokal?: [{	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalConnection"]],
getHomePageLokalLocale?: [{	by: ValueTypes["HomePageLokalLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
listHomePageLokalLocale?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
paginateHomePageLokalLocale?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocaleConnection"]],
getSiteSwitcher?: [{	by: ValueTypes["SiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
listSiteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SiteSwitcherOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
paginateSiteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SiteSwitcherOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcherConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItemConnection"]],
getJobsForm?: [{	by: ValueTypes["JobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsForm"]],
listJobsForm?: [{	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsForm"]],
paginateJobsForm?: [{	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormConnection"]],
getJobsFormLocale?: [{	by: ValueTypes["JobsFormLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
listJobsFormLocale?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
paginateJobsFormLocale?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocaleConnection"]],
getImageTextItem?: [{	by: ValueTypes["ImageTextItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
listImageTextItem?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
paginateImageTextItem?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItemConnection"]],
getImageTextList?: [{	by: ValueTypes["ImageTextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
listImageTextList?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
paginateImageTextList?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextListConnection"]],
getJobsFilter?: [{	by: ValueTypes["JobsFilterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilter"]],
listJobsFilter?: [{	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilter"]],
paginateJobsFilter?: [{	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterConnection"]],
getJobsFilterLocale?: [{	by: ValueTypes["JobsFilterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
listJobsFilterLocale?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
paginateJobsFilterLocale?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocaleConnection"]],
getFile?: [{	by: ValueTypes["FileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>},ValueTypes["File"]],
listFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["File"]],
paginateFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FileConnection"]],
validateCreateFile?: [{	data: ValueTypes["FileCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateCreateJobFormSubscription?: [{	data: ValueTypes["JobFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getJobFormSubscriptionStatus?: [{	by: ValueTypes["JobFormSubscriptionStatusUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatus"]],
listJobFormSubscriptionStatus?: [{	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatus"]],
paginateJobFormSubscriptionStatus?: [{	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatusConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getStickyBar?: [{	by: ValueTypes["StickyBarUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
listStickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["StickyBarOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
paginateStickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["StickyBarOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["StickyBarConnection"]],
validateCreateStickyBar?: [{	data: ValueTypes["StickyBarCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getRecommendationForm?: [{	by: ValueTypes["RecommendationFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationForm"]],
listRecommendationForm?: [{	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationForm"]],
paginateRecommendationForm?: [{	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormConnection"]],
getRecommendationFormLocale?: [{	by: ValueTypes["RecommendationFormLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
listRecommendationFormLocale?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
paginateRecommendationFormLocale?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocaleConnection"]],
validateCreateRecommendationFormSubscription?: [{	data: ValueTypes["RecommendationFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getInfoBox?: [{	by: ValueTypes["InfoBoxUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
listInfoBox?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
paginateInfoBox?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxConnection"]],
validateCreateInfoBox?: [{	data: ValueTypes["InfoBoxCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getInfoBoxLocale?: [{	by: ValueTypes["InfoBoxLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
listInfoBoxLocale?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
paginateInfoBoxLocale?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocaleConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
		__typename?: boolean | `@${string}`
}>;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ValueTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesByLocale?: [{	by: ValueTypes["GeneralLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesBySeo?: [{	by: ValueTypes["GeneralLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["One"]:One;
	["GeneralLocale"]: AliasType<{
	_meta?:ValueTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["One"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	seoDefaults?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,
	businessPages?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,
	jobPages?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,
	contractTypes?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,
	jobCities?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,
	jobPositions?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,
	homePages?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,
	bonusBanners?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,
	homePageLokals?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,
	jobsForms?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	jobsFilters?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,
	general?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	recommendationForms?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,
	infoBoxes?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: string | undefined | null | Variable<any, string>,
	notEq?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	containsCI?: string | undefined | null | Variable<any, string>,
	startsWithCI?: string | undefined | null | Variable<any, string>,
	endsWithCI?: string | undefined | null | Variable<any, string>
};
	["FooterLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ico?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	dic?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	copyright?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	noteSecond?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	linksFirstColumn?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,
	linksSecondColumn?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,
	termsAndConditions?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	form?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bannerTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bannerSubtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	bannerLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	copyrightLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	bannerSecondBtn?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	recommendationForm?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,
	bannerSecondLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	instagramLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>
};
	["FooterWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>
};
	["FooterLinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterLinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterLinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	anchorLink?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	businessPage?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,
	jobPage?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,
	homePage?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,
	homePageLokal?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>
};
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>
};
	["ThemeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	backgroundColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	textColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonBackgroundColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonTextColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonBackgroundHoverColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonTextHoverColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonBorderColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	buttonBorderHoverColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	headerMenuColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ThemeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ThemeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>
};
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	linkables?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,
	textList?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,
	carousel?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,
	bonusBanner?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,
	sideBySide?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	showBoolean?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	isRed?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	focalPointX?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	focalPointY?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["ContentReferenceLinkableItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>
};
	["MediumListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["MediumListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["MediumListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>
};
	["MediumItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,
	medium?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["MediumItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["MediumItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>
};
	["MediumWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["MediumTypeCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>
};
	["MediumTypeCondition"]: {
	and?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["MediumTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["MediumType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["MediumType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["MediumType"] | undefined | null | Variable<any, string>
};
	["MediumType"]:MediumType;
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	videoInfo?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	poster?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>
};
	["TextListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>
};
	["TextItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>
};
	["CarouselWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["BackgroundCondition"] | undefined | null | Variable<any, string>,
	squareImages?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CarouselWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CarouselWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>
};
	["CarouselItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	linkUrl?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["CarouselItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CarouselItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>
};
	["BackgroundCondition"]: {
	and?: Array<ValueTypes["BackgroundCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BackgroundCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BackgroundCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["Background"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["Background"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Background"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["Background"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["Background"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["Background"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["Background"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["Background"] | undefined | null | Variable<any, string>
};
	["Background"]:Background;
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: boolean | undefined | null | Variable<any, string>,
	notEq?: boolean | undefined | null | Variable<any, string>,
	in?: Array<boolean> | undefined | null | Variable<any, string>,
	notIn?: Array<boolean> | undefined | null | Variable<any, string>,
	lt?: boolean | undefined | null | Variable<any, string>,
	lte?: boolean | undefined | null | Variable<any, string>,
	gt?: boolean | undefined | null | Variable<any, string>,
	gte?: boolean | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	tiles?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	bannerName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BonusBannerLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BonusBannerLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BonusBannerWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BonusBannerWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>
};
	["TileWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bonusBanner?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	lightBoxTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	lightBoxText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ligthBoxMedia?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,
	lightBoxSectionTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	lightBoxLinkLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	lightBoxForm?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	jobText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	genericText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	nameLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	emailLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	telefonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileTitleLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileFormatLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	formTextLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	submitLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	imageTextList?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,
	minimalize?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	successTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successBtnLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	successOtherJobsTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successOtherJobsCategoryTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successOtherJobsButtonTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionYesButton?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionNoButton?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileRemoveBtnLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	gdprLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobsFormLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobsFormLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	fileIlustration?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobsFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobsFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>
};
	["ImageTextListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageTextListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageTextListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>
};
	["ImageTextItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageTextItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageTextItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["BackgroundCondition"] | undefined | null | Variable<any, string>,
	alignment?: ValueTypes["ContentAlignmentCondition"] | undefined | null | Variable<any, string>,
	mediumSize?: ValueTypes["MediumSizeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SideBySideWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SideBySideWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,
	formButtonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SideBySideItemTypeCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SideBySideItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SideBySideItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideItemTypeCondition"]: {
	and?: Array<ValueTypes["SideBySideItemTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SideBySideItemTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SideBySideItemTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["SideBySideItemType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["SideBySideItemType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["SideBySideItemType"] | undefined | null | Variable<any, string>
};
	["SideBySideItemType"]:SideBySideItemType;
	["ContentAlignmentCondition"]: {
	and?: Array<ValueTypes["ContentAlignmentCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentAlignmentCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentAlignmentCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentAlignment"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentAlignment"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentAlignment"] | undefined | null | Variable<any, string>
};
	["ContentAlignment"]:ContentAlignment;
	["MediumSizeCondition"]: {
	and?: Array<ValueTypes["MediumSizeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["MediumSizeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["MediumSizeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["MediumSize"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["MediumSize"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["MediumSize"] | undefined | null | Variable<any, string>
};
	["MediumSize"]:MediumSize;
	["GalleryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>
};
	["GalleryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,
	infoBox?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	isLokal?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>
};
	["StickyBarWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	formButtonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["StickyBarWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["StickyBarWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>
};
	["InfoBoxWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	logoSize?: ValueTypes["LogoSizeCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["InfoBoxWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["InfoBoxWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>
};
	["LogoSizeCondition"]: {
	and?: Array<ValueTypes["LogoSizeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LogoSizeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LogoSizeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LogoSize"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["LogoSize"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>
};
	["LogoSize"]:LogoSize;
	["InfoBoxLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	web?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	instagram?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	facebook?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	openingHours?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,
	addressLink?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["InfoBoxLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["InfoBoxLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	keyInfo?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,
	mobileImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	contractType?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,
	jobCity?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,
	business?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,
	isActive?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	jobPosition?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	isSyncedToTabidoo?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>
};
	["ContractTypeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContractTypeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContractTypeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>
};
	["ContractTypeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContractTypeLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContractTypeLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobCityWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobCityWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobCityWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>
};
	["JobCityLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobCityLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobCityLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobPositionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobPositionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobPositionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>
};
	["JobPositionLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobPositionLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobPositionLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["DateTime"]:unknown;
	["KeyInfoListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["KeyInfoListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["KeyInfoListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>
};
	["KeyInfoItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	keyInfo?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["KeyInfoItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["KeyInfoItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>
};
	["IconWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["IconWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["IconWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HomePageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HomePageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["HomePageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HomePageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HomePageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>
};
	["SiteSwitcherWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SiteSwitcherWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SiteSwitcherWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HomePageLokalLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HomePageLokalLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HomePageLokalWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HomePageLokalWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	yourEmailLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	theirEmailLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	yourName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	sendBtnLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successSubtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	successButtonClose?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RecommendationFormLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RecommendationFormLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RecommendationFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RecommendationFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	menuLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	menuTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	menuText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	business?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,
	lokalEnterprise?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,
	businessSectionTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	lokalSectionTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	businessLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	lokalLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>
};
	["HeaderWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	logoMenu?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>
};
	["BusinessWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["BusinessWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BusinessWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SeoDefaultLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SeoDefaultLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SeoDefaultWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SeoDefaultWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	jobPositionLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	jobContractLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	jobCityLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	bannerTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bannerFormLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	bannerJobsForm?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	noJobTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	noJobBtnLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	noJobImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	noJobTitleForSpecificBusiness?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	deactivatedJob?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobsFilterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobsFilterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobsFilterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobsFilterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>
};
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
footers?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
genericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headers?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
seoDefaults?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
businessPages?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
jobPages?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
contractTypes?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
jobCities?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
jobPositions?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
homePages?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
bonusBanners?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
homePageLokals?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
jobsForms?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
jobsFilters?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
general?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
recommendationForms?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
infoBoxes?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
footersByRoot?: [{	by: ValueTypes["LocaleFootersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByTermsAndConditions?: [{	by: ValueTypes["LocaleFootersByTermsAndConditionsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByBannerImage?: [{	by: ValueTypes["LocaleFootersByBannerImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByBannerLink?: [{	by: ValueTypes["LocaleFootersByBannerLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByJobsForm?: [{	by: ValueTypes["LocaleFootersByJobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByCopyrightLink?: [{	by: ValueTypes["LocaleFootersByCopyrightLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByRecommendationForm?: [{	by: ValueTypes["LocaleFootersByRecommendationFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByBannerSecondLink?: [{	by: ValueTypes["LocaleFootersByBannerSecondLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByInstagramLink?: [{	by: ValueTypes["LocaleFootersByInstagramLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
genericPagesByRoot?: [{	by: ValueTypes["LocaleGenericPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ValueTypes["LocaleGenericPagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByLogo?: [{	by: ValueTypes["LocaleGenericPagesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByImage?: [{	by: ValueTypes["LocaleGenericPagesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ValueTypes["LocaleHeadersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
headersByBusinessLink?: [{	by: ValueTypes["LocaleHeadersByBusinessLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
headersByLokalLink?: [{	by: ValueTypes["LocaleHeadersByLokalLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
seoDefaultsByRoot?: [{	by: ValueTypes["LocaleSeoDefaultsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
seoDefaultsBySeo?: [{	by: ValueTypes["LocaleSeoDefaultsBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
businessPagesByRoot?: [{	by: ValueTypes["LocaleBusinessPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
businessPagesByContent?: [{	by: ValueTypes["LocaleBusinessPagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
businessPagesBySeo?: [{	by: ValueTypes["LocaleBusinessPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
businessPagesByLink?: [{	by: ValueTypes["LocaleBusinessPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
businessPagesByImage?: [{	by: ValueTypes["LocaleBusinessPagesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
jobPagesByRoot?: [{	by: ValueTypes["LocaleJobPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesByImage?: [{	by: ValueTypes["LocaleJobPagesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesByKeyInfo?: [{	by: ValueTypes["LocaleJobPagesByKeyInfoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesByContent?: [{	by: ValueTypes["LocaleJobPagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesBySeo?: [{	by: ValueTypes["LocaleJobPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesByLink?: [{	by: ValueTypes["LocaleJobPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
jobPagesByMobileImage?: [{	by: ValueTypes["LocaleJobPagesByMobileImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
contractTypesByRoot?: [{	by: ValueTypes["LocaleContractTypesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
contractTypesBySlug?: [{	by: ValueTypes["LocaleContractTypesBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
jobCitiesByRoot?: [{	by: ValueTypes["LocaleJobCitiesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
jobCitiesBySlug?: [{	by: ValueTypes["LocaleJobCitiesBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
jobPositionsByRoot?: [{	by: ValueTypes["LocaleJobPositionsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
jobPositionsBySlug?: [{	by: ValueTypes["LocaleJobPositionsBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
homePagesByRoot?: [{	by: ValueTypes["LocaleHomePagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesByButton?: [{	by: ValueTypes["LocaleHomePagesByButtonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesByContent?: [{	by: ValueTypes["LocaleHomePagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesBySeo?: [{	by: ValueTypes["LocaleHomePagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesByLink?: [{	by: ValueTypes["LocaleHomePagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesByLogo?: [{	by: ValueTypes["LocaleHomePagesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesBySiteSwitcher?: [{	by: ValueTypes["LocaleHomePagesBySiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePagesByIlustration?: [{	by: ValueTypes["LocaleHomePagesByIlustrationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
bonusBannersByRoot?: [{	by: ValueTypes["LocaleBonusBannersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
bonusBannersByTiles?: [{	by: ValueTypes["LocaleBonusBannersByTilesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
bonusBannersByLogo?: [{	by: ValueTypes["LocaleBonusBannersByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
homePageLokalsByRoot?: [{	by: ValueTypes["LocaleHomePageLokalsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsByLogo?: [{	by: ValueTypes["LocaleHomePageLokalsByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsByButton?: [{	by: ValueTypes["LocaleHomePageLokalsByButtonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsByContent?: [{	by: ValueTypes["LocaleHomePageLokalsByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsBySeo?: [{	by: ValueTypes["LocaleHomePageLokalsBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsByLink?: [{	by: ValueTypes["LocaleHomePageLokalsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsBySiteSwitcher?: [{	by: ValueTypes["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
homePageLokalsByIlustration?: [{	by: ValueTypes["LocaleHomePageLokalsByIlustrationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
jobsFormsByRoot?: [{	by: ValueTypes["LocaleJobsFormsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
jobsFormsByImageTextList?: [{	by: ValueTypes["LocaleJobsFormsByImageTextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
jobsFormsByLogo?: [{	by: ValueTypes["LocaleJobsFormsByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
jobsFormsBySuccessImage?: [{	by: ValueTypes["LocaleJobsFormsBySuccessImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
jobsFiltersByRoot?: [{	by: ValueTypes["LocaleJobsFiltersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
jobsFiltersByBannerJobsForm?: [{	by: ValueTypes["LocaleJobsFiltersByBannerJobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
jobsFiltersByBannerImage?: [{	by: ValueTypes["LocaleJobsFiltersByBannerImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
jobsFiltersByNoJobImage?: [{	by: ValueTypes["LocaleJobsFiltersByNoJobImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
generalByRoot?: [{	by: ValueTypes["LocaleGeneralByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
generalBySeo?: [{	by: ValueTypes["LocaleGeneralBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
recommendationFormsByRoot?: [{	by: ValueTypes["LocaleRecommendationFormsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
infoBoxesByRoot?: [{	by: ValueTypes["LocaleInfoBoxesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
paginateFooters?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
paginateGenericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
paginateSeoDefaults?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocaleConnection"]],
paginateBusinessPages?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocaleConnection"]],
paginateJobPages?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocaleConnection"]],
paginateContractTypes?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocaleConnection"]],
paginateJobCities?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocaleConnection"]],
paginateJobPositions?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocaleConnection"]],
paginateHomePages?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocaleConnection"]],
paginateBonusBanners?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocaleConnection"]],
paginateHomePageLokals?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocaleConnection"]],
paginateJobsForms?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocaleConnection"]],
paginateJobsFilters?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocaleConnection"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
paginateRecommendationForms?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocaleConnection"]],
paginateInfoBoxes?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	footers?:ValueTypes["FieldMeta"],
	genericPages?:ValueTypes["FieldMeta"],
	headers?:ValueTypes["FieldMeta"],
	seoDefaults?:ValueTypes["FieldMeta"],
	businessPages?:ValueTypes["FieldMeta"],
	jobPages?:ValueTypes["FieldMeta"],
	contractTypes?:ValueTypes["FieldMeta"],
	jobCities?:ValueTypes["FieldMeta"],
	jobPositions?:ValueTypes["FieldMeta"],
	homePages?:ValueTypes["FieldMeta"],
	bonusBanners?:ValueTypes["FieldMeta"],
	homePageLokals?:ValueTypes["FieldMeta"],
	jobsForms?:ValueTypes["FieldMeta"],
	jobsFilters?:ValueTypes["FieldMeta"],
	general?:ValueTypes["FieldMeta"],
	recommendationForms?:ValueTypes["FieldMeta"],
	infoBoxes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ValueTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	ico?:boolean | `@${string}`,
	dic?:boolean | `@${string}`,
	copyright?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	noteSecond?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
linksFirstColumn?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
linksSecondColumn?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
termsAndConditions?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	form?:boolean | `@${string}`,
	bannerTitle?:boolean | `@${string}`,
	bannerSubtitle?:boolean | `@${string}`,
bannerImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
bannerLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
jobsForm?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
copyrightLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	bannerSecondBtn?:boolean | `@${string}`,
recommendationForm?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
bannerSecondLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
instagramLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLinksFirstColumn?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLinkConnection"]],
paginateLinksSecondColumn?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLinkConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	ico?:ValueTypes["FieldMeta"],
	dic?:ValueTypes["FieldMeta"],
	copyright?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	noteSecond?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	linksFirstColumn?:ValueTypes["FieldMeta"],
	linksSecondColumn?:ValueTypes["FieldMeta"],
	termsAndConditions?:ValueTypes["FieldMeta"],
	form?:ValueTypes["FieldMeta"],
	bannerTitle?:ValueTypes["FieldMeta"],
	bannerSubtitle?:ValueTypes["FieldMeta"],
	bannerImage?:ValueTypes["FieldMeta"],
	bannerLink?:ValueTypes["FieldMeta"],
	jobsForm?:ValueTypes["FieldMeta"],
	copyrightLink?:ValueTypes["FieldMeta"],
	bannerSecondBtn?:ValueTypes["FieldMeta"],
	recommendationForm?:ValueTypes["FieldMeta"],
	bannerSecondLink?:ValueTypes["FieldMeta"],
	instagramLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Footer"]: AliasType<{
	_meta?:ValueTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByLocale?: [{	by: ValueTypes["FooterLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByTermsAndConditions?: [{	by: ValueTypes["FooterLocalesByTermsAndConditionsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByBannerImage?: [{	by: ValueTypes["FooterLocalesByBannerImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByBannerLink?: [{	by: ValueTypes["FooterLocalesByBannerLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByJobsForm?: [{	by: ValueTypes["FooterLocalesByJobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByCopyrightLink?: [{	by: ValueTypes["FooterLocalesByCopyrightLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByRecommendationForm?: [{	by: ValueTypes["FooterLocalesByRecommendationFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByBannerSecondLink?: [{	by: ValueTypes["FooterLocalesByBannerSecondLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByInstagramLink?: [{	by: ValueTypes["FooterLocalesByInstagramLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ico?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	dic?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	copyright?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noteSecond?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	termsAndConditions?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	form?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bannerTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bannerSubtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	bannerLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleOrderBy"] | undefined | null | Variable<any, string>,
	copyrightLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	bannerSecondBtn?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	recommendationForm?: ValueTypes["RecommendationFormLocaleOrderBy"] | undefined | null | Variable<any, string>,
	bannerSecondLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	instagramLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["OrderDirection"]:OrderDirection;
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	anchorLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	businessPage?: ValueTypes["BusinessPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	jobPage?: ValueTypes["JobPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	homePage?: ValueTypes["HomePageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	homePageLokal?: ValueTypes["HomePageLokalLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>
};
	["ThemeOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	backgroundColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	textColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonBackgroundColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonTextColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonBackgroundHoverColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonTextHoverColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonBorderColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonBorderHoverColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	headerMenuColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	focalPointX?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	focalPointY?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BusinessPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarOrderBy"] | undefined | null | Variable<any, string>,
	infoBox?: ValueTypes["InfoBoxOrderBy"] | undefined | null | Variable<any, string>
};
	["BusinessPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>,
	isLokal?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["StickyBarOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	formButtonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["JobsFormLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	genericText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	nameLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	emailLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	telefonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileTitleLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileFormatLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	formTextLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	submitLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFormOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	imageTextList?: ValueTypes["ImageTextListOrderBy"] | undefined | null | Variable<any, string>,
	minimalize?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	successTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successBtnLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	successOtherJobsTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successOtherJobsCategoryTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successOtherJobsButtonTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionYesButton?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successOtherJobsSelectionNoButton?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileRemoveBtnLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	gdprLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobsFormOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileIlustration?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageTextListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["InfoBoxOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logoSize?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["JobPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	keyInfo?: ValueTypes["KeyInfoListOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarOrderBy"] | undefined | null | Variable<any, string>,
	mobileImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["JobPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>,
	contractType?: ValueTypes["ContractTypeOrderBy"] | undefined | null | Variable<any, string>,
	jobCity?: ValueTypes["JobCityOrderBy"] | undefined | null | Variable<any, string>,
	business?: ValueTypes["BusinessPageOrderBy"] | undefined | null | Variable<any, string>,
	isActive?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobPosition?: ValueTypes["JobPositionOrderBy"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isSyncedToTabidoo?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContractTypeOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobCityOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobPositionOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["KeyInfoListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["HomePageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherOrderBy"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarOrderBy"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>
};
	["HomePageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>
};
	["SiteSwitcherOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageLokalOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherOrderBy"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>,
	stickyBar?: ValueTypes["StickyBarOrderBy"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>
};
	["HomePageLokalOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeOrderBy"] | undefined | null | Variable<any, string>
};
	["RecommendationFormLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	yourEmailLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	theirEmailLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	yourName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	sendBtnLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successSubtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successButtonClose?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["RecommendationFormOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["RecommendationFormOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seoDefaults?: ValueTypes["SeoDefaultLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	businessPages?: ValueTypes["BusinessPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobPages?: ValueTypes["JobPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	contractTypes?: ValueTypes["ContractTypeLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobCities?: ValueTypes["JobCityLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobPositions?: ValueTypes["JobPositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	homePages?: ValueTypes["HomePageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	bonusBanners?: ValueTypes["BonusBannerLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	homePageLokals?: ValueTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobsForms?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobsFilters?: ValueTypes["JobsFilterLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	general?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	recommendationForms?: ValueTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	infoBoxes?: ValueTypes["InfoBoxLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	termsAndConditions?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	bannerLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	copyrightLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	recommendationForm?: ValueTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	bannerSecondLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	instagramLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["JobsFormLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFormUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	imageTextList?: ValueTypes["ImageTextListUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	fileIlustration?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageTextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageTextItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageTextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["RecommendationFormUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	successImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	linkables?: ValueTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceLinkableItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	businessPage?: ValueTypes["BusinessPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobPage?: ValueTypes["JobPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	homePage?: ValueTypes["HomePageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	homePageLokal?: ValueTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BusinessPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BusinessPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	keyInfo?: ValueTypes["KeyInfoListUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	mobileImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["KeyInfoListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["KeyInfoItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["KeyInfoItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["HomePageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HomePageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ThemeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["SiteSwitcherUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GalleryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["GalleryItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GalleryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HomePageLokalUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>,
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	theme?: ValueTypes["ThemeUniqueWhere"] | undefined | null | Variable<any, string>
};
	["MediumListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["MediumItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["MediumItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	businessLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	lokalLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	logoMenu?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["SeoDefaultUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["SeoDefaultLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContractTypeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ContractTypeUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>
};
	["ContractTypeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ContractTypeLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobCityLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobCityUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>
};
	["JobCityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobCityLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPositionLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPositionUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>
};
	["JobPositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobPositionLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BonusBannerUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	tiles?: ValueTypes["TileUniqueWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["BonusBannerLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	ligthBoxMedia?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFilterUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	bannerJobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	noJobImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["JobsFilterLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["InfoBoxLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["InfoBoxUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["InfoBoxUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["InfoBoxLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByBannerLinkUniqueWhere"]: {
	bannerLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByJobsFormUniqueWhere"]: {
	jobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ValueTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByInstagramLinkUniqueWhere"]: {
	instagramLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ValueTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLink"]: AliasType<{
	_meta?:ValueTypes["FooterLinkMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	anchorLink?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
	anchorLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
genericPage?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
businessPage?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
jobPage?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
homePage?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
homePageLokal?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
	genericPage?:ValueTypes["FieldMeta"],
	businessPage?:ValueTypes["FieldMeta"],
	jobPage?:ValueTypes["FieldMeta"],
	homePage?:ValueTypes["FieldMeta"],
	homePageLokal?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocale"]: AliasType<{
	_meta?:ValueTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	text?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ValueTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
localesByLocale?: [{	by: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByContent?: [{	by: ValueTypes["GenericPageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ValueTypes["GenericPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByLink?: [{	by: ValueTypes["GenericPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByLogo?: [{	by: ValueTypes["GenericPageLocalesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByImage?: [{	by: ValueTypes["GenericPageLocalesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Theme"]: AliasType<{
	_meta?:ValueTypes["ThemeMeta"],
	id?:boolean | `@${string}`,
	backgroundColor?:boolean | `@${string}`,
	textColor?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	buttonBackgroundColor?:boolean | `@${string}`,
	buttonTextColor?:boolean | `@${string}`,
	buttonBackgroundHoverColor?:boolean | `@${string}`,
	buttonTextHoverColor?:boolean | `@${string}`,
	buttonBorderColor?:boolean | `@${string}`,
	buttonBorderHoverColor?:boolean | `@${string}`,
	headerMenuColor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ThemeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	backgroundColor?:ValueTypes["FieldMeta"],
	textColor?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	buttonBackgroundColor?:ValueTypes["FieldMeta"],
	buttonTextColor?:ValueTypes["FieldMeta"],
	buttonBackgroundHoverColor?:ValueTypes["FieldMeta"],
	buttonTextHoverColor?:ValueTypes["FieldMeta"],
	buttonBorderColor?:ValueTypes["FieldMeta"],
	buttonBorderHoverColor?:ValueTypes["FieldMeta"],
	headerMenuColor?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLinkables?: [{	by: ValueTypes["ContentBlockReferencesByLinkablesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLink?: [{	by: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByMedia?: [{	by: ValueTypes["ContentBlockReferencesByMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
linkables?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
	secondaryText?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
media?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
carousel?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
bonusBanner?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
sideBySide?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
	showBoolean?:boolean | `@${string}`,
	isRed?:boolean | `@${string}`,
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
linkablesByItem?: [{	by: ValueTypes["ContentReferenceLinkablesByItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
paginateLinkables?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	linkables?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	media?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	carousel?:ValueTypes["FieldMeta"],
	bonusBanner?:ValueTypes["FieldMeta"],
	sideBySide?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	showBoolean?:ValueTypes["FieldMeta"],
	isRed?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
	focalPointX?:boolean | `@${string}`,
	focalPointY?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	fileName?:ValueTypes["FieldMeta"],
	focalPointX?:ValueTypes["FieldMeta"],
	focalPointY?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItem"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceLinkableItemMeta"],
	id?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
reference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	reference?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	reference?: ValueTypes["ContentReferenceOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListOrderBy"] | undefined | null | Variable<any, string>,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null | Variable<any, string>,
	carousel?: ValueTypes["CarouselOrderBy"] | undefined | null | Variable<any, string>,
	bonusBanner?: ValueTypes["BonusBannerLocaleOrderBy"] | undefined | null | Variable<any, string>,
	sideBySide?: ValueTypes["SideBySideOrderBy"] | undefined | null | Variable<any, string>,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>,
	showBoolean?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isRed?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["MediumListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["CarouselOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	squareImages?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["BonusBannerOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	bannerName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["BonusBannerOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SideBySideOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	background?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	alignment?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	mediumSize?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	videoInfo?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	poster?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["MediumList"]: AliasType<{
	_meta?:ValueTypes["MediumListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItem"]],
paginateItems?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItem"]: AliasType<{
	_meta?:ValueTypes["MediumItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Medium"]: AliasType<{
	_meta?:ValueTypes["MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	videoInfo?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	videoInfo?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["MediumListOrderBy"] | undefined | null | Variable<any, string>,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null | Variable<any, string>
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>
};
	["MediumItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemEdge"]: AliasType<{
	node?:ValueTypes["MediumItem"],
		__typename?: boolean | `@${string}`
}>;
	["TextList"]: AliasType<{
	_meta?:ValueTypes["TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TextItem"]],
paginateItems?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TextListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItem"]: AliasType<{
	_meta?:ValueTypes["TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["TextListOrderBy"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemEdge"]: AliasType<{
	node?:ValueTypes["TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["Carousel"]: AliasType<{
	_meta?:ValueTypes["CarouselMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
	background?:boolean | `@${string}`,
	squareImages?:boolean | `@${string}`,
itemsByImage?: [{	by: ValueTypes["CarouselItemsByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
itemsByLinkUrl?: [{	by: ValueTypes["CarouselItemsByLinkUrlUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
paginateItems?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CarouselMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	squareImages?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItem"]: AliasType<{
	_meta?:ValueTypes["CarouselItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	name?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
linkUrl?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	linkUrl?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["CarouselOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	linkUrl?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["CarouselItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CarouselItemsByLinkUrlUniqueWhere"]: {
	linkUrl?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CarouselItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CarouselItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemEdge"]: AliasType<{
	node?:ValueTypes["CarouselItem"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocale"]: AliasType<{
	_meta?:ValueTypes["BonusBannerLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBanner"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
tiles?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	bannerName?:boolean | `@${string}`,
tilesByImage?: [{	by: ValueTypes["BonusBannerLocaleTilesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
tilesByLigthBoxMedia?: [{	by: ValueTypes["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
paginateTiles?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TileConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	tiles?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	bannerName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBanner"]: AliasType<{
	_meta?:ValueTypes["BonusBannerMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
localesByLocale?: [{	by: ValueTypes["BonusBannerLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
localesByTiles?: [{	by: ValueTypes["BonusBannerLocalesByTilesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
localesByLogo?: [{	by: ValueTypes["BonusBannerLocalesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocalesByTilesUniqueWhere"]: {
	tiles?: ValueTypes["TileUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocalesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BonusBannerLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocaleEdge"]: AliasType<{
	node?:ValueTypes["BonusBannerLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Tile"]: AliasType<{
	_meta?:ValueTypes["TileMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
bonusBanner?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	lightBoxTitle?:boolean | `@${string}`,
	lightBoxText?:boolean | `@${string}`,
ligthBoxMedia?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
	lightBoxSectionTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lightBoxLinkLabel?:boolean | `@${string}`,
lightBoxForm?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["TileMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	bonusBanner?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	lightBoxTitle?:ValueTypes["FieldMeta"],
	lightBoxText?:ValueTypes["FieldMeta"],
	ligthBoxMedia?:ValueTypes["FieldMeta"],
	lightBoxSectionTitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lightBoxLinkLabel?:ValueTypes["FieldMeta"],
	lightBoxForm?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocale"]: AliasType<{
	_meta?:ValueTypes["JobsFormLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	jobText?:boolean | `@${string}`,
	genericText?:boolean | `@${string}`,
	nameLabel?:boolean | `@${string}`,
	emailLabel?:boolean | `@${string}`,
	telefonLabel?:boolean | `@${string}`,
	fileTitleLabel?:boolean | `@${string}`,
	fileFormatLabel?:boolean | `@${string}`,
	formTextLabel?:boolean | `@${string}`,
	submitLabel?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsForm"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
imageTextList?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
	minimalize?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	successTitle?:boolean | `@${string}`,
	successText?:boolean | `@${string}`,
	successBtnLabel?:boolean | `@${string}`,
successImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	successOtherJobsTitle?:boolean | `@${string}`,
	successOtherJobsCategoryTitle?:boolean | `@${string}`,
	successOtherJobsButtonTitle?:boolean | `@${string}`,
	successOtherJobsSelectionTitle?:boolean | `@${string}`,
	successOtherJobsSelectionYesButton?:boolean | `@${string}`,
	successOtherJobsSelectionNoButton?:boolean | `@${string}`,
	fileRemoveBtnLabel?:boolean | `@${string}`,
	gdprLabel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	jobText?:ValueTypes["FieldMeta"],
	genericText?:ValueTypes["FieldMeta"],
	nameLabel?:ValueTypes["FieldMeta"],
	emailLabel?:ValueTypes["FieldMeta"],
	telefonLabel?:ValueTypes["FieldMeta"],
	fileTitleLabel?:ValueTypes["FieldMeta"],
	fileFormatLabel?:ValueTypes["FieldMeta"],
	formTextLabel?:ValueTypes["FieldMeta"],
	submitLabel?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	imageTextList?:ValueTypes["FieldMeta"],
	minimalize?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	successTitle?:ValueTypes["FieldMeta"],
	successText?:ValueTypes["FieldMeta"],
	successBtnLabel?:ValueTypes["FieldMeta"],
	successImage?:ValueTypes["FieldMeta"],
	successOtherJobsTitle?:ValueTypes["FieldMeta"],
	successOtherJobsCategoryTitle?:ValueTypes["FieldMeta"],
	successOtherJobsButtonTitle?:ValueTypes["FieldMeta"],
	successOtherJobsSelectionTitle?:ValueTypes["FieldMeta"],
	successOtherJobsSelectionYesButton?:ValueTypes["FieldMeta"],
	successOtherJobsSelectionNoButton?:ValueTypes["FieldMeta"],
	fileRemoveBtnLabel?:ValueTypes["FieldMeta"],
	gdprLabel?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsForm"]: AliasType<{
	_meta?:ValueTypes["JobsFormMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
fileIlustration?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["JobsFormLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
localesByImageTextList?: [{	by: ValueTypes["JobsFormLocalesByImageTextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
localesByLogo?: [{	by: ValueTypes["JobsFormLocalesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
localesBySuccessImage?: [{	by: ValueTypes["JobsFormLocalesBySuccessImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
paginateLocales?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	fileIlustration?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormLocalesByImageTextListUniqueWhere"]: {
	imageTextList?: ValueTypes["ImageTextListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormLocalesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormLocalesBySuccessImageUniqueWhere"]: {
	successImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFormLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobsFormLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocaleEdge"]: AliasType<{
	node?:ValueTypes["JobsFormLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextList"]: AliasType<{
	_meta?:ValueTypes["ImageTextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
itemsByImage?: [{	by: ValueTypes["ImageTextListItemsByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItem"]: AliasType<{
	_meta?:ValueTypes["ImageTextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageTextListOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageTextListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageTextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageTextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemEdge"]: AliasType<{
	node?:ValueTypes["ImageTextItem"],
		__typename?: boolean | `@${string}`
}>;
	["TileOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bonusBanner?: ValueTypes["BonusBannerLocaleOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	lightBoxTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	lightBoxText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ligthBoxMedia?: ValueTypes["MediumListOrderBy"] | undefined | null | Variable<any, string>,
	lightBoxSectionTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	lightBoxLinkLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	lightBoxForm?: ValueTypes["JobsFormLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleTilesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"]: {
	ligthBoxMedia?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TileConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TileEdge"]: AliasType<{
	node?:ValueTypes["Tile"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySide"]: AliasType<{
	_meta?:ValueTypes["SideBySideMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
	background?:boolean | `@${string}`,
	alignment?:boolean | `@${string}`,
	mediumSize?:boolean | `@${string}`,
itemsByMedia?: [{	by: ValueTypes["SideBySideItemsByMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
paginateItems?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	alignment?:ValueTypes["FieldMeta"],
	mediumSize?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItem"]: AliasType<{
	_meta?:ValueTypes["SideBySideItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
media?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
	formButtonLabel?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
jobsForm?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	media?:ValueTypes["FieldMeta"],
	formButtonLabel?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	jobsForm?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["SideBySideOrderBy"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListOrderBy"] | undefined | null | Variable<any, string>,
	formButtonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["JobsFormLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["SideBySideItemsByMediaUniqueWhere"]: {
	media?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SideBySideItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemEdge"]: AliasType<{
	node?:ValueTypes["SideBySideItem"],
		__typename?: boolean | `@${string}`
}>;
	["Gallery"]: AliasType<{
	_meta?:ValueTypes["GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
itemsByImage?: [{	by: ValueTypes["GalleryItemsByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
paginateItems?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ValueTypes["GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["GalleryOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["GalleryItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkablesByItemUniqueWhere"]: {
	item?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceLinkableItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceLinkableItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemEdge"]: AliasType<{
	node?:ValueTypes["ContentReferenceLinkableItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockReferencesByLinkablesUniqueWhere"]: {
	linkables?: ValueTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByMediaUniqueWhere"]: {
	media?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	noIndex?:ValueTypes["FieldMeta"],
	noFollow?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocale"]: AliasType<{
	_meta?:ValueTypes["BusinessPageLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	subtitle?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
stickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
infoBox?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	stickyBar?:ValueTypes["FieldMeta"],
	infoBox?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPage"]: AliasType<{
	_meta?:ValueTypes["BusinessPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
	isLokal?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["BusinessPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
localesByContent?: [{	by: ValueTypes["BusinessPageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
localesBySeo?: [{	by: ValueTypes["BusinessPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
localesByLink?: [{	by: ValueTypes["BusinessPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
localesByImage?: [{	by: ValueTypes["BusinessPageLocalesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
	isLokal?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocalesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["BusinessPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBar"]: AliasType<{
	_meta?:ValueTypes["StickyBarMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	formButtonLabel?:boolean | `@${string}`,
jobsForm?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	formButtonLabel?:ValueTypes["FieldMeta"],
	jobsForm?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBox"]: AliasType<{
	_meta?:ValueTypes["InfoBoxMeta"],
	id?:boolean | `@${string}`,
	logoSize?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
locales?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
localesByLocale?: [{	by: ValueTypes["InfoBoxLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
paginateLocales?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	logoSize?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocale"]: AliasType<{
	_meta?:ValueTypes["InfoBoxLocaleMeta"],
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	web?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	facebook?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
openingHours?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
	addressLink?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	web?:ValueTypes["FieldMeta"],
	instagram?:ValueTypes["FieldMeta"],
	facebook?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	openingHours?:ValueTypes["FieldMeta"],
	addressLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	web?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	instagram?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	facebook?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["InfoBoxOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	openingHours?: ValueTypes["TextListOrderBy"] | undefined | null | Variable<any, string>,
	addressLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["InfoBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["InfoBoxLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InfoBoxLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleEdge"]: AliasType<{
	node?:ValueTypes["InfoBoxLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocale"]: AliasType<{
	_meta?:ValueTypes["JobPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
keyInfo?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	email?:boolean | `@${string}`,
stickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
mobileImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	keyInfo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	stickyBar?:ValueTypes["FieldMeta"],
	mobileImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPage"]: AliasType<{
	_meta?:ValueTypes["JobPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
contractType?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
jobCity?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
business?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
	isActive?:boolean | `@${string}`,
jobPosition?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
	publishedAt?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isSyncedToTabidoo?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["JobPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesByImage?: [{	by: ValueTypes["JobPageLocalesByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesByKeyInfo?: [{	by: ValueTypes["JobPageLocalesByKeyInfoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesByContent?: [{	by: ValueTypes["JobPageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesBySeo?: [{	by: ValueTypes["JobPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesByLink?: [{	by: ValueTypes["JobPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
localesByMobileImage?: [{	by: ValueTypes["JobPageLocalesByMobileImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
	contractType?:ValueTypes["FieldMeta"],
	jobCity?:ValueTypes["FieldMeta"],
	business?:ValueTypes["FieldMeta"],
	isActive?:ValueTypes["FieldMeta"],
	jobPosition?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	isSyncedToTabidoo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractType"]: AliasType<{
	_meta?:ValueTypes["ContractTypeMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
localesByLocale?: [{	by: ValueTypes["ContractTypeLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocale"]: AliasType<{
	_meta?:ValueTypes["ContractTypeLocaleMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ContractTypeOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContractTypeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContractTypeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContractTypeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleEdge"]: AliasType<{
	node?:ValueTypes["ContractTypeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobCity"]: AliasType<{
	_meta?:ValueTypes["JobCityMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
localesByLocale?: [{	by: ValueTypes["JobCityLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
paginateLocales?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobCityMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocale"]: AliasType<{
	_meta?:ValueTypes["JobCityLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobCityOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobCityLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobCityLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobCityLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleEdge"]: AliasType<{
	node?:ValueTypes["JobCityLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPosition"]: AliasType<{
	_meta?:ValueTypes["JobPositionMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
jobs?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
localesByLocale?: [{	by: ValueTypes["JobPositionLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
jobsByLocales?: [{	by: ValueTypes["JobPositionJobsByLocalesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
paginateLocales?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocaleConnection"]],
paginateJobs?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	jobs?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocale"]: AliasType<{
	_meta?:ValueTypes["JobPositionLocaleMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobPositionOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobPositionLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPositionJobsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["JobPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPositionLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobPositionLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleEdge"]: AliasType<{
	node?:ValueTypes["JobPositionLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageEdge"]: AliasType<{
	node?:ValueTypes["JobPage"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesByKeyInfoUniqueWhere"]: {
	keyInfo?: ValueTypes["KeyInfoListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocalesByMobileImageUniqueWhere"]: {
	mobileImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["JobPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoList"]: AliasType<{
	_meta?:ValueTypes["KeyInfoListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItem"]],
paginateItems?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItem"]: AliasType<{
	_meta?:ValueTypes["KeyInfoItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	keyInfo?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
icon?: [{	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>},ValueTypes["Icon"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	keyInfo?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Icon"]: AliasType<{
	_meta?:ValueTypes["IconMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["IconMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	keyInfo?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["KeyInfoListOrderBy"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["IconOrderBy"] | undefined | null | Variable<any, string>
};
	["IconOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["KeyInfoItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["KeyInfoItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemEdge"]: AliasType<{
	node?:ValueTypes["KeyInfoItem"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocale"]: AliasType<{
	_meta?:ValueTypes["HomePageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
button?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
siteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
stickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
	text?:boolean | `@${string}`,
ilustration?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	button?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	siteSwitcher?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	stickyBar?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	ilustration?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePage"]: AliasType<{
	_meta?:ValueTypes["HomePageMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
localesByLocale?: [{	by: ValueTypes["HomePageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesByButton?: [{	by: ValueTypes["HomePageLocalesByButtonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesByContent?: [{	by: ValueTypes["HomePageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesBySeo?: [{	by: ValueTypes["HomePageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesByLink?: [{	by: ValueTypes["HomePageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesByLogo?: [{	by: ValueTypes["HomePageLocalesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesBySiteSwitcher?: [{	by: ValueTypes["HomePageLocalesBySiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
localesByIlustration?: [{	by: ValueTypes["HomePageLocalesByIlustrationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesByButtonUniqueWhere"]: {
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HomePageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocaleEdge"]: AliasType<{
	node?:ValueTypes["HomePageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcher"]: AliasType<{
	_meta?:ValueTypes["SiteSwitcherMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocale"]: AliasType<{
	_meta?:ValueTypes["HomePageLokalLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokal"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
button?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
siteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
stickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
	text?:boolean | `@${string}`,
ilustration?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	button?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	siteSwitcher?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	stickyBar?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	ilustration?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokal"]: AliasType<{
	_meta?:ValueTypes["HomePageLokalMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
theme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
localesByLocale?: [{	by: ValueTypes["HomePageLokalLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesByLogo?: [{	by: ValueTypes["HomePageLokalLocalesByLogoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesByButton?: [{	by: ValueTypes["HomePageLokalLocalesByButtonUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesByContent?: [{	by: ValueTypes["HomePageLokalLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesBySeo?: [{	by: ValueTypes["HomePageLokalLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesByLink?: [{	by: ValueTypes["HomePageLokalLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesBySiteSwitcher?: [{	by: ValueTypes["HomePageLokalLocalesBySiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
localesByIlustration?: [{	by: ValueTypes["HomePageLokalLocalesByIlustrationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
paginateLocales?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesByButtonUniqueWhere"]: {
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HomePageLokalLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HomePageLokalLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocaleEdge"]: AliasType<{
	node?:ValueTypes["HomePageLokalLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["RecommendationFormLocale"]: AliasType<{
	_meta?:ValueTypes["RecommendationFormLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	yourEmailLabel?:boolean | `@${string}`,
	theirEmailLabel?:boolean | `@${string}`,
	yourName?:boolean | `@${string}`,
	sendBtnLabel?:boolean | `@${string}`,
	successTitle?:boolean | `@${string}`,
	successSubtitle?:boolean | `@${string}`,
	successButtonClose?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationForm"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	yourEmailLabel?:ValueTypes["FieldMeta"],
	theirEmailLabel?:ValueTypes["FieldMeta"],
	yourName?:ValueTypes["FieldMeta"],
	sendBtnLabel?:ValueTypes["FieldMeta"],
	successTitle?:ValueTypes["FieldMeta"],
	successSubtitle?:ValueTypes["FieldMeta"],
	successButtonClose?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationForm"]: AliasType<{
	_meta?:ValueTypes["RecommendationFormMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
successImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["RecommendationFormLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
paginateLocales?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	successImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RecommendationFormLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocaleEdge"]: AliasType<{
	node?:ValueTypes["RecommendationFormLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterLinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkEdge"]: AliasType<{
	node?:ValueTypes["FooterLink"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocale"]: AliasType<{
	_meta?:ValueTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	menuLabel?:boolean | `@${string}`,
	menuTitle?:boolean | `@${string}`,
	menuText?:boolean | `@${string}`,
business?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Business"]],
lokalEnterprise?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Business"]],
	businessSectionTitle?:boolean | `@${string}`,
	lokalSectionTitle?:boolean | `@${string}`,
businessLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
lokalLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateBusiness?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessConnection"]],
paginateLokalEnterprise?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	menuLabel?:ValueTypes["FieldMeta"],
	menuTitle?:ValueTypes["FieldMeta"],
	menuText?:ValueTypes["FieldMeta"],
	business?:ValueTypes["FieldMeta"],
	lokalEnterprise?:ValueTypes["FieldMeta"],
	businessSectionTitle?:ValueTypes["FieldMeta"],
	lokalSectionTitle?:ValueTypes["FieldMeta"],
	businessLink?:ValueTypes["FieldMeta"],
	lokalLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ValueTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
logoMenu?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["HeaderLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByBusinessLink?: [{	by: ValueTypes["HeaderLocalesByBusinessLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByLokalLink?: [{	by: ValueTypes["HeaderLocalesByLokalLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	logoMenu?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	menuLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	menuTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	menuText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	businessSectionTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	lokalSectionTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	businessLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	lokalLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	logoMenu?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByBusinessLinkUniqueWhere"]: {
	businessLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByLokalLinkUniqueWhere"]: {
	lokalLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ValueTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Business"]: AliasType<{
	_meta?:ValueTypes["BusinessMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["BusinessConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessEdge"]: AliasType<{
	node?:ValueTypes["Business"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocale"]: AliasType<{
	_meta?:ValueTypes["SeoDefaultLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefault"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefault"]: AliasType<{
	_meta?:ValueTypes["SeoDefaultMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
localesByLocale?: [{	by: ValueTypes["SeoDefaultLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
localesBySeo?: [{	by: ValueTypes["SeoDefaultLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
paginateLocales?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["SeoDefaultOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>
};
	["SeoDefaultOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SeoDefaultLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoDefaultLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoDefaultLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleEdge"]: AliasType<{
	node?:ValueTypes["SeoDefaultLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocale"]: AliasType<{
	_meta?:ValueTypes["JobsFilterLocaleMeta"],
	id?:boolean | `@${string}`,
	jobPositionLabel?:boolean | `@${string}`,
	jobContractLabel?:boolean | `@${string}`,
	jobCityLabel?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilter"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	bannerTitle?:boolean | `@${string}`,
	bannerFormLabel?:boolean | `@${string}`,
bannerJobsForm?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
bannerImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	noJobTitle?:boolean | `@${string}`,
	noJobBtnLabel?:boolean | `@${string}`,
noJobImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	noJobTitleForSpecificBusiness?:boolean | `@${string}`,
	deactivatedJob?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	jobPositionLabel?:ValueTypes["FieldMeta"],
	jobContractLabel?:ValueTypes["FieldMeta"],
	jobCityLabel?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	bannerTitle?:ValueTypes["FieldMeta"],
	bannerFormLabel?:ValueTypes["FieldMeta"],
	bannerJobsForm?:ValueTypes["FieldMeta"],
	bannerImage?:ValueTypes["FieldMeta"],
	noJobTitle?:ValueTypes["FieldMeta"],
	noJobBtnLabel?:ValueTypes["FieldMeta"],
	noJobImage?:ValueTypes["FieldMeta"],
	noJobTitleForSpecificBusiness?:ValueTypes["FieldMeta"],
	deactivatedJob?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilter"]: AliasType<{
	_meta?:ValueTypes["JobsFilterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
localesByLocale?: [{	by: ValueTypes["JobsFilterLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
localesByBannerJobsForm?: [{	by: ValueTypes["JobsFilterLocalesByBannerJobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
localesByBannerImage?: [{	by: ValueTypes["JobsFilterLocalesByBannerImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
localesByNoJobImage?: [{	by: ValueTypes["JobsFilterLocalesByNoJobImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
paginateLocales?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobPositionLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobContractLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	jobCityLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["JobsFilterOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	bannerTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bannerFormLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	bannerJobsForm?: ValueTypes["JobsFormLocaleOrderBy"] | undefined | null | Variable<any, string>,
	bannerImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	noJobTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noJobBtnLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noJobImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	noJobTitleForSpecificBusiness?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	deactivatedJob?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobsFilterOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocalesByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocalesByNoJobImageUniqueWhere"]: {
	noJobImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobsFilterLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobsFilterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleEdge"]: AliasType<{
	node?:ValueTypes["JobsFilterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByBannerImageUniqueWhere"]: {
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByBannerLinkUniqueWhere"]: {
	bannerLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByJobsFormUniqueWhere"]: {
	jobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ValueTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByInstagramLinkUniqueWhere"]: {
	instagramLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByBusinessLinkUniqueWhere"]: {
	businessLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByLokalLinkUniqueWhere"]: {
	lokalLink?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleSeoDefaultsByRootUniqueWhere"]: {
	root?: ValueTypes["SeoDefaultUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleSeoDefaultsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBusinessPagesByRootUniqueWhere"]: {
	root?: ValueTypes["BusinessPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBusinessPagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBusinessPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBusinessPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBusinessPagesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByRootUniqueWhere"]: {
	root?: ValueTypes["JobPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByKeyInfoUniqueWhere"]: {
	keyInfo?: ValueTypes["KeyInfoListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPagesByMobileImageUniqueWhere"]: {
	mobileImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleContractTypesByRootUniqueWhere"]: {
	root?: ValueTypes["ContractTypeUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleContractTypesBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["LocaleJobCitiesByRootUniqueWhere"]: {
	root?: ValueTypes["JobCityUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobCitiesBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["LocaleJobPositionsByRootUniqueWhere"]: {
	root?: ValueTypes["JobPositionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobPositionsBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByRootUniqueWhere"]: {
	root?: ValueTypes["HomePageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByButtonUniqueWhere"]: {
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePagesByIlustrationUniqueWhere"]: {
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBonusBannersByRootUniqueWhere"]: {
	root?: ValueTypes["BonusBannerUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBonusBannersByTilesUniqueWhere"]: {
	tiles?: ValueTypes["TileUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleBonusBannersByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByRootUniqueWhere"]: {
	root?: ValueTypes["HomePageLokalUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByButtonUniqueWhere"]: {
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ValueTypes["SiteSwitcherUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHomePageLokalsByIlustrationUniqueWhere"]: {
	ilustration?: ValueTypes["GalleryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFormsByRootUniqueWhere"]: {
	root?: ValueTypes["JobsFormUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFormsByImageTextListUniqueWhere"]: {
	imageTextList?: ValueTypes["ImageTextListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFormsByLogoUniqueWhere"]: {
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFormsBySuccessImageUniqueWhere"]: {
	successImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFiltersByRootUniqueWhere"]: {
	root?: ValueTypes["JobsFilterUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFiltersByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFiltersByBannerImageUniqueWhere"]: {
	bannerImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleJobsFiltersByNoJobImageUniqueWhere"]: {
	noJobImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleRecommendationFormsByRootUniqueWhere"]: {
	root?: ValueTypes["RecommendationFormUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleInfoBoxesByRootUniqueWhere"]: {
	root?: ValueTypes["InfoBoxUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ValueTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ValueTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ValueTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ValueTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ValueTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoDefaultEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultEdge"]: AliasType<{
	node?:ValueTypes["SeoDefault"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["BusinessPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageEdge"]: AliasType<{
	node?:ValueTypes["BusinessPage"],
		__typename?: boolean | `@${string}`
}>;
	["IconUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["IconConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IconEdge"]: AliasType<{
	node?:ValueTypes["Icon"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["KeyInfoListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListEdge"]: AliasType<{
	node?:ValueTypes["KeyInfoList"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContractTypeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeEdge"]: AliasType<{
	node?:ValueTypes["ContractType"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobCityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityEdge"]: AliasType<{
	node?:ValueTypes["JobCity"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobPositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionEdge"]: AliasType<{
	node?:ValueTypes["JobPosition"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryEdge"]: AliasType<{
	node?:ValueTypes["Gallery"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HomePageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageEdge"]: AliasType<{
	node?:ValueTypes["HomePage"],
		__typename?: boolean | `@${string}`
}>;
	["MediumUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["MediumConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumEdge"]: AliasType<{
	node?:ValueTypes["Medium"],
		__typename?: boolean | `@${string}`
}>;
	["MediumListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumListEdge"]: AliasType<{
	node?:ValueTypes["MediumList"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["TextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["TextItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TextListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextListEdge"]: AliasType<{
	node?:ValueTypes["TextList"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["CarouselItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CarouselItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>,
	linkUrl?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["CarouselConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CarouselEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselEdge"]: AliasType<{
	node?:ValueTypes["Carousel"],
		__typename?: boolean | `@${string}`
}>;
	["ThemeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ThemeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ThemeEdge"]: AliasType<{
	node?:ValueTypes["Theme"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BonusBannerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerEdge"]: AliasType<{
	node?:ValueTypes["BonusBanner"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["SideBySideItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["MediumListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SideBySideConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SideBySideEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideEdge"]: AliasType<{
	node?:ValueTypes["SideBySide"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HomePageLokalEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalEdge"]: AliasType<{
	node?:ValueTypes["HomePageLokal"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SiteSwitcherEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherEdge"]: AliasType<{
	node?:ValueTypes["SiteSwitcher"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobsFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormEdge"]: AliasType<{
	node?:ValueTypes["JobsForm"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageTextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListEdge"]: AliasType<{
	node?:ValueTypes["ImageTextList"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobsFilterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterEdge"]: AliasType<{
	node?:ValueTypes["JobsFilter"],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	_meta?:ValueTypes["FileMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FileWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>
};
	["FileOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FileConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FileEdge"]: AliasType<{
	node?:ValueTypes["File"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCreateInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	file?: ValueTypes["JobFormSubscriptionCreateFileEntityRelationInput"] | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobFormSubscriptionCreateJobEntityRelationInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["JobFormSubscriptionCreateStatusEntityRelationInput"] | undefined | null | Variable<any, string>,
	isSyncedToTabidoo?: boolean | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionCreateFileEntityRelationInput"]: {
	connect?: ValueTypes["FileUniqueWhere"] | undefined | null | Variable<any, string>,
	create?: ValueTypes["FileCreateInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["JobFormSubscriptionConnectOrCreateFileRelationInput"] | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionConnectOrCreateFileRelationInput"]: {
	connect: ValueTypes["FileUniqueWhere"] | Variable<any, string>,
	create: ValueTypes["FileCreateInput"] | Variable<any, string>
};
	["JobFormSubscriptionCreateJobEntityRelationInput"]: {
	connect?: ValueTypes["JobPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionCreateStatusEntityRelationInput"]: {
	connect?: ValueTypes["JobFormSubscriptionStatusUniqueWhere"] | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionStatusUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["ImageListItemsByImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatus"]: AliasType<{
	_meta?:ValueTypes["JobFormSubscriptionStatusMeta"],
	id?:boolean | `@${string}`,
	statusTitle?:boolean | `@${string}`,
	statusColor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	statusTitle?:ValueTypes["FieldMeta"],
	statusColor?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	statusTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	statusColor?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionStatusOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	statusTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	statusColor?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["JobFormSubscriptionStatusConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["JobFormSubscriptionStatusEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusEdge"]: AliasType<{
	node?:ValueTypes["JobFormSubscriptionStatus"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["StickyBarConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StickyBarEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarEdge"]: AliasType<{
	node?:ValueTypes["StickyBar"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarCreateInput"]: {
	title?: string | undefined | null | Variable<any, string>,
	link?: ValueTypes["StickyBarCreateLinkEntityRelationInput"] | undefined | null | Variable<any, string>,
	formButtonLabel?: string | undefined | null | Variable<any, string>,
	jobsForm?: ValueTypes["StickyBarCreateJobsFormEntityRelationInput"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["StickyBarCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["StickyBarCreateJobsFormEntityRelationInput"]: {
	connect?: ValueTypes["JobsFormLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RecommendationFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RecommendationFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormEdge"]: AliasType<{
	node?:ValueTypes["RecommendationForm"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormSubscriptionCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	yourEmail?: string | undefined | null | Variable<any, string>,
	theirEmail?: string | undefined | null | Variable<any, string>,
	yourName?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["InfoBoxConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InfoBoxEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxEdge"]: AliasType<{
	node?:ValueTypes["InfoBox"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxCreateInput"]: {
	logoSize?: ValueTypes["LogoSize"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["InfoBoxCreateLogoEntityRelationInput"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["InfoBoxCreateLogoEntityRelationInput"]: {
	connect?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["QueryTransaction"]: AliasType<{
	schema?:ValueTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getContentReferenceLinkableItem?: [{	by: ValueTypes["ContentReferenceLinkableItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
listContentReferenceLinkableItem?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItem"]],
paginateContentReferenceLinkableItem?: [{	filter?: ValueTypes["ContentReferenceLinkableItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceLinkableItemConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterLink?: [{	by: ValueTypes["FooterLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
listFooterLink?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLink"]],
paginateFooterLink?: [{	filter?: ValueTypes["FooterLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLinkConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getSeoDefault?: [{	by: ValueTypes["SeoDefaultUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefault"]],
listSeoDefault?: [{	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?: ValueTypes["SeoDefaultWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultConnection"]],
getSeoDefaultLocale?: [{	by: ValueTypes["SeoDefaultLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
listSeoDefaultLocale?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocale"]],
paginateSeoDefaultLocale?: [{	filter?: ValueTypes["SeoDefaultLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoDefaultLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoDefaultLocaleConnection"]],
getBusiness?: [{	by: ValueTypes["BusinessUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>},ValueTypes["Business"]],
listBusiness?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Business"]],
paginateBusiness?: [{	filter?: ValueTypes["BusinessWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessConnection"]],
getBusinessPage?: [{	by: ValueTypes["BusinessPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
listBusinessPage?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPage"]],
paginateBusinessPage?: [{	filter?: ValueTypes["BusinessPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageConnection"]],
getBusinessPageLocale?: [{	by: ValueTypes["BusinessPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
listBusinessPageLocale?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocale"]],
paginateBusinessPageLocale?: [{	filter?: ValueTypes["BusinessPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessPageLocaleConnection"]],
getIcon?: [{	by: ValueTypes["IconUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>},ValueTypes["Icon"]],
listIcon?: [{	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["IconOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Icon"]],
paginateIcon?: [{	filter?: ValueTypes["IconWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["IconOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["IconConnection"]],
getJobPage?: [{	by: ValueTypes["JobPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
listJobPage?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPage"]],
paginateJobPage?: [{	filter?: ValueTypes["JobPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageConnection"]],
getJobPageLocale?: [{	by: ValueTypes["JobPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
listJobPageLocale?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocale"]],
paginateJobPageLocale?: [{	filter?: ValueTypes["JobPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPageLocaleConnection"]],
getKeyInfoItem?: [{	by: ValueTypes["KeyInfoItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItem"]],
listKeyInfoItem?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItem"]],
paginateKeyInfoItem?: [{	filter?: ValueTypes["KeyInfoItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoItemConnection"]],
getKeyInfoList?: [{	by: ValueTypes["KeyInfoListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
listKeyInfoList?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoList"]],
paginateKeyInfoList?: [{	filter?: ValueTypes["KeyInfoListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["KeyInfoListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["KeyInfoListConnection"]],
getContractType?: [{	by: ValueTypes["ContractTypeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
listContractType?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractType"]],
paginateContractType?: [{	filter?: ValueTypes["ContractTypeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeConnection"]],
getContractTypeLocale?: [{	by: ValueTypes["ContractTypeLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
listContractTypeLocale?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocale"]],
paginateContractTypeLocale?: [{	filter?: ValueTypes["ContractTypeLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContractTypeLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContractTypeLocaleConnection"]],
getJobCity?: [{	by: ValueTypes["JobCityUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
listJobCity?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCity"]],
paginateJobCity?: [{	filter?: ValueTypes["JobCityWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityConnection"]],
getJobCityLocale?: [{	by: ValueTypes["JobCityLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
listJobCityLocale?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocale"]],
paginateJobCityLocale?: [{	filter?: ValueTypes["JobCityLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobCityLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobCityLocaleConnection"]],
getJobPosition?: [{	by: ValueTypes["JobPositionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
listJobPosition?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPosition"]],
paginateJobPosition?: [{	filter?: ValueTypes["JobPositionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionConnection"]],
getJobPositionLocale?: [{	by: ValueTypes["JobPositionLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
listJobPositionLocale?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocale"]],
paginateJobPositionLocale?: [{	filter?: ValueTypes["JobPositionLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobPositionLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobPositionLocaleConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryConnection"]],
getHomePage?: [{	by: ValueTypes["HomePageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePage"]],
listHomePage?: [{	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePage"]],
paginateHomePage?: [{	filter?: ValueTypes["HomePageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageConnection"]],
getHomePageLocale?: [{	by: ValueTypes["HomePageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
listHomePageLocale?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocale"]],
paginateHomePageLocale?: [{	filter?: ValueTypes["HomePageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLocaleConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumConnection"]],
getMediumItem?: [{	by: ValueTypes["MediumItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumItem"]],
listMediumItem?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItem"]],
paginateMediumItem?: [{	filter?: ValueTypes["MediumItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumItemConnection"]],
getMediumList?: [{	by: ValueTypes["MediumListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
listMediumList?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["MediumList"]],
paginateMediumList?: [{	filter?: ValueTypes["MediumListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MediumListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["MediumListConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TextListConnection"]],
getCarousel?: [{	by: ValueTypes["CarouselUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
listCarousel?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Carousel"]],
paginateCarousel?: [{	filter?: ValueTypes["CarouselWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselConnection"]],
getCarouselItem?: [{	by: ValueTypes["CarouselItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
listCarouselItem?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItem"]],
paginateCarouselItem?: [{	filter?: ValueTypes["CarouselItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CarouselItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["CarouselItemConnection"]],
getTheme?: [{	by: ValueTypes["ThemeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
listTheme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThemeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Theme"]],
paginateTheme?: [{	filter?: ValueTypes["ThemeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ThemeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ThemeConnection"]],
getBonusBanner?: [{	by: ValueTypes["BonusBannerUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBanner"]],
listBonusBanner?: [{	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBanner"]],
paginateBonusBanner?: [{	filter?: ValueTypes["BonusBannerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerConnection"]],
getBonusBannerLocale?: [{	by: ValueTypes["BonusBannerLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
listBonusBannerLocale?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocale"]],
paginateBonusBannerLocale?: [{	filter?: ValueTypes["BonusBannerLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BonusBannerLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["BonusBannerLocaleConnection"]],
getTile?: [{	by: ValueTypes["TileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
listTile?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Tile"]],
paginateTile?: [{	filter?: ValueTypes["TileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TileConnection"]],
getSideBySide?: [{	by: ValueTypes["SideBySideUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
listSideBySide?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySide"]],
paginateSideBySide?: [{	filter?: ValueTypes["SideBySideWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideConnection"]],
getSideBySideItem?: [{	by: ValueTypes["SideBySideItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
listSideBySideItem?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItem"]],
paginateSideBySideItem?: [{	filter?: ValueTypes["SideBySideItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SideBySideItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SideBySideItemConnection"]],
getHomePageLokal?: [{	by: ValueTypes["HomePageLokalUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokal"]],
listHomePageLokal?: [{	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokal"]],
paginateHomePageLokal?: [{	filter?: ValueTypes["HomePageLokalWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalConnection"]],
getHomePageLokalLocale?: [{	by: ValueTypes["HomePageLokalLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
listHomePageLokalLocale?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocale"]],
paginateHomePageLokalLocale?: [{	filter?: ValueTypes["HomePageLokalLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HomePageLokalLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HomePageLokalLocaleConnection"]],
getSiteSwitcher?: [{	by: ValueTypes["SiteSwitcherUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
listSiteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SiteSwitcherOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcher"]],
paginateSiteSwitcher?: [{	filter?: ValueTypes["SiteSwitcherWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SiteSwitcherOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SiteSwitcherConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GalleryItemConnection"]],
getJobsForm?: [{	by: ValueTypes["JobsFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsForm"]],
listJobsForm?: [{	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsForm"]],
paginateJobsForm?: [{	filter?: ValueTypes["JobsFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormConnection"]],
getJobsFormLocale?: [{	by: ValueTypes["JobsFormLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
listJobsFormLocale?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocale"]],
paginateJobsFormLocale?: [{	filter?: ValueTypes["JobsFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFormLocaleConnection"]],
getImageTextItem?: [{	by: ValueTypes["ImageTextItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
listImageTextItem?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItem"]],
paginateImageTextItem?: [{	filter?: ValueTypes["ImageTextItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextItemConnection"]],
getImageTextList?: [{	by: ValueTypes["ImageTextListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
listImageTextList?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextList"]],
paginateImageTextList?: [{	filter?: ValueTypes["ImageTextListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageTextListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageTextListConnection"]],
getJobsFilter?: [{	by: ValueTypes["JobsFilterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilter"]],
listJobsFilter?: [{	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilter"]],
paginateJobsFilter?: [{	filter?: ValueTypes["JobsFilterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterConnection"]],
getJobsFilterLocale?: [{	by: ValueTypes["JobsFilterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
listJobsFilterLocale?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocale"]],
paginateJobsFilterLocale?: [{	filter?: ValueTypes["JobsFilterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobsFilterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobsFilterLocaleConnection"]],
getFile?: [{	by: ValueTypes["FileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>},ValueTypes["File"]],
listFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["File"]],
paginateFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FileConnection"]],
validateCreateFile?: [{	data: ValueTypes["FileCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateCreateJobFormSubscription?: [{	data: ValueTypes["JobFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getJobFormSubscriptionStatus?: [{	by: ValueTypes["JobFormSubscriptionStatusUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatus"]],
listJobFormSubscriptionStatus?: [{	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatus"]],
paginateJobFormSubscriptionStatus?: [{	filter?: ValueTypes["JobFormSubscriptionStatusWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["JobFormSubscriptionStatusConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getStickyBar?: [{	by: ValueTypes["StickyBarUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
listStickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["StickyBarOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["StickyBar"]],
paginateStickyBar?: [{	filter?: ValueTypes["StickyBarWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["StickyBarOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["StickyBarConnection"]],
validateCreateStickyBar?: [{	data: ValueTypes["StickyBarCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getRecommendationForm?: [{	by: ValueTypes["RecommendationFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationForm"]],
listRecommendationForm?: [{	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationForm"]],
paginateRecommendationForm?: [{	filter?: ValueTypes["RecommendationFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormConnection"]],
getRecommendationFormLocale?: [{	by: ValueTypes["RecommendationFormLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
listRecommendationFormLocale?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocale"]],
paginateRecommendationFormLocale?: [{	filter?: ValueTypes["RecommendationFormLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RecommendationFormLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RecommendationFormLocaleConnection"]],
validateCreateRecommendationFormSubscription?: [{	data: ValueTypes["RecommendationFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getInfoBox?: [{	by: ValueTypes["InfoBoxUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
listInfoBox?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBox"]],
paginateInfoBox?: [{	filter?: ValueTypes["InfoBoxWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxConnection"]],
validateCreateInfoBox?: [{	data: ValueTypes["InfoBoxCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getInfoBoxLocale?: [{	by: ValueTypes["InfoBoxLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
listInfoBoxLocale?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocale"]],
paginateInfoBoxLocale?: [{	filter?: ValueTypes["InfoBoxLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["InfoBoxLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
generateUploadUrl?: [{	input?: ValueTypes["S3GenerateSignedUploadInput"] | undefined | null | Variable<any, string>},ValueTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string | Variable<any, string>,	expiration?: number | undefined | null | Variable<any, string>},ValueTypes["S3SignedRead"]],
createFile?: [{	data: ValueTypes["FileCreateInput"] | Variable<any, string>},ValueTypes["FileCreateResult"]],
createJobFormSubscription?: [{	data: ValueTypes["JobFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["JobFormSubscriptionCreateResult"]],
createStickyBar?: [{	data: ValueTypes["StickyBarCreateInput"] | Variable<any, string>},ValueTypes["StickyBarCreateResult"]],
createRecommendationFormSubscription?: [{	data: ValueTypes["RecommendationFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["RecommendationFormSubscriptionCreateResult"]],
createInfoBox?: [{	data: ValueTypes["InfoBoxCreateInput"] | Variable<any, string>},ValueTypes["InfoBoxCreateResult"]],
transaction?: [{	options?: ValueTypes["MutationTransactionOptions"] | undefined | null | Variable<any, string>},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["S3SignedUpload"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ValueTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
	publicUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3Header"]: AliasType<{
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined | null | Variable<any, string>,
	/** Signed URL expiration. */
	expiration?: number | undefined | null | Variable<any, string>,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined | null | Variable<any, string>,
	/** Can be used as a "directory". */
	prefix?: string | undefined | null | Variable<any, string>,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined | null | Variable<any, string>,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined | null | Variable<any, string>,
	contentDisposition?: ValueTypes["S33ContentDisposition"] | undefined | null | Variable<any, string>,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ValueTypes["S3Acl"] | undefined | null | Variable<any, string>
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ValueTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["File"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"];
		['...on FileCreateResult']?: Omit<ValueTypes["FileCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on JobFormSubscriptionCreateResult']?: Omit<ValueTypes["JobFormSubscriptionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on StickyBarCreateResult']?: Omit<ValueTypes["StickyBarCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on RecommendationFormSubscriptionCreateResult']?: Omit<ValueTypes["RecommendationFormSubscriptionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on InfoBoxCreateResult']?: Omit<ValueTypes["InfoBoxCreateResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["JobFormSubscriptionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["StickyBar"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormSubscriptionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["InfoBox"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
generateUploadUrl?: [{	input?: ValueTypes["S3GenerateSignedUploadInput"] | undefined | null | Variable<any, string>},ValueTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string | Variable<any, string>,	expiration?: number | undefined | null | Variable<any, string>},ValueTypes["S3SignedRead"]],
createFile?: [{	data: ValueTypes["FileCreateInput"] | Variable<any, string>},ValueTypes["FileCreateResult"]],
createJobFormSubscription?: [{	data: ValueTypes["JobFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["JobFormSubscriptionCreateResult"]],
createStickyBar?: [{	data: ValueTypes["StickyBarCreateInput"] | Variable<any, string>},ValueTypes["StickyBarCreateResult"]],
createRecommendationFormSubscription?: [{	data: ValueTypes["RecommendationFormSubscriptionCreateInput"] | Variable<any, string>},ValueTypes["RecommendationFormSubscriptionCreateResult"]],
createInfoBox?: [{	data: ValueTypes["InfoBoxCreateInput"] | Variable<any, string>},ValueTypes["InfoBoxCreateResult"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"];
		['...on _Column']?: Omit<ResolverInputTypes["_Column"],keyof ResolverInputTypes["_Field"]>;
		['...on _Relation']?: Omit<ResolverInputTypes["_Relation"],keyof ResolverInputTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ResolverInputTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ResolverInputTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{
	_ValidatorArgument?:ResolverInputTypes["_ValidatorArgument"],
	_PathArgument?:ResolverInputTypes["_PathArgument"],
	_LiteralArgument?:ResolverInputTypes["_LiteralArgument"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ResolverInputTypes["_OrderBy"],
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: AliasType<{
	schema?:ResolverInputTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getContentReferenceLinkableItem?: [{	by: ResolverInputTypes["ContentReferenceLinkableItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
listContentReferenceLinkableItem?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
paginateContentReferenceLinkableItem?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItemConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterLink?: [{	by: ResolverInputTypes["FooterLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null},ResolverInputTypes["FooterLink"]],
listFooterLink?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLink"]],
paginateFooterLink?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLinkConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getSeoDefault?: [{	by: ResolverInputTypes["SeoDefaultUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null},ResolverInputTypes["SeoDefault"]],
listSeoDefault?: [{	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultConnection"]],
getSeoDefaultLocale?: [{	by: ResolverInputTypes["SeoDefaultLocaleUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
listSeoDefaultLocale?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
paginateSeoDefaultLocale?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultLocaleConnection"]],
getBusiness?: [{	by: ResolverInputTypes["BusinessUniqueWhere"],	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null},ResolverInputTypes["Business"]],
listBusiness?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Business"]],
paginateBusiness?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessConnection"]],
getBusinessPage?: [{	by: ResolverInputTypes["BusinessPageUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null},ResolverInputTypes["BusinessPage"]],
listBusinessPage?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPage"]],
paginateBusinessPage?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageConnection"]],
getBusinessPageLocale?: [{	by: ResolverInputTypes["BusinessPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
listBusinessPageLocale?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
paginateBusinessPageLocale?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageLocaleConnection"]],
getIcon?: [{	by: ResolverInputTypes["IconUniqueWhere"],	filter?: ResolverInputTypes["IconWhere"] | undefined | null},ResolverInputTypes["Icon"]],
listIcon?: [{	filter?: ResolverInputTypes["IconWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["IconOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Icon"]],
paginateIcon?: [{	filter?: ResolverInputTypes["IconWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["IconOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["IconConnection"]],
getJobPage?: [{	by: ResolverInputTypes["JobPageUniqueWhere"],	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null},ResolverInputTypes["JobPage"]],
listJobPage?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPage"]],
paginateJobPage?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageConnection"]],
getJobPageLocale?: [{	by: ResolverInputTypes["JobPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
listJobPageLocale?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPageLocale"]],
paginateJobPageLocale?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageLocaleConnection"]],
getKeyInfoItem?: [{	by: ResolverInputTypes["KeyInfoItemUniqueWhere"],	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null},ResolverInputTypes["KeyInfoItem"]],
listKeyInfoItem?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["KeyInfoItem"]],
paginateKeyInfoItem?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["KeyInfoItemConnection"]],
getKeyInfoList?: [{	by: ResolverInputTypes["KeyInfoListUniqueWhere"],	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null},ResolverInputTypes["KeyInfoList"]],
listKeyInfoList?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["KeyInfoList"]],
paginateKeyInfoList?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["KeyInfoListConnection"]],
getContractType?: [{	by: ResolverInputTypes["ContractTypeUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null},ResolverInputTypes["ContractType"]],
listContractType?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractType"]],
paginateContractType?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeConnection"]],
getContractTypeLocale?: [{	by: ResolverInputTypes["ContractTypeLocaleUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
listContractTypeLocale?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
paginateContractTypeLocale?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeLocaleConnection"]],
getJobCity?: [{	by: ResolverInputTypes["JobCityUniqueWhere"],	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null},ResolverInputTypes["JobCity"]],
listJobCity?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCity"]],
paginateJobCity?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityConnection"]],
getJobCityLocale?: [{	by: ResolverInputTypes["JobCityLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null},ResolverInputTypes["JobCityLocale"]],
listJobCityLocale?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCityLocale"]],
paginateJobCityLocale?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityLocaleConnection"]],
getJobPosition?: [{	by: ResolverInputTypes["JobPositionUniqueWhere"],	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null},ResolverInputTypes["JobPosition"]],
listJobPosition?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPosition"]],
paginateJobPosition?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionConnection"]],
getJobPositionLocale?: [{	by: ResolverInputTypes["JobPositionLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null},ResolverInputTypes["JobPositionLocale"]],
listJobPositionLocale?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPositionLocale"]],
paginateJobPositionLocale?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionLocaleConnection"]],
getGallery?: [{	by: ResolverInputTypes["GalleryUniqueWhere"],	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
listGallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Gallery"]],
paginateGallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GalleryConnection"]],
getHomePage?: [{	by: ResolverInputTypes["HomePageUniqueWhere"],	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null},ResolverInputTypes["HomePage"]],
listHomePage?: [{	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePage"]],
paginateHomePage?: [{	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageConnection"]],
getHomePageLocale?: [{	by: ResolverInputTypes["HomePageLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
listHomePageLocale?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLocale"]],
paginateHomePageLocale?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLocaleConnection"]],
getMedium?: [{	by: ResolverInputTypes["MediumUniqueWhere"],	filter?: ResolverInputTypes["MediumWhere"] | undefined | null},ResolverInputTypes["Medium"]],
listMedium?: [{	filter?: ResolverInputTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Medium"]],
paginateMedium?: [{	filter?: ResolverInputTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumConnection"]],
getMediumItem?: [{	by: ResolverInputTypes["MediumItemUniqueWhere"],	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null},ResolverInputTypes["MediumItem"]],
listMediumItem?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["MediumItem"]],
paginateMediumItem?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumItemConnection"]],
getMediumList?: [{	by: ResolverInputTypes["MediumListUniqueWhere"],	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
listMediumList?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["MediumList"]],
paginateMediumList?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumListConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getTextItem?: [{	by: ResolverInputTypes["TextItemUniqueWhere"],	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null},ResolverInputTypes["TextItem"]],
listTextItem?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TextItem"]],
paginateTextItem?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TextItemConnection"]],
getTextList?: [{	by: ResolverInputTypes["TextListUniqueWhere"],	filter?: ResolverInputTypes["TextListWhere"] | undefined | null},ResolverInputTypes["TextList"]],
listTextList?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TextList"]],
paginateTextList?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TextListConnection"]],
getCarousel?: [{	by: ResolverInputTypes["CarouselUniqueWhere"],	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null},ResolverInputTypes["Carousel"]],
listCarousel?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Carousel"]],
paginateCarousel?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CarouselConnection"]],
getCarouselItem?: [{	by: ResolverInputTypes["CarouselItemUniqueWhere"],	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null},ResolverInputTypes["CarouselItem"]],
listCarouselItem?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CarouselItem"]],
paginateCarouselItem?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CarouselItemConnection"]],
getTheme?: [{	by: ResolverInputTypes["ThemeUniqueWhere"],	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
listTheme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThemeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Theme"]],
paginateTheme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThemeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ThemeConnection"]],
getBonusBanner?: [{	by: ResolverInputTypes["BonusBannerUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null},ResolverInputTypes["BonusBanner"]],
listBonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBanner"]],
paginateBonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerConnection"]],
getBonusBannerLocale?: [{	by: ResolverInputTypes["BonusBannerLocaleUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
listBonusBannerLocale?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
paginateBonusBannerLocale?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerLocaleConnection"]],
getTile?: [{	by: ResolverInputTypes["TileUniqueWhere"],	filter?: ResolverInputTypes["TileWhere"] | undefined | null},ResolverInputTypes["Tile"]],
listTile?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Tile"]],
paginateTile?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TileConnection"]],
getSideBySide?: [{	by: ResolverInputTypes["SideBySideUniqueWhere"],	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null},ResolverInputTypes["SideBySide"]],
listSideBySide?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SideBySide"]],
paginateSideBySide?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SideBySideConnection"]],
getSideBySideItem?: [{	by: ResolverInputTypes["SideBySideItemUniqueWhere"],	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null},ResolverInputTypes["SideBySideItem"]],
listSideBySideItem?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SideBySideItem"]],
paginateSideBySideItem?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SideBySideItemConnection"]],
getHomePageLokal?: [{	by: ResolverInputTypes["HomePageLokalUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null},ResolverInputTypes["HomePageLokal"]],
listHomePageLokal?: [{	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokal"]],
paginateHomePageLokal?: [{	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalConnection"]],
getHomePageLokalLocale?: [{	by: ResolverInputTypes["HomePageLokalLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
listHomePageLokalLocale?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
paginateHomePageLokalLocale?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalLocaleConnection"]],
getSiteSwitcher?: [{	by: ResolverInputTypes["SiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null},ResolverInputTypes["SiteSwitcher"]],
listSiteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SiteSwitcherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SiteSwitcher"]],
paginateSiteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SiteSwitcherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SiteSwitcherConnection"]],
getGalleryItem?: [{	by: ResolverInputTypes["GalleryItemUniqueWhere"],	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null},ResolverInputTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GalleryItemConnection"]],
getJobsForm?: [{	by: ResolverInputTypes["JobsFormUniqueWhere"],	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null},ResolverInputTypes["JobsForm"]],
listJobsForm?: [{	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsForm"]],
paginateJobsForm?: [{	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormConnection"]],
getJobsFormLocale?: [{	by: ResolverInputTypes["JobsFormLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
listJobsFormLocale?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFormLocale"]],
paginateJobsFormLocale?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormLocaleConnection"]],
getImageTextItem?: [{	by: ResolverInputTypes["ImageTextItemUniqueWhere"],	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null},ResolverInputTypes["ImageTextItem"]],
listImageTextItem?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageTextItem"]],
paginateImageTextItem?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageTextItemConnection"]],
getImageTextList?: [{	by: ResolverInputTypes["ImageTextListUniqueWhere"],	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null},ResolverInputTypes["ImageTextList"]],
listImageTextList?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageTextList"]],
paginateImageTextList?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageTextListConnection"]],
getJobsFilter?: [{	by: ResolverInputTypes["JobsFilterUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null},ResolverInputTypes["JobsFilter"]],
listJobsFilter?: [{	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilter"]],
paginateJobsFilter?: [{	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterConnection"]],
getJobsFilterLocale?: [{	by: ResolverInputTypes["JobsFilterLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
listJobsFilterLocale?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
paginateJobsFilterLocale?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterLocaleConnection"]],
getFile?: [{	by: ResolverInputTypes["FileUniqueWhere"],	filter?: ResolverInputTypes["FileWhere"] | undefined | null},ResolverInputTypes["File"]],
listFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["File"]],
paginateFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FileConnection"]],
validateCreateFile?: [{	data: ResolverInputTypes["FileCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateCreateJobFormSubscription?: [{	data: ResolverInputTypes["JobFormSubscriptionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getJobFormSubscriptionStatus?: [{	by: ResolverInputTypes["JobFormSubscriptionStatusUniqueWhere"],	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null},ResolverInputTypes["JobFormSubscriptionStatus"]],
listJobFormSubscriptionStatus?: [{	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobFormSubscriptionStatus"]],
paginateJobFormSubscriptionStatus?: [{	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobFormSubscriptionStatusConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getStickyBar?: [{	by: ResolverInputTypes["StickyBarUniqueWhere"],	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
listStickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["StickyBarOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["StickyBar"]],
paginateStickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["StickyBarOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["StickyBarConnection"]],
validateCreateStickyBar?: [{	data: ResolverInputTypes["StickyBarCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getRecommendationForm?: [{	by: ResolverInputTypes["RecommendationFormUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null},ResolverInputTypes["RecommendationForm"]],
listRecommendationForm?: [{	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationForm"]],
paginateRecommendationForm?: [{	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormConnection"]],
getRecommendationFormLocale?: [{	by: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
listRecommendationFormLocale?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
paginateRecommendationFormLocale?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormLocaleConnection"]],
validateCreateRecommendationFormSubscription?: [{	data: ResolverInputTypes["RecommendationFormSubscriptionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getInfoBox?: [{	by: ResolverInputTypes["InfoBoxUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null},ResolverInputTypes["InfoBox"]],
listInfoBox?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBox"]],
paginateInfoBox?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxConnection"]],
validateCreateInfoBox?: [{	data: ResolverInputTypes["InfoBoxCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getInfoBoxLocale?: [{	by: ResolverInputTypes["InfoBoxLocaleUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
listInfoBoxLocale?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
paginateInfoBoxLocale?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxLocaleConnection"]],
	transaction?:ResolverInputTypes["QueryTransaction"],
	_info?:ResolverInputTypes["Info"],
		__typename?: boolean | `@${string}`
}>;
	["_Schema"]: AliasType<{
	enums?:ResolverInputTypes["_Enum"],
	entities?:ResolverInputTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ResolverInputTypes["_Field"],
	unique?:ResolverInputTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["GeneralLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GeneralLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["One"]:One;
	["GeneralLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	not?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["UUID"] | undefined | null,
	notEq?: ResolverInputTypes["UUID"] | undefined | null,
	in?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	lt?: ResolverInputTypes["UUID"] | undefined | null,
	lte?: ResolverInputTypes["UUID"] | undefined | null,
	gt?: ResolverInputTypes["UUID"] | undefined | null,
	gte?: ResolverInputTypes["UUID"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	not?: ResolverInputTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["One"] | undefined | null,
	notEq?: ResolverInputTypes["One"] | undefined | null,
	in?: Array<ResolverInputTypes["One"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["One"]> | undefined | null,
	lt?: ResolverInputTypes["One"] | undefined | null,
	lte?: ResolverInputTypes["One"] | undefined | null,
	gt?: ResolverInputTypes["One"] | undefined | null,
	gte?: ResolverInputTypes["One"] | undefined | null
};
	["GeneralLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["GeneralWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	seoDefaults?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,
	businessPages?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,
	jobPages?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,
	contractTypes?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,
	jobCities?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,
	jobPositions?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,
	homePages?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,
	bonusBanners?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,
	homePageLokals?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,
	jobsForms?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	jobsFilters?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,
	general?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	recommendationForms?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,
	infoBoxes?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LocaleWhere"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	not?: ResolverInputTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["FooterLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	address?: ResolverInputTypes["StringCondition"] | undefined | null,
	ico?: ResolverInputTypes["StringCondition"] | undefined | null,
	dic?: ResolverInputTypes["StringCondition"] | undefined | null,
	copyright?: ResolverInputTypes["StringCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	noteSecond?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["FooterWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	linksFirstColumn?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,
	linksSecondColumn?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,
	termsAndConditions?: ResolverInputTypes["LinkWhere"] | undefined | null,
	form?: ResolverInputTypes["StringCondition"] | undefined | null,
	bannerTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	bannerSubtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	bannerLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	copyrightLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	bannerSecondBtn?: ResolverInputTypes["StringCondition"] | undefined | null,
	recommendationForm?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,
	bannerSecondLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	instagramLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null
};
	["FooterWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterWhere"] | undefined | null
};
	["FooterLinkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterLinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterLinkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterLinkWhere"] | undefined | null
};
	["IntCondition"]: {
	and?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	not?: ResolverInputTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["LinkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	externalLink?: ResolverInputTypes["StringCondition"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	anchorLink?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["LinkType"] | undefined | null,
	notEq?: ResolverInputTypes["LinkType"] | undefined | null,
	in?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	lt?: ResolverInputTypes["LinkType"] | undefined | null,
	lte?: ResolverInputTypes["LinkType"] | undefined | null,
	gt?: ResolverInputTypes["LinkType"] | undefined | null,
	gte?: ResolverInputTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectWhere"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	businessPage?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,
	jobPage?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,
	homePage?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,
	homePageLokal?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkableWhere"] | undefined | null
};
	["RedirectWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RedirectWhere"] | undefined | null
};
	["GenericPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["GenericPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null
};
	["GenericPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageWhere"] | undefined | null
};
	["ThemeWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	backgroundColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	textColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonBackgroundColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonTextColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonBackgroundHoverColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonTextHoverColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonBorderColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	buttonBorderHoverColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	headerMenuColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ThemeWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ThemeWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ThemeWhere"] | undefined | null
};
	["ContentWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	json?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	linkables?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,
	secondaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	media?: ResolverInputTypes["MediumListWhere"] | undefined | null,
	textList?: ResolverInputTypes["TextListWhere"] | undefined | null,
	carousel?: ResolverInputTypes["CarouselWhere"] | undefined | null,
	bonusBanner?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,
	sideBySide?: ResolverInputTypes["SideBySideWhere"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	showBoolean?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	isRed?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	video?: ResolverInputTypes["VideoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	notEq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	lt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	lte?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gte?: ResolverInputTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	alt?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileName?: ResolverInputTypes["StringCondition"] | undefined | null,
	focalPointX?: ResolverInputTypes["FloatCondition"] | undefined | null,
	focalPointY?: ResolverInputTypes["FloatCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	not?: ResolverInputTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ContentReferenceLinkableItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	item?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null
};
	["MediumListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["MediumItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["MediumListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["MediumListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["MediumListWhere"] | undefined | null
};
	["MediumItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["MediumListWhere"] | undefined | null,
	medium?: ResolverInputTypes["MediumWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["MediumItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["MediumItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["MediumItemWhere"] | undefined | null
};
	["MediumWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["MediumTypeCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	video?: ResolverInputTypes["VideoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["MediumWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["MediumWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["MediumWhere"] | undefined | null
};
	["MediumTypeCondition"]: {
	and?: Array<ResolverInputTypes["MediumTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["MediumTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["MediumTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["MediumType"] | undefined | null,
	notEq?: ResolverInputTypes["MediumType"] | undefined | null,
	in?: Array<ResolverInputTypes["MediumType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["MediumType"]> | undefined | null,
	lt?: ResolverInputTypes["MediumType"] | undefined | null,
	lte?: ResolverInputTypes["MediumType"] | undefined | null,
	gt?: ResolverInputTypes["MediumType"] | undefined | null,
	gte?: ResolverInputTypes["MediumType"] | undefined | null
};
	["MediumType"]:MediumType;
	["VideoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	src?: ResolverInputTypes["StringCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	videoInfo?: ResolverInputTypes["StringCondition"] | undefined | null,
	duration?: ResolverInputTypes["FloatCondition"] | undefined | null,
	poster?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["VideoWhere"] | undefined | null
};
	["TextListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["TextItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TextListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TextListWhere"] | undefined | null
};
	["TextItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["TextListWhere"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["TextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TextItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TextItemWhere"] | undefined | null
};
	["CarouselWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,
	background?: ResolverInputTypes["BackgroundCondition"] | undefined | null,
	squareImages?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["CarouselWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CarouselWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CarouselWhere"] | undefined | null
};
	["CarouselItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["CarouselWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	linkUrl?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["CarouselItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["CarouselItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["CarouselItemWhere"] | undefined | null
};
	["BackgroundCondition"]: {
	and?: Array<ResolverInputTypes["BackgroundCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["BackgroundCondition"]> | undefined | null,
	not?: ResolverInputTypes["BackgroundCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["Background"] | undefined | null,
	notEq?: ResolverInputTypes["Background"] | undefined | null,
	in?: Array<ResolverInputTypes["Background"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["Background"]> | undefined | null,
	lt?: ResolverInputTypes["Background"] | undefined | null,
	lte?: ResolverInputTypes["Background"] | undefined | null,
	gt?: ResolverInputTypes["Background"] | undefined | null,
	gte?: ResolverInputTypes["Background"] | undefined | null
};
	["Background"]:Background;
	["BooleanCondition"]: {
	and?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	not?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["BonusBannerLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["BonusBannerWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	tiles?: ResolverInputTypes["TileWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	bannerName?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null
};
	["BonusBannerWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BonusBannerWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BonusBannerWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BonusBannerWhere"] | undefined | null
};
	["TileWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	bonusBanner?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	lightBoxTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	lightBoxText?: ResolverInputTypes["StringCondition"] | undefined | null,
	ligthBoxMedia?: ResolverInputTypes["MediumListWhere"] | undefined | null,
	lightBoxSectionTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	lightBoxLinkLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	lightBoxForm?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TileWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TileWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TileWhere"] | undefined | null
};
	["JobsFormLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	jobText?: ResolverInputTypes["StringCondition"] | undefined | null,
	genericText?: ResolverInputTypes["StringCondition"] | undefined | null,
	nameLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	emailLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	telefonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileTitleLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileFormatLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	formTextLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	submitLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["JobsFormWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	imageTextList?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,
	minimalize?: ResolverInputTypes["StringCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	successTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successText?: ResolverInputTypes["StringCondition"] | undefined | null,
	successBtnLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	successImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	successOtherJobsTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successOtherJobsCategoryTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successOtherJobsButtonTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successOtherJobsSelectionTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successOtherJobsSelectionYesButton?: ResolverInputTypes["StringCondition"] | undefined | null,
	successOtherJobsSelectionNoButton?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileRemoveBtnLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	gdprLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null
};
	["JobsFormWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	fileIlustration?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["JobsFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobsFormWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobsFormWhere"] | undefined | null
};
	["ImageTextListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageTextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageTextListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageTextListWhere"] | undefined | null
};
	["ImageTextItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageTextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageTextItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null
};
	["SideBySideWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,
	background?: ResolverInputTypes["BackgroundCondition"] | undefined | null,
	alignment?: ResolverInputTypes["ContentAlignmentCondition"] | undefined | null,
	mediumSize?: ResolverInputTypes["MediumSizeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["SideBySideWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SideBySideWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SideBySideWhere"] | undefined | null
};
	["SideBySideItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["SideBySideWhere"] | undefined | null,
	media?: ResolverInputTypes["MediumListWhere"] | undefined | null,
	formButtonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	type?: ResolverInputTypes["SideBySideItemTypeCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SideBySideItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SideBySideItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null
};
	["SideBySideItemTypeCondition"]: {
	and?: Array<ResolverInputTypes["SideBySideItemTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["SideBySideItemTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["SideBySideItemTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["SideBySideItemType"] | undefined | null,
	notEq?: ResolverInputTypes["SideBySideItemType"] | undefined | null,
	in?: Array<ResolverInputTypes["SideBySideItemType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["SideBySideItemType"]> | undefined | null,
	lt?: ResolverInputTypes["SideBySideItemType"] | undefined | null,
	lte?: ResolverInputTypes["SideBySideItemType"] | undefined | null,
	gt?: ResolverInputTypes["SideBySideItemType"] | undefined | null,
	gte?: ResolverInputTypes["SideBySideItemType"] | undefined | null
};
	["SideBySideItemType"]:SideBySideItemType;
	["ContentAlignmentCondition"]: {
	and?: Array<ResolverInputTypes["ContentAlignmentCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentAlignmentCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentAlignmentCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentAlignment"] | undefined | null,
	notEq?: ResolverInputTypes["ContentAlignment"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentAlignment"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentAlignment"]> | undefined | null,
	lt?: ResolverInputTypes["ContentAlignment"] | undefined | null,
	lte?: ResolverInputTypes["ContentAlignment"] | undefined | null,
	gt?: ResolverInputTypes["ContentAlignment"] | undefined | null,
	gte?: ResolverInputTypes["ContentAlignment"] | undefined | null
};
	["ContentAlignment"]:ContentAlignment;
	["MediumSizeCondition"]: {
	and?: Array<ResolverInputTypes["MediumSizeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["MediumSizeCondition"]> | undefined | null,
	not?: ResolverInputTypes["MediumSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["MediumSize"] | undefined | null,
	notEq?: ResolverInputTypes["MediumSize"] | undefined | null,
	in?: Array<ResolverInputTypes["MediumSize"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["MediumSize"]> | undefined | null,
	lt?: ResolverInputTypes["MediumSize"] | undefined | null,
	lte?: ResolverInputTypes["MediumSize"] | undefined | null,
	gt?: ResolverInputTypes["MediumSize"] | undefined | null,
	gte?: ResolverInputTypes["MediumSize"] | undefined | null
};
	["MediumSize"]:MediumSize;
	["GalleryWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GalleryWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GalleryWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GalleryWhere"] | undefined | null
};
	["GalleryItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GalleryItemWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogDescription?: ResolverInputTypes["StringCondition"] | undefined | null,
	noIndex?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	noFollow?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SeoWhere"] | undefined | null
};
	["BusinessPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	subtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarWhere"] | undefined | null,
	infoBox?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null
};
	["BusinessPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	isLokal?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessPageWhere"] | undefined | null
};
	["StickyBarWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	formButtonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["StickyBarWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["StickyBarWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["StickyBarWhere"] | undefined | null
};
	["InfoBoxWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	logoSize?: ResolverInputTypes["LogoSizeCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	locales?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["InfoBoxWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["InfoBoxWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["InfoBoxWhere"] | undefined | null
};
	["LogoSizeCondition"]: {
	and?: Array<ResolverInputTypes["LogoSizeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["LogoSizeCondition"]> | undefined | null,
	not?: ResolverInputTypes["LogoSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["LogoSize"] | undefined | null,
	notEq?: ResolverInputTypes["LogoSize"] | undefined | null,
	in?: Array<ResolverInputTypes["LogoSize"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["LogoSize"]> | undefined | null,
	lt?: ResolverInputTypes["LogoSize"] | undefined | null,
	lte?: ResolverInputTypes["LogoSize"] | undefined | null,
	gt?: ResolverInputTypes["LogoSize"] | undefined | null,
	gte?: ResolverInputTypes["LogoSize"] | undefined | null
};
	["LogoSize"]:LogoSize;
	["InfoBoxLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	address?: ResolverInputTypes["StringCondition"] | undefined | null,
	web?: ResolverInputTypes["StringCondition"] | undefined | null,
	instagram?: ResolverInputTypes["StringCondition"] | undefined | null,
	facebook?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	openingHours?: ResolverInputTypes["TextListWhere"] | undefined | null,
	addressLink?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null
};
	["JobPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["JobPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	keyInfo?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarWhere"] | undefined | null,
	mobileImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["JobPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null
};
	["JobPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	contractType?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,
	jobCity?: ResolverInputTypes["JobCityWhere"] | undefined | null,
	business?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,
	isActive?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	jobPosition?: ResolverInputTypes["JobPositionWhere"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	isSyncedToTabidoo?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobPageWhere"] | undefined | null
};
	["ContractTypeWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContractTypeWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContractTypeWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContractTypeWhere"] | undefined | null
};
	["ContractTypeLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null
};
	["JobCityWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["JobCityWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobCityWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobCityWhere"] | undefined | null
};
	["JobCityLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["JobCityWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobCityLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobCityLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null
};
	["JobPositionWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,
	jobs?: ResolverInputTypes["JobPageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["JobPositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobPositionWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobPositionWhere"] | undefined | null
};
	["JobPositionLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["JobPositionWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	not?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["DateTime"] | undefined | null,
	notEq?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null
};
	["DateTime"]:unknown;
	["KeyInfoListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["KeyInfoListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["KeyInfoListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null
};
	["KeyInfoItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	keyInfo?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,
	icon?: ResolverInputTypes["IconWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["KeyInfoItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["KeyInfoItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null
};
	["IconWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	icon?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["IconWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["IconWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["IconWhere"] | undefined | null
};
	["HomePageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["HomePageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	button?: ResolverInputTypes["LinkWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarWhere"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HomePageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HomePageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null
};
	["HomePageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HomePageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HomePageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HomePageWhere"] | undefined | null
};
	["SiteSwitcherWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SiteSwitcherWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SiteSwitcherWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null
};
	["HomePageLokalLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	button?: ResolverInputTypes["LinkWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarWhere"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null
};
	["HomePageLokalWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HomePageLokalWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HomePageLokalWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null
};
	["RecommendationFormLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	subtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	yourEmailLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	theirEmailLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	yourName?: ResolverInputTypes["StringCondition"] | undefined | null,
	sendBtnLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	successTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successSubtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	successButtonClose?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null
};
	["RecommendationFormWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,
	successImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RecommendationFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RecommendationFormWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null
};
	["HeaderLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["HeaderWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	menuLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	menuTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	menuText?: ResolverInputTypes["StringCondition"] | undefined | null,
	business?: ResolverInputTypes["BusinessWhere"] | undefined | null,
	lokalEnterprise?: ResolverInputTypes["BusinessWhere"] | undefined | null,
	businessSectionTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	lokalSectionTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	businessLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	lokalLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null
};
	["HeaderWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	logoMenu?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderWhere"] | undefined | null
};
	["BusinessWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["BusinessWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["BusinessWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["BusinessWhere"] | undefined | null
};
	["SeoDefaultLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null
};
	["SeoDefaultWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SeoDefaultWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SeoDefaultWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null
};
	["JobsFilterLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	jobPositionLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	jobContractLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	jobCityLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["JobsFilterWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	bannerTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	bannerFormLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	bannerJobsForm?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	noJobTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	noJobBtnLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	noJobImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	noJobTitleForSpecificBusiness?: ResolverInputTypes["StringCondition"] | undefined | null,
	deactivatedJob?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null
};
	["JobsFilterWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["JobsFilterWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobsFilterWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobsFilterWhere"] | undefined | null
};
	["Locale"]: AliasType<{
	_meta?:ResolverInputTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
footers?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
genericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headers?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
seoDefaults?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
businessPages?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
jobPages?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPageLocale"]],
contractTypes?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
jobCities?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCityLocale"]],
jobPositions?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPositionLocale"]],
homePages?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLocale"]],
bonusBanners?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
homePageLokals?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
jobsForms?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFormLocale"]],
jobsFilters?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
general?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
recommendationForms?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
infoBoxes?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
footersByRoot?: [{	by: ResolverInputTypes["LocaleFootersByRootUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByTermsAndConditions?: [{	by: ResolverInputTypes["LocaleFootersByTermsAndConditionsUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByBannerImage?: [{	by: ResolverInputTypes["LocaleFootersByBannerImageUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByBannerLink?: [{	by: ResolverInputTypes["LocaleFootersByBannerLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByJobsForm?: [{	by: ResolverInputTypes["LocaleFootersByJobsFormUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByCopyrightLink?: [{	by: ResolverInputTypes["LocaleFootersByCopyrightLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByRecommendationForm?: [{	by: ResolverInputTypes["LocaleFootersByRecommendationFormUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByBannerSecondLink?: [{	by: ResolverInputTypes["LocaleFootersByBannerSecondLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByInstagramLink?: [{	by: ResolverInputTypes["LocaleFootersByInstagramLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
genericPagesByRoot?: [{	by: ResolverInputTypes["LocaleGenericPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ResolverInputTypes["LocaleGenericPagesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ResolverInputTypes["LocaleGenericPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ResolverInputTypes["LocaleGenericPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByLogo?: [{	by: ResolverInputTypes["LocaleGenericPagesByLogoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByImage?: [{	by: ResolverInputTypes["LocaleGenericPagesByImageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ResolverInputTypes["LocaleHeadersByRootUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
headersByBusinessLink?: [{	by: ResolverInputTypes["LocaleHeadersByBusinessLinkUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
headersByLokalLink?: [{	by: ResolverInputTypes["LocaleHeadersByLokalLinkUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
seoDefaultsByRoot?: [{	by: ResolverInputTypes["LocaleSeoDefaultsByRootUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
seoDefaultsBySeo?: [{	by: ResolverInputTypes["LocaleSeoDefaultsBySeoUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
businessPagesByRoot?: [{	by: ResolverInputTypes["LocaleBusinessPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
businessPagesByContent?: [{	by: ResolverInputTypes["LocaleBusinessPagesByContentUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
businessPagesBySeo?: [{	by: ResolverInputTypes["LocaleBusinessPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
businessPagesByLink?: [{	by: ResolverInputTypes["LocaleBusinessPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
businessPagesByImage?: [{	by: ResolverInputTypes["LocaleBusinessPagesByImageUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
jobPagesByRoot?: [{	by: ResolverInputTypes["LocaleJobPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesByImage?: [{	by: ResolverInputTypes["LocaleJobPagesByImageUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesByKeyInfo?: [{	by: ResolverInputTypes["LocaleJobPagesByKeyInfoUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesByContent?: [{	by: ResolverInputTypes["LocaleJobPagesByContentUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesBySeo?: [{	by: ResolverInputTypes["LocaleJobPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesByLink?: [{	by: ResolverInputTypes["LocaleJobPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
jobPagesByMobileImage?: [{	by: ResolverInputTypes["LocaleJobPagesByMobileImageUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
contractTypesByRoot?: [{	by: ResolverInputTypes["LocaleContractTypesByRootUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
contractTypesBySlug?: [{	by: ResolverInputTypes["LocaleContractTypesBySlugUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
jobCitiesByRoot?: [{	by: ResolverInputTypes["LocaleJobCitiesByRootUniqueWhere"],	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null},ResolverInputTypes["JobCityLocale"]],
jobCitiesBySlug?: [{	by: ResolverInputTypes["LocaleJobCitiesBySlugUniqueWhere"],	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null},ResolverInputTypes["JobCityLocale"]],
jobPositionsByRoot?: [{	by: ResolverInputTypes["LocaleJobPositionsByRootUniqueWhere"],	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null},ResolverInputTypes["JobPositionLocale"]],
jobPositionsBySlug?: [{	by: ResolverInputTypes["LocaleJobPositionsBySlugUniqueWhere"],	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null},ResolverInputTypes["JobPositionLocale"]],
homePagesByRoot?: [{	by: ResolverInputTypes["LocaleHomePagesByRootUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesByButton?: [{	by: ResolverInputTypes["LocaleHomePagesByButtonUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesByContent?: [{	by: ResolverInputTypes["LocaleHomePagesByContentUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesBySeo?: [{	by: ResolverInputTypes["LocaleHomePagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesByLink?: [{	by: ResolverInputTypes["LocaleHomePagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesByLogo?: [{	by: ResolverInputTypes["LocaleHomePagesByLogoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesBySiteSwitcher?: [{	by: ResolverInputTypes["LocaleHomePagesBySiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePagesByIlustration?: [{	by: ResolverInputTypes["LocaleHomePagesByIlustrationUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
bonusBannersByRoot?: [{	by: ResolverInputTypes["LocaleBonusBannersByRootUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
bonusBannersByTiles?: [{	by: ResolverInputTypes["LocaleBonusBannersByTilesUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
bonusBannersByLogo?: [{	by: ResolverInputTypes["LocaleBonusBannersByLogoUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
homePageLokalsByRoot?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByRootUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsByLogo?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByLogoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsByButton?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByButtonUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsByContent?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByContentUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsBySeo?: [{	by: ResolverInputTypes["LocaleHomePageLokalsBySeoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsByLink?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByLinkUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsBySiteSwitcher?: [{	by: ResolverInputTypes["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
homePageLokalsByIlustration?: [{	by: ResolverInputTypes["LocaleHomePageLokalsByIlustrationUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
jobsFormsByRoot?: [{	by: ResolverInputTypes["LocaleJobsFormsByRootUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
jobsFormsByImageTextList?: [{	by: ResolverInputTypes["LocaleJobsFormsByImageTextListUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
jobsFormsByLogo?: [{	by: ResolverInputTypes["LocaleJobsFormsByLogoUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
jobsFormsBySuccessImage?: [{	by: ResolverInputTypes["LocaleJobsFormsBySuccessImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
jobsFiltersByRoot?: [{	by: ResolverInputTypes["LocaleJobsFiltersByRootUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
jobsFiltersByBannerJobsForm?: [{	by: ResolverInputTypes["LocaleJobsFiltersByBannerJobsFormUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
jobsFiltersByBannerImage?: [{	by: ResolverInputTypes["LocaleJobsFiltersByBannerImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
jobsFiltersByNoJobImage?: [{	by: ResolverInputTypes["LocaleJobsFiltersByNoJobImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
generalByRoot?: [{	by: ResolverInputTypes["LocaleGeneralByRootUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
generalBySeo?: [{	by: ResolverInputTypes["LocaleGeneralBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
recommendationFormsByRoot?: [{	by: ResolverInputTypes["LocaleRecommendationFormsByRootUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
infoBoxesByRoot?: [{	by: ResolverInputTypes["LocaleInfoBoxesByRootUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
paginateFooters?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
paginateGenericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
paginateSeoDefaults?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultLocaleConnection"]],
paginateBusinessPages?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageLocaleConnection"]],
paginateJobPages?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageLocaleConnection"]],
paginateContractTypes?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeLocaleConnection"]],
paginateJobCities?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityLocaleConnection"]],
paginateJobPositions?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionLocaleConnection"]],
paginateHomePages?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLocaleConnection"]],
paginateBonusBanners?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerLocaleConnection"]],
paginateHomePageLokals?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalLocaleConnection"]],
paginateJobsForms?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormLocaleConnection"]],
paginateJobsFilters?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterLocaleConnection"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
paginateRecommendationForms?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormLocaleConnection"]],
paginateInfoBoxes?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	footers?:ResolverInputTypes["FieldMeta"],
	genericPages?:ResolverInputTypes["FieldMeta"],
	headers?:ResolverInputTypes["FieldMeta"],
	seoDefaults?:ResolverInputTypes["FieldMeta"],
	businessPages?:ResolverInputTypes["FieldMeta"],
	jobPages?:ResolverInputTypes["FieldMeta"],
	contractTypes?:ResolverInputTypes["FieldMeta"],
	jobCities?:ResolverInputTypes["FieldMeta"],
	jobPositions?:ResolverInputTypes["FieldMeta"],
	homePages?:ResolverInputTypes["FieldMeta"],
	bonusBanners?:ResolverInputTypes["FieldMeta"],
	homePageLokals?:ResolverInputTypes["FieldMeta"],
	jobsForms?:ResolverInputTypes["FieldMeta"],
	jobsFilters?:ResolverInputTypes["FieldMeta"],
	general?:ResolverInputTypes["FieldMeta"],
	recommendationForms?:ResolverInputTypes["FieldMeta"],
	infoBoxes?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ResolverInputTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	ico?:boolean | `@${string}`,
	dic?:boolean | `@${string}`,
	copyright?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	noteSecond?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
linksFirstColumn?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLink"]],
linksSecondColumn?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLink"]],
termsAndConditions?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	form?:boolean | `@${string}`,
	bannerTitle?:boolean | `@${string}`,
	bannerSubtitle?:boolean | `@${string}`,
bannerImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
bannerLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
jobsForm?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
copyrightLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	bannerSecondBtn?:boolean | `@${string}`,
recommendationForm?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
bannerSecondLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
instagramLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
paginateLinksFirstColumn?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLinkConnection"]],
paginateLinksSecondColumn?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLinkConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	address?:ResolverInputTypes["FieldMeta"],
	ico?:ResolverInputTypes["FieldMeta"],
	dic?:ResolverInputTypes["FieldMeta"],
	copyright?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	noteSecond?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	linksFirstColumn?:ResolverInputTypes["FieldMeta"],
	linksSecondColumn?:ResolverInputTypes["FieldMeta"],
	termsAndConditions?:ResolverInputTypes["FieldMeta"],
	form?:ResolverInputTypes["FieldMeta"],
	bannerTitle?:ResolverInputTypes["FieldMeta"],
	bannerSubtitle?:ResolverInputTypes["FieldMeta"],
	bannerImage?:ResolverInputTypes["FieldMeta"],
	bannerLink?:ResolverInputTypes["FieldMeta"],
	jobsForm?:ResolverInputTypes["FieldMeta"],
	copyrightLink?:ResolverInputTypes["FieldMeta"],
	bannerSecondBtn?:ResolverInputTypes["FieldMeta"],
	recommendationForm?:ResolverInputTypes["FieldMeta"],
	bannerSecondLink?:ResolverInputTypes["FieldMeta"],
	instagramLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Footer"]: AliasType<{
	_meta?:ResolverInputTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["FooterLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByTermsAndConditions?: [{	by: ResolverInputTypes["FooterLocalesByTermsAndConditionsUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByBannerImage?: [{	by: ResolverInputTypes["FooterLocalesByBannerImageUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByBannerLink?: [{	by: ResolverInputTypes["FooterLocalesByBannerLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByJobsForm?: [{	by: ResolverInputTypes["FooterLocalesByJobsFormUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByCopyrightLink?: [{	by: ResolverInputTypes["FooterLocalesByCopyrightLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByRecommendationForm?: [{	by: ResolverInputTypes["FooterLocalesByRecommendationFormUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByBannerSecondLink?: [{	by: ResolverInputTypes["FooterLocalesByBannerSecondLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByInstagramLink?: [{	by: ResolverInputTypes["FooterLocalesByInstagramLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	address?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ico?: ResolverInputTypes["OrderDirection"] | undefined | null,
	dic?: ResolverInputTypes["OrderDirection"] | undefined | null,
	copyright?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noteSecond?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["FooterOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	termsAndConditions?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	form?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bannerTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bannerSubtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	bannerLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleOrderBy"] | undefined | null,
	copyrightLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	bannerSecondBtn?: ResolverInputTypes["OrderDirection"] | undefined | null,
	recommendationForm?: ResolverInputTypes["RecommendationFormLocaleOrderBy"] | undefined | null,
	bannerSecondLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	instagramLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	externalLink?: ResolverInputTypes["OrderDirection"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	anchorLink?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectOrderBy"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleOrderBy"] | undefined | null,
	businessPage?: ResolverInputTypes["BusinessPageLocaleOrderBy"] | undefined | null,
	jobPage?: ResolverInputTypes["JobPageLocaleOrderBy"] | undefined | null,
	homePage?: ResolverInputTypes["HomePageLocaleOrderBy"] | undefined | null,
	homePageLokal?: ResolverInputTypes["HomePageLokalLocaleOrderBy"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	target?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GenericPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null
};
	["ThemeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	backgroundColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	textColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonBackgroundColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonTextColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonBackgroundHoverColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonTextHoverColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonBorderColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonBorderHoverColor?: ResolverInputTypes["OrderDirection"] | undefined | null,
	headerMenuColor?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogDescription?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noIndex?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noFollow?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	alt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileName?: ResolverInputTypes["OrderDirection"] | undefined | null,
	focalPointX?: ResolverInputTypes["OrderDirection"] | undefined | null,
	focalPointY?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BusinessPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["BusinessPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	subtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarOrderBy"] | undefined | null,
	infoBox?: ResolverInputTypes["InfoBoxOrderBy"] | undefined | null
};
	["BusinessPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null,
	isLokal?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["StickyBarOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	formButtonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleOrderBy"] | undefined | null
};
	["JobsFormLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	genericText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	nameLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	emailLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	telefonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileTitleLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileFormatLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	formTextLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	submitLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["JobsFormOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	imageTextList?: ResolverInputTypes["ImageTextListOrderBy"] | undefined | null,
	minimalize?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	successTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successBtnLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	successOtherJobsTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successOtherJobsCategoryTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successOtherJobsButtonTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successOtherJobsSelectionTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successOtherJobsSelectionYesButton?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successOtherJobsSelectionNoButton?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileRemoveBtnLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	gdprLabel?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobsFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileIlustration?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageTextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["InfoBoxOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logoSize?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["JobPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["JobPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	keyInfo?: ResolverInputTypes["KeyInfoListOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarOrderBy"] | undefined | null,
	mobileImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["JobPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null,
	contractType?: ResolverInputTypes["ContractTypeOrderBy"] | undefined | null,
	jobCity?: ResolverInputTypes["JobCityOrderBy"] | undefined | null,
	business?: ResolverInputTypes["BusinessPageOrderBy"] | undefined | null,
	isActive?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobPosition?: ResolverInputTypes["JobPositionOrderBy"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isSyncedToTabidoo?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContractTypeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobCityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobPositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["KeyInfoListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["HomePageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["HomePageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	button?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherOrderBy"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryOrderBy"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarOrderBy"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryOrderBy"] | undefined | null
};
	["HomePageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null
};
	["SiteSwitcherOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["HomePageLokalLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["HomePageLokalOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	button?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherOrderBy"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryOrderBy"] | undefined | null,
	stickyBar?: ResolverInputTypes["StickyBarOrderBy"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryOrderBy"] | undefined | null
};
	["HomePageLokalOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theme?: ResolverInputTypes["ThemeOrderBy"] | undefined | null
};
	["RecommendationFormLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	subtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	yourEmailLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	theirEmailLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	yourName?: ResolverInputTypes["OrderDirection"] | undefined | null,
	sendBtnLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successSubtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successButtonClose?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["RecommendationFormOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["RecommendationFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	successImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null,
	seoDefaults?: ResolverInputTypes["SeoDefaultLocaleUniqueWhere"] | undefined | null,
	businessPages?: ResolverInputTypes["BusinessPageLocaleUniqueWhere"] | undefined | null,
	jobPages?: ResolverInputTypes["JobPageLocaleUniqueWhere"] | undefined | null,
	contractTypes?: ResolverInputTypes["ContractTypeLocaleUniqueWhere"] | undefined | null,
	jobCities?: ResolverInputTypes["JobCityLocaleUniqueWhere"] | undefined | null,
	jobPositions?: ResolverInputTypes["JobPositionLocaleUniqueWhere"] | undefined | null,
	homePages?: ResolverInputTypes["HomePageLocaleUniqueWhere"] | undefined | null,
	bonusBanners?: ResolverInputTypes["BonusBannerLocaleUniqueWhere"] | undefined | null,
	homePageLokals?: ResolverInputTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null,
	jobsForms?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null,
	jobsFilters?: ResolverInputTypes["JobsFilterLocaleUniqueWhere"] | undefined | null,
	general?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null,
	recommendationForms?: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null,
	infoBoxes?: ResolverInputTypes["InfoBoxLocaleUniqueWhere"] | undefined | null
};
	["FooterLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	termsAndConditions?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	bannerLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null,
	copyrightLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	recommendationForm?: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null,
	bannerSecondLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	instagramLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null
};
	["LinkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ImageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["JobsFormLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["JobsFormUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	imageTextList?: ResolverInputTypes["ImageTextListUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	successImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFormUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null,
	fileIlustration?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageTextListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ImageTextItemUniqueWhere"] | undefined | null
};
	["ImageTextItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["RecommendationFormLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["RecommendationFormUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["RecommendationFormUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null,
	successImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["GenericPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	linkables?: ResolverInputTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	media?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["ContentReferenceLinkableItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	item?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	redirect?: ResolverInputTypes["RedirectUniqueWhere"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	businessPage?: ResolverInputTypes["BusinessPageLocaleUniqueWhere"] | undefined | null,
	jobPage?: ResolverInputTypes["JobPageLocaleUniqueWhere"] | undefined | null,
	homePage?: ResolverInputTypes["HomePageLocaleUniqueWhere"] | undefined | null,
	homePageLokal?: ResolverInputTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["BusinessPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["BusinessPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["BusinessPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["BusinessPageLocaleUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["JobPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	keyInfo?: ResolverInputTypes["KeyInfoListUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	mobileImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["JobPageLocaleUniqueWhere"] | undefined | null
};
	["KeyInfoListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["KeyInfoItemUniqueWhere"] | undefined | null
};
	["KeyInfoItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["HomePageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["HomePageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["HomePageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["HomePageLocaleUniqueWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeUniqueWhere"] | undefined | null
};
	["ThemeUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["SiteSwitcherUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeUniqueWhere"] | undefined | null
};
	["GalleryUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["GalleryItemUniqueWhere"] | undefined | null
};
	["GalleryItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["HomePageLokalLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["HomePageLokalUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null,
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["HomePageLokalUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["HomePageLokalLocaleUniqueWhere"] | undefined | null,
	theme?: ResolverInputTypes["ThemeUniqueWhere"] | undefined | null
};
	["MediumListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["MediumItemUniqueWhere"] | undefined | null
};
	["MediumItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	businessLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	lokalLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HeaderUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	logoMenu?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["SeoDefaultLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["SeoDefaultUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["SeoDefaultUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["SeoDefaultLocaleUniqueWhere"] | undefined | null
};
	["ContractTypeLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["ContractTypeUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	slug?: string | undefined | null
};
	["ContractTypeUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["ContractTypeLocaleUniqueWhere"] | undefined | null
};
	["JobCityLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["JobCityUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	slug?: string | undefined | null
};
	["JobCityUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["JobCityLocaleUniqueWhere"] | undefined | null
};
	["JobPositionLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["JobPositionUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	slug?: string | undefined | null
};
	["JobPositionUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["JobPositionLocaleUniqueWhere"] | undefined | null,
	jobs?: ResolverInputTypes["JobPageUniqueWhere"] | undefined | null
};
	["BonusBannerLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["BonusBannerUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	tiles?: ResolverInputTypes["TileUniqueWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["BonusBannerUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["BonusBannerLocaleUniqueWhere"] | undefined | null
};
	["TileUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	ligthBoxMedia?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["JobsFilterLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["JobsFilterUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	bannerJobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	noJobImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFilterUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["JobsFilterLocaleUniqueWhere"] | undefined | null
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GeneralUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null
};
	["InfoBoxLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["InfoBoxUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["InfoBoxUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	locales?: ResolverInputTypes["InfoBoxLocaleUniqueWhere"] | undefined | null
};
	["FooterLocalesByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["FooterLocalesByBannerLinkUniqueWhere"]: {
	bannerLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocalesByJobsFormUniqueWhere"]: {
	jobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null
};
	["FooterLocalesByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocalesByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null
};
	["FooterLocalesByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocalesByInstagramLinkUniqueWhere"]: {
	instagramLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLink"]: AliasType<{
	_meta?:ResolverInputTypes["FooterLinkMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ResolverInputTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	anchorLink?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	externalLink?:ResolverInputTypes["FieldMeta"],
	internalLink?:ResolverInputTypes["FieldMeta"],
	anchorLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ResolverInputTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
redirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
genericPage?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
businessPage?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
jobPage?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
homePage?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
homePageLokal?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	redirect?:ResolverInputTypes["FieldMeta"],
	genericPage?:ResolverInputTypes["FieldMeta"],
	businessPage?:ResolverInputTypes["FieldMeta"],
	jobPage?:ResolverInputTypes["FieldMeta"],
	homePage?:ResolverInputTypes["FieldMeta"],
	homePageLokal?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ResolverInputTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
target?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	target?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	text?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
localesByLocale?: [{	by: ResolverInputTypes["GenericPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["GenericPageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GenericPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["GenericPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByLogo?: [{	by: ResolverInputTypes["GenericPageLocalesByLogoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByImage?: [{	by: ResolverInputTypes["GenericPageLocalesByImageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Theme"]: AliasType<{
	_meta?:ResolverInputTypes["ThemeMeta"],
	id?:boolean | `@${string}`,
	backgroundColor?:boolean | `@${string}`,
	textColor?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	buttonBackgroundColor?:boolean | `@${string}`,
	buttonTextColor?:boolean | `@${string}`,
	buttonBackgroundHoverColor?:boolean | `@${string}`,
	buttonTextHoverColor?:boolean | `@${string}`,
	buttonBorderColor?:boolean | `@${string}`,
	buttonBorderHoverColor?:boolean | `@${string}`,
	headerMenuColor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ThemeMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	backgroundColor?:ResolverInputTypes["FieldMeta"],
	textColor?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	buttonBackgroundColor?:ResolverInputTypes["FieldMeta"],
	buttonTextColor?:ResolverInputTypes["FieldMeta"],
	buttonBackgroundHoverColor?:ResolverInputTypes["FieldMeta"],
	buttonTextHoverColor?:ResolverInputTypes["FieldMeta"],
	buttonBorderColor?:ResolverInputTypes["FieldMeta"],
	buttonBorderHoverColor?:ResolverInputTypes["FieldMeta"],
	headerMenuColor?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ResolverInputTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
blocksByReferences?: [{	by: ResolverInputTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	blocks?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ResolverInputTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
references?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLinkables?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinkablesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLink?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinkUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByMedia?: [{	by: ResolverInputTypes["ContentBlockReferencesByMediaUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
paginateReferences?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	json?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	references?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
block?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
linkables?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
	secondaryText?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
media?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
textList?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null},ResolverInputTypes["TextList"]],
carousel?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null},ResolverInputTypes["Carousel"]],
bonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
sideBySide?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null},ResolverInputTypes["SideBySide"]],
gallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
	showBoolean?:boolean | `@${string}`,
	isRed?:boolean | `@${string}`,
video?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
linkablesByItem?: [{	by: ResolverInputTypes["ContentReferenceLinkablesByItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
paginateLinkables?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	primaryText?:ResolverInputTypes["FieldMeta"],
	block?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	linkables?:ResolverInputTypes["FieldMeta"],
	secondaryText?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	media?:ResolverInputTypes["FieldMeta"],
	textList?:ResolverInputTypes["FieldMeta"],
	carousel?:ResolverInputTypes["FieldMeta"],
	bonusBanner?:ResolverInputTypes["FieldMeta"],
	sideBySide?:ResolverInputTypes["FieldMeta"],
	gallery?:ResolverInputTypes["FieldMeta"],
	showBoolean?:ResolverInputTypes["FieldMeta"],
	isRed?:ResolverInputTypes["FieldMeta"],
	video?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ResolverInputTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
	focalPointX?:boolean | `@${string}`,
	focalPointY?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	alt?:ResolverInputTypes["FieldMeta"],
	fileName?:ResolverInputTypes["FieldMeta"],
	focalPointX?:ResolverInputTypes["FieldMeta"],
	focalPointY?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItem"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceLinkableItemMeta"],
	id?:boolean | `@${string}`,
item?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
reference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
	reference?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	item?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	reference?: ResolverInputTypes["ContentReferenceOrderBy"] | undefined | null
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	primaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	secondaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	media?: ResolverInputTypes["MediumListOrderBy"] | undefined | null,
	textList?: ResolverInputTypes["TextListOrderBy"] | undefined | null,
	carousel?: ResolverInputTypes["CarouselOrderBy"] | undefined | null,
	bonusBanner?: ResolverInputTypes["BonusBannerLocaleOrderBy"] | undefined | null,
	sideBySide?: ResolverInputTypes["SideBySideOrderBy"] | undefined | null,
	gallery?: ResolverInputTypes["GalleryOrderBy"] | undefined | null,
	showBoolean?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isRed?: ResolverInputTypes["OrderDirection"] | undefined | null,
	video?: ResolverInputTypes["VideoOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	json?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["MediumListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["CarouselOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	background?: ResolverInputTypes["OrderDirection"] | undefined | null,
	squareImages?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BonusBannerLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["BonusBannerOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	bannerName?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["BonusBannerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SideBySideOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	background?: ResolverInputTypes["OrderDirection"] | undefined | null,
	alignment?: ResolverInputTypes["OrderDirection"] | undefined | null,
	mediumSize?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	src?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	videoInfo?: ResolverInputTypes["OrderDirection"] | undefined | null,
	duration?: ResolverInputTypes["OrderDirection"] | undefined | null,
	poster?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["MediumList"]: AliasType<{
	_meta?:ResolverInputTypes["MediumListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["MediumItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItem"]: AliasType<{
	_meta?:ResolverInputTypes["MediumItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
medium?: [{	filter?: ResolverInputTypes["MediumWhere"] | undefined | null},ResolverInputTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	medium?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Medium"]: AliasType<{
	_meta?:ResolverInputTypes["MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
video?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	video?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ResolverInputTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	videoInfo?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	src?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	videoInfo?:ResolverInputTypes["FieldMeta"],
	duration?:ResolverInputTypes["FieldMeta"],
	poster?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["MediumListOrderBy"] | undefined | null,
	medium?: ResolverInputTypes["MediumOrderBy"] | undefined | null
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	video?: ResolverInputTypes["VideoOrderBy"] | undefined | null
};
	["MediumItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["MediumItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumItemEdge"]: AliasType<{
	node?:ResolverInputTypes["MediumItem"],
		__typename?: boolean | `@${string}`
}>;
	["TextList"]: AliasType<{
	_meta?:ResolverInputTypes["TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TextItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TextListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItem"]: AliasType<{
	_meta?:ResolverInputTypes["TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null},ResolverInputTypes["TextList"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["TextListOrderBy"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TextItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemEdge"]: AliasType<{
	node?:ResolverInputTypes["TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["Carousel"]: AliasType<{
	_meta?:ResolverInputTypes["CarouselMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CarouselItem"]],
	background?:boolean | `@${string}`,
	squareImages?:boolean | `@${string}`,
itemsByImage?: [{	by: ResolverInputTypes["CarouselItemsByImageUniqueWhere"],	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null},ResolverInputTypes["CarouselItem"]],
itemsByLinkUrl?: [{	by: ResolverInputTypes["CarouselItemsByLinkUrlUniqueWhere"],	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null},ResolverInputTypes["CarouselItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CarouselItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CarouselMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
	background?:ResolverInputTypes["FieldMeta"],
	squareImages?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItem"]: AliasType<{
	_meta?:ResolverInputTypes["CarouselItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null},ResolverInputTypes["Carousel"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	name?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
linkUrl?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	linkUrl?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["CarouselOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	linkUrl?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["CarouselItemsByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["CarouselItemsByLinkUrlUniqueWhere"]: {
	linkUrl?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["CarouselItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CarouselItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselItemEdge"]: AliasType<{
	node?:ResolverInputTypes["CarouselItem"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocale"]: AliasType<{
	_meta?:ResolverInputTypes["BonusBannerLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null},ResolverInputTypes["BonusBanner"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
tiles?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Tile"]],
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	bannerName?:boolean | `@${string}`,
tilesByImage?: [{	by: ResolverInputTypes["BonusBannerLocaleTilesByImageUniqueWhere"],	filter?: ResolverInputTypes["TileWhere"] | undefined | null},ResolverInputTypes["Tile"]],
tilesByLigthBoxMedia?: [{	by: ResolverInputTypes["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"],	filter?: ResolverInputTypes["TileWhere"] | undefined | null},ResolverInputTypes["Tile"]],
paginateTiles?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TileConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	tiles?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	bannerName?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBanner"]: AliasType<{
	_meta?:ResolverInputTypes["BonusBannerMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["BonusBannerLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
localesByTiles?: [{	by: ResolverInputTypes["BonusBannerLocalesByTilesUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
localesByLogo?: [{	by: ResolverInputTypes["BonusBannerLocalesByLogoUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["BonusBannerLocalesByTilesUniqueWhere"]: {
	tiles?: ResolverInputTypes["TileUniqueWhere"] | undefined | null
};
	["BonusBannerLocalesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["BonusBannerLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BonusBannerLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["BonusBannerLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Tile"]: AliasType<{
	_meta?:ResolverInputTypes["TileMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
bonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	lightBoxTitle?:boolean | `@${string}`,
	lightBoxText?:boolean | `@${string}`,
ligthBoxMedia?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
	lightBoxSectionTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lightBoxLinkLabel?:boolean | `@${string}`,
lightBoxForm?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["TileMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	bonusBanner?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	lightBoxTitle?:ResolverInputTypes["FieldMeta"],
	lightBoxText?:ResolverInputTypes["FieldMeta"],
	ligthBoxMedia?:ResolverInputTypes["FieldMeta"],
	lightBoxSectionTitle?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	lightBoxLinkLabel?:ResolverInputTypes["FieldMeta"],
	lightBoxForm?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocale"]: AliasType<{
	_meta?:ResolverInputTypes["JobsFormLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	jobText?:boolean | `@${string}`,
	genericText?:boolean | `@${string}`,
	nameLabel?:boolean | `@${string}`,
	emailLabel?:boolean | `@${string}`,
	telefonLabel?:boolean | `@${string}`,
	fileTitleLabel?:boolean | `@${string}`,
	fileFormatLabel?:boolean | `@${string}`,
	formTextLabel?:boolean | `@${string}`,
	submitLabel?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null},ResolverInputTypes["JobsForm"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
imageTextList?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null},ResolverInputTypes["ImageTextList"]],
	minimalize?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	successTitle?:boolean | `@${string}`,
	successText?:boolean | `@${string}`,
	successBtnLabel?:boolean | `@${string}`,
successImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	successOtherJobsTitle?:boolean | `@${string}`,
	successOtherJobsCategoryTitle?:boolean | `@${string}`,
	successOtherJobsButtonTitle?:boolean | `@${string}`,
	successOtherJobsSelectionTitle?:boolean | `@${string}`,
	successOtherJobsSelectionYesButton?:boolean | `@${string}`,
	successOtherJobsSelectionNoButton?:boolean | `@${string}`,
	fileRemoveBtnLabel?:boolean | `@${string}`,
	gdprLabel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	jobText?:ResolverInputTypes["FieldMeta"],
	genericText?:ResolverInputTypes["FieldMeta"],
	nameLabel?:ResolverInputTypes["FieldMeta"],
	emailLabel?:ResolverInputTypes["FieldMeta"],
	telefonLabel?:ResolverInputTypes["FieldMeta"],
	fileTitleLabel?:ResolverInputTypes["FieldMeta"],
	fileFormatLabel?:ResolverInputTypes["FieldMeta"],
	formTextLabel?:ResolverInputTypes["FieldMeta"],
	submitLabel?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	imageTextList?:ResolverInputTypes["FieldMeta"],
	minimalize?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	successTitle?:ResolverInputTypes["FieldMeta"],
	successText?:ResolverInputTypes["FieldMeta"],
	successBtnLabel?:ResolverInputTypes["FieldMeta"],
	successImage?:ResolverInputTypes["FieldMeta"],
	successOtherJobsTitle?:ResolverInputTypes["FieldMeta"],
	successOtherJobsCategoryTitle?:ResolverInputTypes["FieldMeta"],
	successOtherJobsButtonTitle?:ResolverInputTypes["FieldMeta"],
	successOtherJobsSelectionTitle?:ResolverInputTypes["FieldMeta"],
	successOtherJobsSelectionYesButton?:ResolverInputTypes["FieldMeta"],
	successOtherJobsSelectionNoButton?:ResolverInputTypes["FieldMeta"],
	fileRemoveBtnLabel?:ResolverInputTypes["FieldMeta"],
	gdprLabel?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsForm"]: AliasType<{
	_meta?:ResolverInputTypes["JobsFormMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFormLocale"]],
fileIlustration?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["JobsFormLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
localesByImageTextList?: [{	by: ResolverInputTypes["JobsFormLocalesByImageTextListUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
localesByLogo?: [{	by: ResolverInputTypes["JobsFormLocalesByLogoUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
localesBySuccessImage?: [{	by: ResolverInputTypes["JobsFormLocalesBySuccessImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	fileIlustration?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["JobsFormLocalesByImageTextListUniqueWhere"]: {
	imageTextList?: ResolverInputTypes["ImageTextListUniqueWhere"] | undefined | null
};
	["JobsFormLocalesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFormLocalesBySuccessImageUniqueWhere"]: {
	successImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFormLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobsFormLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["JobsFormLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextList"]: AliasType<{
	_meta?:ResolverInputTypes["ImageTextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageTextItem"]],
itemsByImage?: [{	by: ResolverInputTypes["ImageTextListItemsByImageUniqueWhere"],	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null},ResolverInputTypes["ImageTextItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageTextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItem"]: AliasType<{
	_meta?:ResolverInputTypes["ImageTextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null},ResolverInputTypes["ImageTextList"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ImageTextListOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageTextListItemsByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageTextItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageTextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageTextItem"],
		__typename?: boolean | `@${string}`
}>;
	["TileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bonusBanner?: ResolverInputTypes["BonusBannerLocaleOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	lightBoxTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	lightBoxText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ligthBoxMedia?: ResolverInputTypes["MediumListOrderBy"] | undefined | null,
	lightBoxSectionTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	lightBoxLinkLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	lightBoxForm?: ResolverInputTypes["JobsFormLocaleOrderBy"] | undefined | null
};
	["BonusBannerLocaleTilesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"]: {
	ligthBoxMedia?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["TileConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TileEdge"]: AliasType<{
	node?:ResolverInputTypes["Tile"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySide"]: AliasType<{
	_meta?:ResolverInputTypes["SideBySideMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SideBySideItem"]],
	background?:boolean | `@${string}`,
	alignment?:boolean | `@${string}`,
	mediumSize?:boolean | `@${string}`,
itemsByMedia?: [{	by: ResolverInputTypes["SideBySideItemsByMediaUniqueWhere"],	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null},ResolverInputTypes["SideBySideItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SideBySideItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
	background?:ResolverInputTypes["FieldMeta"],
	alignment?:ResolverInputTypes["FieldMeta"],
	mediumSize?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItem"]: AliasType<{
	_meta?:ResolverInputTypes["SideBySideItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null},ResolverInputTypes["SideBySide"]],
media?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
	formButtonLabel?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
jobsForm?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	media?:ResolverInputTypes["FieldMeta"],
	formButtonLabel?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	jobsForm?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["SideBySideOrderBy"] | undefined | null,
	media?: ResolverInputTypes["MediumListOrderBy"] | undefined | null,
	formButtonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	jobsForm?: ResolverInputTypes["JobsFormLocaleOrderBy"] | undefined | null
};
	["SideBySideItemsByMediaUniqueWhere"]: {
	media?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["SideBySideItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SideBySideItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideItemEdge"]: AliasType<{
	node?:ResolverInputTypes["SideBySideItem"],
		__typename?: boolean | `@${string}`
}>;
	["Gallery"]: AliasType<{
	_meta?:ResolverInputTypes["GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GalleryItem"]],
itemsByImage?: [{	by: ResolverInputTypes["GalleryItemsByImageUniqueWhere"],	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null},ResolverInputTypes["GalleryItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ResolverInputTypes["GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["GalleryOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["GalleryItemsByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ResolverInputTypes["GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkablesByItemUniqueWhere"]: {
	item?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["ContentReferenceLinkableItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceLinkableItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceLinkableItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReferenceLinkableItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockReferencesByLinkablesUniqueWhere"]: {
	linkables?: ResolverInputTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByMediaUniqueWhere"]: {
	media?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ResolverInputTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
ogImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	ogTitle?:ResolverInputTypes["FieldMeta"],
	ogDescription?:ResolverInputTypes["FieldMeta"],
	noIndex?:ResolverInputTypes["FieldMeta"],
	noFollow?:ResolverInputTypes["FieldMeta"],
	ogImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessPageLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null},ResolverInputTypes["BusinessPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	subtitle?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
stickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
infoBox?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null},ResolverInputTypes["InfoBox"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	subtitle?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	stickyBar?:ResolverInputTypes["FieldMeta"],
	infoBox?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPage"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
	isLokal?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["BusinessPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["BusinessPageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["BusinessPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["BusinessPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
localesByImage?: [{	by: ResolverInputTypes["BusinessPageLocalesByImageUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
	isLokal?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessPageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["BusinessPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["BusinessPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["BusinessPageLocalesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["BusinessPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["BusinessPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBar"]: AliasType<{
	_meta?:ResolverInputTypes["StickyBarMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	formButtonLabel?:boolean | `@${string}`,
jobsForm?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	formButtonLabel?:ResolverInputTypes["FieldMeta"],
	jobsForm?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBox"]: AliasType<{
	_meta?:ResolverInputTypes["InfoBoxMeta"],
	id?:boolean | `@${string}`,
	logoSize?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
locales?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["InfoBoxLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	logoSize?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocale"]: AliasType<{
	_meta?:ResolverInputTypes["InfoBoxLocaleMeta"],
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	web?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	facebook?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null},ResolverInputTypes["InfoBox"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
openingHours?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null},ResolverInputTypes["TextList"]],
	addressLink?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	address?:ResolverInputTypes["FieldMeta"],
	web?:ResolverInputTypes["FieldMeta"],
	instagram?:ResolverInputTypes["FieldMeta"],
	facebook?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	openingHours?:ResolverInputTypes["FieldMeta"],
	addressLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	address?: ResolverInputTypes["OrderDirection"] | undefined | null,
	web?: ResolverInputTypes["OrderDirection"] | undefined | null,
	instagram?: ResolverInputTypes["OrderDirection"] | undefined | null,
	facebook?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["InfoBoxOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	openingHours?: ResolverInputTypes["TextListOrderBy"] | undefined | null,
	addressLink?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["InfoBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["InfoBoxLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["InfoBoxLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["InfoBoxLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["JobPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null},ResolverInputTypes["JobPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
keyInfo?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null},ResolverInputTypes["KeyInfoList"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	email?:boolean | `@${string}`,
stickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
mobileImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	keyInfo?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	stickyBar?:ResolverInputTypes["FieldMeta"],
	mobileImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPage"]: AliasType<{
	_meta?:ResolverInputTypes["JobPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPageLocale"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
contractType?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null},ResolverInputTypes["ContractType"]],
jobCity?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null},ResolverInputTypes["JobCity"]],
business?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null},ResolverInputTypes["BusinessPage"]],
	isActive?:boolean | `@${string}`,
jobPosition?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null},ResolverInputTypes["JobPosition"]],
	publishedAt?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isSyncedToTabidoo?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["JobPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesByImage?: [{	by: ResolverInputTypes["JobPageLocalesByImageUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesByKeyInfo?: [{	by: ResolverInputTypes["JobPageLocalesByKeyInfoUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["JobPageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["JobPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["JobPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
localesByMobileImage?: [{	by: ResolverInputTypes["JobPageLocalesByMobileImageUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
	contractType?:ResolverInputTypes["FieldMeta"],
	jobCity?:ResolverInputTypes["FieldMeta"],
	business?:ResolverInputTypes["FieldMeta"],
	isActive?:ResolverInputTypes["FieldMeta"],
	jobPosition?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	isSyncedToTabidoo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractType"]: AliasType<{
	_meta?:ResolverInputTypes["ContractTypeMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["ContractTypeLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocale"]: AliasType<{
	_meta?:ResolverInputTypes["ContractTypeLocaleMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null},ResolverInputTypes["ContractType"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["ContractTypeOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContractTypeLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ContractTypeLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContractTypeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["ContractTypeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobCity"]: AliasType<{
	_meta?:ResolverInputTypes["JobCityMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCityLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["JobCityLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null},ResolverInputTypes["JobCityLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobCityMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocale"]: AliasType<{
	_meta?:ResolverInputTypes["JobCityLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null},ResolverInputTypes["JobCity"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["JobCityOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobCityLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["JobCityLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobCityLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["JobCityLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPosition"]: AliasType<{
	_meta?:ResolverInputTypes["JobPositionMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPositionLocale"]],
jobs?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPage"]],
localesByLocale?: [{	by: ResolverInputTypes["JobPositionLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null},ResolverInputTypes["JobPositionLocale"]],
jobsByLocales?: [{	by: ResolverInputTypes["JobPositionJobsByLocalesUniqueWhere"],	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null},ResolverInputTypes["JobPage"]],
paginateLocales?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionLocaleConnection"]],
paginateJobs?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	jobs?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocale"]: AliasType<{
	_meta?:ResolverInputTypes["JobPositionLocaleMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null},ResolverInputTypes["JobPosition"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["JobPositionOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobPositionLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["JobPositionJobsByLocalesUniqueWhere"]: {
	locales?: ResolverInputTypes["JobPageLocaleUniqueWhere"] | undefined | null
};
	["JobPositionLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobPositionLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["JobPositionLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageEdge"]: AliasType<{
	node?:ResolverInputTypes["JobPage"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["JobPageLocalesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobPageLocalesByKeyInfoUniqueWhere"]: {
	keyInfo?: ResolverInputTypes["KeyInfoListUniqueWhere"] | undefined | null
};
	["JobPageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["JobPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["JobPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["JobPageLocalesByMobileImageUniqueWhere"]: {
	mobileImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["JobPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoList"]: AliasType<{
	_meta?:ResolverInputTypes["KeyInfoListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["KeyInfoItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["KeyInfoItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItem"]: AliasType<{
	_meta?:ResolverInputTypes["KeyInfoItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	keyInfo?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null},ResolverInputTypes["KeyInfoList"]],
icon?: [{	filter?: ResolverInputTypes["IconWhere"] | undefined | null},ResolverInputTypes["Icon"]],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	keyInfo?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	icon?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Icon"]: AliasType<{
	_meta?:ResolverInputTypes["IconMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["IconMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	icon?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	keyInfo?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["KeyInfoListOrderBy"] | undefined | null,
	icon?: ResolverInputTypes["IconOrderBy"] | undefined | null
};
	["IconOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	icon?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["KeyInfoItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["KeyInfoItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoItemEdge"]: AliasType<{
	node?:ResolverInputTypes["KeyInfoItem"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["HomePageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null},ResolverInputTypes["HomePage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
button?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
siteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null},ResolverInputTypes["SiteSwitcher"]],
gallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
stickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
	text?:boolean | `@${string}`,
ilustration?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	button?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	siteSwitcher?:ResolverInputTypes["FieldMeta"],
	gallery?:ResolverInputTypes["FieldMeta"],
	stickyBar?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	ilustration?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePage"]: AliasType<{
	_meta?:ResolverInputTypes["HomePageMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLocale"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
localesByLocale?: [{	by: ResolverInputTypes["HomePageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesByButton?: [{	by: ResolverInputTypes["HomePageLocalesByButtonUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["HomePageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["HomePageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["HomePageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesByLogo?: [{	by: ResolverInputTypes["HomePageLocalesByLogoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesBySiteSwitcher?: [{	by: ResolverInputTypes["HomePageLocalesBySiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
localesByIlustration?: [{	by: ResolverInputTypes["HomePageLocalesByIlustrationUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["HomePageLocalesByButtonUniqueWhere"]: {
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HomePageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["HomePageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["HomePageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["HomePageLocalesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["HomePageLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null
};
	["HomePageLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["HomePageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HomePageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["HomePageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcher"]: AliasType<{
	_meta?:ResolverInputTypes["SiteSwitcherMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocale"]: AliasType<{
	_meta?:ResolverInputTypes["HomePageLokalLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null},ResolverInputTypes["HomePageLokal"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
button?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
siteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null},ResolverInputTypes["SiteSwitcher"]],
gallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
stickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
	text?:boolean | `@${string}`,
ilustration?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	button?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	siteSwitcher?:ResolverInputTypes["FieldMeta"],
	gallery?:ResolverInputTypes["FieldMeta"],
	stickyBar?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	ilustration?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokal"]: AliasType<{
	_meta?:ResolverInputTypes["HomePageLokalMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
theme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
localesByLocale?: [{	by: ResolverInputTypes["HomePageLokalLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesByLogo?: [{	by: ResolverInputTypes["HomePageLokalLocalesByLogoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesByButton?: [{	by: ResolverInputTypes["HomePageLokalLocalesByButtonUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesByContent?: [{	by: ResolverInputTypes["HomePageLokalLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["HomePageLokalLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesByLink?: [{	by: ResolverInputTypes["HomePageLokalLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesBySiteSwitcher?: [{	by: ResolverInputTypes["HomePageLokalLocalesBySiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
localesByIlustration?: [{	by: ResolverInputTypes["HomePageLokalLocalesByIlustrationUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	theme?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesByButtonUniqueWhere"]: {
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null
};
	["HomePageLokalLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["HomePageLokalLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HomePageLokalLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["HomePageLokalLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["RecommendationFormLocale"]: AliasType<{
	_meta?:ResolverInputTypes["RecommendationFormLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	yourEmailLabel?:boolean | `@${string}`,
	theirEmailLabel?:boolean | `@${string}`,
	yourName?:boolean | `@${string}`,
	sendBtnLabel?:boolean | `@${string}`,
	successTitle?:boolean | `@${string}`,
	successSubtitle?:boolean | `@${string}`,
	successButtonClose?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null},ResolverInputTypes["RecommendationForm"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	subtitle?:ResolverInputTypes["FieldMeta"],
	yourEmailLabel?:ResolverInputTypes["FieldMeta"],
	theirEmailLabel?:ResolverInputTypes["FieldMeta"],
	yourName?:ResolverInputTypes["FieldMeta"],
	sendBtnLabel?:ResolverInputTypes["FieldMeta"],
	successTitle?:ResolverInputTypes["FieldMeta"],
	successSubtitle?:ResolverInputTypes["FieldMeta"],
	successButtonClose?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationForm"]: AliasType<{
	_meta?:ResolverInputTypes["RecommendationFormMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
successImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["RecommendationFormLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	successImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["RecommendationFormLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RecommendationFormLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["RecommendationFormLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterLinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkEdge"]: AliasType<{
	node?:ResolverInputTypes["FooterLink"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocale"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	menuLabel?:boolean | `@${string}`,
	menuTitle?:boolean | `@${string}`,
	menuText?:boolean | `@${string}`,
business?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Business"]],
lokalEnterprise?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Business"]],
	businessSectionTitle?:boolean | `@${string}`,
	lokalSectionTitle?:boolean | `@${string}`,
businessLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
lokalLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
paginateBusiness?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessConnection"]],
paginateLokalEnterprise?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	menuLabel?:ResolverInputTypes["FieldMeta"],
	menuTitle?:ResolverInputTypes["FieldMeta"],
	menuText?:ResolverInputTypes["FieldMeta"],
	business?:ResolverInputTypes["FieldMeta"],
	lokalEnterprise?:ResolverInputTypes["FieldMeta"],
	businessSectionTitle?:ResolverInputTypes["FieldMeta"],
	lokalSectionTitle?:ResolverInputTypes["FieldMeta"],
	businessLink?:ResolverInputTypes["FieldMeta"],
	lokalLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
logoMenu?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["HeaderLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByBusinessLink?: [{	by: ResolverInputTypes["HeaderLocalesByBusinessLinkUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByLokalLink?: [{	by: ResolverInputTypes["HeaderLocalesByLokalLinkUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	logoMenu?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["HeaderOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	menuLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	menuTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	menuText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	businessSectionTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	lokalSectionTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	businessLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	lokalLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	logoMenu?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["HeaderLocalesByBusinessLinkUniqueWhere"]: {
	businessLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HeaderLocalesByLokalLinkUniqueWhere"]: {
	lokalLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Business"]: AliasType<{
	_meta?:ResolverInputTypes["BusinessMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["BusinessConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessEdge"]: AliasType<{
	node?:ResolverInputTypes["Business"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocale"]: AliasType<{
	_meta?:ResolverInputTypes["SeoDefaultLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null},ResolverInputTypes["SeoDefault"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefault"]: AliasType<{
	_meta?:ResolverInputTypes["SeoDefaultMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["SeoDefaultLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["SeoDefaultLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["SeoDefaultOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null
};
	["SeoDefaultOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SeoDefaultLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["SeoDefaultLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["SeoDefaultLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SeoDefaultLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["SeoDefaultLocale"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocale"]: AliasType<{
	_meta?:ResolverInputTypes["JobsFilterLocaleMeta"],
	id?:boolean | `@${string}`,
	jobPositionLabel?:boolean | `@${string}`,
	jobContractLabel?:boolean | `@${string}`,
	jobCityLabel?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null},ResolverInputTypes["JobsFilter"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	bannerTitle?:boolean | `@${string}`,
	bannerFormLabel?:boolean | `@${string}`,
bannerJobsForm?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
bannerImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	noJobTitle?:boolean | `@${string}`,
	noJobBtnLabel?:boolean | `@${string}`,
noJobImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	noJobTitleForSpecificBusiness?:boolean | `@${string}`,
	deactivatedJob?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	jobPositionLabel?:ResolverInputTypes["FieldMeta"],
	jobContractLabel?:ResolverInputTypes["FieldMeta"],
	jobCityLabel?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	bannerTitle?:ResolverInputTypes["FieldMeta"],
	bannerFormLabel?:ResolverInputTypes["FieldMeta"],
	bannerJobsForm?:ResolverInputTypes["FieldMeta"],
	bannerImage?:ResolverInputTypes["FieldMeta"],
	noJobTitle?:ResolverInputTypes["FieldMeta"],
	noJobBtnLabel?:ResolverInputTypes["FieldMeta"],
	noJobImage?:ResolverInputTypes["FieldMeta"],
	noJobTitleForSpecificBusiness?:ResolverInputTypes["FieldMeta"],
	deactivatedJob?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilter"]: AliasType<{
	_meta?:ResolverInputTypes["JobsFilterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["JobsFilterLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
localesByBannerJobsForm?: [{	by: ResolverInputTypes["JobsFilterLocalesByBannerJobsFormUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
localesByBannerImage?: [{	by: ResolverInputTypes["JobsFilterLocalesByBannerImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
localesByNoJobImage?: [{	by: ResolverInputTypes["JobsFilterLocalesByNoJobImageUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobPositionLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobContractLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	jobCityLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["JobsFilterOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	bannerTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bannerFormLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	bannerJobsForm?: ResolverInputTypes["JobsFormLocaleOrderBy"] | undefined | null,
	bannerImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	noJobTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noJobBtnLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noJobImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	noJobTitleForSpecificBusiness?: ResolverInputTypes["OrderDirection"] | undefined | null,
	deactivatedJob?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobsFilterOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobsFilterLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["JobsFilterLocalesByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null
};
	["JobsFilterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFilterLocalesByNoJobImageUniqueWhere"]: {
	noJobImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["JobsFilterLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobsFilterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["JobsFilterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GeneralOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null
};
	["LocaleFootersByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleFootersByBannerImageUniqueWhere"]: {
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleFootersByBannerLinkUniqueWhere"]: {
	bannerLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleFootersByJobsFormUniqueWhere"]: {
	jobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null
};
	["LocaleFootersByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleFootersByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"] | undefined | null
};
	["LocaleFootersByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleFootersByInstagramLinkUniqueWhere"]: {
	instagramLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null
};
	["LocaleHeadersByBusinessLinkUniqueWhere"]: {
	businessLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleHeadersByLokalLinkUniqueWhere"]: {
	lokalLink?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleSeoDefaultsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["SeoDefaultUniqueWhere"] | undefined | null
};
	["LocaleSeoDefaultsBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleBusinessPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["BusinessPageUniqueWhere"] | undefined | null
};
	["LocaleBusinessPagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleBusinessPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleBusinessPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleBusinessPagesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["JobPageUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByKeyInfoUniqueWhere"]: {
	keyInfo?: ResolverInputTypes["KeyInfoListUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleJobPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleJobPagesByMobileImageUniqueWhere"]: {
	mobileImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleContractTypesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["ContractTypeUniqueWhere"] | undefined | null
};
	["LocaleContractTypesBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["LocaleJobCitiesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["JobCityUniqueWhere"] | undefined | null
};
	["LocaleJobCitiesBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["LocaleJobPositionsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["JobPositionUniqueWhere"] | undefined | null
};
	["LocaleJobPositionsBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["LocaleHomePagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["HomePageUniqueWhere"] | undefined | null
};
	["LocaleHomePagesByButtonUniqueWhere"]: {
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleHomePagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleHomePagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleHomePagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleHomePagesByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleHomePagesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null
};
	["LocaleHomePagesByIlustrationUniqueWhere"]: {
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["LocaleBonusBannersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["BonusBannerUniqueWhere"] | undefined | null
};
	["LocaleBonusBannersByTilesUniqueWhere"]: {
	tiles?: ResolverInputTypes["TileUniqueWhere"] | undefined | null
};
	["LocaleBonusBannersByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["HomePageLokalUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByButtonUniqueWhere"]: {
	button?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ResolverInputTypes["SiteSwitcherUniqueWhere"] | undefined | null
};
	["LocaleHomePageLokalsByIlustrationUniqueWhere"]: {
	ilustration?: ResolverInputTypes["GalleryUniqueWhere"] | undefined | null
};
	["LocaleJobsFormsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["JobsFormUniqueWhere"] | undefined | null
};
	["LocaleJobsFormsByImageTextListUniqueWhere"]: {
	imageTextList?: ResolverInputTypes["ImageTextListUniqueWhere"] | undefined | null
};
	["LocaleJobsFormsByLogoUniqueWhere"]: {
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleJobsFormsBySuccessImageUniqueWhere"]: {
	successImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleJobsFiltersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["JobsFilterUniqueWhere"] | undefined | null
};
	["LocaleJobsFiltersByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null
};
	["LocaleJobsFiltersByBannerImageUniqueWhere"]: {
	bannerImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleJobsFiltersByNoJobImageUniqueWhere"]: {
	noJobImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleRecommendationFormsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["RecommendationFormUniqueWhere"] | undefined | null
};
	["LocaleInfoBoxesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["InfoBoxUniqueWhere"] | undefined | null
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GeneralConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ResolverInputTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ResolverInputTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ResolverInputTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ResolverInputTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ResolverInputTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ResolverInputTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ResolverInputTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ResolverInputTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLinkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["HeaderConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ResolverInputTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SeoDefaultEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoDefaultEdge"]: AliasType<{
	node?:ResolverInputTypes["SeoDefault"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	logo?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["BusinessPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BusinessPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessPageEdge"]: AliasType<{
	node?:ResolverInputTypes["BusinessPage"],
		__typename?: boolean | `@${string}`
}>;
	["IconUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	icon?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["IconConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["IconEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IconEdge"]: AliasType<{
	node?:ResolverInputTypes["Icon"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["KeyInfoListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["KeyInfoListEdge"]: AliasType<{
	node?:ResolverInputTypes["KeyInfoList"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContractTypeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContractTypeEdge"]: AliasType<{
	node?:ResolverInputTypes["ContractType"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobCityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobCityEdge"]: AliasType<{
	node?:ResolverInputTypes["JobCity"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobPositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobPositionEdge"]: AliasType<{
	node?:ResolverInputTypes["JobPosition"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GalleryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryEdge"]: AliasType<{
	node?:ResolverInputTypes["Gallery"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HomePageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageEdge"]: AliasType<{
	node?:ResolverInputTypes["HomePage"],
		__typename?: boolean | `@${string}`
}>;
	["MediumUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["MediumConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["MediumEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumEdge"]: AliasType<{
	node?:ResolverInputTypes["Medium"],
		__typename?: boolean | `@${string}`
}>;
	["MediumListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["MediumListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumListEdge"]: AliasType<{
	node?:ResolverInputTypes["MediumList"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["TextListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["TextItemUniqueWhere"] | undefined | null
};
	["TextListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextListEdge"]: AliasType<{
	node?:ResolverInputTypes["TextList"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["CarouselItemUniqueWhere"] | undefined | null
};
	["CarouselItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null,
	linkUrl?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["CarouselConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["CarouselEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CarouselEdge"]: AliasType<{
	node?:ResolverInputTypes["Carousel"],
		__typename?: boolean | `@${string}`
}>;
	["ThemeConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ThemeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ThemeEdge"]: AliasType<{
	node?:ResolverInputTypes["Theme"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["BonusBannerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BonusBannerEdge"]: AliasType<{
	node?:ResolverInputTypes["BonusBanner"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["SideBySideItemUniqueWhere"] | undefined | null
};
	["SideBySideItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	media?: ResolverInputTypes["MediumListUniqueWhere"] | undefined | null
};
	["SideBySideConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SideBySideEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SideBySideEdge"]: AliasType<{
	node?:ResolverInputTypes["SideBySide"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HomePageLokalEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HomePageLokalEdge"]: AliasType<{
	node?:ResolverInputTypes["HomePageLokal"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SiteSwitcherEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SiteSwitcherEdge"]: AliasType<{
	node?:ResolverInputTypes["SiteSwitcher"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobsFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFormEdge"]: AliasType<{
	node?:ResolverInputTypes["JobsForm"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageTextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageTextListEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageTextList"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobsFilterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobsFilterEdge"]: AliasType<{
	node?:ResolverInputTypes["JobsFilter"],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	_meta?:ResolverInputTypes["FileMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FileUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FileWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FileWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FileWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FileWhere"] | undefined | null
};
	["FileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FileConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FileEdge"]: AliasType<{
	node?:ResolverInputTypes["File"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	message?:ResolverInputTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{
	_FieldPathFragment?:ResolverInputTypes["_FieldPathFragment"],
	_IndexPathFragment?:ResolverInputTypes["_IndexPathFragment"],
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCreateInput"]: {
	url?: string | undefined | null,
	size?: number | undefined | null,
	type?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["JobFormSubscriptionCreateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	name?: string | undefined | null,
	phone?: string | undefined | null,
	email?: string | undefined | null,
	message?: string | undefined | null,
	file?: ResolverInputTypes["JobFormSubscriptionCreateFileEntityRelationInput"] | undefined | null,
	job?: ResolverInputTypes["JobFormSubscriptionCreateJobEntityRelationInput"] | undefined | null,
	status?: ResolverInputTypes["JobFormSubscriptionCreateStatusEntityRelationInput"] | undefined | null,
	isSyncedToTabidoo?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["JobFormSubscriptionCreateFileEntityRelationInput"]: {
	connect?: ResolverInputTypes["FileUniqueWhere"] | undefined | null,
	create?: ResolverInputTypes["FileCreateInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["JobFormSubscriptionConnectOrCreateFileRelationInput"] | undefined | null
};
	["JobFormSubscriptionConnectOrCreateFileRelationInput"]: {
	connect: ResolverInputTypes["FileUniqueWhere"],
	create: ResolverInputTypes["FileCreateInput"]
};
	["JobFormSubscriptionCreateJobEntityRelationInput"]: {
	connect?: ResolverInputTypes["JobPageLocaleUniqueWhere"] | undefined | null
};
	["JobFormSubscriptionCreateStatusEntityRelationInput"]: {
	connect?: ResolverInputTypes["JobFormSubscriptionStatusUniqueWhere"] | undefined | null
};
	["JobFormSubscriptionStatusUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ImageList"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
itemsByImage?: [{	by: ResolverInputTypes["ImageListItemsByImageUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListItemWhere"] | undefined | null
};
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatus"]: AliasType<{
	_meta?:ResolverInputTypes["JobFormSubscriptionStatusMeta"],
	id?:boolean | `@${string}`,
	statusTitle?:boolean | `@${string}`,
	statusColor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	statusTitle?:ResolverInputTypes["FieldMeta"],
	statusColor?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	statusTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	statusColor?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null
};
	["JobFormSubscriptionStatusOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	statusTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	statusColor?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["JobFormSubscriptionStatusConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["JobFormSubscriptionStatusEdge"],
		__typename?: boolean | `@${string}`
}>;
	["JobFormSubscriptionStatusEdge"]: AliasType<{
	node?:ResolverInputTypes["JobFormSubscriptionStatus"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["StickyBarConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["StickyBarEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarEdge"]: AliasType<{
	node?:ResolverInputTypes["StickyBar"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarCreateInput"]: {
	title?: string | undefined | null,
	link?: ResolverInputTypes["StickyBarCreateLinkEntityRelationInput"] | undefined | null,
	formButtonLabel?: string | undefined | null,
	jobsForm?: ResolverInputTypes["StickyBarCreateJobsFormEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["StickyBarCreateLinkEntityRelationInput"]: {
	connect?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["StickyBarCreateJobsFormEntityRelationInput"]: {
	connect?: ResolverInputTypes["JobsFormLocaleUniqueWhere"] | undefined | null
};
	["RecommendationFormConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RecommendationFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormEdge"]: AliasType<{
	node?:ResolverInputTypes["RecommendationForm"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormSubscriptionCreateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	yourEmail?: string | undefined | null,
	theirEmail?: string | undefined | null,
	yourName?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["InfoBoxConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["InfoBoxEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxEdge"]: AliasType<{
	node?:ResolverInputTypes["InfoBox"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxCreateInput"]: {
	logoSize?: ResolverInputTypes["LogoSize"] | undefined | null,
	logo?: ResolverInputTypes["InfoBoxCreateLogoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["InfoBoxCreateLogoEntityRelationInput"]: {
	connect?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["QueryTransaction"]: AliasType<{
	schema?:ResolverInputTypes["_Schema"],
	s3DummyQuery?:boolean | `@${string}`,
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getContentReferenceLinkableItem?: [{	by: ResolverInputTypes["ContentReferenceLinkableItemUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
listContentReferenceLinkableItem?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItem"]],
paginateContentReferenceLinkableItem?: [{	filter?: ResolverInputTypes["ContentReferenceLinkableItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceLinkableItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceLinkableItemConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterLink?: [{	by: ResolverInputTypes["FooterLinkUniqueWhere"],	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null},ResolverInputTypes["FooterLink"]],
listFooterLink?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLink"]],
paginateFooterLink?: [{	filter?: ResolverInputTypes["FooterLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLinkConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getSeoDefault?: [{	by: ResolverInputTypes["SeoDefaultUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null},ResolverInputTypes["SeoDefault"]],
listSeoDefault?: [{	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?: ResolverInputTypes["SeoDefaultWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultConnection"]],
getSeoDefaultLocale?: [{	by: ResolverInputTypes["SeoDefaultLocaleUniqueWhere"],	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
listSeoDefaultLocale?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SeoDefaultLocale"]],
paginateSeoDefaultLocale?: [{	filter?: ResolverInputTypes["SeoDefaultLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoDefaultLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoDefaultLocaleConnection"]],
getBusiness?: [{	by: ResolverInputTypes["BusinessUniqueWhere"],	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null},ResolverInputTypes["Business"]],
listBusiness?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Business"]],
paginateBusiness?: [{	filter?: ResolverInputTypes["BusinessWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessConnection"]],
getBusinessPage?: [{	by: ResolverInputTypes["BusinessPageUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null},ResolverInputTypes["BusinessPage"]],
listBusinessPage?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPage"]],
paginateBusinessPage?: [{	filter?: ResolverInputTypes["BusinessPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageConnection"]],
getBusinessPageLocale?: [{	by: ResolverInputTypes["BusinessPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
listBusinessPageLocale?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BusinessPageLocale"]],
paginateBusinessPageLocale?: [{	filter?: ResolverInputTypes["BusinessPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BusinessPageLocaleConnection"]],
getIcon?: [{	by: ResolverInputTypes["IconUniqueWhere"],	filter?: ResolverInputTypes["IconWhere"] | undefined | null},ResolverInputTypes["Icon"]],
listIcon?: [{	filter?: ResolverInputTypes["IconWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["IconOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Icon"]],
paginateIcon?: [{	filter?: ResolverInputTypes["IconWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["IconOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["IconConnection"]],
getJobPage?: [{	by: ResolverInputTypes["JobPageUniqueWhere"],	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null},ResolverInputTypes["JobPage"]],
listJobPage?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPage"]],
paginateJobPage?: [{	filter?: ResolverInputTypes["JobPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageConnection"]],
getJobPageLocale?: [{	by: ResolverInputTypes["JobPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null},ResolverInputTypes["JobPageLocale"]],
listJobPageLocale?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPageLocale"]],
paginateJobPageLocale?: [{	filter?: ResolverInputTypes["JobPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPageLocaleConnection"]],
getKeyInfoItem?: [{	by: ResolverInputTypes["KeyInfoItemUniqueWhere"],	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null},ResolverInputTypes["KeyInfoItem"]],
listKeyInfoItem?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["KeyInfoItem"]],
paginateKeyInfoItem?: [{	filter?: ResolverInputTypes["KeyInfoItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["KeyInfoItemConnection"]],
getKeyInfoList?: [{	by: ResolverInputTypes["KeyInfoListUniqueWhere"],	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null},ResolverInputTypes["KeyInfoList"]],
listKeyInfoList?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["KeyInfoList"]],
paginateKeyInfoList?: [{	filter?: ResolverInputTypes["KeyInfoListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["KeyInfoListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["KeyInfoListConnection"]],
getContractType?: [{	by: ResolverInputTypes["ContractTypeUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null},ResolverInputTypes["ContractType"]],
listContractType?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractType"]],
paginateContractType?: [{	filter?: ResolverInputTypes["ContractTypeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeConnection"]],
getContractTypeLocale?: [{	by: ResolverInputTypes["ContractTypeLocaleUniqueWhere"],	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
listContractTypeLocale?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContractTypeLocale"]],
paginateContractTypeLocale?: [{	filter?: ResolverInputTypes["ContractTypeLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContractTypeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContractTypeLocaleConnection"]],
getJobCity?: [{	by: ResolverInputTypes["JobCityUniqueWhere"],	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null},ResolverInputTypes["JobCity"]],
listJobCity?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCity"]],
paginateJobCity?: [{	filter?: ResolverInputTypes["JobCityWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityConnection"]],
getJobCityLocale?: [{	by: ResolverInputTypes["JobCityLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null},ResolverInputTypes["JobCityLocale"]],
listJobCityLocale?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobCityLocale"]],
paginateJobCityLocale?: [{	filter?: ResolverInputTypes["JobCityLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobCityLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobCityLocaleConnection"]],
getJobPosition?: [{	by: ResolverInputTypes["JobPositionUniqueWhere"],	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null},ResolverInputTypes["JobPosition"]],
listJobPosition?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPosition"]],
paginateJobPosition?: [{	filter?: ResolverInputTypes["JobPositionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionConnection"]],
getJobPositionLocale?: [{	by: ResolverInputTypes["JobPositionLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null},ResolverInputTypes["JobPositionLocale"]],
listJobPositionLocale?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobPositionLocale"]],
paginateJobPositionLocale?: [{	filter?: ResolverInputTypes["JobPositionLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobPositionLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobPositionLocaleConnection"]],
getGallery?: [{	by: ResolverInputTypes["GalleryUniqueWhere"],	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null},ResolverInputTypes["Gallery"]],
listGallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Gallery"]],
paginateGallery?: [{	filter?: ResolverInputTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GalleryConnection"]],
getHomePage?: [{	by: ResolverInputTypes["HomePageUniqueWhere"],	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null},ResolverInputTypes["HomePage"]],
listHomePage?: [{	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePage"]],
paginateHomePage?: [{	filter?: ResolverInputTypes["HomePageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageConnection"]],
getHomePageLocale?: [{	by: ResolverInputTypes["HomePageLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLocale"]],
listHomePageLocale?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLocale"]],
paginateHomePageLocale?: [{	filter?: ResolverInputTypes["HomePageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLocaleConnection"]],
getMedium?: [{	by: ResolverInputTypes["MediumUniqueWhere"],	filter?: ResolverInputTypes["MediumWhere"] | undefined | null},ResolverInputTypes["Medium"]],
listMedium?: [{	filter?: ResolverInputTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Medium"]],
paginateMedium?: [{	filter?: ResolverInputTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumConnection"]],
getMediumItem?: [{	by: ResolverInputTypes["MediumItemUniqueWhere"],	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null},ResolverInputTypes["MediumItem"]],
listMediumItem?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["MediumItem"]],
paginateMediumItem?: [{	filter?: ResolverInputTypes["MediumItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumItemConnection"]],
getMediumList?: [{	by: ResolverInputTypes["MediumListUniqueWhere"],	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null},ResolverInputTypes["MediumList"]],
listMediumList?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["MediumList"]],
paginateMediumList?: [{	filter?: ResolverInputTypes["MediumListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MediumListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["MediumListConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getTextItem?: [{	by: ResolverInputTypes["TextItemUniqueWhere"],	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null},ResolverInputTypes["TextItem"]],
listTextItem?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TextItem"]],
paginateTextItem?: [{	filter?: ResolverInputTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TextItemConnection"]],
getTextList?: [{	by: ResolverInputTypes["TextListUniqueWhere"],	filter?: ResolverInputTypes["TextListWhere"] | undefined | null},ResolverInputTypes["TextList"]],
listTextList?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TextList"]],
paginateTextList?: [{	filter?: ResolverInputTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TextListConnection"]],
getCarousel?: [{	by: ResolverInputTypes["CarouselUniqueWhere"],	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null},ResolverInputTypes["Carousel"]],
listCarousel?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Carousel"]],
paginateCarousel?: [{	filter?: ResolverInputTypes["CarouselWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CarouselConnection"]],
getCarouselItem?: [{	by: ResolverInputTypes["CarouselItemUniqueWhere"],	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null},ResolverInputTypes["CarouselItem"]],
listCarouselItem?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["CarouselItem"]],
paginateCarouselItem?: [{	filter?: ResolverInputTypes["CarouselItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CarouselItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["CarouselItemConnection"]],
getTheme?: [{	by: ResolverInputTypes["ThemeUniqueWhere"],	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null},ResolverInputTypes["Theme"]],
listTheme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThemeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Theme"]],
paginateTheme?: [{	filter?: ResolverInputTypes["ThemeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ThemeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ThemeConnection"]],
getBonusBanner?: [{	by: ResolverInputTypes["BonusBannerUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null},ResolverInputTypes["BonusBanner"]],
listBonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBanner"]],
paginateBonusBanner?: [{	filter?: ResolverInputTypes["BonusBannerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerConnection"]],
getBonusBannerLocale?: [{	by: ResolverInputTypes["BonusBannerLocaleUniqueWhere"],	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
listBonusBannerLocale?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["BonusBannerLocale"]],
paginateBonusBannerLocale?: [{	filter?: ResolverInputTypes["BonusBannerLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BonusBannerLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["BonusBannerLocaleConnection"]],
getTile?: [{	by: ResolverInputTypes["TileUniqueWhere"],	filter?: ResolverInputTypes["TileWhere"] | undefined | null},ResolverInputTypes["Tile"]],
listTile?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Tile"]],
paginateTile?: [{	filter?: ResolverInputTypes["TileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TileConnection"]],
getSideBySide?: [{	by: ResolverInputTypes["SideBySideUniqueWhere"],	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null},ResolverInputTypes["SideBySide"]],
listSideBySide?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SideBySide"]],
paginateSideBySide?: [{	filter?: ResolverInputTypes["SideBySideWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SideBySideConnection"]],
getSideBySideItem?: [{	by: ResolverInputTypes["SideBySideItemUniqueWhere"],	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null},ResolverInputTypes["SideBySideItem"]],
listSideBySideItem?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SideBySideItem"]],
paginateSideBySideItem?: [{	filter?: ResolverInputTypes["SideBySideItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SideBySideItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SideBySideItemConnection"]],
getHomePageLokal?: [{	by: ResolverInputTypes["HomePageLokalUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null},ResolverInputTypes["HomePageLokal"]],
listHomePageLokal?: [{	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokal"]],
paginateHomePageLokal?: [{	filter?: ResolverInputTypes["HomePageLokalWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalConnection"]],
getHomePageLokalLocale?: [{	by: ResolverInputTypes["HomePageLokalLocaleUniqueWhere"],	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
listHomePageLokalLocale?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HomePageLokalLocale"]],
paginateHomePageLokalLocale?: [{	filter?: ResolverInputTypes["HomePageLokalLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HomePageLokalLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HomePageLokalLocaleConnection"]],
getSiteSwitcher?: [{	by: ResolverInputTypes["SiteSwitcherUniqueWhere"],	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null},ResolverInputTypes["SiteSwitcher"]],
listSiteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SiteSwitcherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["SiteSwitcher"]],
paginateSiteSwitcher?: [{	filter?: ResolverInputTypes["SiteSwitcherWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SiteSwitcherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SiteSwitcherConnection"]],
getGalleryItem?: [{	by: ResolverInputTypes["GalleryItemUniqueWhere"],	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null},ResolverInputTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ResolverInputTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GalleryItemConnection"]],
getJobsForm?: [{	by: ResolverInputTypes["JobsFormUniqueWhere"],	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null},ResolverInputTypes["JobsForm"]],
listJobsForm?: [{	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsForm"]],
paginateJobsForm?: [{	filter?: ResolverInputTypes["JobsFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormConnection"]],
getJobsFormLocale?: [{	by: ResolverInputTypes["JobsFormLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFormLocale"]],
listJobsFormLocale?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFormLocale"]],
paginateJobsFormLocale?: [{	filter?: ResolverInputTypes["JobsFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFormLocaleConnection"]],
getImageTextItem?: [{	by: ResolverInputTypes["ImageTextItemUniqueWhere"],	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null},ResolverInputTypes["ImageTextItem"]],
listImageTextItem?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageTextItem"]],
paginateImageTextItem?: [{	filter?: ResolverInputTypes["ImageTextItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageTextItemConnection"]],
getImageTextList?: [{	by: ResolverInputTypes["ImageTextListUniqueWhere"],	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null},ResolverInputTypes["ImageTextList"]],
listImageTextList?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageTextList"]],
paginateImageTextList?: [{	filter?: ResolverInputTypes["ImageTextListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageTextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageTextListConnection"]],
getJobsFilter?: [{	by: ResolverInputTypes["JobsFilterUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null},ResolverInputTypes["JobsFilter"]],
listJobsFilter?: [{	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilter"]],
paginateJobsFilter?: [{	filter?: ResolverInputTypes["JobsFilterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterConnection"]],
getJobsFilterLocale?: [{	by: ResolverInputTypes["JobsFilterLocaleUniqueWhere"],	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
listJobsFilterLocale?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobsFilterLocale"]],
paginateJobsFilterLocale?: [{	filter?: ResolverInputTypes["JobsFilterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobsFilterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobsFilterLocaleConnection"]],
getFile?: [{	by: ResolverInputTypes["FileUniqueWhere"],	filter?: ResolverInputTypes["FileWhere"] | undefined | null},ResolverInputTypes["File"]],
listFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["File"]],
paginateFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FileConnection"]],
validateCreateFile?: [{	data: ResolverInputTypes["FileCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateCreateJobFormSubscription?: [{	data: ResolverInputTypes["JobFormSubscriptionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getJobFormSubscriptionStatus?: [{	by: ResolverInputTypes["JobFormSubscriptionStatusUniqueWhere"],	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null},ResolverInputTypes["JobFormSubscriptionStatus"]],
listJobFormSubscriptionStatus?: [{	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["JobFormSubscriptionStatus"]],
paginateJobFormSubscriptionStatus?: [{	filter?: ResolverInputTypes["JobFormSubscriptionStatusWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["JobFormSubscriptionStatusOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["JobFormSubscriptionStatusConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getStickyBar?: [{	by: ResolverInputTypes["StickyBarUniqueWhere"],	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null},ResolverInputTypes["StickyBar"]],
listStickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["StickyBarOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["StickyBar"]],
paginateStickyBar?: [{	filter?: ResolverInputTypes["StickyBarWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["StickyBarOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["StickyBarConnection"]],
validateCreateStickyBar?: [{	data: ResolverInputTypes["StickyBarCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getRecommendationForm?: [{	by: ResolverInputTypes["RecommendationFormUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null},ResolverInputTypes["RecommendationForm"]],
listRecommendationForm?: [{	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationForm"]],
paginateRecommendationForm?: [{	filter?: ResolverInputTypes["RecommendationFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormConnection"]],
getRecommendationFormLocale?: [{	by: ResolverInputTypes["RecommendationFormLocaleUniqueWhere"],	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
listRecommendationFormLocale?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["RecommendationFormLocale"]],
paginateRecommendationFormLocale?: [{	filter?: ResolverInputTypes["RecommendationFormLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RecommendationFormLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RecommendationFormLocaleConnection"]],
validateCreateRecommendationFormSubscription?: [{	data: ResolverInputTypes["RecommendationFormSubscriptionCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getInfoBox?: [{	by: ResolverInputTypes["InfoBoxUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null},ResolverInputTypes["InfoBox"]],
listInfoBox?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBox"]],
paginateInfoBox?: [{	filter?: ResolverInputTypes["InfoBoxWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxConnection"]],
validateCreateInfoBox?: [{	data: ResolverInputTypes["InfoBoxCreateInput"]},ResolverInputTypes["_ValidationResult"]],
getInfoBoxLocale?: [{	by: ResolverInputTypes["InfoBoxLocaleUniqueWhere"],	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
listInfoBoxLocale?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["InfoBoxLocale"]],
paginateInfoBoxLocale?: [{	filter?: ResolverInputTypes["InfoBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["InfoBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["InfoBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
generateUploadUrl?: [{	input?: ResolverInputTypes["S3GenerateSignedUploadInput"] | undefined | null},ResolverInputTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string,	expiration?: number | undefined | null},ResolverInputTypes["S3SignedRead"]],
createFile?: [{	data: ResolverInputTypes["FileCreateInput"]},ResolverInputTypes["FileCreateResult"]],
createJobFormSubscription?: [{	data: ResolverInputTypes["JobFormSubscriptionCreateInput"]},ResolverInputTypes["JobFormSubscriptionCreateResult"]],
createStickyBar?: [{	data: ResolverInputTypes["StickyBarCreateInput"]},ResolverInputTypes["StickyBarCreateResult"]],
createRecommendationFormSubscription?: [{	data: ResolverInputTypes["RecommendationFormSubscriptionCreateInput"]},ResolverInputTypes["RecommendationFormSubscriptionCreateResult"]],
createInfoBox?: [{	data: ResolverInputTypes["InfoBoxCreateInput"]},ResolverInputTypes["InfoBoxCreateResult"]],
transaction?: [{	options?: ResolverInputTypes["MutationTransactionOptions"] | undefined | null},ResolverInputTypes["MutationTransaction"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["S3SignedUpload"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ResolverInputTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
	publicUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3Header"]: AliasType<{
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined | null,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined | null,
	/** Signed URL expiration. */
	expiration?: number | undefined | null,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined | null,
	/** Can be used as a "directory". */
	prefix?: string | undefined | null,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined | null,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined | null,
	contentDisposition?: ResolverInputTypes["S33ContentDisposition"] | undefined | null,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ResolverInputTypes["S3Acl"] | undefined | null
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:ResolverInputTypes["S3Header"],
	method?:boolean | `@${string}`,
	objectKey?:boolean | `@${string}`,
	bucket?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["File"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"];
		['...on FileCreateResult']?: Omit<ResolverInputTypes["FileCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on JobFormSubscriptionCreateResult']?: Omit<ResolverInputTypes["JobFormSubscriptionCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on StickyBarCreateResult']?: Omit<ResolverInputTypes["StickyBarCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on RecommendationFormSubscriptionCreateResult']?: Omit<ResolverInputTypes["RecommendationFormSubscriptionCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on InfoBoxCreateResult']?: Omit<ResolverInputTypes["InfoBoxCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	paths?:ResolverInputTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["JobFormSubscriptionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["StickyBarCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["StickyBar"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["RecommendationFormSubscriptionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["InfoBoxCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["InfoBox"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
generateUploadUrl?: [{	input?: ResolverInputTypes["S3GenerateSignedUploadInput"] | undefined | null},ResolverInputTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey: string,	expiration?: number | undefined | null},ResolverInputTypes["S3SignedRead"]],
createFile?: [{	data: ResolverInputTypes["FileCreateInput"]},ResolverInputTypes["FileCreateResult"]],
createJobFormSubscription?: [{	data: ResolverInputTypes["JobFormSubscriptionCreateInput"]},ResolverInputTypes["JobFormSubscriptionCreateResult"]],
createStickyBar?: [{	data: ResolverInputTypes["StickyBarCreateInput"]},ResolverInputTypes["StickyBarCreateResult"]],
createRecommendationFormSubscription?: [{	data: ResolverInputTypes["RecommendationFormSubscriptionCreateInput"]},ResolverInputTypes["RecommendationFormSubscriptionCreateResult"]],
createInfoBox?: [{	data: ResolverInputTypes["InfoBoxCreateInput"]},ResolverInputTypes["InfoBoxCreateResult"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null
}
  }

export type ModelTypes = {
    ["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: ModelTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Rule"]: {
		message?: ModelTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Validator"]: {
		operation: string,
	arguments: Array<ModelTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: ModelTypes["Json"] | undefined
};
	/** Json custom scalar type */
["Json"]:any;
	["_Relation"]: {
		name: string,
	type: string,
	side: ModelTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: ModelTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<ModelTypes["_OrderBy"]> | undefined,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_RelationSide"]:_RelationSide;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_OrderBy"]: {
		path: Array<string>,
	direction: ModelTypes["_OrderByDirection"]
};
	["_OrderByDirection"]:_OrderByDirection;
	["Query"]: {
		schema?: ModelTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getContentReferenceLinkableItem?: ModelTypes["ContentReferenceLinkableItem"] | undefined,
	listContentReferenceLinkableItem: Array<ModelTypes["ContentReferenceLinkableItem"]>,
	paginateContentReferenceLinkableItem: ModelTypes["ContentReferenceLinkableItemConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterLink?: ModelTypes["FooterLink"] | undefined,
	listFooterLink: Array<ModelTypes["FooterLink"]>,
	paginateFooterLink: ModelTypes["FooterLinkConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getSeoDefault?: ModelTypes["SeoDefault"] | undefined,
	listSeoDefault: Array<ModelTypes["SeoDefault"]>,
	paginateSeoDefault: ModelTypes["SeoDefaultConnection"],
	getSeoDefaultLocale?: ModelTypes["SeoDefaultLocale"] | undefined,
	listSeoDefaultLocale: Array<ModelTypes["SeoDefaultLocale"]>,
	paginateSeoDefaultLocale: ModelTypes["SeoDefaultLocaleConnection"],
	getBusiness?: ModelTypes["Business"] | undefined,
	listBusiness: Array<ModelTypes["Business"]>,
	paginateBusiness: ModelTypes["BusinessConnection"],
	getBusinessPage?: ModelTypes["BusinessPage"] | undefined,
	listBusinessPage: Array<ModelTypes["BusinessPage"]>,
	paginateBusinessPage: ModelTypes["BusinessPageConnection"],
	getBusinessPageLocale?: ModelTypes["BusinessPageLocale"] | undefined,
	listBusinessPageLocale: Array<ModelTypes["BusinessPageLocale"]>,
	paginateBusinessPageLocale: ModelTypes["BusinessPageLocaleConnection"],
	getIcon?: ModelTypes["Icon"] | undefined,
	listIcon: Array<ModelTypes["Icon"]>,
	paginateIcon: ModelTypes["IconConnection"],
	getJobPage?: ModelTypes["JobPage"] | undefined,
	listJobPage: Array<ModelTypes["JobPage"]>,
	paginateJobPage: ModelTypes["JobPageConnection"],
	getJobPageLocale?: ModelTypes["JobPageLocale"] | undefined,
	listJobPageLocale: Array<ModelTypes["JobPageLocale"]>,
	paginateJobPageLocale: ModelTypes["JobPageLocaleConnection"],
	getKeyInfoItem?: ModelTypes["KeyInfoItem"] | undefined,
	listKeyInfoItem: Array<ModelTypes["KeyInfoItem"]>,
	paginateKeyInfoItem: ModelTypes["KeyInfoItemConnection"],
	getKeyInfoList?: ModelTypes["KeyInfoList"] | undefined,
	listKeyInfoList: Array<ModelTypes["KeyInfoList"]>,
	paginateKeyInfoList: ModelTypes["KeyInfoListConnection"],
	getContractType?: ModelTypes["ContractType"] | undefined,
	listContractType: Array<ModelTypes["ContractType"]>,
	paginateContractType: ModelTypes["ContractTypeConnection"],
	getContractTypeLocale?: ModelTypes["ContractTypeLocale"] | undefined,
	listContractTypeLocale: Array<ModelTypes["ContractTypeLocale"]>,
	paginateContractTypeLocale: ModelTypes["ContractTypeLocaleConnection"],
	getJobCity?: ModelTypes["JobCity"] | undefined,
	listJobCity: Array<ModelTypes["JobCity"]>,
	paginateJobCity: ModelTypes["JobCityConnection"],
	getJobCityLocale?: ModelTypes["JobCityLocale"] | undefined,
	listJobCityLocale: Array<ModelTypes["JobCityLocale"]>,
	paginateJobCityLocale: ModelTypes["JobCityLocaleConnection"],
	getJobPosition?: ModelTypes["JobPosition"] | undefined,
	listJobPosition: Array<ModelTypes["JobPosition"]>,
	paginateJobPosition: ModelTypes["JobPositionConnection"],
	getJobPositionLocale?: ModelTypes["JobPositionLocale"] | undefined,
	listJobPositionLocale: Array<ModelTypes["JobPositionLocale"]>,
	paginateJobPositionLocale: ModelTypes["JobPositionLocaleConnection"],
	getGallery?: ModelTypes["Gallery"] | undefined,
	listGallery: Array<ModelTypes["Gallery"]>,
	paginateGallery: ModelTypes["GalleryConnection"],
	getHomePage?: ModelTypes["HomePage"] | undefined,
	listHomePage: Array<ModelTypes["HomePage"]>,
	paginateHomePage: ModelTypes["HomePageConnection"],
	getHomePageLocale?: ModelTypes["HomePageLocale"] | undefined,
	listHomePageLocale: Array<ModelTypes["HomePageLocale"]>,
	paginateHomePageLocale: ModelTypes["HomePageLocaleConnection"],
	getMedium?: ModelTypes["Medium"] | undefined,
	listMedium: Array<ModelTypes["Medium"]>,
	paginateMedium: ModelTypes["MediumConnection"],
	getMediumItem?: ModelTypes["MediumItem"] | undefined,
	listMediumItem: Array<ModelTypes["MediumItem"]>,
	paginateMediumItem: ModelTypes["MediumItemConnection"],
	getMediumList?: ModelTypes["MediumList"] | undefined,
	listMediumList: Array<ModelTypes["MediumList"]>,
	paginateMediumList: ModelTypes["MediumListConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getTextItem?: ModelTypes["TextItem"] | undefined,
	listTextItem: Array<ModelTypes["TextItem"]>,
	paginateTextItem: ModelTypes["TextItemConnection"],
	getTextList?: ModelTypes["TextList"] | undefined,
	listTextList: Array<ModelTypes["TextList"]>,
	paginateTextList: ModelTypes["TextListConnection"],
	getCarousel?: ModelTypes["Carousel"] | undefined,
	listCarousel: Array<ModelTypes["Carousel"]>,
	paginateCarousel: ModelTypes["CarouselConnection"],
	getCarouselItem?: ModelTypes["CarouselItem"] | undefined,
	listCarouselItem: Array<ModelTypes["CarouselItem"]>,
	paginateCarouselItem: ModelTypes["CarouselItemConnection"],
	getTheme?: ModelTypes["Theme"] | undefined,
	listTheme: Array<ModelTypes["Theme"]>,
	paginateTheme: ModelTypes["ThemeConnection"],
	getBonusBanner?: ModelTypes["BonusBanner"] | undefined,
	listBonusBanner: Array<ModelTypes["BonusBanner"]>,
	paginateBonusBanner: ModelTypes["BonusBannerConnection"],
	getBonusBannerLocale?: ModelTypes["BonusBannerLocale"] | undefined,
	listBonusBannerLocale: Array<ModelTypes["BonusBannerLocale"]>,
	paginateBonusBannerLocale: ModelTypes["BonusBannerLocaleConnection"],
	getTile?: ModelTypes["Tile"] | undefined,
	listTile: Array<ModelTypes["Tile"]>,
	paginateTile: ModelTypes["TileConnection"],
	getSideBySide?: ModelTypes["SideBySide"] | undefined,
	listSideBySide: Array<ModelTypes["SideBySide"]>,
	paginateSideBySide: ModelTypes["SideBySideConnection"],
	getSideBySideItem?: ModelTypes["SideBySideItem"] | undefined,
	listSideBySideItem: Array<ModelTypes["SideBySideItem"]>,
	paginateSideBySideItem: ModelTypes["SideBySideItemConnection"],
	getHomePageLokal?: ModelTypes["HomePageLokal"] | undefined,
	listHomePageLokal: Array<ModelTypes["HomePageLokal"]>,
	paginateHomePageLokal: ModelTypes["HomePageLokalConnection"],
	getHomePageLokalLocale?: ModelTypes["HomePageLokalLocale"] | undefined,
	listHomePageLokalLocale: Array<ModelTypes["HomePageLokalLocale"]>,
	paginateHomePageLokalLocale: ModelTypes["HomePageLokalLocaleConnection"],
	getSiteSwitcher?: ModelTypes["SiteSwitcher"] | undefined,
	listSiteSwitcher: Array<ModelTypes["SiteSwitcher"]>,
	paginateSiteSwitcher: ModelTypes["SiteSwitcherConnection"],
	getGalleryItem?: ModelTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<ModelTypes["GalleryItem"]>,
	paginateGalleryItem: ModelTypes["GalleryItemConnection"],
	getJobsForm?: ModelTypes["JobsForm"] | undefined,
	listJobsForm: Array<ModelTypes["JobsForm"]>,
	paginateJobsForm: ModelTypes["JobsFormConnection"],
	getJobsFormLocale?: ModelTypes["JobsFormLocale"] | undefined,
	listJobsFormLocale: Array<ModelTypes["JobsFormLocale"]>,
	paginateJobsFormLocale: ModelTypes["JobsFormLocaleConnection"],
	getImageTextItem?: ModelTypes["ImageTextItem"] | undefined,
	listImageTextItem: Array<ModelTypes["ImageTextItem"]>,
	paginateImageTextItem: ModelTypes["ImageTextItemConnection"],
	getImageTextList?: ModelTypes["ImageTextList"] | undefined,
	listImageTextList: Array<ModelTypes["ImageTextList"]>,
	paginateImageTextList: ModelTypes["ImageTextListConnection"],
	getJobsFilter?: ModelTypes["JobsFilter"] | undefined,
	listJobsFilter: Array<ModelTypes["JobsFilter"]>,
	paginateJobsFilter: ModelTypes["JobsFilterConnection"],
	getJobsFilterLocale?: ModelTypes["JobsFilterLocale"] | undefined,
	listJobsFilterLocale: Array<ModelTypes["JobsFilterLocale"]>,
	paginateJobsFilterLocale: ModelTypes["JobsFilterLocaleConnection"],
	getFile?: ModelTypes["File"] | undefined,
	listFile: Array<ModelTypes["File"]>,
	paginateFile: ModelTypes["FileConnection"],
	validateCreateFile: ModelTypes["_ValidationResult"],
	validateCreateJobFormSubscription: ModelTypes["_ValidationResult"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getJobFormSubscriptionStatus?: ModelTypes["JobFormSubscriptionStatus"] | undefined,
	listJobFormSubscriptionStatus: Array<ModelTypes["JobFormSubscriptionStatus"]>,
	paginateJobFormSubscriptionStatus: ModelTypes["JobFormSubscriptionStatusConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getStickyBar?: ModelTypes["StickyBar"] | undefined,
	listStickyBar: Array<ModelTypes["StickyBar"]>,
	paginateStickyBar: ModelTypes["StickyBarConnection"],
	validateCreateStickyBar: ModelTypes["_ValidationResult"],
	getRecommendationForm?: ModelTypes["RecommendationForm"] | undefined,
	listRecommendationForm: Array<ModelTypes["RecommendationForm"]>,
	paginateRecommendationForm: ModelTypes["RecommendationFormConnection"],
	getRecommendationFormLocale?: ModelTypes["RecommendationFormLocale"] | undefined,
	listRecommendationFormLocale: Array<ModelTypes["RecommendationFormLocale"]>,
	paginateRecommendationFormLocale: ModelTypes["RecommendationFormLocaleConnection"],
	validateCreateRecommendationFormSubscription: ModelTypes["_ValidationResult"],
	getInfoBox?: ModelTypes["InfoBox"] | undefined,
	listInfoBox: Array<ModelTypes["InfoBox"]>,
	paginateInfoBox: ModelTypes["InfoBoxConnection"],
	validateCreateInfoBox: ModelTypes["_ValidationResult"],
	getInfoBoxLocale?: ModelTypes["InfoBoxLocale"] | undefined,
	listInfoBoxLocale: Array<ModelTypes["InfoBoxLocale"]>,
	paginateInfoBoxLocale: ModelTypes["InfoBoxLocaleConnection"],
	transaction?: ModelTypes["QueryTransaction"] | undefined,
	_info?: ModelTypes["Info"] | undefined
};
	["_Schema"]: {
		enums: Array<ModelTypes["_Enum"]>,
	entities: Array<ModelTypes["_Entity"]>
};
	["_Enum"]: {
		name: string,
	values: Array<string>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<ModelTypes["_Field"]>,
	unique: Array<ModelTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["General"]: {
		_meta?: ModelTypes["GeneralMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["GeneralLocale"]>,
	localesByLocale?: ModelTypes["GeneralLocale"] | undefined,
	localesBySeo?: ModelTypes["GeneralLocale"] | undefined,
	paginateLocales: ModelTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:any;
	["One"]:One;
	["GeneralLocale"]: {
		_meta?: ModelTypes["GeneralLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["General"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined
};
	["GeneralLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined
};
	["GeneralWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralWhere"] | undefined
};
	["UUIDCondition"]: {
	and?: Array<ModelTypes["UUIDCondition"]> | undefined,
	or?: Array<ModelTypes["UUIDCondition"]> | undefined,
	not?: ModelTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["UUID"] | undefined,
	notEq?: ModelTypes["UUID"] | undefined,
	in?: Array<ModelTypes["UUID"]> | undefined,
	notIn?: Array<ModelTypes["UUID"]> | undefined,
	lt?: ModelTypes["UUID"] | undefined,
	lte?: ModelTypes["UUID"] | undefined,
	gt?: ModelTypes["UUID"] | undefined,
	gte?: ModelTypes["UUID"] | undefined
};
	["OneCondition"]: {
	and?: Array<ModelTypes["OneCondition"]> | undefined,
	or?: Array<ModelTypes["OneCondition"]> | undefined,
	not?: ModelTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["One"] | undefined,
	notEq?: ModelTypes["One"] | undefined,
	in?: Array<ModelTypes["One"]> | undefined,
	notIn?: Array<ModelTypes["One"]> | undefined,
	lt?: ModelTypes["One"] | undefined,
	lte?: ModelTypes["One"] | undefined,
	gt?: ModelTypes["One"] | undefined,
	gte?: ModelTypes["One"] | undefined
};
	["GeneralLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["GeneralWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	and?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	footers?: ModelTypes["FooterLocaleWhere"] | undefined,
	genericPages?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleWhere"] | undefined,
	seoDefaults?: ModelTypes["SeoDefaultLocaleWhere"] | undefined,
	businessPages?: ModelTypes["BusinessPageLocaleWhere"] | undefined,
	jobPages?: ModelTypes["JobPageLocaleWhere"] | undefined,
	contractTypes?: ModelTypes["ContractTypeLocaleWhere"] | undefined,
	jobCities?: ModelTypes["JobCityLocaleWhere"] | undefined,
	jobPositions?: ModelTypes["JobPositionLocaleWhere"] | undefined,
	homePages?: ModelTypes["HomePageLocaleWhere"] | undefined,
	bonusBanners?: ModelTypes["BonusBannerLocaleWhere"] | undefined,
	homePageLokals?: ModelTypes["HomePageLokalLocaleWhere"] | undefined,
	jobsForms?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	jobsFilters?: ModelTypes["JobsFilterLocaleWhere"] | undefined,
	general?: ModelTypes["GeneralLocaleWhere"] | undefined,
	recommendationForms?: ModelTypes["RecommendationFormLocaleWhere"] | undefined,
	infoBoxes?: ModelTypes["InfoBoxLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["LocaleWhere"] | undefined
};
	["StringCondition"]: {
	and?: Array<ModelTypes["StringCondition"]> | undefined,
	or?: Array<ModelTypes["StringCondition"]> | undefined,
	not?: ModelTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["FooterLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	address?: ModelTypes["StringCondition"] | undefined,
	ico?: ModelTypes["StringCondition"] | undefined,
	dic?: ModelTypes["StringCondition"] | undefined,
	copyright?: ModelTypes["StringCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	noteSecond?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["FooterWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	linksFirstColumn?: ModelTypes["FooterLinkWhere"] | undefined,
	linksSecondColumn?: ModelTypes["FooterLinkWhere"] | undefined,
	termsAndConditions?: ModelTypes["LinkWhere"] | undefined,
	form?: ModelTypes["StringCondition"] | undefined,
	bannerTitle?: ModelTypes["StringCondition"] | undefined,
	bannerSubtitle?: ModelTypes["StringCondition"] | undefined,
	bannerImage?: ModelTypes["ImageWhere"] | undefined,
	bannerLink?: ModelTypes["LinkWhere"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	copyrightLink?: ModelTypes["LinkWhere"] | undefined,
	bannerSecondBtn?: ModelTypes["StringCondition"] | undefined,
	recommendationForm?: ModelTypes["RecommendationFormLocaleWhere"] | undefined,
	bannerSecondLink?: ModelTypes["LinkWhere"] | undefined,
	instagramLink?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterLocaleWhere"] | undefined
};
	["FooterWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["FooterLocaleWhere"] | undefined,
	and?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterWhere"] | undefined
};
	["FooterLinkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["FooterLinkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterLinkWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterLinkWhere"] | undefined
};
	["IntCondition"]: {
	and?: Array<ModelTypes["IntCondition"]> | undefined,
	or?: Array<ModelTypes["IntCondition"]> | undefined,
	not?: ModelTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["LinkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["LinkTypeCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	externalLink?: ModelTypes["StringCondition"] | undefined,
	internalLink?: ModelTypes["LinkableWhere"] | undefined,
	anchorLink?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
	and?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	or?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	not?: ModelTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["LinkType"] | undefined,
	notEq?: ModelTypes["LinkType"] | undefined,
	in?: Array<ModelTypes["LinkType"]> | undefined,
	notIn?: Array<ModelTypes["LinkType"]> | undefined,
	lt?: ModelTypes["LinkType"] | undefined,
	lte?: ModelTypes["LinkType"] | undefined,
	gt?: ModelTypes["LinkType"] | undefined,
	gte?: ModelTypes["LinkType"] | undefined
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	redirect?: ModelTypes["RedirectWhere"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	businessPage?: ModelTypes["BusinessPageLocaleWhere"] | undefined,
	jobPage?: ModelTypes["JobPageLocaleWhere"] | undefined,
	homePage?: ModelTypes["HomePageLocaleWhere"] | undefined,
	homePageLokal?: ModelTypes["HomePageLokalLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkableWhere"] | undefined
};
	["RedirectWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	target?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	not?: ModelTypes["RedirectWhere"] | undefined
};
	["GenericPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["GenericPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageLocaleWhere"] | undefined
};
	["GenericPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	and?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageWhere"] | undefined
};
	["ThemeWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	backgroundColor?: ModelTypes["StringCondition"] | undefined,
	textColor?: ModelTypes["StringCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	buttonBackgroundColor?: ModelTypes["StringCondition"] | undefined,
	buttonTextColor?: ModelTypes["StringCondition"] | undefined,
	buttonBackgroundHoverColor?: ModelTypes["StringCondition"] | undefined,
	buttonTextHoverColor?: ModelTypes["StringCondition"] | undefined,
	buttonBorderColor?: ModelTypes["StringCondition"] | undefined,
	buttonBorderHoverColor?: ModelTypes["StringCondition"] | undefined,
	headerMenuColor?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ThemeWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ThemeWhere"] | undefined> | undefined,
	not?: ModelTypes["ThemeWhere"] | undefined
};
	["ContentWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	blocks?: ModelTypes["ContentBlockWhere"] | undefined,
	and?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	json?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	references?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: ModelTypes["StringCondition"] | undefined,
	block?: ModelTypes["ContentBlockWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	linkables?: ModelTypes["ContentReferenceLinkableItemWhere"] | undefined,
	secondaryText?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	media?: ModelTypes["MediumListWhere"] | undefined,
	textList?: ModelTypes["TextListWhere"] | undefined,
	carousel?: ModelTypes["CarouselWhere"] | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocaleWhere"] | undefined,
	sideBySide?: ModelTypes["SideBySideWhere"] | undefined,
	gallery?: ModelTypes["GalleryWhere"] | undefined,
	showBoolean?: ModelTypes["BooleanCondition"] | undefined,
	isRed?: ModelTypes["BooleanCondition"] | undefined,
	video?: ModelTypes["VideoWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentReferenceType"] | undefined,
	notEq?: ModelTypes["ContentReferenceType"] | undefined,
	in?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	lt?: ModelTypes["ContentReferenceType"] | undefined,
	lte?: ModelTypes["ContentReferenceType"] | undefined,
	gt?: ModelTypes["ContentReferenceType"] | undefined,
	gte?: ModelTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]:ContentReferenceType;
	["ImageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	alt?: ModelTypes["StringCondition"] | undefined,
	fileName?: ModelTypes["StringCondition"] | undefined,
	focalPointX?: ModelTypes["FloatCondition"] | undefined,
	focalPointY?: ModelTypes["FloatCondition"] | undefined,
	and?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageWhere"] | undefined
};
	["FloatCondition"]: {
	and?: Array<ModelTypes["FloatCondition"]> | undefined,
	or?: Array<ModelTypes["FloatCondition"]> | undefined,
	not?: ModelTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ContentReferenceLinkableItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	item?: ModelTypes["LinkableWhere"] | undefined,
	reference?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceLinkableItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceLinkableItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceLinkableItemWhere"] | undefined
};
	["MediumListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["MediumItemWhere"] | undefined,
	and?: Array<ModelTypes["MediumListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["MediumListWhere"] | undefined> | undefined,
	not?: ModelTypes["MediumListWhere"] | undefined
};
	["MediumItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["MediumListWhere"] | undefined,
	medium?: ModelTypes["MediumWhere"] | undefined,
	and?: Array<ModelTypes["MediumItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["MediumItemWhere"] | undefined> | undefined,
	not?: ModelTypes["MediumItemWhere"] | undefined
};
	["MediumWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["MediumTypeCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	video?: ModelTypes["VideoWhere"] | undefined,
	and?: Array<ModelTypes["MediumWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["MediumWhere"] | undefined> | undefined,
	not?: ModelTypes["MediumWhere"] | undefined
};
	["MediumTypeCondition"]: {
	and?: Array<ModelTypes["MediumTypeCondition"]> | undefined,
	or?: Array<ModelTypes["MediumTypeCondition"]> | undefined,
	not?: ModelTypes["MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["MediumType"] | undefined,
	notEq?: ModelTypes["MediumType"] | undefined,
	in?: Array<ModelTypes["MediumType"]> | undefined,
	notIn?: Array<ModelTypes["MediumType"]> | undefined,
	lt?: ModelTypes["MediumType"] | undefined,
	lte?: ModelTypes["MediumType"] | undefined,
	gt?: ModelTypes["MediumType"] | undefined,
	gte?: ModelTypes["MediumType"] | undefined
};
	["MediumType"]:MediumType;
	["VideoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	src?: ModelTypes["StringCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	videoInfo?: ModelTypes["StringCondition"] | undefined,
	duration?: ModelTypes["FloatCondition"] | undefined,
	poster?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	not?: ModelTypes["VideoWhere"] | undefined
};
	["TextListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["TextItemWhere"] | undefined,
	and?: Array<ModelTypes["TextListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TextListWhere"] | undefined> | undefined,
	not?: ModelTypes["TextListWhere"] | undefined
};
	["TextItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["TextListWhere"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["TextItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TextItemWhere"] | undefined> | undefined,
	not?: ModelTypes["TextItemWhere"] | undefined
};
	["CarouselWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["CarouselItemWhere"] | undefined,
	background?: ModelTypes["BackgroundCondition"] | undefined,
	squareImages?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["CarouselWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CarouselWhere"] | undefined> | undefined,
	not?: ModelTypes["CarouselWhere"] | undefined
};
	["CarouselItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["CarouselWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	linkUrl?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["CarouselItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["CarouselItemWhere"] | undefined> | undefined,
	not?: ModelTypes["CarouselItemWhere"] | undefined
};
	["BackgroundCondition"]: {
	and?: Array<ModelTypes["BackgroundCondition"]> | undefined,
	or?: Array<ModelTypes["BackgroundCondition"]> | undefined,
	not?: ModelTypes["BackgroundCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["Background"] | undefined,
	notEq?: ModelTypes["Background"] | undefined,
	in?: Array<ModelTypes["Background"]> | undefined,
	notIn?: Array<ModelTypes["Background"]> | undefined,
	lt?: ModelTypes["Background"] | undefined,
	lte?: ModelTypes["Background"] | undefined,
	gt?: ModelTypes["Background"] | undefined,
	gte?: ModelTypes["Background"] | undefined
};
	["Background"]:Background;
	["BooleanCondition"]: {
	and?: Array<ModelTypes["BooleanCondition"]> | undefined,
	or?: Array<ModelTypes["BooleanCondition"]> | undefined,
	not?: ModelTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["BonusBannerLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["BonusBannerWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	tiles?: ModelTypes["TileWhere"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	bannerName?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["BonusBannerLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BonusBannerLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["BonusBannerLocaleWhere"] | undefined
};
	["BonusBannerWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["BonusBannerLocaleWhere"] | undefined,
	and?: Array<ModelTypes["BonusBannerWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BonusBannerWhere"] | undefined> | undefined,
	not?: ModelTypes["BonusBannerWhere"] | undefined
};
	["TileWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocaleWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	lightBoxTitle?: ModelTypes["StringCondition"] | undefined,
	lightBoxText?: ModelTypes["StringCondition"] | undefined,
	ligthBoxMedia?: ModelTypes["MediumListWhere"] | undefined,
	lightBoxSectionTitle?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	lightBoxLinkLabel?: ModelTypes["StringCondition"] | undefined,
	lightBoxForm?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<ModelTypes["TileWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TileWhere"] | undefined> | undefined,
	not?: ModelTypes["TileWhere"] | undefined
};
	["JobsFormLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	jobText?: ModelTypes["StringCondition"] | undefined,
	genericText?: ModelTypes["StringCondition"] | undefined,
	nameLabel?: ModelTypes["StringCondition"] | undefined,
	emailLabel?: ModelTypes["StringCondition"] | undefined,
	telefonLabel?: ModelTypes["StringCondition"] | undefined,
	fileTitleLabel?: ModelTypes["StringCondition"] | undefined,
	fileFormatLabel?: ModelTypes["StringCondition"] | undefined,
	formTextLabel?: ModelTypes["StringCondition"] | undefined,
	submitLabel?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["JobsFormWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	imageTextList?: ModelTypes["ImageTextListWhere"] | undefined,
	minimalize?: ModelTypes["StringCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	successTitle?: ModelTypes["StringCondition"] | undefined,
	successText?: ModelTypes["StringCondition"] | undefined,
	successBtnLabel?: ModelTypes["StringCondition"] | undefined,
	successImage?: ModelTypes["ImageWhere"] | undefined,
	successOtherJobsTitle?: ModelTypes["StringCondition"] | undefined,
	successOtherJobsCategoryTitle?: ModelTypes["StringCondition"] | undefined,
	successOtherJobsButtonTitle?: ModelTypes["StringCondition"] | undefined,
	successOtherJobsSelectionTitle?: ModelTypes["StringCondition"] | undefined,
	successOtherJobsSelectionYesButton?: ModelTypes["StringCondition"] | undefined,
	successOtherJobsSelectionNoButton?: ModelTypes["StringCondition"] | undefined,
	fileRemoveBtnLabel?: ModelTypes["StringCondition"] | undefined,
	gdprLabel?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["JobsFormLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobsFormLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["JobsFormLocaleWhere"] | undefined
};
	["JobsFormWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	fileIlustration?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["JobsFormWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobsFormWhere"] | undefined> | undefined,
	not?: ModelTypes["JobsFormWhere"] | undefined
};
	["ImageTextListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ImageTextItemWhere"] | undefined,
	and?: Array<ModelTypes["ImageTextListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageTextListWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageTextListWhere"] | undefined
};
	["ImageTextItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["ImageTextListWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["ImageTextItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageTextItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageTextItemWhere"] | undefined
};
	["SideBySideWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["SideBySideItemWhere"] | undefined,
	background?: ModelTypes["BackgroundCondition"] | undefined,
	alignment?: ModelTypes["ContentAlignmentCondition"] | undefined,
	mediumSize?: ModelTypes["MediumSizeCondition"] | undefined,
	and?: Array<ModelTypes["SideBySideWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SideBySideWhere"] | undefined> | undefined,
	not?: ModelTypes["SideBySideWhere"] | undefined
};
	["SideBySideItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["SideBySideWhere"] | undefined,
	media?: ModelTypes["MediumListWhere"] | undefined,
	formButtonLabel?: ModelTypes["StringCondition"] | undefined,
	type?: ModelTypes["SideBySideItemTypeCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<ModelTypes["SideBySideItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SideBySideItemWhere"] | undefined> | undefined,
	not?: ModelTypes["SideBySideItemWhere"] | undefined
};
	["SideBySideItemTypeCondition"]: {
	and?: Array<ModelTypes["SideBySideItemTypeCondition"]> | undefined,
	or?: Array<ModelTypes["SideBySideItemTypeCondition"]> | undefined,
	not?: ModelTypes["SideBySideItemTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["SideBySideItemType"] | undefined,
	notEq?: ModelTypes["SideBySideItemType"] | undefined,
	in?: Array<ModelTypes["SideBySideItemType"]> | undefined,
	notIn?: Array<ModelTypes["SideBySideItemType"]> | undefined,
	lt?: ModelTypes["SideBySideItemType"] | undefined,
	lte?: ModelTypes["SideBySideItemType"] | undefined,
	gt?: ModelTypes["SideBySideItemType"] | undefined,
	gte?: ModelTypes["SideBySideItemType"] | undefined
};
	["SideBySideItemType"]:SideBySideItemType;
	["ContentAlignmentCondition"]: {
	and?: Array<ModelTypes["ContentAlignmentCondition"]> | undefined,
	or?: Array<ModelTypes["ContentAlignmentCondition"]> | undefined,
	not?: ModelTypes["ContentAlignmentCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentAlignment"] | undefined,
	notEq?: ModelTypes["ContentAlignment"] | undefined,
	in?: Array<ModelTypes["ContentAlignment"]> | undefined,
	notIn?: Array<ModelTypes["ContentAlignment"]> | undefined,
	lt?: ModelTypes["ContentAlignment"] | undefined,
	lte?: ModelTypes["ContentAlignment"] | undefined,
	gt?: ModelTypes["ContentAlignment"] | undefined,
	gte?: ModelTypes["ContentAlignment"] | undefined
};
	["ContentAlignment"]:ContentAlignment;
	["MediumSizeCondition"]: {
	and?: Array<ModelTypes["MediumSizeCondition"]> | undefined,
	or?: Array<ModelTypes["MediumSizeCondition"]> | undefined,
	not?: ModelTypes["MediumSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["MediumSize"] | undefined,
	notEq?: ModelTypes["MediumSize"] | undefined,
	in?: Array<ModelTypes["MediumSize"]> | undefined,
	notIn?: Array<ModelTypes["MediumSize"]> | undefined,
	lt?: ModelTypes["MediumSize"] | undefined,
	lte?: ModelTypes["MediumSize"] | undefined,
	gt?: ModelTypes["MediumSize"] | undefined,
	gte?: ModelTypes["MediumSize"] | undefined
};
	["MediumSize"]:MediumSize;
	["GalleryWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["GalleryItemWhere"] | undefined,
	and?: Array<ModelTypes["GalleryWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GalleryWhere"] | undefined> | undefined,
	not?: ModelTypes["GalleryWhere"] | undefined
};
	["GalleryItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["GalleryWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["GalleryItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GalleryItemWhere"] | undefined> | undefined,
	not?: ModelTypes["GalleryItemWhere"] | undefined
};
	["SeoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	ogTitle?: ModelTypes["StringCondition"] | undefined,
	ogDescription?: ModelTypes["StringCondition"] | undefined,
	noIndex?: ModelTypes["BooleanCondition"] | undefined,
	noFollow?: ModelTypes["BooleanCondition"] | undefined,
	ogImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	not?: ModelTypes["SeoWhere"] | undefined
};
	["BusinessPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["BusinessPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	subtitle?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	stickyBar?: ModelTypes["StickyBarWhere"] | undefined,
	infoBox?: ModelTypes["InfoBoxWhere"] | undefined,
	and?: Array<ModelTypes["BusinessPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessPageLocaleWhere"] | undefined
};
	["BusinessPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["BusinessPageLocaleWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	isLokal?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["BusinessPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessPageWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessPageWhere"] | undefined
};
	["StickyBarWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	formButtonLabel?: ModelTypes["StringCondition"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<ModelTypes["StickyBarWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["StickyBarWhere"] | undefined> | undefined,
	not?: ModelTypes["StickyBarWhere"] | undefined
};
	["InfoBoxWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	logoSize?: ModelTypes["LogoSizeCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	locales?: ModelTypes["InfoBoxLocaleWhere"] | undefined,
	and?: Array<ModelTypes["InfoBoxWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["InfoBoxWhere"] | undefined> | undefined,
	not?: ModelTypes["InfoBoxWhere"] | undefined
};
	["LogoSizeCondition"]: {
	and?: Array<ModelTypes["LogoSizeCondition"]> | undefined,
	or?: Array<ModelTypes["LogoSizeCondition"]> | undefined,
	not?: ModelTypes["LogoSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["LogoSize"] | undefined,
	notEq?: ModelTypes["LogoSize"] | undefined,
	in?: Array<ModelTypes["LogoSize"]> | undefined,
	notIn?: Array<ModelTypes["LogoSize"]> | undefined,
	lt?: ModelTypes["LogoSize"] | undefined,
	lte?: ModelTypes["LogoSize"] | undefined,
	gt?: ModelTypes["LogoSize"] | undefined,
	gte?: ModelTypes["LogoSize"] | undefined
};
	["LogoSize"]:LogoSize;
	["InfoBoxLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	address?: ModelTypes["StringCondition"] | undefined,
	web?: ModelTypes["StringCondition"] | undefined,
	instagram?: ModelTypes["StringCondition"] | undefined,
	facebook?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["InfoBoxWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	openingHours?: ModelTypes["TextListWhere"] | undefined,
	addressLink?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["InfoBoxLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["InfoBoxLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["InfoBoxLocaleWhere"] | undefined
};
	["JobPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["JobPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	keyInfo?: ModelTypes["KeyInfoListWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	stickyBar?: ModelTypes["StickyBarWhere"] | undefined,
	mobileImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["JobPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["JobPageLocaleWhere"] | undefined
};
	["JobPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["JobPageLocaleWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	contractType?: ModelTypes["ContractTypeWhere"] | undefined,
	jobCity?: ModelTypes["JobCityWhere"] | undefined,
	business?: ModelTypes["BusinessPageWhere"] | undefined,
	isActive?: ModelTypes["BooleanCondition"] | undefined,
	jobPosition?: ModelTypes["JobPositionWhere"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	isSyncedToTabidoo?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["JobPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobPageWhere"] | undefined> | undefined,
	not?: ModelTypes["JobPageWhere"] | undefined
};
	["ContractTypeWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["ContractTypeLocaleWhere"] | undefined,
	and?: Array<ModelTypes["ContractTypeWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContractTypeWhere"] | undefined> | undefined,
	not?: ModelTypes["ContractTypeWhere"] | undefined
};
	["ContractTypeLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["ContractTypeWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ContractTypeLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContractTypeLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["ContractTypeLocaleWhere"] | undefined
};
	["JobCityWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["JobCityLocaleWhere"] | undefined,
	and?: Array<ModelTypes["JobCityWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobCityWhere"] | undefined> | undefined,
	not?: ModelTypes["JobCityWhere"] | undefined
};
	["JobCityLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["JobCityWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["JobCityLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobCityLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["JobCityLocaleWhere"] | undefined
};
	["JobPositionWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["JobPositionLocaleWhere"] | undefined,
	jobs?: ModelTypes["JobPageWhere"] | undefined,
	and?: Array<ModelTypes["JobPositionWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobPositionWhere"] | undefined> | undefined,
	not?: ModelTypes["JobPositionWhere"] | undefined
};
	["JobPositionLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["JobPositionWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["JobPositionLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobPositionLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["JobPositionLocaleWhere"] | undefined
};
	["DateTimeCondition"]: {
	and?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	or?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	not?: ModelTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["DateTime"] | undefined,
	notEq?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined
};
	["DateTime"]:any;
	["KeyInfoListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["KeyInfoItemWhere"] | undefined,
	and?: Array<ModelTypes["KeyInfoListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["KeyInfoListWhere"] | undefined> | undefined,
	not?: ModelTypes["KeyInfoListWhere"] | undefined
};
	["KeyInfoItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	keyInfo?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["KeyInfoListWhere"] | undefined,
	icon?: ModelTypes["IconWhere"] | undefined,
	and?: Array<ModelTypes["KeyInfoItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["KeyInfoItemWhere"] | undefined> | undefined,
	not?: ModelTypes["KeyInfoItemWhere"] | undefined
};
	["IconWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	icon?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["IconWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["IconWhere"] | undefined> | undefined,
	not?: ModelTypes["IconWhere"] | undefined
};
	["HomePageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["HomePageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	button?: ModelTypes["LinkWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherWhere"] | undefined,
	gallery?: ModelTypes["GalleryWhere"] | undefined,
	stickyBar?: ModelTypes["StickyBarWhere"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	ilustration?: ModelTypes["GalleryWhere"] | undefined,
	and?: Array<ModelTypes["HomePageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HomePageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["HomePageLocaleWhere"] | undefined
};
	["HomePageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["HomePageLocaleWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	and?: Array<ModelTypes["HomePageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HomePageWhere"] | undefined> | undefined,
	not?: ModelTypes["HomePageWhere"] | undefined
};
	["SiteSwitcherWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	and?: Array<ModelTypes["SiteSwitcherWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SiteSwitcherWhere"] | undefined> | undefined,
	not?: ModelTypes["SiteSwitcherWhere"] | undefined
};
	["HomePageLokalLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["HomePageLokalWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	button?: ModelTypes["LinkWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherWhere"] | undefined,
	gallery?: ModelTypes["GalleryWhere"] | undefined,
	stickyBar?: ModelTypes["StickyBarWhere"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	ilustration?: ModelTypes["GalleryWhere"] | undefined,
	and?: Array<ModelTypes["HomePageLokalLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HomePageLokalLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["HomePageLokalLocaleWhere"] | undefined
};
	["HomePageLokalWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["HomePageLokalLocaleWhere"] | undefined,
	theme?: ModelTypes["ThemeWhere"] | undefined,
	and?: Array<ModelTypes["HomePageLokalWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HomePageLokalWhere"] | undefined> | undefined,
	not?: ModelTypes["HomePageLokalWhere"] | undefined
};
	["RecommendationFormLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	subtitle?: ModelTypes["StringCondition"] | undefined,
	yourEmailLabel?: ModelTypes["StringCondition"] | undefined,
	theirEmailLabel?: ModelTypes["StringCondition"] | undefined,
	yourName?: ModelTypes["StringCondition"] | undefined,
	sendBtnLabel?: ModelTypes["StringCondition"] | undefined,
	successTitle?: ModelTypes["StringCondition"] | undefined,
	successSubtitle?: ModelTypes["StringCondition"] | undefined,
	successButtonClose?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["RecommendationFormWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["RecommendationFormLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RecommendationFormLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["RecommendationFormLocaleWhere"] | undefined
};
	["RecommendationFormWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["RecommendationFormLocaleWhere"] | undefined,
	successImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["RecommendationFormWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RecommendationFormWhere"] | undefined> | undefined,
	not?: ModelTypes["RecommendationFormWhere"] | undefined
};
	["HeaderLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["HeaderWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	menuLabel?: ModelTypes["StringCondition"] | undefined,
	menuTitle?: ModelTypes["StringCondition"] | undefined,
	menuText?: ModelTypes["StringCondition"] | undefined,
	business?: ModelTypes["BusinessWhere"] | undefined,
	lokalEnterprise?: ModelTypes["BusinessWhere"] | undefined,
	businessSectionTitle?: ModelTypes["StringCondition"] | undefined,
	lokalSectionTitle?: ModelTypes["StringCondition"] | undefined,
	businessLink?: ModelTypes["LinkWhere"] | undefined,
	lokalLink?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["HeaderLocaleWhere"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	logoMenu?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderWhere"] | undefined
};
	["BusinessWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["BusinessWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["BusinessWhere"] | undefined> | undefined,
	not?: ModelTypes["BusinessWhere"] | undefined
};
	["SeoDefaultLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["SeoDefaultWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	and?: Array<ModelTypes["SeoDefaultLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SeoDefaultLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["SeoDefaultLocaleWhere"] | undefined
};
	["SeoDefaultWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["SeoDefaultLocaleWhere"] | undefined,
	and?: Array<ModelTypes["SeoDefaultWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SeoDefaultWhere"] | undefined> | undefined,
	not?: ModelTypes["SeoDefaultWhere"] | undefined
};
	["JobsFilterLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	jobPositionLabel?: ModelTypes["StringCondition"] | undefined,
	jobContractLabel?: ModelTypes["StringCondition"] | undefined,
	jobCityLabel?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["JobsFilterWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	bannerTitle?: ModelTypes["StringCondition"] | undefined,
	bannerFormLabel?: ModelTypes["StringCondition"] | undefined,
	bannerJobsForm?: ModelTypes["JobsFormLocaleWhere"] | undefined,
	bannerImage?: ModelTypes["ImageWhere"] | undefined,
	noJobTitle?: ModelTypes["StringCondition"] | undefined,
	noJobBtnLabel?: ModelTypes["StringCondition"] | undefined,
	noJobImage?: ModelTypes["ImageWhere"] | undefined,
	noJobTitleForSpecificBusiness?: ModelTypes["StringCondition"] | undefined,
	deactivatedJob?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["JobsFilterLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobsFilterLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["JobsFilterLocaleWhere"] | undefined
};
	["JobsFilterWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["JobsFilterLocaleWhere"] | undefined,
	and?: Array<ModelTypes["JobsFilterWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobsFilterWhere"] | undefined> | undefined,
	not?: ModelTypes["JobsFilterWhere"] | undefined
};
	["Locale"]: {
		_meta?: ModelTypes["LocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	code: string,
	label?: string | undefined,
	footers: Array<ModelTypes["FooterLocale"]>,
	genericPages: Array<ModelTypes["GenericPageLocale"]>,
	headers: Array<ModelTypes["HeaderLocale"]>,
	seoDefaults: Array<ModelTypes["SeoDefaultLocale"]>,
	businessPages: Array<ModelTypes["BusinessPageLocale"]>,
	jobPages: Array<ModelTypes["JobPageLocale"]>,
	contractTypes: Array<ModelTypes["ContractTypeLocale"]>,
	jobCities: Array<ModelTypes["JobCityLocale"]>,
	jobPositions: Array<ModelTypes["JobPositionLocale"]>,
	homePages: Array<ModelTypes["HomePageLocale"]>,
	bonusBanners: Array<ModelTypes["BonusBannerLocale"]>,
	homePageLokals: Array<ModelTypes["HomePageLokalLocale"]>,
	jobsForms: Array<ModelTypes["JobsFormLocale"]>,
	jobsFilters: Array<ModelTypes["JobsFilterLocale"]>,
	general: Array<ModelTypes["GeneralLocale"]>,
	recommendationForms: Array<ModelTypes["RecommendationFormLocale"]>,
	infoBoxes: Array<ModelTypes["InfoBoxLocale"]>,
	footersByRoot?: ModelTypes["FooterLocale"] | undefined,
	footersByTermsAndConditions?: ModelTypes["FooterLocale"] | undefined,
	footersByBannerImage?: ModelTypes["FooterLocale"] | undefined,
	footersByBannerLink?: ModelTypes["FooterLocale"] | undefined,
	footersByJobsForm?: ModelTypes["FooterLocale"] | undefined,
	footersByCopyrightLink?: ModelTypes["FooterLocale"] | undefined,
	footersByRecommendationForm?: ModelTypes["FooterLocale"] | undefined,
	footersByBannerSecondLink?: ModelTypes["FooterLocale"] | undefined,
	footersByInstagramLink?: ModelTypes["FooterLocale"] | undefined,
	genericPagesByRoot?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByLogo?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByImage?: ModelTypes["GenericPageLocale"] | undefined,
	headersByRoot?: ModelTypes["HeaderLocale"] | undefined,
	headersByBusinessLink?: ModelTypes["HeaderLocale"] | undefined,
	headersByLokalLink?: ModelTypes["HeaderLocale"] | undefined,
	seoDefaultsByRoot?: ModelTypes["SeoDefaultLocale"] | undefined,
	seoDefaultsBySeo?: ModelTypes["SeoDefaultLocale"] | undefined,
	businessPagesByRoot?: ModelTypes["BusinessPageLocale"] | undefined,
	businessPagesByContent?: ModelTypes["BusinessPageLocale"] | undefined,
	businessPagesBySeo?: ModelTypes["BusinessPageLocale"] | undefined,
	businessPagesByLink?: ModelTypes["BusinessPageLocale"] | undefined,
	businessPagesByImage?: ModelTypes["BusinessPageLocale"] | undefined,
	jobPagesByRoot?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesByImage?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesByKeyInfo?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesByContent?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesBySeo?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesByLink?: ModelTypes["JobPageLocale"] | undefined,
	jobPagesByMobileImage?: ModelTypes["JobPageLocale"] | undefined,
	contractTypesByRoot?: ModelTypes["ContractTypeLocale"] | undefined,
	contractTypesBySlug?: ModelTypes["ContractTypeLocale"] | undefined,
	jobCitiesByRoot?: ModelTypes["JobCityLocale"] | undefined,
	jobCitiesBySlug?: ModelTypes["JobCityLocale"] | undefined,
	jobPositionsByRoot?: ModelTypes["JobPositionLocale"] | undefined,
	jobPositionsBySlug?: ModelTypes["JobPositionLocale"] | undefined,
	homePagesByRoot?: ModelTypes["HomePageLocale"] | undefined,
	homePagesByButton?: ModelTypes["HomePageLocale"] | undefined,
	homePagesByContent?: ModelTypes["HomePageLocale"] | undefined,
	homePagesBySeo?: ModelTypes["HomePageLocale"] | undefined,
	homePagesByLink?: ModelTypes["HomePageLocale"] | undefined,
	homePagesByLogo?: ModelTypes["HomePageLocale"] | undefined,
	homePagesBySiteSwitcher?: ModelTypes["HomePageLocale"] | undefined,
	homePagesByIlustration?: ModelTypes["HomePageLocale"] | undefined,
	bonusBannersByRoot?: ModelTypes["BonusBannerLocale"] | undefined,
	bonusBannersByTiles?: ModelTypes["BonusBannerLocale"] | undefined,
	bonusBannersByLogo?: ModelTypes["BonusBannerLocale"] | undefined,
	homePageLokalsByRoot?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByLogo?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByButton?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByContent?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsBySeo?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByLink?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsBySiteSwitcher?: ModelTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByIlustration?: ModelTypes["HomePageLokalLocale"] | undefined,
	jobsFormsByRoot?: ModelTypes["JobsFormLocale"] | undefined,
	jobsFormsByImageTextList?: ModelTypes["JobsFormLocale"] | undefined,
	jobsFormsByLogo?: ModelTypes["JobsFormLocale"] | undefined,
	jobsFormsBySuccessImage?: ModelTypes["JobsFormLocale"] | undefined,
	jobsFiltersByRoot?: ModelTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByBannerJobsForm?: ModelTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByBannerImage?: ModelTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByNoJobImage?: ModelTypes["JobsFilterLocale"] | undefined,
	generalByRoot?: ModelTypes["GeneralLocale"] | undefined,
	generalBySeo?: ModelTypes["GeneralLocale"] | undefined,
	recommendationFormsByRoot?: ModelTypes["RecommendationFormLocale"] | undefined,
	infoBoxesByRoot?: ModelTypes["InfoBoxLocale"] | undefined,
	paginateFooters: ModelTypes["FooterLocaleConnection"],
	paginateGenericPages: ModelTypes["GenericPageLocaleConnection"],
	paginateHeaders: ModelTypes["HeaderLocaleConnection"],
	paginateSeoDefaults: ModelTypes["SeoDefaultLocaleConnection"],
	paginateBusinessPages: ModelTypes["BusinessPageLocaleConnection"],
	paginateJobPages: ModelTypes["JobPageLocaleConnection"],
	paginateContractTypes: ModelTypes["ContractTypeLocaleConnection"],
	paginateJobCities: ModelTypes["JobCityLocaleConnection"],
	paginateJobPositions: ModelTypes["JobPositionLocaleConnection"],
	paginateHomePages: ModelTypes["HomePageLocaleConnection"],
	paginateBonusBanners: ModelTypes["BonusBannerLocaleConnection"],
	paginateHomePageLokals: ModelTypes["HomePageLokalLocaleConnection"],
	paginateJobsForms: ModelTypes["JobsFormLocaleConnection"],
	paginateJobsFilters: ModelTypes["JobsFilterLocaleConnection"],
	paginateGeneral: ModelTypes["GeneralLocaleConnection"],
	paginateRecommendationForms: ModelTypes["RecommendationFormLocaleConnection"],
	paginateInfoBoxes: ModelTypes["InfoBoxLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	footers?: ModelTypes["FieldMeta"] | undefined,
	genericPages?: ModelTypes["FieldMeta"] | undefined,
	headers?: ModelTypes["FieldMeta"] | undefined,
	seoDefaults?: ModelTypes["FieldMeta"] | undefined,
	businessPages?: ModelTypes["FieldMeta"] | undefined,
	jobPages?: ModelTypes["FieldMeta"] | undefined,
	contractTypes?: ModelTypes["FieldMeta"] | undefined,
	jobCities?: ModelTypes["FieldMeta"] | undefined,
	jobPositions?: ModelTypes["FieldMeta"] | undefined,
	homePages?: ModelTypes["FieldMeta"] | undefined,
	bonusBanners?: ModelTypes["FieldMeta"] | undefined,
	homePageLokals?: ModelTypes["FieldMeta"] | undefined,
	jobsForms?: ModelTypes["FieldMeta"] | undefined,
	jobsFilters?: ModelTypes["FieldMeta"] | undefined,
	general?: ModelTypes["FieldMeta"] | undefined,
	recommendationForms?: ModelTypes["FieldMeta"] | undefined,
	infoBoxes?: ModelTypes["FieldMeta"] | undefined
};
	["FooterLocale"]: {
		_meta?: ModelTypes["FooterLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	email?: string | undefined,
	address?: string | undefined,
	ico?: string | undefined,
	dic?: string | undefined,
	copyright?: string | undefined,
	note?: string | undefined,
	noteSecond?: string | undefined,
	root?: ModelTypes["Footer"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	linksFirstColumn: Array<ModelTypes["FooterLink"]>,
	linksSecondColumn: Array<ModelTypes["FooterLink"]>,
	termsAndConditions?: ModelTypes["Link"] | undefined,
	form?: string | undefined,
	bannerTitle?: string | undefined,
	bannerSubtitle?: string | undefined,
	bannerImage?: ModelTypes["Image"] | undefined,
	bannerLink?: ModelTypes["Link"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocale"] | undefined,
	copyrightLink?: ModelTypes["Link"] | undefined,
	bannerSecondBtn?: string | undefined,
	recommendationForm?: ModelTypes["RecommendationFormLocale"] | undefined,
	bannerSecondLink?: ModelTypes["Link"] | undefined,
	instagramLink?: ModelTypes["Link"] | undefined,
	paginateLinksFirstColumn: ModelTypes["FooterLinkConnection"],
	paginateLinksSecondColumn: ModelTypes["FooterLinkConnection"]
};
	["FooterLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	address?: ModelTypes["FieldMeta"] | undefined,
	ico?: ModelTypes["FieldMeta"] | undefined,
	dic?: ModelTypes["FieldMeta"] | undefined,
	copyright?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	noteSecond?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	linksFirstColumn?: ModelTypes["FieldMeta"] | undefined,
	linksSecondColumn?: ModelTypes["FieldMeta"] | undefined,
	termsAndConditions?: ModelTypes["FieldMeta"] | undefined,
	form?: ModelTypes["FieldMeta"] | undefined,
	bannerTitle?: ModelTypes["FieldMeta"] | undefined,
	bannerSubtitle?: ModelTypes["FieldMeta"] | undefined,
	bannerImage?: ModelTypes["FieldMeta"] | undefined,
	bannerLink?: ModelTypes["FieldMeta"] | undefined,
	jobsForm?: ModelTypes["FieldMeta"] | undefined,
	copyrightLink?: ModelTypes["FieldMeta"] | undefined,
	bannerSecondBtn?: ModelTypes["FieldMeta"] | undefined,
	recommendationForm?: ModelTypes["FieldMeta"] | undefined,
	bannerSecondLink?: ModelTypes["FieldMeta"] | undefined,
	instagramLink?: ModelTypes["FieldMeta"] | undefined
};
	["Footer"]: {
		_meta?: ModelTypes["FooterMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["FooterLocale"]>,
	localesByLocale?: ModelTypes["FooterLocale"] | undefined,
	localesByTermsAndConditions?: ModelTypes["FooterLocale"] | undefined,
	localesByBannerImage?: ModelTypes["FooterLocale"] | undefined,
	localesByBannerLink?: ModelTypes["FooterLocale"] | undefined,
	localesByJobsForm?: ModelTypes["FooterLocale"] | undefined,
	localesByCopyrightLink?: ModelTypes["FooterLocale"] | undefined,
	localesByRecommendationForm?: ModelTypes["FooterLocale"] | undefined,
	localesByBannerSecondLink?: ModelTypes["FooterLocale"] | undefined,
	localesByInstagramLink?: ModelTypes["FooterLocale"] | undefined,
	paginateLocales: ModelTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	address?: ModelTypes["OrderDirection"] | undefined,
	ico?: ModelTypes["OrderDirection"] | undefined,
	dic?: ModelTypes["OrderDirection"] | undefined,
	copyright?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	noteSecond?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["FooterOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	termsAndConditions?: ModelTypes["LinkOrderBy"] | undefined,
	form?: ModelTypes["OrderDirection"] | undefined,
	bannerTitle?: ModelTypes["OrderDirection"] | undefined,
	bannerSubtitle?: ModelTypes["OrderDirection"] | undefined,
	bannerImage?: ModelTypes["ImageOrderBy"] | undefined,
	bannerLink?: ModelTypes["LinkOrderBy"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleOrderBy"] | undefined,
	copyrightLink?: ModelTypes["LinkOrderBy"] | undefined,
	bannerSecondBtn?: ModelTypes["OrderDirection"] | undefined,
	recommendationForm?: ModelTypes["RecommendationFormLocaleOrderBy"] | undefined,
	bannerSecondLink?: ModelTypes["LinkOrderBy"] | undefined,
	instagramLink?: ModelTypes["LinkOrderBy"] | undefined
};
	["OrderDirection"]:OrderDirection;
	["FooterOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	externalLink?: ModelTypes["OrderDirection"] | undefined,
	internalLink?: ModelTypes["LinkableOrderBy"] | undefined,
	anchorLink?: ModelTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	redirect?: ModelTypes["RedirectOrderBy"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleOrderBy"] | undefined,
	businessPage?: ModelTypes["BusinessPageLocaleOrderBy"] | undefined,
	jobPage?: ModelTypes["JobPageLocaleOrderBy"] | undefined,
	homePage?: ModelTypes["HomePageLocaleOrderBy"] | undefined,
	homePageLokal?: ModelTypes["HomePageLokalLocaleOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	target?: ModelTypes["LinkOrderBy"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GenericPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined
};
	["ThemeOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	backgroundColor?: ModelTypes["OrderDirection"] | undefined,
	textColor?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	buttonBackgroundColor?: ModelTypes["OrderDirection"] | undefined,
	buttonTextColor?: ModelTypes["OrderDirection"] | undefined,
	buttonBackgroundHoverColor?: ModelTypes["OrderDirection"] | undefined,
	buttonTextHoverColor?: ModelTypes["OrderDirection"] | undefined,
	buttonBorderColor?: ModelTypes["OrderDirection"] | undefined,
	buttonBorderHoverColor?: ModelTypes["OrderDirection"] | undefined,
	headerMenuColor?: ModelTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	ogTitle?: ModelTypes["OrderDirection"] | undefined,
	ogDescription?: ModelTypes["OrderDirection"] | undefined,
	noIndex?: ModelTypes["OrderDirection"] | undefined,
	noFollow?: ModelTypes["OrderDirection"] | undefined,
	ogImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	alt?: ModelTypes["OrderDirection"] | undefined,
	fileName?: ModelTypes["OrderDirection"] | undefined,
	focalPointX?: ModelTypes["OrderDirection"] | undefined,
	focalPointY?: ModelTypes["OrderDirection"] | undefined
};
	["BusinessPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["BusinessPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	subtitle?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	stickyBar?: ModelTypes["StickyBarOrderBy"] | undefined,
	infoBox?: ModelTypes["InfoBoxOrderBy"] | undefined
};
	["BusinessPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined,
	isLokal?: ModelTypes["OrderDirection"] | undefined
};
	["StickyBarOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	formButtonLabel?: ModelTypes["OrderDirection"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleOrderBy"] | undefined
};
	["JobsFormLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	jobText?: ModelTypes["OrderDirection"] | undefined,
	genericText?: ModelTypes["OrderDirection"] | undefined,
	nameLabel?: ModelTypes["OrderDirection"] | undefined,
	emailLabel?: ModelTypes["OrderDirection"] | undefined,
	telefonLabel?: ModelTypes["OrderDirection"] | undefined,
	fileTitleLabel?: ModelTypes["OrderDirection"] | undefined,
	fileFormatLabel?: ModelTypes["OrderDirection"] | undefined,
	formTextLabel?: ModelTypes["OrderDirection"] | undefined,
	submitLabel?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["JobsFormOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	imageTextList?: ModelTypes["ImageTextListOrderBy"] | undefined,
	minimalize?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	successTitle?: ModelTypes["OrderDirection"] | undefined,
	successText?: ModelTypes["OrderDirection"] | undefined,
	successBtnLabel?: ModelTypes["OrderDirection"] | undefined,
	successImage?: ModelTypes["ImageOrderBy"] | undefined,
	successOtherJobsTitle?: ModelTypes["OrderDirection"] | undefined,
	successOtherJobsCategoryTitle?: ModelTypes["OrderDirection"] | undefined,
	successOtherJobsButtonTitle?: ModelTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionTitle?: ModelTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionYesButton?: ModelTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionNoButton?: ModelTypes["OrderDirection"] | undefined,
	fileRemoveBtnLabel?: ModelTypes["OrderDirection"] | undefined,
	gdprLabel?: ModelTypes["OrderDirection"] | undefined
};
	["JobsFormOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	fileIlustration?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageTextListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["InfoBoxOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	logoSize?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined
};
	["JobPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["JobPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	keyInfo?: ModelTypes["KeyInfoListOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	stickyBar?: ModelTypes["StickyBarOrderBy"] | undefined,
	mobileImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["JobPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined,
	contractType?: ModelTypes["ContractTypeOrderBy"] | undefined,
	jobCity?: ModelTypes["JobCityOrderBy"] | undefined,
	business?: ModelTypes["BusinessPageOrderBy"] | undefined,
	isActive?: ModelTypes["OrderDirection"] | undefined,
	jobPosition?: ModelTypes["JobPositionOrderBy"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	isSyncedToTabidoo?: ModelTypes["OrderDirection"] | undefined
};
	["ContractTypeOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["JobCityOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["JobPositionOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["KeyInfoListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["HomePageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["HomePageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	button?: ModelTypes["LinkOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherOrderBy"] | undefined,
	gallery?: ModelTypes["GalleryOrderBy"] | undefined,
	stickyBar?: ModelTypes["StickyBarOrderBy"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	ilustration?: ModelTypes["GalleryOrderBy"] | undefined
};
	["HomePageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined
};
	["SiteSwitcherOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["HomePageLokalLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["HomePageLokalOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	button?: ModelTypes["LinkOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherOrderBy"] | undefined,
	gallery?: ModelTypes["GalleryOrderBy"] | undefined,
	stickyBar?: ModelTypes["StickyBarOrderBy"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	ilustration?: ModelTypes["GalleryOrderBy"] | undefined
};
	["HomePageLokalOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	theme?: ModelTypes["ThemeOrderBy"] | undefined
};
	["RecommendationFormLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	subtitle?: ModelTypes["OrderDirection"] | undefined,
	yourEmailLabel?: ModelTypes["OrderDirection"] | undefined,
	theirEmailLabel?: ModelTypes["OrderDirection"] | undefined,
	yourName?: ModelTypes["OrderDirection"] | undefined,
	sendBtnLabel?: ModelTypes["OrderDirection"] | undefined,
	successTitle?: ModelTypes["OrderDirection"] | undefined,
	successSubtitle?: ModelTypes["OrderDirection"] | undefined,
	successButtonClose?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["RecommendationFormOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["RecommendationFormOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	successImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined,
	footers?: ModelTypes["FooterLocaleUniqueWhere"] | undefined,
	genericPages?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined,
	seoDefaults?: ModelTypes["SeoDefaultLocaleUniqueWhere"] | undefined,
	businessPages?: ModelTypes["BusinessPageLocaleUniqueWhere"] | undefined,
	jobPages?: ModelTypes["JobPageLocaleUniqueWhere"] | undefined,
	contractTypes?: ModelTypes["ContractTypeLocaleUniqueWhere"] | undefined,
	jobCities?: ModelTypes["JobCityLocaleUniqueWhere"] | undefined,
	jobPositions?: ModelTypes["JobPositionLocaleUniqueWhere"] | undefined,
	homePages?: ModelTypes["HomePageLocaleUniqueWhere"] | undefined,
	bonusBanners?: ModelTypes["BonusBannerLocaleUniqueWhere"] | undefined,
	homePageLokals?: ModelTypes["HomePageLokalLocaleUniqueWhere"] | undefined,
	jobsForms?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined,
	jobsFilters?: ModelTypes["JobsFilterLocaleUniqueWhere"] | undefined,
	general?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined,
	recommendationForms?: ModelTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	infoBoxes?: ModelTypes["InfoBoxLocaleUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["FooterUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	termsAndConditions?: ModelTypes["LinkUniqueWhere"] | undefined,
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined,
	bannerLink?: ModelTypes["LinkUniqueWhere"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined,
	copyrightLink?: ModelTypes["LinkUniqueWhere"] | undefined,
	recommendationForm?: ModelTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	bannerSecondLink?: ModelTypes["LinkUniqueWhere"] | undefined,
	instagramLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["FooterLocaleUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ImageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["JobsFormLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["JobsFormUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	imageTextList?: ModelTypes["ImageTextListUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	successImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined,
	fileIlustration?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageTextListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ImageTextItemUniqueWhere"] | undefined
};
	["ImageTextItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["RecommendationFormLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["RecommendationFormUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["RecommendationFormUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	successImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["ContentUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	blocks?: ModelTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	linkables?: ModelTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined,
	media?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["ContentReferenceLinkableItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	item?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	url?: string | undefined,
	redirect?: ModelTypes["RedirectUniqueWhere"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	businessPage?: ModelTypes["BusinessPageLocaleUniqueWhere"] | undefined,
	jobPage?: ModelTypes["JobPageLocaleUniqueWhere"] | undefined,
	homePage?: ModelTypes["HomePageLocaleUniqueWhere"] | undefined,
	homePageLokal?: ModelTypes["HomePageLokalLocaleUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	target?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["BusinessPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["BusinessPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["BusinessPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["BusinessPageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	ogImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["JobPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined,
	keyInfo?: ModelTypes["KeyInfoListUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	mobileImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["KeyInfoListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["KeyInfoItemUniqueWhere"] | undefined
};
	["KeyInfoItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["HomePageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["HomePageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	button?: ModelTypes["LinkUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined,
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["HomePageLocaleUniqueWhere"] | undefined,
	theme?: ModelTypes["ThemeUniqueWhere"] | undefined
};
	["ThemeUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["SiteSwitcherUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined,
	theme?: ModelTypes["ThemeUniqueWhere"] | undefined
};
	["GalleryUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["GalleryItemUniqueWhere"] | undefined
};
	["GalleryItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLokalLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["HomePageLokalUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	button?: ModelTypes["LinkUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined,
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLokalUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["HomePageLokalLocaleUniqueWhere"] | undefined,
	theme?: ModelTypes["ThemeUniqueWhere"] | undefined
};
	["MediumListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["MediumItemUniqueWhere"] | undefined
};
	["MediumItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["HeaderUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	businessLink?: ModelTypes["LinkUniqueWhere"] | undefined,
	lokalLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	logoMenu?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["SeoDefaultLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["SeoDefaultUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["SeoDefaultUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["SeoDefaultLocaleUniqueWhere"] | undefined
};
	["ContractTypeLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["ContractTypeUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["ContractTypeUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["ContractTypeLocaleUniqueWhere"] | undefined
};
	["JobCityLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["JobCityUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["JobCityUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["JobCityLocaleUniqueWhere"] | undefined
};
	["JobPositionLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["JobPositionUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["JobPositionUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["JobPositionLocaleUniqueWhere"] | undefined,
	jobs?: ModelTypes["JobPageUniqueWhere"] | undefined
};
	["BonusBannerLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["BonusBannerUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	tiles?: ModelTypes["TileUniqueWhere"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["BonusBannerLocaleUniqueWhere"] | undefined
};
	["TileUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined,
	ligthBoxMedia?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["JobsFilterLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["JobsFilterUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	bannerJobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined,
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined,
	noJobImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["JobsFilterLocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GeneralUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["InfoBoxLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["InfoBoxUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["InfoBoxUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	locales?: ModelTypes["InfoBoxLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["FooterLocalesByBannerLinkUniqueWhere"]: {
	bannerLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByJobsFormUniqueWhere"]: {
	jobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ModelTypes["RecommendationFormLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByInstagramLinkUniqueWhere"]: {
	instagramLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterLocaleEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["FooterLocaleEdge"]: {
		node: ModelTypes["FooterLocale"]
};
	["FooterLink"]: {
		_meta?: ModelTypes["FooterLinkMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	link?: ModelTypes["Link"] | undefined
};
	["FooterLinkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["Link"]: {
		_meta?: ModelTypes["LinkMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: ModelTypes["Linkable"] | undefined,
	anchorLink?: string | undefined
};
	["LinkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	externalLink?: ModelTypes["FieldMeta"] | undefined,
	internalLink?: ModelTypes["FieldMeta"] | undefined,
	anchorLink?: ModelTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: ModelTypes["LinkableMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	redirect?: ModelTypes["Redirect"] | undefined,
	genericPage?: ModelTypes["GenericPageLocale"] | undefined,
	businessPage?: ModelTypes["BusinessPageLocale"] | undefined,
	jobPage?: ModelTypes["JobPageLocale"] | undefined,
	homePage?: ModelTypes["HomePageLocale"] | undefined,
	homePageLokal?: ModelTypes["HomePageLokalLocale"] | undefined
};
	["LinkableMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	redirect?: ModelTypes["FieldMeta"] | undefined,
	genericPage?: ModelTypes["FieldMeta"] | undefined,
	businessPage?: ModelTypes["FieldMeta"] | undefined,
	jobPage?: ModelTypes["FieldMeta"] | undefined,
	homePage?: ModelTypes["FieldMeta"] | undefined,
	homePageLokal?: ModelTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
		_meta?: ModelTypes["RedirectMeta"] | undefined,
	id: ModelTypes["UUID"],
	link?: ModelTypes["Linkable"] | undefined,
	target?: ModelTypes["Link"] | undefined
};
	["RedirectMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	target?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageLocale"]: {
		_meta?: ModelTypes["GenericPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["GenericPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	text?: string | undefined,
	logo?: ModelTypes["Image"] | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["GenericPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPage"]: {
		_meta?: ModelTypes["GenericPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["GenericPageLocale"]>,
	theme?: ModelTypes["Theme"] | undefined,
	localesByLocale?: ModelTypes["GenericPageLocale"] | undefined,
	localesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	localesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	localesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	localesByLogo?: ModelTypes["GenericPageLocale"] | undefined,
	localesByImage?: ModelTypes["GenericPageLocale"] | undefined,
	paginateLocales: ModelTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined
};
	["Theme"]: {
		_meta?: ModelTypes["ThemeMeta"] | undefined,
	id: ModelTypes["UUID"],
	backgroundColor?: string | undefined,
	textColor?: string | undefined,
	name?: string | undefined,
	buttonBackgroundColor?: string | undefined,
	buttonTextColor?: string | undefined,
	buttonBackgroundHoverColor?: string | undefined,
	buttonTextHoverColor?: string | undefined,
	buttonBorderColor?: string | undefined,
	buttonBorderHoverColor?: string | undefined,
	headerMenuColor?: string | undefined
};
	["ThemeMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	backgroundColor?: ModelTypes["FieldMeta"] | undefined,
	textColor?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	buttonBackgroundColor?: ModelTypes["FieldMeta"] | undefined,
	buttonTextColor?: ModelTypes["FieldMeta"] | undefined,
	buttonBackgroundHoverColor?: ModelTypes["FieldMeta"] | undefined,
	buttonTextHoverColor?: ModelTypes["FieldMeta"] | undefined,
	buttonBorderColor?: ModelTypes["FieldMeta"] | undefined,
	buttonBorderHoverColor?: ModelTypes["FieldMeta"] | undefined,
	headerMenuColor?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocalesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageLocaleEdge"]>
};
	["GenericPageLocaleEdge"]: {
		node: ModelTypes["GenericPageLocale"]
};
	["Content"]: {
		_meta?: ModelTypes["ContentMeta"] | undefined,
	id: ModelTypes["UUID"],
	blocks: Array<ModelTypes["ContentBlock"]>,
	blocksByReferences?: ModelTypes["ContentBlock"] | undefined,
	paginateBlocks: ModelTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	blocks?: ModelTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: ModelTypes["ContentBlockMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	json: string,
	content?: ModelTypes["Content"] | undefined,
	references: Array<ModelTypes["ContentReference"]>,
	referencesByLinkables?: ModelTypes["ContentReference"] | undefined,
	referencesByLink?: ModelTypes["ContentReference"] | undefined,
	referencesByMedia?: ModelTypes["ContentReference"] | undefined,
	paginateReferences: ModelTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	json?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	references?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: ModelTypes["ContentReferenceMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	block?: ModelTypes["ContentBlock"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	linkables: Array<ModelTypes["ContentReferenceLinkableItem"]>,
	secondaryText?: string | undefined,
	link?: ModelTypes["Link"] | undefined,
	media?: ModelTypes["MediumList"] | undefined,
	textList?: ModelTypes["TextList"] | undefined,
	carousel?: ModelTypes["Carousel"] | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocale"] | undefined,
	sideBySide?: ModelTypes["SideBySide"] | undefined,
	gallery?: ModelTypes["Gallery"] | undefined,
	showBoolean?: boolean | undefined,
	isRed?: boolean | undefined,
	video?: ModelTypes["Video"] | undefined,
	linkablesByItem?: ModelTypes["ContentReferenceLinkableItem"] | undefined,
	paginateLinkables: ModelTypes["ContentReferenceLinkableItemConnection"]
};
	["ContentReferenceMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	primaryText?: ModelTypes["FieldMeta"] | undefined,
	block?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	linkables?: ModelTypes["FieldMeta"] | undefined,
	secondaryText?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	media?: ModelTypes["FieldMeta"] | undefined,
	textList?: ModelTypes["FieldMeta"] | undefined,
	carousel?: ModelTypes["FieldMeta"] | undefined,
	bonusBanner?: ModelTypes["FieldMeta"] | undefined,
	sideBySide?: ModelTypes["FieldMeta"] | undefined,
	gallery?: ModelTypes["FieldMeta"] | undefined,
	showBoolean?: ModelTypes["FieldMeta"] | undefined,
	isRed?: ModelTypes["FieldMeta"] | undefined,
	video?: ModelTypes["FieldMeta"] | undefined
};
	["Image"]: {
		_meta?: ModelTypes["ImageMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined,
	focalPointX?: number | undefined,
	focalPointY?: number | undefined
};
	["ImageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	alt?: ModelTypes["FieldMeta"] | undefined,
	fileName?: ModelTypes["FieldMeta"] | undefined,
	focalPointX?: ModelTypes["FieldMeta"] | undefined,
	focalPointY?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceLinkableItem"]: {
		_meta?: ModelTypes["ContentReferenceLinkableItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	item?: ModelTypes["Linkable"] | undefined,
	reference?: ModelTypes["ContentReference"] | undefined
};
	["ContentReferenceLinkableItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined,
	reference?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceLinkableItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	item?: ModelTypes["LinkableOrderBy"] | undefined,
	reference?: ModelTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	primaryText?: ModelTypes["OrderDirection"] | undefined,
	block?: ModelTypes["ContentBlockOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	secondaryText?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	media?: ModelTypes["MediumListOrderBy"] | undefined,
	textList?: ModelTypes["TextListOrderBy"] | undefined,
	carousel?: ModelTypes["CarouselOrderBy"] | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocaleOrderBy"] | undefined,
	sideBySide?: ModelTypes["SideBySideOrderBy"] | undefined,
	gallery?: ModelTypes["GalleryOrderBy"] | undefined,
	showBoolean?: ModelTypes["OrderDirection"] | undefined,
	isRed?: ModelTypes["OrderDirection"] | undefined,
	video?: ModelTypes["VideoOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	json?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["MediumListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["CarouselOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	background?: ModelTypes["OrderDirection"] | undefined,
	squareImages?: ModelTypes["OrderDirection"] | undefined
};
	["BonusBannerLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["BonusBannerOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	bannerName?: ModelTypes["OrderDirection"] | undefined
};
	["BonusBannerOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["SideBySideOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	background?: ModelTypes["OrderDirection"] | undefined,
	alignment?: ModelTypes["OrderDirection"] | undefined,
	mediumSize?: ModelTypes["OrderDirection"] | undefined
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	src?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	videoInfo?: ModelTypes["OrderDirection"] | undefined,
	duration?: ModelTypes["OrderDirection"] | undefined,
	poster?: ModelTypes["ImageOrderBy"] | undefined
};
	["MediumList"]: {
		_meta?: ModelTypes["MediumListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["MediumItem"]>,
	paginateItems: ModelTypes["MediumItemConnection"]
};
	["MediumListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["MediumItem"]: {
		_meta?: ModelTypes["MediumItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["MediumList"] | undefined,
	medium?: ModelTypes["Medium"] | undefined
};
	["MediumItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	medium?: ModelTypes["FieldMeta"] | undefined
};
	["Medium"]: {
		_meta?: ModelTypes["MediumMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["MediumType"],
	image?: ModelTypes["Image"] | undefined,
	video?: ModelTypes["Video"] | undefined
};
	["MediumMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	video?: ModelTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: ModelTypes["VideoMeta"] | undefined,
	id: ModelTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	videoInfo?: string | undefined,
	duration?: number | undefined,
	poster?: ModelTypes["Image"] | undefined
};
	["VideoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	src?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	videoInfo?: ModelTypes["FieldMeta"] | undefined,
	duration?: ModelTypes["FieldMeta"] | undefined,
	poster?: ModelTypes["FieldMeta"] | undefined
};
	["MediumItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["MediumListOrderBy"] | undefined,
	medium?: ModelTypes["MediumOrderBy"] | undefined
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	video?: ModelTypes["VideoOrderBy"] | undefined
};
	["MediumItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["MediumItemEdge"]>
};
	["MediumItemEdge"]: {
		node: ModelTypes["MediumItem"]
};
	["TextList"]: {
		_meta?: ModelTypes["TextListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["TextItem"]>,
	paginateItems: ModelTypes["TextItemConnection"]
};
	["TextListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
		_meta?: ModelTypes["TextItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	text?: string | undefined,
	list?: ModelTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["TextListOrderBy"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined
};
	["TextItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
		node: ModelTypes["TextItem"]
};
	["Carousel"]: {
		_meta?: ModelTypes["CarouselMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["CarouselItem"]>,
	background?: ModelTypes["Background"] | undefined,
	squareImages?: boolean | undefined,
	itemsByImage?: ModelTypes["CarouselItem"] | undefined,
	itemsByLinkUrl?: ModelTypes["CarouselItem"] | undefined,
	paginateItems: ModelTypes["CarouselItemConnection"]
};
	["CarouselMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined,
	background?: ModelTypes["FieldMeta"] | undefined,
	squareImages?: ModelTypes["FieldMeta"] | undefined
};
	["CarouselItem"]: {
		_meta?: ModelTypes["CarouselItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	text?: string | undefined,
	list?: ModelTypes["Carousel"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	name?: string | undefined,
	title?: string | undefined,
	linkUrl?: ModelTypes["Link"] | undefined
};
	["CarouselItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	linkUrl?: ModelTypes["FieldMeta"] | undefined
};
	["CarouselItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["CarouselOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	linkUrl?: ModelTypes["LinkOrderBy"] | undefined
};
	["CarouselItemsByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["CarouselItemsByLinkUrlUniqueWhere"]: {
	linkUrl?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["CarouselItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CarouselItemEdge"]>
};
	["CarouselItemEdge"]: {
		node: ModelTypes["CarouselItem"]
};
	["BonusBannerLocale"]: {
		_meta?: ModelTypes["BonusBannerLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	root?: ModelTypes["BonusBanner"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	tiles: Array<ModelTypes["Tile"]>,
	logo?: ModelTypes["Image"] | undefined,
	bannerName?: string | undefined,
	tilesByImage?: ModelTypes["Tile"] | undefined,
	tilesByLigthBoxMedia?: ModelTypes["Tile"] | undefined,
	paginateTiles: ModelTypes["TileConnection"]
};
	["BonusBannerLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	tiles?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	bannerName?: ModelTypes["FieldMeta"] | undefined
};
	["BonusBanner"]: {
		_meta?: ModelTypes["BonusBannerMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["BonusBannerLocale"]>,
	localesByLocale?: ModelTypes["BonusBannerLocale"] | undefined,
	localesByTiles?: ModelTypes["BonusBannerLocale"] | undefined,
	localesByLogo?: ModelTypes["BonusBannerLocale"] | undefined,
	paginateLocales: ModelTypes["BonusBannerLocaleConnection"]
};
	["BonusBannerMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["BonusBannerLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["BonusBannerLocalesByTilesUniqueWhere"]: {
	tiles?: ModelTypes["TileUniqueWhere"] | undefined
};
	["BonusBannerLocalesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BonusBannerLocaleEdge"]>
};
	["BonusBannerLocaleEdge"]: {
		node: ModelTypes["BonusBannerLocale"]
};
	["Tile"]: {
		_meta?: ModelTypes["TileMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	text?: string | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocale"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	lightBoxTitle?: string | undefined,
	lightBoxText?: string | undefined,
	ligthBoxMedia?: ModelTypes["MediumList"] | undefined,
	lightBoxSectionTitle?: string | undefined,
	title?: string | undefined,
	lightBoxLinkLabel?: string | undefined,
	lightBoxForm?: ModelTypes["JobsFormLocale"] | undefined
};
	["TileMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	bonusBanner?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	lightBoxTitle?: ModelTypes["FieldMeta"] | undefined,
	lightBoxText?: ModelTypes["FieldMeta"] | undefined,
	ligthBoxMedia?: ModelTypes["FieldMeta"] | undefined,
	lightBoxSectionTitle?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	lightBoxLinkLabel?: ModelTypes["FieldMeta"] | undefined,
	lightBoxForm?: ModelTypes["FieldMeta"] | undefined
};
	["JobsFormLocale"]: {
		_meta?: ModelTypes["JobsFormLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	jobText?: string | undefined,
	genericText?: string | undefined,
	nameLabel?: string | undefined,
	emailLabel?: string | undefined,
	telefonLabel?: string | undefined,
	fileTitleLabel?: string | undefined,
	fileFormatLabel?: string | undefined,
	formTextLabel?: string | undefined,
	submitLabel?: string | undefined,
	root?: ModelTypes["JobsForm"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	imageTextList?: ModelTypes["ImageTextList"] | undefined,
	minimalize?: string | undefined,
	logo?: ModelTypes["Image"] | undefined,
	successTitle?: string | undefined,
	successText?: string | undefined,
	successBtnLabel?: string | undefined,
	successImage?: ModelTypes["Image"] | undefined,
	successOtherJobsTitle?: string | undefined,
	successOtherJobsCategoryTitle?: string | undefined,
	successOtherJobsButtonTitle?: string | undefined,
	successOtherJobsSelectionTitle?: string | undefined,
	successOtherJobsSelectionYesButton?: string | undefined,
	successOtherJobsSelectionNoButton?: string | undefined,
	fileRemoveBtnLabel?: string | undefined,
	gdprLabel?: string | undefined
};
	["JobsFormLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	jobText?: ModelTypes["FieldMeta"] | undefined,
	genericText?: ModelTypes["FieldMeta"] | undefined,
	nameLabel?: ModelTypes["FieldMeta"] | undefined,
	emailLabel?: ModelTypes["FieldMeta"] | undefined,
	telefonLabel?: ModelTypes["FieldMeta"] | undefined,
	fileTitleLabel?: ModelTypes["FieldMeta"] | undefined,
	fileFormatLabel?: ModelTypes["FieldMeta"] | undefined,
	formTextLabel?: ModelTypes["FieldMeta"] | undefined,
	submitLabel?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	imageTextList?: ModelTypes["FieldMeta"] | undefined,
	minimalize?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	successTitle?: ModelTypes["FieldMeta"] | undefined,
	successText?: ModelTypes["FieldMeta"] | undefined,
	successBtnLabel?: ModelTypes["FieldMeta"] | undefined,
	successImage?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsTitle?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsCategoryTitle?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsButtonTitle?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionTitle?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionYesButton?: ModelTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionNoButton?: ModelTypes["FieldMeta"] | undefined,
	fileRemoveBtnLabel?: ModelTypes["FieldMeta"] | undefined,
	gdprLabel?: ModelTypes["FieldMeta"] | undefined
};
	["JobsForm"]: {
		_meta?: ModelTypes["JobsFormMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["JobsFormLocale"]>,
	fileIlustration?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["JobsFormLocale"] | undefined,
	localesByImageTextList?: ModelTypes["JobsFormLocale"] | undefined,
	localesByLogo?: ModelTypes["JobsFormLocale"] | undefined,
	localesBySuccessImage?: ModelTypes["JobsFormLocale"] | undefined,
	paginateLocales: ModelTypes["JobsFormLocaleConnection"]
};
	["JobsFormMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	fileIlustration?: ModelTypes["FieldMeta"] | undefined
};
	["JobsFormLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["JobsFormLocalesByImageTextListUniqueWhere"]: {
	imageTextList?: ModelTypes["ImageTextListUniqueWhere"] | undefined
};
	["JobsFormLocalesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormLocalesBySuccessImageUniqueWhere"]: {
	successImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobsFormLocaleEdge"]>
};
	["JobsFormLocaleEdge"]: {
		node: ModelTypes["JobsFormLocale"]
};
	["ImageTextList"]: {
		_meta?: ModelTypes["ImageTextListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ImageTextItem"]>,
	itemsByImage?: ModelTypes["ImageTextItem"] | undefined,
	paginateItems: ModelTypes["ImageTextItemConnection"]
};
	["ImageTextListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ImageTextItem"]: {
		_meta?: ModelTypes["ImageTextItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	title?: string | undefined,
	list?: ModelTypes["ImageTextList"] | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["ImageTextItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["ImageTextItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ImageTextListOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageTextListItemsByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageTextItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageTextItemEdge"]>
};
	["ImageTextItemEdge"]: {
		node: ModelTypes["ImageTextItem"]
};
	["TileOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	bonusBanner?: ModelTypes["BonusBannerLocaleOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	lightBoxTitle?: ModelTypes["OrderDirection"] | undefined,
	lightBoxText?: ModelTypes["OrderDirection"] | undefined,
	ligthBoxMedia?: ModelTypes["MediumListOrderBy"] | undefined,
	lightBoxSectionTitle?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	lightBoxLinkLabel?: ModelTypes["OrderDirection"] | undefined,
	lightBoxForm?: ModelTypes["JobsFormLocaleOrderBy"] | undefined
};
	["BonusBannerLocaleTilesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"]: {
	ligthBoxMedia?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["TileConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TileEdge"]>
};
	["TileEdge"]: {
		node: ModelTypes["Tile"]
};
	["SideBySide"]: {
		_meta?: ModelTypes["SideBySideMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["SideBySideItem"]>,
	background: ModelTypes["Background"],
	alignment: ModelTypes["ContentAlignment"],
	mediumSize: ModelTypes["MediumSize"],
	itemsByMedia?: ModelTypes["SideBySideItem"] | undefined,
	paginateItems: ModelTypes["SideBySideItemConnection"]
};
	["SideBySideMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined,
	background?: ModelTypes["FieldMeta"] | undefined,
	alignment?: ModelTypes["FieldMeta"] | undefined,
	mediumSize?: ModelTypes["FieldMeta"] | undefined
};
	["SideBySideItem"]: {
		_meta?: ModelTypes["SideBySideItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	title?: string | undefined,
	text?: string | undefined,
	list?: ModelTypes["SideBySide"] | undefined,
	media?: ModelTypes["MediumList"] | undefined,
	formButtonLabel?: string | undefined,
	type: ModelTypes["SideBySideItemType"],
	link?: ModelTypes["Link"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocale"] | undefined
};
	["SideBySideItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	media?: ModelTypes["FieldMeta"] | undefined,
	formButtonLabel?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	jobsForm?: ModelTypes["FieldMeta"] | undefined
};
	["SideBySideItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["SideBySideOrderBy"] | undefined,
	media?: ModelTypes["MediumListOrderBy"] | undefined,
	formButtonLabel?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	jobsForm?: ModelTypes["JobsFormLocaleOrderBy"] | undefined
};
	["SideBySideItemsByMediaUniqueWhere"]: {
	media?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["SideBySideItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SideBySideItemEdge"]>
};
	["SideBySideItemEdge"]: {
		node: ModelTypes["SideBySideItem"]
};
	["Gallery"]: {
		_meta?: ModelTypes["GalleryMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["GalleryItem"]>,
	itemsByImage?: ModelTypes["GalleryItem"] | undefined,
	paginateItems: ModelTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
		_meta?: ModelTypes["GalleryItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	list?: ModelTypes["Gallery"] | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["GalleryItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["GalleryOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["GalleryItemsByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["GalleryItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
		node: ModelTypes["GalleryItem"]
};
	["ContentReferenceLinkablesByItemUniqueWhere"]: {
	item?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["ContentReferenceLinkableItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceLinkableItemEdge"]>
};
	["ContentReferenceLinkableItemEdge"]: {
		node: ModelTypes["ContentReferenceLinkableItem"]
};
	["ContentBlockReferencesByLinkablesUniqueWhere"]: {
	linkables?: ModelTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByMediaUniqueWhere"]: {
	media?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: ModelTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: ModelTypes["ContentBlock"]
};
	["Seo"]: {
		_meta?: ModelTypes["SeoMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined,
	ogImage?: ModelTypes["Image"] | undefined
};
	["SeoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	ogTitle?: ModelTypes["FieldMeta"] | undefined,
	ogDescription?: ModelTypes["FieldMeta"] | undefined,
	noIndex?: ModelTypes["FieldMeta"] | undefined,
	noFollow?: ModelTypes["FieldMeta"] | undefined,
	ogImage?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessPageLocale"]: {
		_meta?: ModelTypes["BusinessPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["BusinessPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	subtitle?: string | undefined,
	text?: string | undefined,
	name?: string | undefined,
	image?: ModelTypes["Image"] | undefined,
	stickyBar?: ModelTypes["StickyBar"] | undefined,
	infoBox?: ModelTypes["InfoBox"] | undefined
};
	["BusinessPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	subtitle?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	stickyBar?: ModelTypes["FieldMeta"] | undefined,
	infoBox?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessPage"]: {
		_meta?: ModelTypes["BusinessPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["BusinessPageLocale"]>,
	theme?: ModelTypes["Theme"] | undefined,
	isLokal?: boolean | undefined,
	localesByLocale?: ModelTypes["BusinessPageLocale"] | undefined,
	localesByContent?: ModelTypes["BusinessPageLocale"] | undefined,
	localesBySeo?: ModelTypes["BusinessPageLocale"] | undefined,
	localesByLink?: ModelTypes["BusinessPageLocale"] | undefined,
	localesByImage?: ModelTypes["BusinessPageLocale"] | undefined,
	paginateLocales: ModelTypes["BusinessPageLocaleConnection"]
};
	["BusinessPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined,
	isLokal?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessPageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["BusinessPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["BusinessPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["BusinessPageLocalesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["BusinessPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessPageLocaleEdge"]>
};
	["BusinessPageLocaleEdge"]: {
		node: ModelTypes["BusinessPageLocale"]
};
	["StickyBar"]: {
		_meta?: ModelTypes["StickyBarMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	link?: ModelTypes["Link"] | undefined,
	formButtonLabel?: string | undefined,
	jobsForm?: ModelTypes["JobsFormLocale"] | undefined
};
	["StickyBarMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	formButtonLabel?: ModelTypes["FieldMeta"] | undefined,
	jobsForm?: ModelTypes["FieldMeta"] | undefined
};
	["InfoBox"]: {
		_meta?: ModelTypes["InfoBoxMeta"] | undefined,
	id: ModelTypes["UUID"],
	logoSize: ModelTypes["LogoSize"],
	logo?: ModelTypes["Image"] | undefined,
	locales: Array<ModelTypes["InfoBoxLocale"]>,
	localesByLocale?: ModelTypes["InfoBoxLocale"] | undefined,
	paginateLocales: ModelTypes["InfoBoxLocaleConnection"]
};
	["InfoBoxMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	logoSize?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["InfoBoxLocale"]: {
		_meta?: ModelTypes["InfoBoxLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	address?: string | undefined,
	web?: string | undefined,
	instagram?: string | undefined,
	facebook?: string | undefined,
	root?: ModelTypes["InfoBox"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	openingHours?: ModelTypes["TextList"] | undefined,
	addressLink?: string | undefined
};
	["InfoBoxLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	address?: ModelTypes["FieldMeta"] | undefined,
	web?: ModelTypes["FieldMeta"] | undefined,
	instagram?: ModelTypes["FieldMeta"] | undefined,
	facebook?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	openingHours?: ModelTypes["FieldMeta"] | undefined,
	addressLink?: ModelTypes["FieldMeta"] | undefined
};
	["InfoBoxLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	address?: ModelTypes["OrderDirection"] | undefined,
	web?: ModelTypes["OrderDirection"] | undefined,
	instagram?: ModelTypes["OrderDirection"] | undefined,
	facebook?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["InfoBoxOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	openingHours?: ModelTypes["TextListOrderBy"] | undefined,
	addressLink?: ModelTypes["OrderDirection"] | undefined
};
	["InfoBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["InfoBoxLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["InfoBoxLocaleEdge"]>
};
	["InfoBoxLocaleEdge"]: {
		node: ModelTypes["InfoBoxLocale"]
};
	["JobPageLocale"]: {
		_meta?: ModelTypes["JobPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	root?: ModelTypes["JobPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	keyInfo?: ModelTypes["KeyInfoList"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	email?: string | undefined,
	stickyBar?: ModelTypes["StickyBar"] | undefined,
	mobileImage?: ModelTypes["Image"] | undefined
};
	["JobPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	keyInfo?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	stickyBar?: ModelTypes["FieldMeta"] | undefined,
	mobileImage?: ModelTypes["FieldMeta"] | undefined
};
	["JobPage"]: {
		_meta?: ModelTypes["JobPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["JobPageLocale"]>,
	theme?: ModelTypes["Theme"] | undefined,
	contractType?: ModelTypes["ContractType"] | undefined,
	jobCity?: ModelTypes["JobCity"] | undefined,
	business?: ModelTypes["BusinessPage"] | undefined,
	isActive?: boolean | undefined,
	jobPosition?: ModelTypes["JobPosition"] | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	order?: number | undefined,
	isSyncedToTabidoo?: boolean | undefined,
	localesByLocale?: ModelTypes["JobPageLocale"] | undefined,
	localesByImage?: ModelTypes["JobPageLocale"] | undefined,
	localesByKeyInfo?: ModelTypes["JobPageLocale"] | undefined,
	localesByContent?: ModelTypes["JobPageLocale"] | undefined,
	localesBySeo?: ModelTypes["JobPageLocale"] | undefined,
	localesByLink?: ModelTypes["JobPageLocale"] | undefined,
	localesByMobileImage?: ModelTypes["JobPageLocale"] | undefined,
	paginateLocales: ModelTypes["JobPageLocaleConnection"]
};
	["JobPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined,
	contractType?: ModelTypes["FieldMeta"] | undefined,
	jobCity?: ModelTypes["FieldMeta"] | undefined,
	business?: ModelTypes["FieldMeta"] | undefined,
	isActive?: ModelTypes["FieldMeta"] | undefined,
	jobPosition?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	isSyncedToTabidoo?: ModelTypes["FieldMeta"] | undefined
};
	["ContractType"]: {
		_meta?: ModelTypes["ContractTypeMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["ContractTypeLocale"]>,
	localesByLocale?: ModelTypes["ContractTypeLocale"] | undefined,
	paginateLocales: ModelTypes["ContractTypeLocaleConnection"]
};
	["ContractTypeMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["ContractTypeLocale"]: {
		_meta?: ModelTypes["ContractTypeLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	type?: string | undefined,
	root?: ModelTypes["ContractType"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	slug: string
};
	["ContractTypeLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined
};
	["ContractTypeLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["ContractTypeOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined
};
	["ContractTypeLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ContractTypeLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContractTypeLocaleEdge"]>
};
	["ContractTypeLocaleEdge"]: {
		node: ModelTypes["ContractTypeLocale"]
};
	["JobCity"]: {
		_meta?: ModelTypes["JobCityMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["JobCityLocale"]>,
	localesByLocale?: ModelTypes["JobCityLocale"] | undefined,
	paginateLocales: ModelTypes["JobCityLocaleConnection"]
};
	["JobCityMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["JobCityLocale"]: {
		_meta?: ModelTypes["JobCityLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["JobCity"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	name?: string | undefined,
	slug: string
};
	["JobCityLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined
};
	["JobCityLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["JobCityOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined
};
	["JobCityLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["JobCityLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobCityLocaleEdge"]>
};
	["JobCityLocaleEdge"]: {
		node: ModelTypes["JobCityLocale"]
};
	["JobPosition"]: {
		_meta?: ModelTypes["JobPositionMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["JobPositionLocale"]>,
	jobs: Array<ModelTypes["JobPage"]>,
	localesByLocale?: ModelTypes["JobPositionLocale"] | undefined,
	jobsByLocales?: ModelTypes["JobPage"] | undefined,
	paginateLocales: ModelTypes["JobPositionLocaleConnection"],
	paginateJobs: ModelTypes["JobPageConnection"]
};
	["JobPositionMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	jobs?: ModelTypes["FieldMeta"] | undefined
};
	["JobPositionLocale"]: {
		_meta?: ModelTypes["JobPositionLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	type?: string | undefined,
	root?: ModelTypes["JobPosition"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	slug: string
};
	["JobPositionLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined
};
	["JobPositionLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["JobPositionOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined
};
	["JobPositionLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["JobPositionJobsByLocalesUniqueWhere"]: {
	locales?: ModelTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["JobPositionLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobPositionLocaleEdge"]>
};
	["JobPositionLocaleEdge"]: {
		node: ModelTypes["JobPositionLocale"]
};
	["JobPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobPageEdge"]>
};
	["JobPageEdge"]: {
		node: ModelTypes["JobPage"]
};
	["JobPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["JobPageLocalesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocalesByKeyInfoUniqueWhere"]: {
	keyInfo?: ModelTypes["KeyInfoListUniqueWhere"] | undefined
};
	["JobPageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["JobPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["JobPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["JobPageLocalesByMobileImageUniqueWhere"]: {
	mobileImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobPageLocaleEdge"]>
};
	["JobPageLocaleEdge"]: {
		node: ModelTypes["JobPageLocale"]
};
	["KeyInfoList"]: {
		_meta?: ModelTypes["KeyInfoListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["KeyInfoItem"]>,
	paginateItems: ModelTypes["KeyInfoItemConnection"]
};
	["KeyInfoListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["KeyInfoItem"]: {
		_meta?: ModelTypes["KeyInfoItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order?: number | undefined,
	keyInfo?: string | undefined,
	list?: ModelTypes["KeyInfoList"] | undefined,
	icon?: ModelTypes["Icon"] | undefined
};
	["KeyInfoItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	keyInfo?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	icon?: ModelTypes["FieldMeta"] | undefined
};
	["Icon"]: {
		_meta?: ModelTypes["IconMeta"] | undefined,
	id: ModelTypes["UUID"],
	name?: string | undefined,
	icon?: ModelTypes["Image"] | undefined
};
	["IconMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	icon?: ModelTypes["FieldMeta"] | undefined
};
	["KeyInfoItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	keyInfo?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["KeyInfoListOrderBy"] | undefined,
	icon?: ModelTypes["IconOrderBy"] | undefined
};
	["IconOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	icon?: ModelTypes["ImageOrderBy"] | undefined
};
	["KeyInfoItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["KeyInfoItemEdge"]>
};
	["KeyInfoItemEdge"]: {
		node: ModelTypes["KeyInfoItem"]
};
	["HomePageLocale"]: {
		_meta?: ModelTypes["HomePageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["HomePage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	button?: ModelTypes["Link"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	logo?: ModelTypes["Image"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcher"] | undefined,
	gallery?: ModelTypes["Gallery"] | undefined,
	stickyBar?: ModelTypes["StickyBar"] | undefined,
	text?: string | undefined,
	ilustration?: ModelTypes["Gallery"] | undefined
};
	["HomePageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	button?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	siteSwitcher?: ModelTypes["FieldMeta"] | undefined,
	gallery?: ModelTypes["FieldMeta"] | undefined,
	stickyBar?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	ilustration?: ModelTypes["FieldMeta"] | undefined
};
	["HomePage"]: {
		_meta?: ModelTypes["HomePageMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["HomePageLocale"]>,
	theme?: ModelTypes["Theme"] | undefined,
	localesByLocale?: ModelTypes["HomePageLocale"] | undefined,
	localesByButton?: ModelTypes["HomePageLocale"] | undefined,
	localesByContent?: ModelTypes["HomePageLocale"] | undefined,
	localesBySeo?: ModelTypes["HomePageLocale"] | undefined,
	localesByLink?: ModelTypes["HomePageLocale"] | undefined,
	localesByLogo?: ModelTypes["HomePageLocale"] | undefined,
	localesBySiteSwitcher?: ModelTypes["HomePageLocale"] | undefined,
	localesByIlustration?: ModelTypes["HomePageLocale"] | undefined,
	paginateLocales: ModelTypes["HomePageLocaleConnection"]
};
	["HomePageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined
};
	["HomePageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["HomePageLocalesByButtonUniqueWhere"]: {
	button?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HomePageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["HomePageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["HomePageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["HomePageLocalesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["HomePageLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HomePageLocaleEdge"]>
};
	["HomePageLocaleEdge"]: {
		node: ModelTypes["HomePageLocale"]
};
	["SiteSwitcher"]: {
		_meta?: ModelTypes["SiteSwitcherMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	link?: ModelTypes["Link"] | undefined,
	theme?: ModelTypes["Theme"] | undefined
};
	["SiteSwitcherMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined
};
	["HomePageLokalLocale"]: {
		_meta?: ModelTypes["HomePageLokalLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["HomePageLokal"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	logo?: ModelTypes["Image"] | undefined,
	button?: ModelTypes["Link"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	siteSwitcher?: ModelTypes["SiteSwitcher"] | undefined,
	gallery?: ModelTypes["Gallery"] | undefined,
	stickyBar?: ModelTypes["StickyBar"] | undefined,
	text?: string | undefined,
	ilustration?: ModelTypes["Gallery"] | undefined
};
	["HomePageLokalLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	button?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	siteSwitcher?: ModelTypes["FieldMeta"] | undefined,
	gallery?: ModelTypes["FieldMeta"] | undefined,
	stickyBar?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	ilustration?: ModelTypes["FieldMeta"] | undefined
};
	["HomePageLokal"]: {
		_meta?: ModelTypes["HomePageLokalMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["HomePageLokalLocale"]>,
	theme?: ModelTypes["Theme"] | undefined,
	localesByLocale?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesByLogo?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesByButton?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesByContent?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesBySeo?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesByLink?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesBySiteSwitcher?: ModelTypes["HomePageLokalLocale"] | undefined,
	localesByIlustration?: ModelTypes["HomePageLokalLocale"] | undefined,
	paginateLocales: ModelTypes["HomePageLokalLocaleConnection"]
};
	["HomePageLokalMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	theme?: ModelTypes["FieldMeta"] | undefined
};
	["HomePageLokalLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByButtonUniqueWhere"]: {
	button?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["HomePageLokalLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["HomePageLokalLocalesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByIlustrationUniqueWhere"]: {
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLokalLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HomePageLokalLocaleEdge"]>
};
	["HomePageLokalLocaleEdge"]: {
		node: ModelTypes["HomePageLokalLocale"]
};
	["FooterLinkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["RecommendationFormLocale"]: {
		_meta?: ModelTypes["RecommendationFormLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	subtitle?: string | undefined,
	yourEmailLabel?: string | undefined,
	theirEmailLabel?: string | undefined,
	yourName?: string | undefined,
	sendBtnLabel?: string | undefined,
	successTitle?: string | undefined,
	successSubtitle?: string | undefined,
	successButtonClose?: string | undefined,
	root?: ModelTypes["RecommendationForm"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["RecommendationFormLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	subtitle?: ModelTypes["FieldMeta"] | undefined,
	yourEmailLabel?: ModelTypes["FieldMeta"] | undefined,
	theirEmailLabel?: ModelTypes["FieldMeta"] | undefined,
	yourName?: ModelTypes["FieldMeta"] | undefined,
	sendBtnLabel?: ModelTypes["FieldMeta"] | undefined,
	successTitle?: ModelTypes["FieldMeta"] | undefined,
	successSubtitle?: ModelTypes["FieldMeta"] | undefined,
	successButtonClose?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["RecommendationForm"]: {
		_meta?: ModelTypes["RecommendationFormMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["RecommendationFormLocale"]>,
	successImage?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["RecommendationFormLocale"] | undefined,
	paginateLocales: ModelTypes["RecommendationFormLocaleConnection"]
};
	["RecommendationFormMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	successImage?: ModelTypes["FieldMeta"] | undefined
};
	["RecommendationFormLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["RecommendationFormLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RecommendationFormLocaleEdge"]>
};
	["RecommendationFormLocaleEdge"]: {
		node: ModelTypes["RecommendationFormLocale"]
};
	["FooterLinkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterLinkEdge"]>
};
	["FooterLinkEdge"]: {
		node: ModelTypes["FooterLink"]
};
	["HeaderLocale"]: {
		_meta?: ModelTypes["HeaderLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["Header"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	menuLabel?: string | undefined,
	menuTitle?: string | undefined,
	menuText?: string | undefined,
	business: Array<ModelTypes["Business"]>,
	lokalEnterprise: Array<ModelTypes["Business"]>,
	businessSectionTitle?: string | undefined,
	lokalSectionTitle?: string | undefined,
	businessLink?: ModelTypes["Link"] | undefined,
	lokalLink?: ModelTypes["Link"] | undefined,
	paginateBusiness: ModelTypes["BusinessConnection"],
	paginateLokalEnterprise: ModelTypes["BusinessConnection"]
};
	["HeaderLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	menuLabel?: ModelTypes["FieldMeta"] | undefined,
	menuTitle?: ModelTypes["FieldMeta"] | undefined,
	menuText?: ModelTypes["FieldMeta"] | undefined,
	business?: ModelTypes["FieldMeta"] | undefined,
	lokalEnterprise?: ModelTypes["FieldMeta"] | undefined,
	businessSectionTitle?: ModelTypes["FieldMeta"] | undefined,
	lokalSectionTitle?: ModelTypes["FieldMeta"] | undefined,
	businessLink?: ModelTypes["FieldMeta"] | undefined,
	lokalLink?: ModelTypes["FieldMeta"] | undefined
};
	["Header"]: {
		_meta?: ModelTypes["HeaderMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["HeaderLocale"]>,
	logo?: ModelTypes["Image"] | undefined,
	logoMenu?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["HeaderLocale"] | undefined,
	localesByBusinessLink?: ModelTypes["HeaderLocale"] | undefined,
	localesByLokalLink?: ModelTypes["HeaderLocale"] | undefined,
	paginateLocales: ModelTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	logoMenu?: ModelTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["HeaderOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	menuLabel?: ModelTypes["OrderDirection"] | undefined,
	menuTitle?: ModelTypes["OrderDirection"] | undefined,
	menuText?: ModelTypes["OrderDirection"] | undefined,
	businessSectionTitle?: ModelTypes["OrderDirection"] | undefined,
	lokalSectionTitle?: ModelTypes["OrderDirection"] | undefined,
	businessLink?: ModelTypes["LinkOrderBy"] | undefined,
	lokalLink?: ModelTypes["LinkOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	logoMenu?: ModelTypes["ImageOrderBy"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderLocalesByBusinessLinkUniqueWhere"]: {
	businessLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HeaderLocalesByLokalLinkUniqueWhere"]: {
	lokalLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
		node: ModelTypes["HeaderLocale"]
};
	["Business"]: {
		_meta?: ModelTypes["BusinessMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	logo?: ModelTypes["Image"] | undefined,
	link?: ModelTypes["Link"] | undefined
};
	["BusinessMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["BusinessOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["BusinessConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessEdge"]>
};
	["BusinessEdge"]: {
		node: ModelTypes["Business"]
};
	["SeoDefaultLocale"]: {
		_meta?: ModelTypes["SeoDefaultLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["SeoDefault"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined
};
	["SeoDefaultLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined
};
	["SeoDefault"]: {
		_meta?: ModelTypes["SeoDefaultMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["SeoDefaultLocale"]>,
	localesByLocale?: ModelTypes["SeoDefaultLocale"] | undefined,
	localesBySeo?: ModelTypes["SeoDefaultLocale"] | undefined,
	paginateLocales: ModelTypes["SeoDefaultLocaleConnection"]
};
	["SeoDefaultMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["SeoDefaultLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["SeoDefaultOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined
};
	["SeoDefaultOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["SeoDefaultLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["SeoDefaultLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["SeoDefaultLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SeoDefaultLocaleEdge"]>
};
	["SeoDefaultLocaleEdge"]: {
		node: ModelTypes["SeoDefaultLocale"]
};
	["JobsFilterLocale"]: {
		_meta?: ModelTypes["JobsFilterLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	jobPositionLabel?: string | undefined,
	jobContractLabel?: string | undefined,
	jobCityLabel?: string | undefined,
	root?: ModelTypes["JobsFilter"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	bannerTitle?: string | undefined,
	bannerFormLabel?: string | undefined,
	bannerJobsForm?: ModelTypes["JobsFormLocale"] | undefined,
	bannerImage?: ModelTypes["Image"] | undefined,
	noJobTitle?: string | undefined,
	noJobBtnLabel?: string | undefined,
	noJobImage?: ModelTypes["Image"] | undefined,
	noJobTitleForSpecificBusiness?: string | undefined,
	deactivatedJob?: string | undefined
};
	["JobsFilterLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	jobPositionLabel?: ModelTypes["FieldMeta"] | undefined,
	jobContractLabel?: ModelTypes["FieldMeta"] | undefined,
	jobCityLabel?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	bannerTitle?: ModelTypes["FieldMeta"] | undefined,
	bannerFormLabel?: ModelTypes["FieldMeta"] | undefined,
	bannerJobsForm?: ModelTypes["FieldMeta"] | undefined,
	bannerImage?: ModelTypes["FieldMeta"] | undefined,
	noJobTitle?: ModelTypes["FieldMeta"] | undefined,
	noJobBtnLabel?: ModelTypes["FieldMeta"] | undefined,
	noJobImage?: ModelTypes["FieldMeta"] | undefined,
	noJobTitleForSpecificBusiness?: ModelTypes["FieldMeta"] | undefined,
	deactivatedJob?: ModelTypes["FieldMeta"] | undefined
};
	["JobsFilter"]: {
		_meta?: ModelTypes["JobsFilterMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["JobsFilterLocale"]>,
	localesByLocale?: ModelTypes["JobsFilterLocale"] | undefined,
	localesByBannerJobsForm?: ModelTypes["JobsFilterLocale"] | undefined,
	localesByBannerImage?: ModelTypes["JobsFilterLocale"] | undefined,
	localesByNoJobImage?: ModelTypes["JobsFilterLocale"] | undefined,
	paginateLocales: ModelTypes["JobsFilterLocaleConnection"]
};
	["JobsFilterMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["JobsFilterLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	jobPositionLabel?: ModelTypes["OrderDirection"] | undefined,
	jobContractLabel?: ModelTypes["OrderDirection"] | undefined,
	jobCityLabel?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["JobsFilterOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	bannerTitle?: ModelTypes["OrderDirection"] | undefined,
	bannerFormLabel?: ModelTypes["OrderDirection"] | undefined,
	bannerJobsForm?: ModelTypes["JobsFormLocaleOrderBy"] | undefined,
	bannerImage?: ModelTypes["ImageOrderBy"] | undefined,
	noJobTitle?: ModelTypes["OrderDirection"] | undefined,
	noJobBtnLabel?: ModelTypes["OrderDirection"] | undefined,
	noJobImage?: ModelTypes["ImageOrderBy"] | undefined,
	noJobTitleForSpecificBusiness?: ModelTypes["OrderDirection"] | undefined,
	deactivatedJob?: ModelTypes["OrderDirection"] | undefined
};
	["JobsFilterOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["JobsFilterLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["JobsFilterLocalesByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["JobsFilterLocalesByBannerImageUniqueWhere"]: {
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterLocalesByNoJobImageUniqueWhere"]: {
	noJobImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobsFilterLocaleEdge"]>
};
	["JobsFilterLocaleEdge"]: {
		node: ModelTypes["JobsFilterLocale"]
};
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GeneralOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ModelTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByTermsAndConditionsUniqueWhere"]: {
	termsAndConditions?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByBannerImageUniqueWhere"]: {
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleFootersByBannerLinkUniqueWhere"]: {
	bannerLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByJobsFormUniqueWhere"]: {
	jobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["LocaleFootersByCopyrightLinkUniqueWhere"]: {
	copyrightLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByRecommendationFormUniqueWhere"]: {
	recommendationForm?: ModelTypes["RecommendationFormLocaleUniqueWhere"] | undefined
};
	["LocaleFootersByBannerSecondLinkUniqueWhere"]: {
	bannerSecondLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByInstagramLinkUniqueWhere"]: {
	instagramLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ModelTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleHeadersByBusinessLinkUniqueWhere"]: {
	businessLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHeadersByLokalLinkUniqueWhere"]: {
	lokalLink?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleSeoDefaultsByRootUniqueWhere"]: {
	root?: ModelTypes["SeoDefaultUniqueWhere"] | undefined
};
	["LocaleSeoDefaultsBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByRootUniqueWhere"]: {
	root?: ModelTypes["BusinessPageUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBusinessPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobPagesByRootUniqueWhere"]: {
	root?: ModelTypes["JobPageUniqueWhere"] | undefined
};
	["LocaleJobPagesByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobPagesByKeyInfoUniqueWhere"]: {
	keyInfo?: ModelTypes["KeyInfoListUniqueWhere"] | undefined
};
	["LocaleJobPagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleJobPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleJobPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleJobPagesByMobileImageUniqueWhere"]: {
	mobileImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleContractTypesByRootUniqueWhere"]: {
	root?: ModelTypes["ContractTypeUniqueWhere"] | undefined
};
	["LocaleContractTypesBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["LocaleJobCitiesByRootUniqueWhere"]: {
	root?: ModelTypes["JobCityUniqueWhere"] | undefined
};
	["LocaleJobCitiesBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["LocaleJobPositionsByRootUniqueWhere"]: {
	root?: ModelTypes["JobPositionUniqueWhere"] | undefined
};
	["LocaleJobPositionsBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["LocaleHomePagesByRootUniqueWhere"]: {
	root?: ModelTypes["HomePageUniqueWhere"] | undefined
};
	["LocaleHomePagesByButtonUniqueWhere"]: {
	button?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHomePagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleHomePagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHomePagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleHomePagesByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePagesBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["LocaleHomePagesByIlustrationUniqueWhere"]: {
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["LocaleBonusBannersByRootUniqueWhere"]: {
	root?: ModelTypes["BonusBannerUniqueWhere"] | undefined
};
	["LocaleBonusBannersByTilesUniqueWhere"]: {
	tiles?: ModelTypes["TileUniqueWhere"] | undefined
};
	["LocaleBonusBannersByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByRootUniqueWhere"]: {
	root?: ModelTypes["HomePageLokalUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByButtonUniqueWhere"]: {
	button?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"]: {
	siteSwitcher?: ModelTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByIlustrationUniqueWhere"]: {
	ilustration?: ModelTypes["GalleryUniqueWhere"] | undefined
};
	["LocaleJobsFormsByRootUniqueWhere"]: {
	root?: ModelTypes["JobsFormUniqueWhere"] | undefined
};
	["LocaleJobsFormsByImageTextListUniqueWhere"]: {
	imageTextList?: ModelTypes["ImageTextListUniqueWhere"] | undefined
};
	["LocaleJobsFormsByLogoUniqueWhere"]: {
	logo?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFormsBySuccessImageUniqueWhere"]: {
	successImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByRootUniqueWhere"]: {
	root?: ModelTypes["JobsFilterUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByBannerJobsFormUniqueWhere"]: {
	bannerJobsForm?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByBannerImageUniqueWhere"]: {
	bannerImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByNoJobImageUniqueWhere"]: {
	noJobImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LocaleGeneralByRootUniqueWhere"]: {
	root?: ModelTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleRecommendationFormsByRootUniqueWhere"]: {
	root?: ModelTypes["RecommendationFormUniqueWhere"] | undefined
};
	["LocaleInfoBoxesByRootUniqueWhere"]: {
	root?: ModelTypes["InfoBoxUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
		node: ModelTypes["GeneralLocale"]
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GeneralConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
		node: ModelTypes["General"]
};
	["ImageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageEdge"]>
};
	["ImageEdge"]: {
		node: ModelTypes["Image"]
};
	["GenericPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
		node: ModelTypes["GenericPage"]
};
	["LinkableConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: ModelTypes["Linkable"]
};
	["SeoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: ModelTypes["Seo"]
};
	["ContentConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: ModelTypes["Content"]
};
	["RedirectConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: ModelTypes["Redirect"]
};
	["LinkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: ModelTypes["Link"]
};
	["FooterConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterEdge"]>
};
	["FooterEdge"]: {
		node: ModelTypes["Footer"]
};
	["FooterLinkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["HeaderConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
		node: ModelTypes["Header"]
};
	["LocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: ModelTypes["Locale"]
};
	["SeoDefaultConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SeoDefaultEdge"]>
};
	["SeoDefaultEdge"]: {
		node: ModelTypes["SeoDefault"]
};
	["BusinessUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	logo?: ModelTypes["ImageUniqueWhere"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["BusinessPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BusinessPageEdge"]>
};
	["BusinessPageEdge"]: {
		node: ModelTypes["BusinessPage"]
};
	["IconUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	icon?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["IconConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["IconEdge"]>
};
	["IconEdge"]: {
		node: ModelTypes["Icon"]
};
	["KeyInfoListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["KeyInfoListEdge"]>
};
	["KeyInfoListEdge"]: {
		node: ModelTypes["KeyInfoList"]
};
	["ContractTypeConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContractTypeEdge"]>
};
	["ContractTypeEdge"]: {
		node: ModelTypes["ContractType"]
};
	["JobCityConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobCityEdge"]>
};
	["JobCityEdge"]: {
		node: ModelTypes["JobCity"]
};
	["JobPositionConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobPositionEdge"]>
};
	["JobPositionEdge"]: {
		node: ModelTypes["JobPosition"]
};
	["GalleryConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
		node: ModelTypes["Gallery"]
};
	["HomePageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HomePageEdge"]>
};
	["HomePageEdge"]: {
		node: ModelTypes["HomePage"]
};
	["MediumUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["MediumConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["MediumEdge"]>
};
	["MediumEdge"]: {
		node: ModelTypes["Medium"]
};
	["MediumListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["MediumListEdge"]>
};
	["MediumListEdge"]: {
		node: ModelTypes["MediumList"]
};
	["VideoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["VideoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: ModelTypes["Video"]
};
	["TextItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["TextListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["TextItemUniqueWhere"] | undefined
};
	["TextListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TextListEdge"]>
};
	["TextListEdge"]: {
		node: ModelTypes["TextList"]
};
	["CarouselUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["CarouselItemUniqueWhere"] | undefined
};
	["CarouselItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined,
	linkUrl?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["CarouselConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["CarouselEdge"]>
};
	["CarouselEdge"]: {
		node: ModelTypes["Carousel"]
};
	["ThemeConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ThemeEdge"]>
};
	["ThemeEdge"]: {
		node: ModelTypes["Theme"]
};
	["BonusBannerConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["BonusBannerEdge"]>
};
	["BonusBannerEdge"]: {
		node: ModelTypes["BonusBanner"]
};
	["SideBySideUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["SideBySideItemUniqueWhere"] | undefined
};
	["SideBySideItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	media?: ModelTypes["MediumListUniqueWhere"] | undefined
};
	["SideBySideConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SideBySideEdge"]>
};
	["SideBySideEdge"]: {
		node: ModelTypes["SideBySide"]
};
	["HomePageLokalConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HomePageLokalEdge"]>
};
	["HomePageLokalEdge"]: {
		node: ModelTypes["HomePageLokal"]
};
	["SiteSwitcherConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SiteSwitcherEdge"]>
};
	["SiteSwitcherEdge"]: {
		node: ModelTypes["SiteSwitcher"]
};
	["JobsFormConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobsFormEdge"]>
};
	["JobsFormEdge"]: {
		node: ModelTypes["JobsForm"]
};
	["ImageTextListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageTextListEdge"]>
};
	["ImageTextListEdge"]: {
		node: ModelTypes["ImageTextList"]
};
	["JobsFilterConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobsFilterEdge"]>
};
	["JobsFilterEdge"]: {
		node: ModelTypes["JobsFilter"]
};
	["File"]: {
		_meta?: ModelTypes["FileMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	size?: number | undefined,
	type?: string | undefined
};
	["FileMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined
};
	["FileUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FileWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FileWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FileWhere"] | undefined> | undefined,
	not?: ModelTypes["FileWhere"] | undefined
};
	["FileOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined
};
	["FileConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FileEdge"]>
};
	["FileEdge"]: {
		node: ModelTypes["File"]
};
	["_ValidationResult"]: {
		valid: boolean,
	errors: Array<ModelTypes["_ValidationError"]>
};
	["_ValidationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	message: ModelTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field: string
};
	["_IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
		text: string
};
	["FileCreateInput"]: {
	url?: string | undefined,
	size?: number | undefined,
	type?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["JobFormSubscriptionCreateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	email?: string | undefined,
	message?: string | undefined,
	file?: ModelTypes["JobFormSubscriptionCreateFileEntityRelationInput"] | undefined,
	job?: ModelTypes["JobFormSubscriptionCreateJobEntityRelationInput"] | undefined,
	status?: ModelTypes["JobFormSubscriptionCreateStatusEntityRelationInput"] | undefined,
	isSyncedToTabidoo?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["JobFormSubscriptionCreateFileEntityRelationInput"]: {
	connect?: ModelTypes["FileUniqueWhere"] | undefined,
	create?: ModelTypes["FileCreateInput"] | undefined,
	connectOrCreate?: ModelTypes["JobFormSubscriptionConnectOrCreateFileRelationInput"] | undefined
};
	["JobFormSubscriptionConnectOrCreateFileRelationInput"]: {
	connect: ModelTypes["FileUniqueWhere"],
	create: ModelTypes["FileCreateInput"]
};
	["JobFormSubscriptionCreateJobEntityRelationInput"]: {
	connect?: ModelTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["JobFormSubscriptionCreateStatusEntityRelationInput"]: {
	connect?: ModelTypes["JobFormSubscriptionStatusUniqueWhere"] | undefined
};
	["JobFormSubscriptionStatusUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ImageList"]: {
		_meta?: ModelTypes["ImageListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ImageListItem"]>,
	itemsByImage?: ModelTypes["ImageListItem"] | undefined,
	paginateItems: ModelTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: ModelTypes["ImageListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["ImageList"] | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ImageListItemWhere"] | undefined,
	and?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["ImageListWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListItemWhere"] | undefined
};
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ImageListOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: ModelTypes["ImageListItem"]
};
	["ImageListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ImageListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: ModelTypes["ImageList"]
};
	["JobFormSubscriptionStatus"]: {
		_meta?: ModelTypes["JobFormSubscriptionStatusMeta"] | undefined,
	id: ModelTypes["UUID"],
	statusTitle?: string | undefined,
	statusColor?: string | undefined
};
	["JobFormSubscriptionStatusMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	statusTitle?: ModelTypes["FieldMeta"] | undefined,
	statusColor?: ModelTypes["FieldMeta"] | undefined
};
	["JobFormSubscriptionStatusWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	statusTitle?: ModelTypes["StringCondition"] | undefined,
	statusColor?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["JobFormSubscriptionStatusWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["JobFormSubscriptionStatusWhere"] | undefined> | undefined,
	not?: ModelTypes["JobFormSubscriptionStatusWhere"] | undefined
};
	["JobFormSubscriptionStatusOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	statusTitle?: ModelTypes["OrderDirection"] | undefined,
	statusColor?: ModelTypes["OrderDirection"] | undefined
};
	["JobFormSubscriptionStatusConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["JobFormSubscriptionStatusEdge"]>
};
	["JobFormSubscriptionStatusEdge"]: {
		node: ModelTypes["JobFormSubscriptionStatus"]
};
	["StickyBarUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["StickyBarConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["StickyBarEdge"]>
};
	["StickyBarEdge"]: {
		node: ModelTypes["StickyBar"]
};
	["StickyBarCreateInput"]: {
	title?: string | undefined,
	link?: ModelTypes["StickyBarCreateLinkEntityRelationInput"] | undefined,
	formButtonLabel?: string | undefined,
	jobsForm?: ModelTypes["StickyBarCreateJobsFormEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["StickyBarCreateLinkEntityRelationInput"]: {
	connect?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["StickyBarCreateJobsFormEntityRelationInput"]: {
	connect?: ModelTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["RecommendationFormConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RecommendationFormEdge"]>
};
	["RecommendationFormEdge"]: {
		node: ModelTypes["RecommendationForm"]
};
	["RecommendationFormSubscriptionCreateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	yourEmail?: string | undefined,
	theirEmail?: string | undefined,
	yourName?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["InfoBoxConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["InfoBoxEdge"]>
};
	["InfoBoxEdge"]: {
		node: ModelTypes["InfoBox"]
};
	["InfoBoxCreateInput"]: {
	logoSize?: ModelTypes["LogoSize"] | undefined,
	logo?: ModelTypes["InfoBoxCreateLogoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["InfoBoxCreateLogoEntityRelationInput"]: {
	connect?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["QueryTransaction"]: {
		schema?: ModelTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getContentReferenceLinkableItem?: ModelTypes["ContentReferenceLinkableItem"] | undefined,
	listContentReferenceLinkableItem: Array<ModelTypes["ContentReferenceLinkableItem"]>,
	paginateContentReferenceLinkableItem: ModelTypes["ContentReferenceLinkableItemConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterLink?: ModelTypes["FooterLink"] | undefined,
	listFooterLink: Array<ModelTypes["FooterLink"]>,
	paginateFooterLink: ModelTypes["FooterLinkConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getSeoDefault?: ModelTypes["SeoDefault"] | undefined,
	listSeoDefault: Array<ModelTypes["SeoDefault"]>,
	paginateSeoDefault: ModelTypes["SeoDefaultConnection"],
	getSeoDefaultLocale?: ModelTypes["SeoDefaultLocale"] | undefined,
	listSeoDefaultLocale: Array<ModelTypes["SeoDefaultLocale"]>,
	paginateSeoDefaultLocale: ModelTypes["SeoDefaultLocaleConnection"],
	getBusiness?: ModelTypes["Business"] | undefined,
	listBusiness: Array<ModelTypes["Business"]>,
	paginateBusiness: ModelTypes["BusinessConnection"],
	getBusinessPage?: ModelTypes["BusinessPage"] | undefined,
	listBusinessPage: Array<ModelTypes["BusinessPage"]>,
	paginateBusinessPage: ModelTypes["BusinessPageConnection"],
	getBusinessPageLocale?: ModelTypes["BusinessPageLocale"] | undefined,
	listBusinessPageLocale: Array<ModelTypes["BusinessPageLocale"]>,
	paginateBusinessPageLocale: ModelTypes["BusinessPageLocaleConnection"],
	getIcon?: ModelTypes["Icon"] | undefined,
	listIcon: Array<ModelTypes["Icon"]>,
	paginateIcon: ModelTypes["IconConnection"],
	getJobPage?: ModelTypes["JobPage"] | undefined,
	listJobPage: Array<ModelTypes["JobPage"]>,
	paginateJobPage: ModelTypes["JobPageConnection"],
	getJobPageLocale?: ModelTypes["JobPageLocale"] | undefined,
	listJobPageLocale: Array<ModelTypes["JobPageLocale"]>,
	paginateJobPageLocale: ModelTypes["JobPageLocaleConnection"],
	getKeyInfoItem?: ModelTypes["KeyInfoItem"] | undefined,
	listKeyInfoItem: Array<ModelTypes["KeyInfoItem"]>,
	paginateKeyInfoItem: ModelTypes["KeyInfoItemConnection"],
	getKeyInfoList?: ModelTypes["KeyInfoList"] | undefined,
	listKeyInfoList: Array<ModelTypes["KeyInfoList"]>,
	paginateKeyInfoList: ModelTypes["KeyInfoListConnection"],
	getContractType?: ModelTypes["ContractType"] | undefined,
	listContractType: Array<ModelTypes["ContractType"]>,
	paginateContractType: ModelTypes["ContractTypeConnection"],
	getContractTypeLocale?: ModelTypes["ContractTypeLocale"] | undefined,
	listContractTypeLocale: Array<ModelTypes["ContractTypeLocale"]>,
	paginateContractTypeLocale: ModelTypes["ContractTypeLocaleConnection"],
	getJobCity?: ModelTypes["JobCity"] | undefined,
	listJobCity: Array<ModelTypes["JobCity"]>,
	paginateJobCity: ModelTypes["JobCityConnection"],
	getJobCityLocale?: ModelTypes["JobCityLocale"] | undefined,
	listJobCityLocale: Array<ModelTypes["JobCityLocale"]>,
	paginateJobCityLocale: ModelTypes["JobCityLocaleConnection"],
	getJobPosition?: ModelTypes["JobPosition"] | undefined,
	listJobPosition: Array<ModelTypes["JobPosition"]>,
	paginateJobPosition: ModelTypes["JobPositionConnection"],
	getJobPositionLocale?: ModelTypes["JobPositionLocale"] | undefined,
	listJobPositionLocale: Array<ModelTypes["JobPositionLocale"]>,
	paginateJobPositionLocale: ModelTypes["JobPositionLocaleConnection"],
	getGallery?: ModelTypes["Gallery"] | undefined,
	listGallery: Array<ModelTypes["Gallery"]>,
	paginateGallery: ModelTypes["GalleryConnection"],
	getHomePage?: ModelTypes["HomePage"] | undefined,
	listHomePage: Array<ModelTypes["HomePage"]>,
	paginateHomePage: ModelTypes["HomePageConnection"],
	getHomePageLocale?: ModelTypes["HomePageLocale"] | undefined,
	listHomePageLocale: Array<ModelTypes["HomePageLocale"]>,
	paginateHomePageLocale: ModelTypes["HomePageLocaleConnection"],
	getMedium?: ModelTypes["Medium"] | undefined,
	listMedium: Array<ModelTypes["Medium"]>,
	paginateMedium: ModelTypes["MediumConnection"],
	getMediumItem?: ModelTypes["MediumItem"] | undefined,
	listMediumItem: Array<ModelTypes["MediumItem"]>,
	paginateMediumItem: ModelTypes["MediumItemConnection"],
	getMediumList?: ModelTypes["MediumList"] | undefined,
	listMediumList: Array<ModelTypes["MediumList"]>,
	paginateMediumList: ModelTypes["MediumListConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getTextItem?: ModelTypes["TextItem"] | undefined,
	listTextItem: Array<ModelTypes["TextItem"]>,
	paginateTextItem: ModelTypes["TextItemConnection"],
	getTextList?: ModelTypes["TextList"] | undefined,
	listTextList: Array<ModelTypes["TextList"]>,
	paginateTextList: ModelTypes["TextListConnection"],
	getCarousel?: ModelTypes["Carousel"] | undefined,
	listCarousel: Array<ModelTypes["Carousel"]>,
	paginateCarousel: ModelTypes["CarouselConnection"],
	getCarouselItem?: ModelTypes["CarouselItem"] | undefined,
	listCarouselItem: Array<ModelTypes["CarouselItem"]>,
	paginateCarouselItem: ModelTypes["CarouselItemConnection"],
	getTheme?: ModelTypes["Theme"] | undefined,
	listTheme: Array<ModelTypes["Theme"]>,
	paginateTheme: ModelTypes["ThemeConnection"],
	getBonusBanner?: ModelTypes["BonusBanner"] | undefined,
	listBonusBanner: Array<ModelTypes["BonusBanner"]>,
	paginateBonusBanner: ModelTypes["BonusBannerConnection"],
	getBonusBannerLocale?: ModelTypes["BonusBannerLocale"] | undefined,
	listBonusBannerLocale: Array<ModelTypes["BonusBannerLocale"]>,
	paginateBonusBannerLocale: ModelTypes["BonusBannerLocaleConnection"],
	getTile?: ModelTypes["Tile"] | undefined,
	listTile: Array<ModelTypes["Tile"]>,
	paginateTile: ModelTypes["TileConnection"],
	getSideBySide?: ModelTypes["SideBySide"] | undefined,
	listSideBySide: Array<ModelTypes["SideBySide"]>,
	paginateSideBySide: ModelTypes["SideBySideConnection"],
	getSideBySideItem?: ModelTypes["SideBySideItem"] | undefined,
	listSideBySideItem: Array<ModelTypes["SideBySideItem"]>,
	paginateSideBySideItem: ModelTypes["SideBySideItemConnection"],
	getHomePageLokal?: ModelTypes["HomePageLokal"] | undefined,
	listHomePageLokal: Array<ModelTypes["HomePageLokal"]>,
	paginateHomePageLokal: ModelTypes["HomePageLokalConnection"],
	getHomePageLokalLocale?: ModelTypes["HomePageLokalLocale"] | undefined,
	listHomePageLokalLocale: Array<ModelTypes["HomePageLokalLocale"]>,
	paginateHomePageLokalLocale: ModelTypes["HomePageLokalLocaleConnection"],
	getSiteSwitcher?: ModelTypes["SiteSwitcher"] | undefined,
	listSiteSwitcher: Array<ModelTypes["SiteSwitcher"]>,
	paginateSiteSwitcher: ModelTypes["SiteSwitcherConnection"],
	getGalleryItem?: ModelTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<ModelTypes["GalleryItem"]>,
	paginateGalleryItem: ModelTypes["GalleryItemConnection"],
	getJobsForm?: ModelTypes["JobsForm"] | undefined,
	listJobsForm: Array<ModelTypes["JobsForm"]>,
	paginateJobsForm: ModelTypes["JobsFormConnection"],
	getJobsFormLocale?: ModelTypes["JobsFormLocale"] | undefined,
	listJobsFormLocale: Array<ModelTypes["JobsFormLocale"]>,
	paginateJobsFormLocale: ModelTypes["JobsFormLocaleConnection"],
	getImageTextItem?: ModelTypes["ImageTextItem"] | undefined,
	listImageTextItem: Array<ModelTypes["ImageTextItem"]>,
	paginateImageTextItem: ModelTypes["ImageTextItemConnection"],
	getImageTextList?: ModelTypes["ImageTextList"] | undefined,
	listImageTextList: Array<ModelTypes["ImageTextList"]>,
	paginateImageTextList: ModelTypes["ImageTextListConnection"],
	getJobsFilter?: ModelTypes["JobsFilter"] | undefined,
	listJobsFilter: Array<ModelTypes["JobsFilter"]>,
	paginateJobsFilter: ModelTypes["JobsFilterConnection"],
	getJobsFilterLocale?: ModelTypes["JobsFilterLocale"] | undefined,
	listJobsFilterLocale: Array<ModelTypes["JobsFilterLocale"]>,
	paginateJobsFilterLocale: ModelTypes["JobsFilterLocaleConnection"],
	getFile?: ModelTypes["File"] | undefined,
	listFile: Array<ModelTypes["File"]>,
	paginateFile: ModelTypes["FileConnection"],
	validateCreateFile: ModelTypes["_ValidationResult"],
	validateCreateJobFormSubscription: ModelTypes["_ValidationResult"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getJobFormSubscriptionStatus?: ModelTypes["JobFormSubscriptionStatus"] | undefined,
	listJobFormSubscriptionStatus: Array<ModelTypes["JobFormSubscriptionStatus"]>,
	paginateJobFormSubscriptionStatus: ModelTypes["JobFormSubscriptionStatusConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getStickyBar?: ModelTypes["StickyBar"] | undefined,
	listStickyBar: Array<ModelTypes["StickyBar"]>,
	paginateStickyBar: ModelTypes["StickyBarConnection"],
	validateCreateStickyBar: ModelTypes["_ValidationResult"],
	getRecommendationForm?: ModelTypes["RecommendationForm"] | undefined,
	listRecommendationForm: Array<ModelTypes["RecommendationForm"]>,
	paginateRecommendationForm: ModelTypes["RecommendationFormConnection"],
	getRecommendationFormLocale?: ModelTypes["RecommendationFormLocale"] | undefined,
	listRecommendationFormLocale: Array<ModelTypes["RecommendationFormLocale"]>,
	paginateRecommendationFormLocale: ModelTypes["RecommendationFormLocaleConnection"],
	validateCreateRecommendationFormSubscription: ModelTypes["_ValidationResult"],
	getInfoBox?: ModelTypes["InfoBox"] | undefined,
	listInfoBox: Array<ModelTypes["InfoBox"]>,
	paginateInfoBox: ModelTypes["InfoBoxConnection"],
	validateCreateInfoBox: ModelTypes["_ValidationResult"],
	getInfoBoxLocale?: ModelTypes["InfoBoxLocale"] | undefined,
	listInfoBoxLocale: Array<ModelTypes["InfoBoxLocale"]>,
	paginateInfoBoxLocale: ModelTypes["InfoBoxLocaleConnection"]
};
	["Info"]: {
		description?: string | undefined
};
	["Mutation"]: {
		generateUploadUrl: ModelTypes["S3SignedUpload"],
	generateReadUrl: ModelTypes["S3SignedRead"],
	createFile: ModelTypes["FileCreateResult"],
	createJobFormSubscription: ModelTypes["JobFormSubscriptionCreateResult"],
	createStickyBar: ModelTypes["StickyBarCreateResult"],
	createRecommendationFormSubscription: ModelTypes["RecommendationFormSubscriptionCreateResult"],
	createInfoBox: ModelTypes["InfoBoxCreateResult"],
	transaction: ModelTypes["MutationTransaction"],
	query: ModelTypes["Query"]
};
	["S3SignedUpload"]: {
		url: string,
	headers: Array<ModelTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string,
	publicUrl: string
};
	["S3Header"]: {
		key: string,
	value: string
};
	["S3GenerateSignedUploadInput"]: {
	contentType?: string | undefined,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined,
	/** Signed URL expiration. */
	expiration?: number | undefined,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined,
	/** Can be used as a "directory". */
	prefix?: string | undefined,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined,
	contentDisposition?: ModelTypes["S33ContentDisposition"] | undefined,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: ModelTypes["S3Acl"] | undefined
};
	["S33ContentDisposition"]:S33ContentDisposition;
	["S3Acl"]:S3Acl;
	["S3SignedRead"]: {
		url: string,
	headers: Array<ModelTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string
};
	["FileCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["File"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["FileCreateResult"] | ModelTypes["JobFormSubscriptionCreateResult"] | ModelTypes["StickyBarCreateResult"] | ModelTypes["RecommendationFormSubscriptionCreateResult"] | ModelTypes["InfoBoxCreateResult"];
	["_MutationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	paths: Array<Array<ModelTypes["_PathFragment"]>>,
	type: ModelTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]:_MutationErrorType;
	["JobFormSubscriptionCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"]
};
	["StickyBarCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["StickyBar"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["RecommendationFormSubscriptionCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"]
};
	["InfoBoxCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["InfoBox"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"],
	generateUploadUrl: ModelTypes["S3SignedUpload"],
	generateReadUrl: ModelTypes["S3SignedRead"],
	createFile: ModelTypes["FileCreateResult"],
	createJobFormSubscription: ModelTypes["JobFormSubscriptionCreateResult"],
	createStickyBar: ModelTypes["StickyBarCreateResult"],
	createRecommendationFormSubscription: ModelTypes["RecommendationFormSubscriptionCreateResult"],
	createInfoBox: ModelTypes["InfoBoxCreateResult"],
	query?: ModelTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined
}
    }

export type GraphQLTypes = {
    ["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	/** Json custom scalar type */
["Json"]: "scalar" & { name: "Json" };
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_RelationSide"]: _RelationSide;
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_OrderByDirection"]: _OrderByDirection;
	["Query"]: {
	__typename: "Query",
	schema?: GraphQLTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getContentReferenceLinkableItem?: GraphQLTypes["ContentReferenceLinkableItem"] | undefined,
	listContentReferenceLinkableItem: Array<GraphQLTypes["ContentReferenceLinkableItem"]>,
	paginateContentReferenceLinkableItem: GraphQLTypes["ContentReferenceLinkableItemConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterLink?: GraphQLTypes["FooterLink"] | undefined,
	listFooterLink: Array<GraphQLTypes["FooterLink"]>,
	paginateFooterLink: GraphQLTypes["FooterLinkConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getSeoDefault?: GraphQLTypes["SeoDefault"] | undefined,
	listSeoDefault: Array<GraphQLTypes["SeoDefault"]>,
	paginateSeoDefault: GraphQLTypes["SeoDefaultConnection"],
	getSeoDefaultLocale?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	listSeoDefaultLocale: Array<GraphQLTypes["SeoDefaultLocale"]>,
	paginateSeoDefaultLocale: GraphQLTypes["SeoDefaultLocaleConnection"],
	getBusiness?: GraphQLTypes["Business"] | undefined,
	listBusiness: Array<GraphQLTypes["Business"]>,
	paginateBusiness: GraphQLTypes["BusinessConnection"],
	getBusinessPage?: GraphQLTypes["BusinessPage"] | undefined,
	listBusinessPage: Array<GraphQLTypes["BusinessPage"]>,
	paginateBusinessPage: GraphQLTypes["BusinessPageConnection"],
	getBusinessPageLocale?: GraphQLTypes["BusinessPageLocale"] | undefined,
	listBusinessPageLocale: Array<GraphQLTypes["BusinessPageLocale"]>,
	paginateBusinessPageLocale: GraphQLTypes["BusinessPageLocaleConnection"],
	getIcon?: GraphQLTypes["Icon"] | undefined,
	listIcon: Array<GraphQLTypes["Icon"]>,
	paginateIcon: GraphQLTypes["IconConnection"],
	getJobPage?: GraphQLTypes["JobPage"] | undefined,
	listJobPage: Array<GraphQLTypes["JobPage"]>,
	paginateJobPage: GraphQLTypes["JobPageConnection"],
	getJobPageLocale?: GraphQLTypes["JobPageLocale"] | undefined,
	listJobPageLocale: Array<GraphQLTypes["JobPageLocale"]>,
	paginateJobPageLocale: GraphQLTypes["JobPageLocaleConnection"],
	getKeyInfoItem?: GraphQLTypes["KeyInfoItem"] | undefined,
	listKeyInfoItem: Array<GraphQLTypes["KeyInfoItem"]>,
	paginateKeyInfoItem: GraphQLTypes["KeyInfoItemConnection"],
	getKeyInfoList?: GraphQLTypes["KeyInfoList"] | undefined,
	listKeyInfoList: Array<GraphQLTypes["KeyInfoList"]>,
	paginateKeyInfoList: GraphQLTypes["KeyInfoListConnection"],
	getContractType?: GraphQLTypes["ContractType"] | undefined,
	listContractType: Array<GraphQLTypes["ContractType"]>,
	paginateContractType: GraphQLTypes["ContractTypeConnection"],
	getContractTypeLocale?: GraphQLTypes["ContractTypeLocale"] | undefined,
	listContractTypeLocale: Array<GraphQLTypes["ContractTypeLocale"]>,
	paginateContractTypeLocale: GraphQLTypes["ContractTypeLocaleConnection"],
	getJobCity?: GraphQLTypes["JobCity"] | undefined,
	listJobCity: Array<GraphQLTypes["JobCity"]>,
	paginateJobCity: GraphQLTypes["JobCityConnection"],
	getJobCityLocale?: GraphQLTypes["JobCityLocale"] | undefined,
	listJobCityLocale: Array<GraphQLTypes["JobCityLocale"]>,
	paginateJobCityLocale: GraphQLTypes["JobCityLocaleConnection"],
	getJobPosition?: GraphQLTypes["JobPosition"] | undefined,
	listJobPosition: Array<GraphQLTypes["JobPosition"]>,
	paginateJobPosition: GraphQLTypes["JobPositionConnection"],
	getJobPositionLocale?: GraphQLTypes["JobPositionLocale"] | undefined,
	listJobPositionLocale: Array<GraphQLTypes["JobPositionLocale"]>,
	paginateJobPositionLocale: GraphQLTypes["JobPositionLocaleConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getHomePage?: GraphQLTypes["HomePage"] | undefined,
	listHomePage: Array<GraphQLTypes["HomePage"]>,
	paginateHomePage: GraphQLTypes["HomePageConnection"],
	getHomePageLocale?: GraphQLTypes["HomePageLocale"] | undefined,
	listHomePageLocale: Array<GraphQLTypes["HomePageLocale"]>,
	paginateHomePageLocale: GraphQLTypes["HomePageLocaleConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getMediumItem?: GraphQLTypes["MediumItem"] | undefined,
	listMediumItem: Array<GraphQLTypes["MediumItem"]>,
	paginateMediumItem: GraphQLTypes["MediumItemConnection"],
	getMediumList?: GraphQLTypes["MediumList"] | undefined,
	listMediumList: Array<GraphQLTypes["MediumList"]>,
	paginateMediumList: GraphQLTypes["MediumListConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getCarousel?: GraphQLTypes["Carousel"] | undefined,
	listCarousel: Array<GraphQLTypes["Carousel"]>,
	paginateCarousel: GraphQLTypes["CarouselConnection"],
	getCarouselItem?: GraphQLTypes["CarouselItem"] | undefined,
	listCarouselItem: Array<GraphQLTypes["CarouselItem"]>,
	paginateCarouselItem: GraphQLTypes["CarouselItemConnection"],
	getTheme?: GraphQLTypes["Theme"] | undefined,
	listTheme: Array<GraphQLTypes["Theme"]>,
	paginateTheme: GraphQLTypes["ThemeConnection"],
	getBonusBanner?: GraphQLTypes["BonusBanner"] | undefined,
	listBonusBanner: Array<GraphQLTypes["BonusBanner"]>,
	paginateBonusBanner: GraphQLTypes["BonusBannerConnection"],
	getBonusBannerLocale?: GraphQLTypes["BonusBannerLocale"] | undefined,
	listBonusBannerLocale: Array<GraphQLTypes["BonusBannerLocale"]>,
	paginateBonusBannerLocale: GraphQLTypes["BonusBannerLocaleConnection"],
	getTile?: GraphQLTypes["Tile"] | undefined,
	listTile: Array<GraphQLTypes["Tile"]>,
	paginateTile: GraphQLTypes["TileConnection"],
	getSideBySide?: GraphQLTypes["SideBySide"] | undefined,
	listSideBySide: Array<GraphQLTypes["SideBySide"]>,
	paginateSideBySide: GraphQLTypes["SideBySideConnection"],
	getSideBySideItem?: GraphQLTypes["SideBySideItem"] | undefined,
	listSideBySideItem: Array<GraphQLTypes["SideBySideItem"]>,
	paginateSideBySideItem: GraphQLTypes["SideBySideItemConnection"],
	getHomePageLokal?: GraphQLTypes["HomePageLokal"] | undefined,
	listHomePageLokal: Array<GraphQLTypes["HomePageLokal"]>,
	paginateHomePageLokal: GraphQLTypes["HomePageLokalConnection"],
	getHomePageLokalLocale?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	listHomePageLokalLocale: Array<GraphQLTypes["HomePageLokalLocale"]>,
	paginateHomePageLokalLocale: GraphQLTypes["HomePageLokalLocaleConnection"],
	getSiteSwitcher?: GraphQLTypes["SiteSwitcher"] | undefined,
	listSiteSwitcher: Array<GraphQLTypes["SiteSwitcher"]>,
	paginateSiteSwitcher: GraphQLTypes["SiteSwitcherConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getJobsForm?: GraphQLTypes["JobsForm"] | undefined,
	listJobsForm: Array<GraphQLTypes["JobsForm"]>,
	paginateJobsForm: GraphQLTypes["JobsFormConnection"],
	getJobsFormLocale?: GraphQLTypes["JobsFormLocale"] | undefined,
	listJobsFormLocale: Array<GraphQLTypes["JobsFormLocale"]>,
	paginateJobsFormLocale: GraphQLTypes["JobsFormLocaleConnection"],
	getImageTextItem?: GraphQLTypes["ImageTextItem"] | undefined,
	listImageTextItem: Array<GraphQLTypes["ImageTextItem"]>,
	paginateImageTextItem: GraphQLTypes["ImageTextItemConnection"],
	getImageTextList?: GraphQLTypes["ImageTextList"] | undefined,
	listImageTextList: Array<GraphQLTypes["ImageTextList"]>,
	paginateImageTextList: GraphQLTypes["ImageTextListConnection"],
	getJobsFilter?: GraphQLTypes["JobsFilter"] | undefined,
	listJobsFilter: Array<GraphQLTypes["JobsFilter"]>,
	paginateJobsFilter: GraphQLTypes["JobsFilterConnection"],
	getJobsFilterLocale?: GraphQLTypes["JobsFilterLocale"] | undefined,
	listJobsFilterLocale: Array<GraphQLTypes["JobsFilterLocale"]>,
	paginateJobsFilterLocale: GraphQLTypes["JobsFilterLocaleConnection"],
	getFile?: GraphQLTypes["File"] | undefined,
	listFile: Array<GraphQLTypes["File"]>,
	paginateFile: GraphQLTypes["FileConnection"],
	validateCreateFile: GraphQLTypes["_ValidationResult"],
	validateCreateJobFormSubscription: GraphQLTypes["_ValidationResult"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getJobFormSubscriptionStatus?: GraphQLTypes["JobFormSubscriptionStatus"] | undefined,
	listJobFormSubscriptionStatus: Array<GraphQLTypes["JobFormSubscriptionStatus"]>,
	paginateJobFormSubscriptionStatus: GraphQLTypes["JobFormSubscriptionStatusConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getStickyBar?: GraphQLTypes["StickyBar"] | undefined,
	listStickyBar: Array<GraphQLTypes["StickyBar"]>,
	paginateStickyBar: GraphQLTypes["StickyBarConnection"],
	validateCreateStickyBar: GraphQLTypes["_ValidationResult"],
	getRecommendationForm?: GraphQLTypes["RecommendationForm"] | undefined,
	listRecommendationForm: Array<GraphQLTypes["RecommendationForm"]>,
	paginateRecommendationForm: GraphQLTypes["RecommendationFormConnection"],
	getRecommendationFormLocale?: GraphQLTypes["RecommendationFormLocale"] | undefined,
	listRecommendationFormLocale: Array<GraphQLTypes["RecommendationFormLocale"]>,
	paginateRecommendationFormLocale: GraphQLTypes["RecommendationFormLocaleConnection"],
	validateCreateRecommendationFormSubscription: GraphQLTypes["_ValidationResult"],
	getInfoBox?: GraphQLTypes["InfoBox"] | undefined,
	listInfoBox: Array<GraphQLTypes["InfoBox"]>,
	paginateInfoBox: GraphQLTypes["InfoBoxConnection"],
	validateCreateInfoBox: GraphQLTypes["_ValidationResult"],
	getInfoBoxLocale?: GraphQLTypes["InfoBoxLocale"] | undefined,
	listInfoBoxLocale: Array<GraphQLTypes["InfoBoxLocale"]>,
	paginateInfoBoxLocale: GraphQLTypes["InfoBoxLocaleConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined
};
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["General"]: {
	__typename: "General",
	_meta?: GraphQLTypes["GeneralMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["GeneralLocale"]>,
	localesByLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	paginateLocales: GraphQLTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
	__typename: "GeneralMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: "scalar" & { name: "UUID" };
	["One"]: One;
	["GeneralLocale"]: {
	__typename: "GeneralLocale",
	_meta?: GraphQLTypes["GeneralLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["General"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined
};
	["GeneralLocaleMeta"]: {
	__typename: "GeneralLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined
};
	["GeneralWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["GeneralLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["GeneralWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	footers?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	seoDefaults?: GraphQLTypes["SeoDefaultLocaleWhere"] | undefined,
	businessPages?: GraphQLTypes["BusinessPageLocaleWhere"] | undefined,
	jobPages?: GraphQLTypes["JobPageLocaleWhere"] | undefined,
	contractTypes?: GraphQLTypes["ContractTypeLocaleWhere"] | undefined,
	jobCities?: GraphQLTypes["JobCityLocaleWhere"] | undefined,
	jobPositions?: GraphQLTypes["JobPositionLocaleWhere"] | undefined,
	homePages?: GraphQLTypes["HomePageLocaleWhere"] | undefined,
	bonusBanners?: GraphQLTypes["BonusBannerLocaleWhere"] | undefined,
	homePageLokals?: GraphQLTypes["HomePageLokalLocaleWhere"] | undefined,
	jobsForms?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	jobsFilters?: GraphQLTypes["JobsFilterLocaleWhere"] | undefined,
	general?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	recommendationForms?: GraphQLTypes["RecommendationFormLocaleWhere"] | undefined,
	infoBoxes?: GraphQLTypes["InfoBoxLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["FooterLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	address?: GraphQLTypes["StringCondition"] | undefined,
	ico?: GraphQLTypes["StringCondition"] | undefined,
	dic?: GraphQLTypes["StringCondition"] | undefined,
	copyright?: GraphQLTypes["StringCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	noteSecond?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["FooterWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	linksFirstColumn?: GraphQLTypes["FooterLinkWhere"] | undefined,
	linksSecondColumn?: GraphQLTypes["FooterLinkWhere"] | undefined,
	termsAndConditions?: GraphQLTypes["LinkWhere"] | undefined,
	form?: GraphQLTypes["StringCondition"] | undefined,
	bannerTitle?: GraphQLTypes["StringCondition"] | undefined,
	bannerSubtitle?: GraphQLTypes["StringCondition"] | undefined,
	bannerImage?: GraphQLTypes["ImageWhere"] | undefined,
	bannerLink?: GraphQLTypes["LinkWhere"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	copyrightLink?: GraphQLTypes["LinkWhere"] | undefined,
	bannerSecondBtn?: GraphQLTypes["StringCondition"] | undefined,
	recommendationForm?: GraphQLTypes["RecommendationFormLocaleWhere"] | undefined,
	bannerSecondLink?: GraphQLTypes["LinkWhere"] | undefined,
	instagramLink?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterLocaleWhere"] | undefined
};
	["FooterWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterWhere"] | undefined
};
	["FooterLinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterLinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterLinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterLinkWhere"] | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	anchorLink?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	businessPage?: GraphQLTypes["BusinessPageLocaleWhere"] | undefined,
	jobPage?: GraphQLTypes["JobPageLocaleWhere"] | undefined,
	homePage?: GraphQLTypes["HomePageLocaleWhere"] | undefined,
	homePageLokal?: GraphQLTypes["HomePageLokalLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["GenericPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["GenericPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageLocaleWhere"] | undefined
};
	["GenericPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	and?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageWhere"] | undefined
};
	["ThemeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	backgroundColor?: GraphQLTypes["StringCondition"] | undefined,
	textColor?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	buttonBackgroundColor?: GraphQLTypes["StringCondition"] | undefined,
	buttonTextColor?: GraphQLTypes["StringCondition"] | undefined,
	buttonBackgroundHoverColor?: GraphQLTypes["StringCondition"] | undefined,
	buttonTextHoverColor?: GraphQLTypes["StringCondition"] | undefined,
	buttonBorderColor?: GraphQLTypes["StringCondition"] | undefined,
	buttonBorderHoverColor?: GraphQLTypes["StringCondition"] | undefined,
	headerMenuColor?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ThemeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ThemeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ThemeWhere"] | undefined
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	linkables?: GraphQLTypes["ContentReferenceLinkableItemWhere"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	media?: GraphQLTypes["MediumListWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	carousel?: GraphQLTypes["CarouselWhere"] | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocaleWhere"] | undefined,
	sideBySide?: GraphQLTypes["SideBySideWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	showBoolean?: GraphQLTypes["BooleanCondition"] | undefined,
	isRed?: GraphQLTypes["BooleanCondition"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	fileName?: GraphQLTypes["StringCondition"] | undefined,
	focalPointX?: GraphQLTypes["FloatCondition"] | undefined,
	focalPointY?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ContentReferenceLinkableItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	item?: GraphQLTypes["LinkableWhere"] | undefined,
	reference?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceLinkableItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceLinkableItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceLinkableItemWhere"] | undefined
};
	["MediumListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["MediumItemWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumListWhere"] | undefined
};
	["MediumItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["MediumListWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumItemWhere"] | undefined
};
	["MediumWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["MediumTypeCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumWhere"] | undefined
};
	["MediumTypeCondition"]: {
		and?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	not?: GraphQLTypes["MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MediumType"] | undefined,
	notEq?: GraphQLTypes["MediumType"] | undefined,
	in?: Array<GraphQLTypes["MediumType"]> | undefined,
	notIn?: Array<GraphQLTypes["MediumType"]> | undefined,
	lt?: GraphQLTypes["MediumType"] | undefined,
	lte?: GraphQLTypes["MediumType"] | undefined,
	gt?: GraphQLTypes["MediumType"] | undefined,
	gte?: GraphQLTypes["MediumType"] | undefined
};
	["MediumType"]: MediumType;
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	videoInfo?: GraphQLTypes["StringCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	poster?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["TextListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextListWhere"] | undefined
};
	["TextItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["TextListWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextItemWhere"] | undefined
};
	["CarouselWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["CarouselItemWhere"] | undefined,
	background?: GraphQLTypes["BackgroundCondition"] | undefined,
	squareImages?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["CarouselWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CarouselWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CarouselWhere"] | undefined
};
	["CarouselItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["CarouselWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	linkUrl?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["CarouselItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CarouselItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CarouselItemWhere"] | undefined
};
	["BackgroundCondition"]: {
		and?: Array<GraphQLTypes["BackgroundCondition"]> | undefined,
	or?: Array<GraphQLTypes["BackgroundCondition"]> | undefined,
	not?: GraphQLTypes["BackgroundCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Background"] | undefined,
	notEq?: GraphQLTypes["Background"] | undefined,
	in?: Array<GraphQLTypes["Background"]> | undefined,
	notIn?: Array<GraphQLTypes["Background"]> | undefined,
	lt?: GraphQLTypes["Background"] | undefined,
	lte?: GraphQLTypes["Background"] | undefined,
	gt?: GraphQLTypes["Background"] | undefined,
	gte?: GraphQLTypes["Background"] | undefined
};
	["Background"]: Background;
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["BonusBannerLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BonusBannerWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	tiles?: GraphQLTypes["TileWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	bannerName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BonusBannerLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BonusBannerLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BonusBannerLocaleWhere"] | undefined
};
	["BonusBannerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["BonusBannerLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BonusBannerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BonusBannerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BonusBannerWhere"] | undefined
};
	["TileWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocaleWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	lightBoxTitle?: GraphQLTypes["StringCondition"] | undefined,
	lightBoxText?: GraphQLTypes["StringCondition"] | undefined,
	ligthBoxMedia?: GraphQLTypes["MediumListWhere"] | undefined,
	lightBoxSectionTitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lightBoxLinkLabel?: GraphQLTypes["StringCondition"] | undefined,
	lightBoxForm?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["TileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TileWhere"] | undefined
};
	["JobsFormLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	jobText?: GraphQLTypes["StringCondition"] | undefined,
	genericText?: GraphQLTypes["StringCondition"] | undefined,
	nameLabel?: GraphQLTypes["StringCondition"] | undefined,
	emailLabel?: GraphQLTypes["StringCondition"] | undefined,
	telefonLabel?: GraphQLTypes["StringCondition"] | undefined,
	fileTitleLabel?: GraphQLTypes["StringCondition"] | undefined,
	fileFormatLabel?: GraphQLTypes["StringCondition"] | undefined,
	formTextLabel?: GraphQLTypes["StringCondition"] | undefined,
	submitLabel?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["JobsFormWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	imageTextList?: GraphQLTypes["ImageTextListWhere"] | undefined,
	minimalize?: GraphQLTypes["StringCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	successTitle?: GraphQLTypes["StringCondition"] | undefined,
	successText?: GraphQLTypes["StringCondition"] | undefined,
	successBtnLabel?: GraphQLTypes["StringCondition"] | undefined,
	successImage?: GraphQLTypes["ImageWhere"] | undefined,
	successOtherJobsTitle?: GraphQLTypes["StringCondition"] | undefined,
	successOtherJobsCategoryTitle?: GraphQLTypes["StringCondition"] | undefined,
	successOtherJobsButtonTitle?: GraphQLTypes["StringCondition"] | undefined,
	successOtherJobsSelectionTitle?: GraphQLTypes["StringCondition"] | undefined,
	successOtherJobsSelectionYesButton?: GraphQLTypes["StringCondition"] | undefined,
	successOtherJobsSelectionNoButton?: GraphQLTypes["StringCondition"] | undefined,
	fileRemoveBtnLabel?: GraphQLTypes["StringCondition"] | undefined,
	gdprLabel?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["JobsFormLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobsFormLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobsFormLocaleWhere"] | undefined
};
	["JobsFormWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	fileIlustration?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["JobsFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobsFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobsFormWhere"] | undefined
};
	["ImageTextListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageTextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageTextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageTextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageTextListWhere"] | undefined
};
	["ImageTextItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["ImageTextListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageTextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageTextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageTextItemWhere"] | undefined
};
	["SideBySideWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["SideBySideItemWhere"] | undefined,
	background?: GraphQLTypes["BackgroundCondition"] | undefined,
	alignment?: GraphQLTypes["ContentAlignmentCondition"] | undefined,
	mediumSize?: GraphQLTypes["MediumSizeCondition"] | undefined,
	and?: Array<GraphQLTypes["SideBySideWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SideBySideWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SideBySideWhere"] | undefined
};
	["SideBySideItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["SideBySideWhere"] | undefined,
	media?: GraphQLTypes["MediumListWhere"] | undefined,
	formButtonLabel?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["SideBySideItemTypeCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["SideBySideItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SideBySideItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SideBySideItemWhere"] | undefined
};
	["SideBySideItemTypeCondition"]: {
		and?: Array<GraphQLTypes["SideBySideItemTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["SideBySideItemTypeCondition"]> | undefined,
	not?: GraphQLTypes["SideBySideItemTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["SideBySideItemType"] | undefined,
	notEq?: GraphQLTypes["SideBySideItemType"] | undefined,
	in?: Array<GraphQLTypes["SideBySideItemType"]> | undefined,
	notIn?: Array<GraphQLTypes["SideBySideItemType"]> | undefined,
	lt?: GraphQLTypes["SideBySideItemType"] | undefined,
	lte?: GraphQLTypes["SideBySideItemType"] | undefined,
	gt?: GraphQLTypes["SideBySideItemType"] | undefined,
	gte?: GraphQLTypes["SideBySideItemType"] | undefined
};
	["SideBySideItemType"]: SideBySideItemType;
	["ContentAlignmentCondition"]: {
		and?: Array<GraphQLTypes["ContentAlignmentCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentAlignmentCondition"]> | undefined,
	not?: GraphQLTypes["ContentAlignmentCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentAlignment"] | undefined,
	notEq?: GraphQLTypes["ContentAlignment"] | undefined,
	in?: Array<GraphQLTypes["ContentAlignment"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentAlignment"]> | undefined,
	lt?: GraphQLTypes["ContentAlignment"] | undefined,
	lte?: GraphQLTypes["ContentAlignment"] | undefined,
	gt?: GraphQLTypes["ContentAlignment"] | undefined,
	gte?: GraphQLTypes["ContentAlignment"] | undefined
};
	["ContentAlignment"]: ContentAlignment;
	["MediumSizeCondition"]: {
		and?: Array<GraphQLTypes["MediumSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MediumSizeCondition"]> | undefined,
	not?: GraphQLTypes["MediumSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MediumSize"] | undefined,
	notEq?: GraphQLTypes["MediumSize"] | undefined,
	in?: Array<GraphQLTypes["MediumSize"]> | undefined,
	notIn?: Array<GraphQLTypes["MediumSize"]> | undefined,
	lt?: GraphQLTypes["MediumSize"] | undefined,
	lte?: GraphQLTypes["MediumSize"] | undefined,
	gt?: GraphQLTypes["MediumSize"] | undefined,
	gte?: GraphQLTypes["MediumSize"] | undefined
};
	["MediumSize"]: MediumSize;
	["GalleryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GalleryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryWhere"] | undefined
};
	["GalleryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["GalleryWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryItemWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	noIndex?: GraphQLTypes["BooleanCondition"] | undefined,
	noFollow?: GraphQLTypes["BooleanCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["BusinessPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["BusinessPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	subtitle?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarWhere"] | undefined,
	infoBox?: GraphQLTypes["InfoBoxWhere"] | undefined,
	and?: Array<GraphQLTypes["BusinessPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessPageLocaleWhere"] | undefined
};
	["BusinessPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["BusinessPageLocaleWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	isLokal?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessPageWhere"] | undefined
};
	["StickyBarWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	formButtonLabel?: GraphQLTypes["StringCondition"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["StickyBarWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StickyBarWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StickyBarWhere"] | undefined
};
	["InfoBoxWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	logoSize?: GraphQLTypes["LogoSizeCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	locales?: GraphQLTypes["InfoBoxLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["InfoBoxWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InfoBoxWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InfoBoxWhere"] | undefined
};
	["LogoSizeCondition"]: {
		and?: Array<GraphQLTypes["LogoSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LogoSizeCondition"]> | undefined,
	not?: GraphQLTypes["LogoSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LogoSize"] | undefined,
	notEq?: GraphQLTypes["LogoSize"] | undefined,
	in?: Array<GraphQLTypes["LogoSize"]> | undefined,
	notIn?: Array<GraphQLTypes["LogoSize"]> | undefined,
	lt?: GraphQLTypes["LogoSize"] | undefined,
	lte?: GraphQLTypes["LogoSize"] | undefined,
	gt?: GraphQLTypes["LogoSize"] | undefined,
	gte?: GraphQLTypes["LogoSize"] | undefined
};
	["LogoSize"]: LogoSize;
	["InfoBoxLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	address?: GraphQLTypes["StringCondition"] | undefined,
	web?: GraphQLTypes["StringCondition"] | undefined,
	instagram?: GraphQLTypes["StringCondition"] | undefined,
	facebook?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["InfoBoxWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	openingHours?: GraphQLTypes["TextListWhere"] | undefined,
	addressLink?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["InfoBoxLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InfoBoxLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InfoBoxLocaleWhere"] | undefined
};
	["JobPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["JobPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	keyInfo?: GraphQLTypes["KeyInfoListWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarWhere"] | undefined,
	mobileImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["JobPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobPageLocaleWhere"] | undefined
};
	["JobPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["JobPageLocaleWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	contractType?: GraphQLTypes["ContractTypeWhere"] | undefined,
	jobCity?: GraphQLTypes["JobCityWhere"] | undefined,
	business?: GraphQLTypes["BusinessPageWhere"] | undefined,
	isActive?: GraphQLTypes["BooleanCondition"] | undefined,
	jobPosition?: GraphQLTypes["JobPositionWhere"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	isSyncedToTabidoo?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["JobPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobPageWhere"] | undefined
};
	["ContractTypeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["ContractTypeLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ContractTypeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContractTypeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContractTypeWhere"] | undefined
};
	["ContractTypeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ContractTypeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ContractTypeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContractTypeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContractTypeLocaleWhere"] | undefined
};
	["JobCityWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["JobCityLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["JobCityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobCityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobCityWhere"] | undefined
};
	["JobCityLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["JobCityWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["JobCityLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobCityLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobCityLocaleWhere"] | undefined
};
	["JobPositionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["JobPositionLocaleWhere"] | undefined,
	jobs?: GraphQLTypes["JobPageWhere"] | undefined,
	and?: Array<GraphQLTypes["JobPositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobPositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobPositionWhere"] | undefined
};
	["JobPositionLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["JobPositionWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["JobPositionLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobPositionLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobPositionLocaleWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["DateTime"]: "scalar" & { name: "DateTime" };
	["KeyInfoListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["KeyInfoItemWhere"] | undefined,
	and?: Array<GraphQLTypes["KeyInfoListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["KeyInfoListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["KeyInfoListWhere"] | undefined
};
	["KeyInfoItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	keyInfo?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["KeyInfoListWhere"] | undefined,
	icon?: GraphQLTypes["IconWhere"] | undefined,
	and?: Array<GraphQLTypes["KeyInfoItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["KeyInfoItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["KeyInfoItemWhere"] | undefined
};
	["IconWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["IconWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IconWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IconWhere"] | undefined
};
	["HomePageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["HomePageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	button?: GraphQLTypes["LinkWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarWhere"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	ilustration?: GraphQLTypes["GalleryWhere"] | undefined,
	and?: Array<GraphQLTypes["HomePageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HomePageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HomePageLocaleWhere"] | undefined
};
	["HomePageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["HomePageLocaleWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	and?: Array<GraphQLTypes["HomePageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HomePageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HomePageWhere"] | undefined
};
	["SiteSwitcherWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	and?: Array<GraphQLTypes["SiteSwitcherWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SiteSwitcherWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SiteSwitcherWhere"] | undefined
};
	["HomePageLokalLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["HomePageLokalWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	button?: GraphQLTypes["LinkWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarWhere"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	ilustration?: GraphQLTypes["GalleryWhere"] | undefined,
	and?: Array<GraphQLTypes["HomePageLokalLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HomePageLokalLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HomePageLokalLocaleWhere"] | undefined
};
	["HomePageLokalWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["HomePageLokalLocaleWhere"] | undefined,
	theme?: GraphQLTypes["ThemeWhere"] | undefined,
	and?: Array<GraphQLTypes["HomePageLokalWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HomePageLokalWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HomePageLokalWhere"] | undefined
};
	["RecommendationFormLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	subtitle?: GraphQLTypes["StringCondition"] | undefined,
	yourEmailLabel?: GraphQLTypes["StringCondition"] | undefined,
	theirEmailLabel?: GraphQLTypes["StringCondition"] | undefined,
	yourName?: GraphQLTypes["StringCondition"] | undefined,
	sendBtnLabel?: GraphQLTypes["StringCondition"] | undefined,
	successTitle?: GraphQLTypes["StringCondition"] | undefined,
	successSubtitle?: GraphQLTypes["StringCondition"] | undefined,
	successButtonClose?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["RecommendationFormWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["RecommendationFormLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RecommendationFormLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RecommendationFormLocaleWhere"] | undefined
};
	["RecommendationFormWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["RecommendationFormLocaleWhere"] | undefined,
	successImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["RecommendationFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RecommendationFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RecommendationFormWhere"] | undefined
};
	["HeaderLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["HeaderWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	menuLabel?: GraphQLTypes["StringCondition"] | undefined,
	menuTitle?: GraphQLTypes["StringCondition"] | undefined,
	menuText?: GraphQLTypes["StringCondition"] | undefined,
	business?: GraphQLTypes["BusinessWhere"] | undefined,
	lokalEnterprise?: GraphQLTypes["BusinessWhere"] | undefined,
	businessSectionTitle?: GraphQLTypes["StringCondition"] | undefined,
	lokalSectionTitle?: GraphQLTypes["StringCondition"] | undefined,
	businessLink?: GraphQLTypes["LinkWhere"] | undefined,
	lokalLink?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	logoMenu?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderWhere"] | undefined
};
	["BusinessWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["BusinessWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessWhere"] | undefined
};
	["SeoDefaultLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["SeoDefaultWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoDefaultLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoDefaultLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoDefaultLocaleWhere"] | undefined
};
	["SeoDefaultWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["SeoDefaultLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoDefaultWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoDefaultWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoDefaultWhere"] | undefined
};
	["JobsFilterLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	jobPositionLabel?: GraphQLTypes["StringCondition"] | undefined,
	jobContractLabel?: GraphQLTypes["StringCondition"] | undefined,
	jobCityLabel?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["JobsFilterWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	bannerTitle?: GraphQLTypes["StringCondition"] | undefined,
	bannerFormLabel?: GraphQLTypes["StringCondition"] | undefined,
	bannerJobsForm?: GraphQLTypes["JobsFormLocaleWhere"] | undefined,
	bannerImage?: GraphQLTypes["ImageWhere"] | undefined,
	noJobTitle?: GraphQLTypes["StringCondition"] | undefined,
	noJobBtnLabel?: GraphQLTypes["StringCondition"] | undefined,
	noJobImage?: GraphQLTypes["ImageWhere"] | undefined,
	noJobTitleForSpecificBusiness?: GraphQLTypes["StringCondition"] | undefined,
	deactivatedJob?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["JobsFilterLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobsFilterLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobsFilterLocaleWhere"] | undefined
};
	["JobsFilterWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["JobsFilterLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["JobsFilterWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobsFilterWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobsFilterWhere"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	label?: string | undefined,
	footers: Array<GraphQLTypes["FooterLocale"]>,
	genericPages: Array<GraphQLTypes["GenericPageLocale"]>,
	headers: Array<GraphQLTypes["HeaderLocale"]>,
	seoDefaults: Array<GraphQLTypes["SeoDefaultLocale"]>,
	businessPages: Array<GraphQLTypes["BusinessPageLocale"]>,
	jobPages: Array<GraphQLTypes["JobPageLocale"]>,
	contractTypes: Array<GraphQLTypes["ContractTypeLocale"]>,
	jobCities: Array<GraphQLTypes["JobCityLocale"]>,
	jobPositions: Array<GraphQLTypes["JobPositionLocale"]>,
	homePages: Array<GraphQLTypes["HomePageLocale"]>,
	bonusBanners: Array<GraphQLTypes["BonusBannerLocale"]>,
	homePageLokals: Array<GraphQLTypes["HomePageLokalLocale"]>,
	jobsForms: Array<GraphQLTypes["JobsFormLocale"]>,
	jobsFilters: Array<GraphQLTypes["JobsFilterLocale"]>,
	general: Array<GraphQLTypes["GeneralLocale"]>,
	recommendationForms: Array<GraphQLTypes["RecommendationFormLocale"]>,
	infoBoxes: Array<GraphQLTypes["InfoBoxLocale"]>,
	footersByRoot?: GraphQLTypes["FooterLocale"] | undefined,
	footersByTermsAndConditions?: GraphQLTypes["FooterLocale"] | undefined,
	footersByBannerImage?: GraphQLTypes["FooterLocale"] | undefined,
	footersByBannerLink?: GraphQLTypes["FooterLocale"] | undefined,
	footersByJobsForm?: GraphQLTypes["FooterLocale"] | undefined,
	footersByCopyrightLink?: GraphQLTypes["FooterLocale"] | undefined,
	footersByRecommendationForm?: GraphQLTypes["FooterLocale"] | undefined,
	footersByBannerSecondLink?: GraphQLTypes["FooterLocale"] | undefined,
	footersByInstagramLink?: GraphQLTypes["FooterLocale"] | undefined,
	genericPagesByRoot?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByLogo?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByImage?: GraphQLTypes["GenericPageLocale"] | undefined,
	headersByRoot?: GraphQLTypes["HeaderLocale"] | undefined,
	headersByBusinessLink?: GraphQLTypes["HeaderLocale"] | undefined,
	headersByLokalLink?: GraphQLTypes["HeaderLocale"] | undefined,
	seoDefaultsByRoot?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	seoDefaultsBySeo?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	businessPagesByRoot?: GraphQLTypes["BusinessPageLocale"] | undefined,
	businessPagesByContent?: GraphQLTypes["BusinessPageLocale"] | undefined,
	businessPagesBySeo?: GraphQLTypes["BusinessPageLocale"] | undefined,
	businessPagesByLink?: GraphQLTypes["BusinessPageLocale"] | undefined,
	businessPagesByImage?: GraphQLTypes["BusinessPageLocale"] | undefined,
	jobPagesByRoot?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesByImage?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesByKeyInfo?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesByContent?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesBySeo?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesByLink?: GraphQLTypes["JobPageLocale"] | undefined,
	jobPagesByMobileImage?: GraphQLTypes["JobPageLocale"] | undefined,
	contractTypesByRoot?: GraphQLTypes["ContractTypeLocale"] | undefined,
	contractTypesBySlug?: GraphQLTypes["ContractTypeLocale"] | undefined,
	jobCitiesByRoot?: GraphQLTypes["JobCityLocale"] | undefined,
	jobCitiesBySlug?: GraphQLTypes["JobCityLocale"] | undefined,
	jobPositionsByRoot?: GraphQLTypes["JobPositionLocale"] | undefined,
	jobPositionsBySlug?: GraphQLTypes["JobPositionLocale"] | undefined,
	homePagesByRoot?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesByButton?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesByContent?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesBySeo?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesByLink?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesByLogo?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesBySiteSwitcher?: GraphQLTypes["HomePageLocale"] | undefined,
	homePagesByIlustration?: GraphQLTypes["HomePageLocale"] | undefined,
	bonusBannersByRoot?: GraphQLTypes["BonusBannerLocale"] | undefined,
	bonusBannersByTiles?: GraphQLTypes["BonusBannerLocale"] | undefined,
	bonusBannersByLogo?: GraphQLTypes["BonusBannerLocale"] | undefined,
	homePageLokalsByRoot?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByLogo?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByButton?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByContent?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsBySeo?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByLink?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsBySiteSwitcher?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	homePageLokalsByIlustration?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	jobsFormsByRoot?: GraphQLTypes["JobsFormLocale"] | undefined,
	jobsFormsByImageTextList?: GraphQLTypes["JobsFormLocale"] | undefined,
	jobsFormsByLogo?: GraphQLTypes["JobsFormLocale"] | undefined,
	jobsFormsBySuccessImage?: GraphQLTypes["JobsFormLocale"] | undefined,
	jobsFiltersByRoot?: GraphQLTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByBannerJobsForm?: GraphQLTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByBannerImage?: GraphQLTypes["JobsFilterLocale"] | undefined,
	jobsFiltersByNoJobImage?: GraphQLTypes["JobsFilterLocale"] | undefined,
	generalByRoot?: GraphQLTypes["GeneralLocale"] | undefined,
	generalBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	recommendationFormsByRoot?: GraphQLTypes["RecommendationFormLocale"] | undefined,
	infoBoxesByRoot?: GraphQLTypes["InfoBoxLocale"] | undefined,
	paginateFooters: GraphQLTypes["FooterLocaleConnection"],
	paginateGenericPages: GraphQLTypes["GenericPageLocaleConnection"],
	paginateHeaders: GraphQLTypes["HeaderLocaleConnection"],
	paginateSeoDefaults: GraphQLTypes["SeoDefaultLocaleConnection"],
	paginateBusinessPages: GraphQLTypes["BusinessPageLocaleConnection"],
	paginateJobPages: GraphQLTypes["JobPageLocaleConnection"],
	paginateContractTypes: GraphQLTypes["ContractTypeLocaleConnection"],
	paginateJobCities: GraphQLTypes["JobCityLocaleConnection"],
	paginateJobPositions: GraphQLTypes["JobPositionLocaleConnection"],
	paginateHomePages: GraphQLTypes["HomePageLocaleConnection"],
	paginateBonusBanners: GraphQLTypes["BonusBannerLocaleConnection"],
	paginateHomePageLokals: GraphQLTypes["HomePageLokalLocaleConnection"],
	paginateJobsForms: GraphQLTypes["JobsFormLocaleConnection"],
	paginateJobsFilters: GraphQLTypes["JobsFilterLocaleConnection"],
	paginateGeneral: GraphQLTypes["GeneralLocaleConnection"],
	paginateRecommendationForms: GraphQLTypes["RecommendationFormLocaleConnection"],
	paginateInfoBoxes: GraphQLTypes["InfoBoxLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	footers?: GraphQLTypes["FieldMeta"] | undefined,
	genericPages?: GraphQLTypes["FieldMeta"] | undefined,
	headers?: GraphQLTypes["FieldMeta"] | undefined,
	seoDefaults?: GraphQLTypes["FieldMeta"] | undefined,
	businessPages?: GraphQLTypes["FieldMeta"] | undefined,
	jobPages?: GraphQLTypes["FieldMeta"] | undefined,
	contractTypes?: GraphQLTypes["FieldMeta"] | undefined,
	jobCities?: GraphQLTypes["FieldMeta"] | undefined,
	jobPositions?: GraphQLTypes["FieldMeta"] | undefined,
	homePages?: GraphQLTypes["FieldMeta"] | undefined,
	bonusBanners?: GraphQLTypes["FieldMeta"] | undefined,
	homePageLokals?: GraphQLTypes["FieldMeta"] | undefined,
	jobsForms?: GraphQLTypes["FieldMeta"] | undefined,
	jobsFilters?: GraphQLTypes["FieldMeta"] | undefined,
	general?: GraphQLTypes["FieldMeta"] | undefined,
	recommendationForms?: GraphQLTypes["FieldMeta"] | undefined,
	infoBoxes?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterLocale"]: {
	__typename: "FooterLocale",
	_meta?: GraphQLTypes["FooterLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	email?: string | undefined,
	address?: string | undefined,
	ico?: string | undefined,
	dic?: string | undefined,
	copyright?: string | undefined,
	note?: string | undefined,
	noteSecond?: string | undefined,
	root?: GraphQLTypes["Footer"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	linksFirstColumn: Array<GraphQLTypes["FooterLink"]>,
	linksSecondColumn: Array<GraphQLTypes["FooterLink"]>,
	termsAndConditions?: GraphQLTypes["Link"] | undefined,
	form?: string | undefined,
	bannerTitle?: string | undefined,
	bannerSubtitle?: string | undefined,
	bannerImage?: GraphQLTypes["Image"] | undefined,
	bannerLink?: GraphQLTypes["Link"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocale"] | undefined,
	copyrightLink?: GraphQLTypes["Link"] | undefined,
	bannerSecondBtn?: string | undefined,
	recommendationForm?: GraphQLTypes["RecommendationFormLocale"] | undefined,
	bannerSecondLink?: GraphQLTypes["Link"] | undefined,
	instagramLink?: GraphQLTypes["Link"] | undefined,
	paginateLinksFirstColumn: GraphQLTypes["FooterLinkConnection"],
	paginateLinksSecondColumn: GraphQLTypes["FooterLinkConnection"]
};
	["FooterLocaleMeta"]: {
	__typename: "FooterLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	ico?: GraphQLTypes["FieldMeta"] | undefined,
	dic?: GraphQLTypes["FieldMeta"] | undefined,
	copyright?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	noteSecond?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	linksFirstColumn?: GraphQLTypes["FieldMeta"] | undefined,
	linksSecondColumn?: GraphQLTypes["FieldMeta"] | undefined,
	termsAndConditions?: GraphQLTypes["FieldMeta"] | undefined,
	form?: GraphQLTypes["FieldMeta"] | undefined,
	bannerTitle?: GraphQLTypes["FieldMeta"] | undefined,
	bannerSubtitle?: GraphQLTypes["FieldMeta"] | undefined,
	bannerImage?: GraphQLTypes["FieldMeta"] | undefined,
	bannerLink?: GraphQLTypes["FieldMeta"] | undefined,
	jobsForm?: GraphQLTypes["FieldMeta"] | undefined,
	copyrightLink?: GraphQLTypes["FieldMeta"] | undefined,
	bannerSecondBtn?: GraphQLTypes["FieldMeta"] | undefined,
	recommendationForm?: GraphQLTypes["FieldMeta"] | undefined,
	bannerSecondLink?: GraphQLTypes["FieldMeta"] | undefined,
	instagramLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Footer"]: {
	__typename: "Footer",
	_meta?: GraphQLTypes["FooterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["FooterLocale"]>,
	localesByLocale?: GraphQLTypes["FooterLocale"] | undefined,
	localesByTermsAndConditions?: GraphQLTypes["FooterLocale"] | undefined,
	localesByBannerImage?: GraphQLTypes["FooterLocale"] | undefined,
	localesByBannerLink?: GraphQLTypes["FooterLocale"] | undefined,
	localesByJobsForm?: GraphQLTypes["FooterLocale"] | undefined,
	localesByCopyrightLink?: GraphQLTypes["FooterLocale"] | undefined,
	localesByRecommendationForm?: GraphQLTypes["FooterLocale"] | undefined,
	localesByBannerSecondLink?: GraphQLTypes["FooterLocale"] | undefined,
	localesByInstagramLink?: GraphQLTypes["FooterLocale"] | undefined,
	paginateLocales: GraphQLTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
	__typename: "FooterMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["OrderDirection"] | undefined,
	ico?: GraphQLTypes["OrderDirection"] | undefined,
	dic?: GraphQLTypes["OrderDirection"] | undefined,
	copyright?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	noteSecond?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["FooterOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	termsAndConditions?: GraphQLTypes["LinkOrderBy"] | undefined,
	form?: GraphQLTypes["OrderDirection"] | undefined,
	bannerTitle?: GraphQLTypes["OrderDirection"] | undefined,
	bannerSubtitle?: GraphQLTypes["OrderDirection"] | undefined,
	bannerImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	bannerLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleOrderBy"] | undefined,
	copyrightLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	bannerSecondBtn?: GraphQLTypes["OrderDirection"] | undefined,
	recommendationForm?: GraphQLTypes["RecommendationFormLocaleOrderBy"] | undefined,
	bannerSecondLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	instagramLink?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["FooterOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined,
	anchorLink?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleOrderBy"] | undefined,
	businessPage?: GraphQLTypes["BusinessPageLocaleOrderBy"] | undefined,
	jobPage?: GraphQLTypes["JobPageLocaleOrderBy"] | undefined,
	homePage?: GraphQLTypes["HomePageLocaleOrderBy"] | undefined,
	homePageLokal?: GraphQLTypes["HomePageLokalLocaleOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GenericPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined
};
	["ThemeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	backgroundColor?: GraphQLTypes["OrderDirection"] | undefined,
	textColor?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	buttonBackgroundColor?: GraphQLTypes["OrderDirection"] | undefined,
	buttonTextColor?: GraphQLTypes["OrderDirection"] | undefined,
	buttonBackgroundHoverColor?: GraphQLTypes["OrderDirection"] | undefined,
	buttonTextHoverColor?: GraphQLTypes["OrderDirection"] | undefined,
	buttonBorderColor?: GraphQLTypes["OrderDirection"] | undefined,
	buttonBorderHoverColor?: GraphQLTypes["OrderDirection"] | undefined,
	headerMenuColor?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	noIndex?: GraphQLTypes["OrderDirection"] | undefined,
	noFollow?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined,
	fileName?: GraphQLTypes["OrderDirection"] | undefined,
	focalPointX?: GraphQLTypes["OrderDirection"] | undefined,
	focalPointY?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BusinessPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	subtitle?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarOrderBy"] | undefined,
	infoBox?: GraphQLTypes["InfoBoxOrderBy"] | undefined
};
	["BusinessPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined,
	isLokal?: GraphQLTypes["OrderDirection"] | undefined
};
	["StickyBarOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	formButtonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleOrderBy"] | undefined
};
	["JobsFormLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	jobText?: GraphQLTypes["OrderDirection"] | undefined,
	genericText?: GraphQLTypes["OrderDirection"] | undefined,
	nameLabel?: GraphQLTypes["OrderDirection"] | undefined,
	emailLabel?: GraphQLTypes["OrderDirection"] | undefined,
	telefonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	fileTitleLabel?: GraphQLTypes["OrderDirection"] | undefined,
	fileFormatLabel?: GraphQLTypes["OrderDirection"] | undefined,
	formTextLabel?: GraphQLTypes["OrderDirection"] | undefined,
	submitLabel?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["JobsFormOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	imageTextList?: GraphQLTypes["ImageTextListOrderBy"] | undefined,
	minimalize?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	successTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successText?: GraphQLTypes["OrderDirection"] | undefined,
	successBtnLabel?: GraphQLTypes["OrderDirection"] | undefined,
	successImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	successOtherJobsTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successOtherJobsCategoryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successOtherJobsButtonTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionYesButton?: GraphQLTypes["OrderDirection"] | undefined,
	successOtherJobsSelectionNoButton?: GraphQLTypes["OrderDirection"] | undefined,
	fileRemoveBtnLabel?: GraphQLTypes["OrderDirection"] | undefined,
	gdprLabel?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobsFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	fileIlustration?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageTextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["InfoBoxOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	logoSize?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["JobPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["JobPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	keyInfo?: GraphQLTypes["KeyInfoListOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarOrderBy"] | undefined,
	mobileImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["JobPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined,
	contractType?: GraphQLTypes["ContractTypeOrderBy"] | undefined,
	jobCity?: GraphQLTypes["JobCityOrderBy"] | undefined,
	business?: GraphQLTypes["BusinessPageOrderBy"] | undefined,
	isActive?: GraphQLTypes["OrderDirection"] | undefined,
	jobPosition?: GraphQLTypes["JobPositionOrderBy"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	isSyncedToTabidoo?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContractTypeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobCityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobPositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["KeyInfoListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["HomePageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["HomePageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	button?: GraphQLTypes["LinkOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarOrderBy"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	ilustration?: GraphQLTypes["GalleryOrderBy"] | undefined
};
	["HomePageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined
};
	["SiteSwitcherOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined
};
	["GalleryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["HomePageLokalLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["HomePageLokalOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	button?: GraphQLTypes["LinkOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	stickyBar?: GraphQLTypes["StickyBarOrderBy"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	ilustration?: GraphQLTypes["GalleryOrderBy"] | undefined
};
	["HomePageLokalOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	theme?: GraphQLTypes["ThemeOrderBy"] | undefined
};
	["RecommendationFormLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	subtitle?: GraphQLTypes["OrderDirection"] | undefined,
	yourEmailLabel?: GraphQLTypes["OrderDirection"] | undefined,
	theirEmailLabel?: GraphQLTypes["OrderDirection"] | undefined,
	yourName?: GraphQLTypes["OrderDirection"] | undefined,
	sendBtnLabel?: GraphQLTypes["OrderDirection"] | undefined,
	successTitle?: GraphQLTypes["OrderDirection"] | undefined,
	successSubtitle?: GraphQLTypes["OrderDirection"] | undefined,
	successButtonClose?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["RecommendationFormOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["RecommendationFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	successImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	footers?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined,
	seoDefaults?: GraphQLTypes["SeoDefaultLocaleUniqueWhere"] | undefined,
	businessPages?: GraphQLTypes["BusinessPageLocaleUniqueWhere"] | undefined,
	jobPages?: GraphQLTypes["JobPageLocaleUniqueWhere"] | undefined,
	contractTypes?: GraphQLTypes["ContractTypeLocaleUniqueWhere"] | undefined,
	jobCities?: GraphQLTypes["JobCityLocaleUniqueWhere"] | undefined,
	jobPositions?: GraphQLTypes["JobPositionLocaleUniqueWhere"] | undefined,
	homePages?: GraphQLTypes["HomePageLocaleUniqueWhere"] | undefined,
	bonusBanners?: GraphQLTypes["BonusBannerLocaleUniqueWhere"] | undefined,
	homePageLokals?: GraphQLTypes["HomePageLokalLocaleUniqueWhere"] | undefined,
	jobsForms?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined,
	jobsFilters?: GraphQLTypes["JobsFilterLocaleUniqueWhere"] | undefined,
	general?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined,
	recommendationForms?: GraphQLTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	infoBoxes?: GraphQLTypes["InfoBoxLocaleUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["FooterUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	termsAndConditions?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	bannerLink?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined,
	copyrightLink?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	recommendationForm?: GraphQLTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	bannerSecondLink?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	instagramLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["JobsFormLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["JobsFormUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	imageTextList?: GraphQLTypes["ImageTextListUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	successImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined,
	fileIlustration?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageTextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageTextItemUniqueWhere"] | undefined
};
	["ImageTextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["RecommendationFormLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["RecommendationFormUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["RecommendationFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["RecommendationFormLocaleUniqueWhere"] | undefined,
	successImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	linkables?: GraphQLTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	media?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["ContentReferenceLinkableItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	item?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	businessPage?: GraphQLTypes["BusinessPageLocaleUniqueWhere"] | undefined,
	jobPage?: GraphQLTypes["JobPageLocaleUniqueWhere"] | undefined,
	homePage?: GraphQLTypes["HomePageLocaleUniqueWhere"] | undefined,
	homePageLokal?: GraphQLTypes["HomePageLokalLocaleUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	target?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["BusinessPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BusinessPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["BusinessPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BusinessPageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["JobPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	keyInfo?: GraphQLTypes["KeyInfoListUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	mobileImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["KeyInfoListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["KeyInfoItemUniqueWhere"] | undefined
};
	["KeyInfoItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HomePageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["HomePageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	button?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined,
	ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["HomePageLocaleUniqueWhere"] | undefined,
	theme?: GraphQLTypes["ThemeUniqueWhere"] | undefined
};
	["ThemeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SiteSwitcherUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	theme?: GraphQLTypes["ThemeUniqueWhere"] | undefined
};
	["GalleryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GalleryItemUniqueWhere"] | undefined
};
	["GalleryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLokalLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["HomePageLokalUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	button?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined,
	ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLokalUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["HomePageLokalLocaleUniqueWhere"] | undefined,
	theme?: GraphQLTypes["ThemeUniqueWhere"] | undefined
};
	["MediumListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["MediumItemUniqueWhere"] | undefined
};
	["MediumItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["HeaderUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	businessLink?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	lokalLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	logoMenu?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["SeoDefaultLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["SeoDefaultUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["SeoDefaultUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["SeoDefaultLocaleUniqueWhere"] | undefined
};
	["ContractTypeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ContractTypeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["ContractTypeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ContractTypeLocaleUniqueWhere"] | undefined
};
	["JobCityLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["JobCityUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["JobCityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["JobCityLocaleUniqueWhere"] | undefined
};
	["JobPositionLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["JobPositionUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	slug?: string | undefined
};
	["JobPositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["JobPositionLocaleUniqueWhere"] | undefined,
	jobs?: GraphQLTypes["JobPageUniqueWhere"] | undefined
};
	["BonusBannerLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BonusBannerUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	tiles?: GraphQLTypes["TileUniqueWhere"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BonusBannerLocaleUniqueWhere"] | undefined
};
	["TileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	ligthBoxMedia?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["JobsFilterLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["JobsFilterUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	bannerJobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined,
	bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	noJobImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["JobsFilterLocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GeneralUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["InfoBoxLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["InfoBoxUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["InfoBoxUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	locales?: GraphQLTypes["InfoBoxLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByTermsAndConditionsUniqueWhere"]: {
		termsAndConditions?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByBannerImageUniqueWhere"]: {
		bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["FooterLocalesByBannerLinkUniqueWhere"]: {
		bannerLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByJobsFormUniqueWhere"]: {
		jobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByCopyrightLinkUniqueWhere"]: {
		copyrightLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByRecommendationFormUniqueWhere"]: {
		recommendationForm?: GraphQLTypes["RecommendationFormLocaleUniqueWhere"] | undefined
};
	["FooterLocalesByBannerSecondLinkUniqueWhere"]: {
		bannerSecondLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocalesByInstagramLinkUniqueWhere"]: {
		instagramLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
	__typename: "FooterLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterLocaleEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["FooterLocaleEdge"]: {
	__typename: "FooterLocaleEdge",
	node: GraphQLTypes["FooterLocale"]
};
	["FooterLink"]: {
	__typename: "FooterLink",
	_meta?: GraphQLTypes["FooterLinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	link?: GraphQLTypes["Link"] | undefined
};
	["FooterLinkMeta"]: {
	__typename: "FooterLinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined,
	anchorLink?: string | undefined
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined,
	anchorLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	redirect?: GraphQLTypes["Redirect"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocale"] | undefined,
	businessPage?: GraphQLTypes["BusinessPageLocale"] | undefined,
	jobPage?: GraphQLTypes["JobPageLocale"] | undefined,
	homePage?: GraphQLTypes["HomePageLocale"] | undefined,
	homePageLokal?: GraphQLTypes["HomePageLokalLocale"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined,
	genericPage?: GraphQLTypes["FieldMeta"] | undefined,
	businessPage?: GraphQLTypes["FieldMeta"] | undefined,
	jobPage?: GraphQLTypes["FieldMeta"] | undefined,
	homePage?: GraphQLTypes["FieldMeta"] | undefined,
	homePageLokal?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageLocale"]: {
	__typename: "GenericPageLocale",
	_meta?: GraphQLTypes["GenericPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["GenericPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	text?: string | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["GenericPageLocaleMeta"]: {
	__typename: "GenericPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPage"]: {
	__typename: "GenericPage",
	_meta?: GraphQLTypes["GenericPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["GenericPageLocale"]>,
	theme?: GraphQLTypes["Theme"] | undefined,
	localesByLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByLogo?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByImage?: GraphQLTypes["GenericPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
	__typename: "GenericPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Theme"]: {
	__typename: "Theme",
	_meta?: GraphQLTypes["ThemeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	backgroundColor?: string | undefined,
	textColor?: string | undefined,
	name?: string | undefined,
	buttonBackgroundColor?: string | undefined,
	buttonTextColor?: string | undefined,
	buttonBackgroundHoverColor?: string | undefined,
	buttonTextHoverColor?: string | undefined,
	buttonBorderColor?: string | undefined,
	buttonBorderHoverColor?: string | undefined,
	headerMenuColor?: string | undefined
};
	["ThemeMeta"]: {
	__typename: "ThemeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundColor?: GraphQLTypes["FieldMeta"] | undefined,
	textColor?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	buttonBackgroundColor?: GraphQLTypes["FieldMeta"] | undefined,
	buttonTextColor?: GraphQLTypes["FieldMeta"] | undefined,
	buttonBackgroundHoverColor?: GraphQLTypes["FieldMeta"] | undefined,
	buttonTextHoverColor?: GraphQLTypes["FieldMeta"] | undefined,
	buttonBorderColor?: GraphQLTypes["FieldMeta"] | undefined,
	buttonBorderHoverColor?: GraphQLTypes["FieldMeta"] | undefined,
	headerMenuColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocalesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
	__typename: "GenericPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageLocaleEdge"]>
};
	["GenericPageLocaleEdge"]: {
	__typename: "GenericPageLocaleEdge",
	node: GraphQLTypes["GenericPageLocale"]
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByLinkables?: GraphQLTypes["ContentReference"] | undefined,
	referencesByLink?: GraphQLTypes["ContentReference"] | undefined,
	referencesByMedia?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	linkables: Array<GraphQLTypes["ContentReferenceLinkableItem"]>,
	secondaryText?: string | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	media?: GraphQLTypes["MediumList"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	carousel?: GraphQLTypes["Carousel"] | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocale"] | undefined,
	sideBySide?: GraphQLTypes["SideBySide"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	showBoolean?: boolean | undefined,
	isRed?: boolean | undefined,
	video?: GraphQLTypes["Video"] | undefined,
	linkablesByItem?: GraphQLTypes["ContentReferenceLinkableItem"] | undefined,
	paginateLinkables: GraphQLTypes["ContentReferenceLinkableItemConnection"]
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	linkables?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	media?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	carousel?: GraphQLTypes["FieldMeta"] | undefined,
	bonusBanner?: GraphQLTypes["FieldMeta"] | undefined,
	sideBySide?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	showBoolean?: GraphQLTypes["FieldMeta"] | undefined,
	isRed?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined,
	focalPointX?: number | undefined,
	focalPointY?: number | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined,
	fileName?: GraphQLTypes["FieldMeta"] | undefined,
	focalPointX?: GraphQLTypes["FieldMeta"] | undefined,
	focalPointY?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceLinkableItem"]: {
	__typename: "ContentReferenceLinkableItem",
	_meta?: GraphQLTypes["ContentReferenceLinkableItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	item?: GraphQLTypes["Linkable"] | undefined,
	reference?: GraphQLTypes["ContentReference"] | undefined
};
	["ContentReferenceLinkableItemMeta"]: {
	__typename: "ContentReferenceLinkableItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	reference?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceLinkableItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["LinkableOrderBy"] | undefined,
	reference?: GraphQLTypes["ContentReferenceOrderBy"] | undefined
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	media?: GraphQLTypes["MediumListOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	carousel?: GraphQLTypes["CarouselOrderBy"] | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocaleOrderBy"] | undefined,
	sideBySide?: GraphQLTypes["SideBySideOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	showBoolean?: GraphQLTypes["OrderDirection"] | undefined,
	isRed?: GraphQLTypes["OrderDirection"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["MediumListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["CarouselOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	squareImages?: GraphQLTypes["OrderDirection"] | undefined
};
	["BonusBannerLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BonusBannerOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	bannerName?: GraphQLTypes["OrderDirection"] | undefined
};
	["BonusBannerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["SideBySideOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	alignment?: GraphQLTypes["OrderDirection"] | undefined,
	mediumSize?: GraphQLTypes["OrderDirection"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	videoInfo?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	poster?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["MediumList"]: {
	__typename: "MediumList",
	_meta?: GraphQLTypes["MediumListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["MediumItem"]>,
	paginateItems: GraphQLTypes["MediumItemConnection"]
};
	["MediumListMeta"]: {
	__typename: "MediumListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["MediumItem"]: {
	__typename: "MediumItem",
	_meta?: GraphQLTypes["MediumItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["MediumList"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["MediumItemMeta"]: {
	__typename: "MediumItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
	__typename: "Medium",
	_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["MediumType"],
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
	__typename: "MediumMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	videoInfo?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	videoInfo?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["MediumItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["MediumListOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["MediumOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["MediumItemConnection"]: {
	__typename: "MediumItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumItemEdge"]>
};
	["MediumItemEdge"]: {
	__typename: "MediumItemEdge",
	node: GraphQLTypes["MediumItem"]
};
	["TextList"]: {
	__typename: "TextList",
	_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
	__typename: "TextListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
	__typename: "TextItem",
	_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
	__typename: "TextItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TextListOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextItemConnection"]: {
	__typename: "TextItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
	__typename: "TextItemEdge",
	node: GraphQLTypes["TextItem"]
};
	["Carousel"]: {
	__typename: "Carousel",
	_meta?: GraphQLTypes["CarouselMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["CarouselItem"]>,
	background?: GraphQLTypes["Background"] | undefined,
	squareImages?: boolean | undefined,
	itemsByImage?: GraphQLTypes["CarouselItem"] | undefined,
	itemsByLinkUrl?: GraphQLTypes["CarouselItem"] | undefined,
	paginateItems: GraphQLTypes["CarouselItemConnection"]
};
	["CarouselMeta"]: {
	__typename: "CarouselMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	squareImages?: GraphQLTypes["FieldMeta"] | undefined
};
	["CarouselItem"]: {
	__typename: "CarouselItem",
	_meta?: GraphQLTypes["CarouselItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	text?: string | undefined,
	list?: GraphQLTypes["Carousel"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	name?: string | undefined,
	title?: string | undefined,
	linkUrl?: GraphQLTypes["Link"] | undefined
};
	["CarouselItemMeta"]: {
	__typename: "CarouselItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	linkUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["CarouselItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["CarouselOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	linkUrl?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["CarouselItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["CarouselItemsByLinkUrlUniqueWhere"]: {
		linkUrl?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["CarouselItemConnection"]: {
	__typename: "CarouselItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CarouselItemEdge"]>
};
	["CarouselItemEdge"]: {
	__typename: "CarouselItemEdge",
	node: GraphQLTypes["CarouselItem"]
};
	["BonusBannerLocale"]: {
	__typename: "BonusBannerLocale",
	_meta?: GraphQLTypes["BonusBannerLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	root?: GraphQLTypes["BonusBanner"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	tiles: Array<GraphQLTypes["Tile"]>,
	logo?: GraphQLTypes["Image"] | undefined,
	bannerName?: string | undefined,
	tilesByImage?: GraphQLTypes["Tile"] | undefined,
	tilesByLigthBoxMedia?: GraphQLTypes["Tile"] | undefined,
	paginateTiles: GraphQLTypes["TileConnection"]
};
	["BonusBannerLocaleMeta"]: {
	__typename: "BonusBannerLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	tiles?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	bannerName?: GraphQLTypes["FieldMeta"] | undefined
};
	["BonusBanner"]: {
	__typename: "BonusBanner",
	_meta?: GraphQLTypes["BonusBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["BonusBannerLocale"]>,
	localesByLocale?: GraphQLTypes["BonusBannerLocale"] | undefined,
	localesByTiles?: GraphQLTypes["BonusBannerLocale"] | undefined,
	localesByLogo?: GraphQLTypes["BonusBannerLocale"] | undefined,
	paginateLocales: GraphQLTypes["BonusBannerLocaleConnection"]
};
	["BonusBannerMeta"]: {
	__typename: "BonusBannerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BonusBannerLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BonusBannerLocalesByTilesUniqueWhere"]: {
		tiles?: GraphQLTypes["TileUniqueWhere"] | undefined
};
	["BonusBannerLocalesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerLocaleConnection"]: {
	__typename: "BonusBannerLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BonusBannerLocaleEdge"]>
};
	["BonusBannerLocaleEdge"]: {
	__typename: "BonusBannerLocaleEdge",
	node: GraphQLTypes["BonusBannerLocale"]
};
	["Tile"]: {
	__typename: "Tile",
	_meta?: GraphQLTypes["TileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocale"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	lightBoxTitle?: string | undefined,
	lightBoxText?: string | undefined,
	ligthBoxMedia?: GraphQLTypes["MediumList"] | undefined,
	lightBoxSectionTitle?: string | undefined,
	title?: string | undefined,
	lightBoxLinkLabel?: string | undefined,
	lightBoxForm?: GraphQLTypes["JobsFormLocale"] | undefined
};
	["TileMeta"]: {
	__typename: "TileMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	bonusBanner?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	lightBoxTitle?: GraphQLTypes["FieldMeta"] | undefined,
	lightBoxText?: GraphQLTypes["FieldMeta"] | undefined,
	ligthBoxMedia?: GraphQLTypes["FieldMeta"] | undefined,
	lightBoxSectionTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lightBoxLinkLabel?: GraphQLTypes["FieldMeta"] | undefined,
	lightBoxForm?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobsFormLocale"]: {
	__typename: "JobsFormLocale",
	_meta?: GraphQLTypes["JobsFormLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	jobText?: string | undefined,
	genericText?: string | undefined,
	nameLabel?: string | undefined,
	emailLabel?: string | undefined,
	telefonLabel?: string | undefined,
	fileTitleLabel?: string | undefined,
	fileFormatLabel?: string | undefined,
	formTextLabel?: string | undefined,
	submitLabel?: string | undefined,
	root?: GraphQLTypes["JobsForm"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	imageTextList?: GraphQLTypes["ImageTextList"] | undefined,
	minimalize?: string | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	successTitle?: string | undefined,
	successText?: string | undefined,
	successBtnLabel?: string | undefined,
	successImage?: GraphQLTypes["Image"] | undefined,
	successOtherJobsTitle?: string | undefined,
	successOtherJobsCategoryTitle?: string | undefined,
	successOtherJobsButtonTitle?: string | undefined,
	successOtherJobsSelectionTitle?: string | undefined,
	successOtherJobsSelectionYesButton?: string | undefined,
	successOtherJobsSelectionNoButton?: string | undefined,
	fileRemoveBtnLabel?: string | undefined,
	gdprLabel?: string | undefined
};
	["JobsFormLocaleMeta"]: {
	__typename: "JobsFormLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	jobText?: GraphQLTypes["FieldMeta"] | undefined,
	genericText?: GraphQLTypes["FieldMeta"] | undefined,
	nameLabel?: GraphQLTypes["FieldMeta"] | undefined,
	emailLabel?: GraphQLTypes["FieldMeta"] | undefined,
	telefonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	fileTitleLabel?: GraphQLTypes["FieldMeta"] | undefined,
	fileFormatLabel?: GraphQLTypes["FieldMeta"] | undefined,
	formTextLabel?: GraphQLTypes["FieldMeta"] | undefined,
	submitLabel?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	imageTextList?: GraphQLTypes["FieldMeta"] | undefined,
	minimalize?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	successTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successText?: GraphQLTypes["FieldMeta"] | undefined,
	successBtnLabel?: GraphQLTypes["FieldMeta"] | undefined,
	successImage?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsCategoryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsButtonTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionYesButton?: GraphQLTypes["FieldMeta"] | undefined,
	successOtherJobsSelectionNoButton?: GraphQLTypes["FieldMeta"] | undefined,
	fileRemoveBtnLabel?: GraphQLTypes["FieldMeta"] | undefined,
	gdprLabel?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobsForm"]: {
	__typename: "JobsForm",
	_meta?: GraphQLTypes["JobsFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["JobsFormLocale"]>,
	fileIlustration?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["JobsFormLocale"] | undefined,
	localesByImageTextList?: GraphQLTypes["JobsFormLocale"] | undefined,
	localesByLogo?: GraphQLTypes["JobsFormLocale"] | undefined,
	localesBySuccessImage?: GraphQLTypes["JobsFormLocale"] | undefined,
	paginateLocales: GraphQLTypes["JobsFormLocaleConnection"]
};
	["JobsFormMeta"]: {
	__typename: "JobsFormMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	fileIlustration?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobsFormLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["JobsFormLocalesByImageTextListUniqueWhere"]: {
		imageTextList?: GraphQLTypes["ImageTextListUniqueWhere"] | undefined
};
	["JobsFormLocalesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormLocalesBySuccessImageUniqueWhere"]: {
		successImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFormLocaleConnection"]: {
	__typename: "JobsFormLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobsFormLocaleEdge"]>
};
	["JobsFormLocaleEdge"]: {
	__typename: "JobsFormLocaleEdge",
	node: GraphQLTypes["JobsFormLocale"]
};
	["ImageTextList"]: {
	__typename: "ImageTextList",
	_meta?: GraphQLTypes["ImageTextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageTextItem"]>,
	itemsByImage?: GraphQLTypes["ImageTextItem"] | undefined,
	paginateItems: GraphQLTypes["ImageTextItemConnection"]
};
	["ImageTextListMeta"]: {
	__typename: "ImageTextListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageTextItem"]: {
	__typename: "ImageTextItem",
	_meta?: GraphQLTypes["ImageTextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	title?: string | undefined,
	list?: GraphQLTypes["ImageTextList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageTextItemMeta"]: {
	__typename: "ImageTextItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageTextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageTextListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageTextListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageTextItemConnection"]: {
	__typename: "ImageTextItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageTextItemEdge"]>
};
	["ImageTextItemEdge"]: {
	__typename: "ImageTextItemEdge",
	node: GraphQLTypes["ImageTextItem"]
};
	["TileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	bonusBanner?: GraphQLTypes["BonusBannerLocaleOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	lightBoxTitle?: GraphQLTypes["OrderDirection"] | undefined,
	lightBoxText?: GraphQLTypes["OrderDirection"] | undefined,
	ligthBoxMedia?: GraphQLTypes["MediumListOrderBy"] | undefined,
	lightBoxSectionTitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lightBoxLinkLabel?: GraphQLTypes["OrderDirection"] | undefined,
	lightBoxForm?: GraphQLTypes["JobsFormLocaleOrderBy"] | undefined
};
	["BonusBannerLocaleTilesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"]: {
		ligthBoxMedia?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["TileConnection"]: {
	__typename: "TileConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TileEdge"]>
};
	["TileEdge"]: {
	__typename: "TileEdge",
	node: GraphQLTypes["Tile"]
};
	["SideBySide"]: {
	__typename: "SideBySide",
	_meta?: GraphQLTypes["SideBySideMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["SideBySideItem"]>,
	background: GraphQLTypes["Background"],
	alignment: GraphQLTypes["ContentAlignment"],
	mediumSize: GraphQLTypes["MediumSize"],
	itemsByMedia?: GraphQLTypes["SideBySideItem"] | undefined,
	paginateItems: GraphQLTypes["SideBySideItemConnection"]
};
	["SideBySideMeta"]: {
	__typename: "SideBySideMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	alignment?: GraphQLTypes["FieldMeta"] | undefined,
	mediumSize?: GraphQLTypes["FieldMeta"] | undefined
};
	["SideBySideItem"]: {
	__typename: "SideBySideItem",
	_meta?: GraphQLTypes["SideBySideItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	title?: string | undefined,
	text?: string | undefined,
	list?: GraphQLTypes["SideBySide"] | undefined,
	media?: GraphQLTypes["MediumList"] | undefined,
	formButtonLabel?: string | undefined,
	type: GraphQLTypes["SideBySideItemType"],
	link?: GraphQLTypes["Link"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocale"] | undefined
};
	["SideBySideItemMeta"]: {
	__typename: "SideBySideItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	media?: GraphQLTypes["FieldMeta"] | undefined,
	formButtonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	jobsForm?: GraphQLTypes["FieldMeta"] | undefined
};
	["SideBySideItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["SideBySideOrderBy"] | undefined,
	media?: GraphQLTypes["MediumListOrderBy"] | undefined,
	formButtonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocaleOrderBy"] | undefined
};
	["SideBySideItemsByMediaUniqueWhere"]: {
		media?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["SideBySideItemConnection"]: {
	__typename: "SideBySideItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SideBySideItemEdge"]>
};
	["SideBySideItemEdge"]: {
	__typename: "SideBySideItemEdge",
	node: GraphQLTypes["SideBySideItem"]
};
	["Gallery"]: {
	__typename: "Gallery",
	_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	itemsByImage?: GraphQLTypes["GalleryItem"] | undefined,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
	__typename: "GalleryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
	__typename: "GalleryItem",
	_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["GalleryItemMeta"]: {
	__typename: "GalleryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GalleryOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["GalleryItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["GalleryItemConnection"]: {
	__typename: "GalleryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
	__typename: "GalleryItemEdge",
	node: GraphQLTypes["GalleryItem"]
};
	["ContentReferenceLinkablesByItemUniqueWhere"]: {
		item?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["ContentReferenceLinkableItemConnection"]: {
	__typename: "ContentReferenceLinkableItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceLinkableItemEdge"]>
};
	["ContentReferenceLinkableItemEdge"]: {
	__typename: "ContentReferenceLinkableItemEdge",
	node: GraphQLTypes["ContentReferenceLinkableItem"]
};
	["ContentBlockReferencesByLinkablesUniqueWhere"]: {
		linkables?: GraphQLTypes["ContentReferenceLinkableItemUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByMediaUniqueWhere"]: {
		media?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	noIndex?: GraphQLTypes["FieldMeta"] | undefined,
	noFollow?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessPageLocale"]: {
	__typename: "BusinessPageLocale",
	_meta?: GraphQLTypes["BusinessPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["BusinessPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	subtitle?: string | undefined,
	text?: string | undefined,
	name?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	stickyBar?: GraphQLTypes["StickyBar"] | undefined,
	infoBox?: GraphQLTypes["InfoBox"] | undefined
};
	["BusinessPageLocaleMeta"]: {
	__typename: "BusinessPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	subtitle?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	stickyBar?: GraphQLTypes["FieldMeta"] | undefined,
	infoBox?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessPage"]: {
	__typename: "BusinessPage",
	_meta?: GraphQLTypes["BusinessPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["BusinessPageLocale"]>,
	theme?: GraphQLTypes["Theme"] | undefined,
	isLokal?: boolean | undefined,
	localesByLocale?: GraphQLTypes["BusinessPageLocale"] | undefined,
	localesByContent?: GraphQLTypes["BusinessPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BusinessPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["BusinessPageLocale"] | undefined,
	localesByImage?: GraphQLTypes["BusinessPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["BusinessPageLocaleConnection"]
};
	["BusinessPageMeta"]: {
	__typename: "BusinessPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined,
	isLokal?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessPageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["BusinessPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BusinessPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BusinessPageLocalesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["BusinessPageLocaleConnection"]: {
	__typename: "BusinessPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessPageLocaleEdge"]>
};
	["BusinessPageLocaleEdge"]: {
	__typename: "BusinessPageLocaleEdge",
	node: GraphQLTypes["BusinessPageLocale"]
};
	["StickyBar"]: {
	__typename: "StickyBar",
	_meta?: GraphQLTypes["StickyBarMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	formButtonLabel?: string | undefined,
	jobsForm?: GraphQLTypes["JobsFormLocale"] | undefined
};
	["StickyBarMeta"]: {
	__typename: "StickyBarMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	formButtonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	jobsForm?: GraphQLTypes["FieldMeta"] | undefined
};
	["InfoBox"]: {
	__typename: "InfoBox",
	_meta?: GraphQLTypes["InfoBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	logoSize: GraphQLTypes["LogoSize"],
	logo?: GraphQLTypes["Image"] | undefined,
	locales: Array<GraphQLTypes["InfoBoxLocale"]>,
	localesByLocale?: GraphQLTypes["InfoBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["InfoBoxLocaleConnection"]
};
	["InfoBoxMeta"]: {
	__typename: "InfoBoxMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	logoSize?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["InfoBoxLocale"]: {
	__typename: "InfoBoxLocale",
	_meta?: GraphQLTypes["InfoBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	address?: string | undefined,
	web?: string | undefined,
	instagram?: string | undefined,
	facebook?: string | undefined,
	root?: GraphQLTypes["InfoBox"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	openingHours?: GraphQLTypes["TextList"] | undefined,
	addressLink?: string | undefined
};
	["InfoBoxLocaleMeta"]: {
	__typename: "InfoBoxLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	web?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	facebook?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	addressLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["InfoBoxLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["OrderDirection"] | undefined,
	web?: GraphQLTypes["OrderDirection"] | undefined,
	instagram?: GraphQLTypes["OrderDirection"] | undefined,
	facebook?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["InfoBoxOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	openingHours?: GraphQLTypes["TextListOrderBy"] | undefined,
	addressLink?: GraphQLTypes["OrderDirection"] | undefined
};
	["InfoBoxLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["InfoBoxLocaleConnection"]: {
	__typename: "InfoBoxLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InfoBoxLocaleEdge"]>
};
	["InfoBoxLocaleEdge"]: {
	__typename: "InfoBoxLocaleEdge",
	node: GraphQLTypes["InfoBoxLocale"]
};
	["JobPageLocale"]: {
	__typename: "JobPageLocale",
	_meta?: GraphQLTypes["JobPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	root?: GraphQLTypes["JobPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	keyInfo?: GraphQLTypes["KeyInfoList"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	email?: string | undefined,
	stickyBar?: GraphQLTypes["StickyBar"] | undefined,
	mobileImage?: GraphQLTypes["Image"] | undefined
};
	["JobPageLocaleMeta"]: {
	__typename: "JobPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	keyInfo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	stickyBar?: GraphQLTypes["FieldMeta"] | undefined,
	mobileImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobPage"]: {
	__typename: "JobPage",
	_meta?: GraphQLTypes["JobPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["JobPageLocale"]>,
	theme?: GraphQLTypes["Theme"] | undefined,
	contractType?: GraphQLTypes["ContractType"] | undefined,
	jobCity?: GraphQLTypes["JobCity"] | undefined,
	business?: GraphQLTypes["BusinessPage"] | undefined,
	isActive?: boolean | undefined,
	jobPosition?: GraphQLTypes["JobPosition"] | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: number | undefined,
	isSyncedToTabidoo?: boolean | undefined,
	localesByLocale?: GraphQLTypes["JobPageLocale"] | undefined,
	localesByImage?: GraphQLTypes["JobPageLocale"] | undefined,
	localesByKeyInfo?: GraphQLTypes["JobPageLocale"] | undefined,
	localesByContent?: GraphQLTypes["JobPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["JobPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["JobPageLocale"] | undefined,
	localesByMobileImage?: GraphQLTypes["JobPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["JobPageLocaleConnection"]
};
	["JobPageMeta"]: {
	__typename: "JobPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined,
	contractType?: GraphQLTypes["FieldMeta"] | undefined,
	jobCity?: GraphQLTypes["FieldMeta"] | undefined,
	business?: GraphQLTypes["FieldMeta"] | undefined,
	isActive?: GraphQLTypes["FieldMeta"] | undefined,
	jobPosition?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isSyncedToTabidoo?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContractType"]: {
	__typename: "ContractType",
	_meta?: GraphQLTypes["ContractTypeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["ContractTypeLocale"]>,
	localesByLocale?: GraphQLTypes["ContractTypeLocale"] | undefined,
	paginateLocales: GraphQLTypes["ContractTypeLocaleConnection"]
};
	["ContractTypeMeta"]: {
	__typename: "ContractTypeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContractTypeLocale"]: {
	__typename: "ContractTypeLocale",
	_meta?: GraphQLTypes["ContractTypeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: string | undefined,
	root?: GraphQLTypes["ContractType"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	slug: string
};
	["ContractTypeLocaleMeta"]: {
	__typename: "ContractTypeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContractTypeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ContractTypeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContractTypeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ContractTypeLocaleConnection"]: {
	__typename: "ContractTypeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContractTypeLocaleEdge"]>
};
	["ContractTypeLocaleEdge"]: {
	__typename: "ContractTypeLocaleEdge",
	node: GraphQLTypes["ContractTypeLocale"]
};
	["JobCity"]: {
	__typename: "JobCity",
	_meta?: GraphQLTypes["JobCityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["JobCityLocale"]>,
	localesByLocale?: GraphQLTypes["JobCityLocale"] | undefined,
	paginateLocales: GraphQLTypes["JobCityLocaleConnection"]
};
	["JobCityMeta"]: {
	__typename: "JobCityMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobCityLocale"]: {
	__typename: "JobCityLocale",
	_meta?: GraphQLTypes["JobCityLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["JobCity"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	name?: string | undefined,
	slug: string
};
	["JobCityLocaleMeta"]: {
	__typename: "JobCityLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobCityLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["JobCityOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobCityLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["JobCityLocaleConnection"]: {
	__typename: "JobCityLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobCityLocaleEdge"]>
};
	["JobCityLocaleEdge"]: {
	__typename: "JobCityLocaleEdge",
	node: GraphQLTypes["JobCityLocale"]
};
	["JobPosition"]: {
	__typename: "JobPosition",
	_meta?: GraphQLTypes["JobPositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["JobPositionLocale"]>,
	jobs: Array<GraphQLTypes["JobPage"]>,
	localesByLocale?: GraphQLTypes["JobPositionLocale"] | undefined,
	jobsByLocales?: GraphQLTypes["JobPage"] | undefined,
	paginateLocales: GraphQLTypes["JobPositionLocaleConnection"],
	paginateJobs: GraphQLTypes["JobPageConnection"]
};
	["JobPositionMeta"]: {
	__typename: "JobPositionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	jobs?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobPositionLocale"]: {
	__typename: "JobPositionLocale",
	_meta?: GraphQLTypes["JobPositionLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: string | undefined,
	root?: GraphQLTypes["JobPosition"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	slug: string
};
	["JobPositionLocaleMeta"]: {
	__typename: "JobPositionLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobPositionLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["JobPositionOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobPositionLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["JobPositionJobsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["JobPositionLocaleConnection"]: {
	__typename: "JobPositionLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobPositionLocaleEdge"]>
};
	["JobPositionLocaleEdge"]: {
	__typename: "JobPositionLocaleEdge",
	node: GraphQLTypes["JobPositionLocale"]
};
	["JobPageConnection"]: {
	__typename: "JobPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobPageEdge"]>
};
	["JobPageEdge"]: {
	__typename: "JobPageEdge",
	node: GraphQLTypes["JobPage"]
};
	["JobPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["JobPageLocalesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocalesByKeyInfoUniqueWhere"]: {
		keyInfo?: GraphQLTypes["KeyInfoListUniqueWhere"] | undefined
};
	["JobPageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["JobPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["JobPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["JobPageLocalesByMobileImageUniqueWhere"]: {
		mobileImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobPageLocaleConnection"]: {
	__typename: "JobPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobPageLocaleEdge"]>
};
	["JobPageLocaleEdge"]: {
	__typename: "JobPageLocaleEdge",
	node: GraphQLTypes["JobPageLocale"]
};
	["KeyInfoList"]: {
	__typename: "KeyInfoList",
	_meta?: GraphQLTypes["KeyInfoListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["KeyInfoItem"]>,
	paginateItems: GraphQLTypes["KeyInfoItemConnection"]
};
	["KeyInfoListMeta"]: {
	__typename: "KeyInfoListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["KeyInfoItem"]: {
	__typename: "KeyInfoItem",
	_meta?: GraphQLTypes["KeyInfoItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	keyInfo?: string | undefined,
	list?: GraphQLTypes["KeyInfoList"] | undefined,
	icon?: GraphQLTypes["Icon"] | undefined
};
	["KeyInfoItemMeta"]: {
	__typename: "KeyInfoItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	keyInfo?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["Icon"]: {
	__typename: "Icon",
	_meta?: GraphQLTypes["IconMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	icon?: GraphQLTypes["Image"] | undefined
};
	["IconMeta"]: {
	__typename: "IconMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["KeyInfoItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	keyInfo?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["KeyInfoListOrderBy"] | undefined,
	icon?: GraphQLTypes["IconOrderBy"] | undefined
};
	["IconOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["KeyInfoItemConnection"]: {
	__typename: "KeyInfoItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["KeyInfoItemEdge"]>
};
	["KeyInfoItemEdge"]: {
	__typename: "KeyInfoItemEdge",
	node: GraphQLTypes["KeyInfoItem"]
};
	["HomePageLocale"]: {
	__typename: "HomePageLocale",
	_meta?: GraphQLTypes["HomePageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["HomePage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcher"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	stickyBar?: GraphQLTypes["StickyBar"] | undefined,
	text?: string | undefined,
	ilustration?: GraphQLTypes["Gallery"] | undefined
};
	["HomePageLocaleMeta"]: {
	__typename: "HomePageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	siteSwitcher?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	stickyBar?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	ilustration?: GraphQLTypes["FieldMeta"] | undefined
};
	["HomePage"]: {
	__typename: "HomePage",
	_meta?: GraphQLTypes["HomePageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["HomePageLocale"]>,
	theme?: GraphQLTypes["Theme"] | undefined,
	localesByLocale?: GraphQLTypes["HomePageLocale"] | undefined,
	localesByButton?: GraphQLTypes["HomePageLocale"] | undefined,
	localesByContent?: GraphQLTypes["HomePageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["HomePageLocale"] | undefined,
	localesByLink?: GraphQLTypes["HomePageLocale"] | undefined,
	localesByLogo?: GraphQLTypes["HomePageLocale"] | undefined,
	localesBySiteSwitcher?: GraphQLTypes["HomePageLocale"] | undefined,
	localesByIlustration?: GraphQLTypes["HomePageLocale"] | undefined,
	paginateLocales: GraphQLTypes["HomePageLocaleConnection"]
};
	["HomePageMeta"]: {
	__typename: "HomePageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["HomePageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["HomePageLocalesByButtonUniqueWhere"]: {
		button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HomePageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["HomePageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["HomePageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["HomePageLocalesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLocalesBySiteSwitcherUniqueWhere"]: {
		siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["HomePageLocalesByIlustrationUniqueWhere"]: {
		ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLocaleConnection"]: {
	__typename: "HomePageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HomePageLocaleEdge"]>
};
	["HomePageLocaleEdge"]: {
	__typename: "HomePageLocaleEdge",
	node: GraphQLTypes["HomePageLocale"]
};
	["SiteSwitcher"]: {
	__typename: "SiteSwitcher",
	_meta?: GraphQLTypes["SiteSwitcherMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	theme?: GraphQLTypes["Theme"] | undefined
};
	["SiteSwitcherMeta"]: {
	__typename: "SiteSwitcherMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["HomePageLokalLocale"]: {
	__typename: "HomePageLokalLocale",
	_meta?: GraphQLTypes["HomePageLokalLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["HomePageLokal"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	siteSwitcher?: GraphQLTypes["SiteSwitcher"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	stickyBar?: GraphQLTypes["StickyBar"] | undefined,
	text?: string | undefined,
	ilustration?: GraphQLTypes["Gallery"] | undefined
};
	["HomePageLokalLocaleMeta"]: {
	__typename: "HomePageLokalLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	siteSwitcher?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	stickyBar?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	ilustration?: GraphQLTypes["FieldMeta"] | undefined
};
	["HomePageLokal"]: {
	__typename: "HomePageLokal",
	_meta?: GraphQLTypes["HomePageLokalMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["HomePageLokalLocale"]>,
	theme?: GraphQLTypes["Theme"] | undefined,
	localesByLocale?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesByLogo?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesByButton?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesByContent?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesBySeo?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesByLink?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesBySiteSwitcher?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	localesByIlustration?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	paginateLocales: GraphQLTypes["HomePageLokalLocaleConnection"]
};
	["HomePageLokalMeta"]: {
	__typename: "HomePageLokalMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["HomePageLokalLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByButtonUniqueWhere"]: {
		button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["HomePageLokalLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["HomePageLokalLocalesBySiteSwitcherUniqueWhere"]: {
		siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["HomePageLokalLocalesByIlustrationUniqueWhere"]: {
		ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["HomePageLokalLocaleConnection"]: {
	__typename: "HomePageLokalLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HomePageLokalLocaleEdge"]>
};
	["HomePageLokalLocaleEdge"]: {
	__typename: "HomePageLokalLocaleEdge",
	node: GraphQLTypes["HomePageLokalLocale"]
};
	["FooterLinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["RecommendationFormLocale"]: {
	__typename: "RecommendationFormLocale",
	_meta?: GraphQLTypes["RecommendationFormLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	subtitle?: string | undefined,
	yourEmailLabel?: string | undefined,
	theirEmailLabel?: string | undefined,
	yourName?: string | undefined,
	sendBtnLabel?: string | undefined,
	successTitle?: string | undefined,
	successSubtitle?: string | undefined,
	successButtonClose?: string | undefined,
	root?: GraphQLTypes["RecommendationForm"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["RecommendationFormLocaleMeta"]: {
	__typename: "RecommendationFormLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	subtitle?: GraphQLTypes["FieldMeta"] | undefined,
	yourEmailLabel?: GraphQLTypes["FieldMeta"] | undefined,
	theirEmailLabel?: GraphQLTypes["FieldMeta"] | undefined,
	yourName?: GraphQLTypes["FieldMeta"] | undefined,
	sendBtnLabel?: GraphQLTypes["FieldMeta"] | undefined,
	successTitle?: GraphQLTypes["FieldMeta"] | undefined,
	successSubtitle?: GraphQLTypes["FieldMeta"] | undefined,
	successButtonClose?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["RecommendationForm"]: {
	__typename: "RecommendationForm",
	_meta?: GraphQLTypes["RecommendationFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["RecommendationFormLocale"]>,
	successImage?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["RecommendationFormLocale"] | undefined,
	paginateLocales: GraphQLTypes["RecommendationFormLocaleConnection"]
};
	["RecommendationFormMeta"]: {
	__typename: "RecommendationFormMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	successImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["RecommendationFormLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["RecommendationFormLocaleConnection"]: {
	__typename: "RecommendationFormLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RecommendationFormLocaleEdge"]>
};
	["RecommendationFormLocaleEdge"]: {
	__typename: "RecommendationFormLocaleEdge",
	node: GraphQLTypes["RecommendationFormLocale"]
};
	["FooterLinkConnection"]: {
	__typename: "FooterLinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterLinkEdge"]>
};
	["FooterLinkEdge"]: {
	__typename: "FooterLinkEdge",
	node: GraphQLTypes["FooterLink"]
};
	["HeaderLocale"]: {
	__typename: "HeaderLocale",
	_meta?: GraphQLTypes["HeaderLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["Header"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	menuLabel?: string | undefined,
	menuTitle?: string | undefined,
	menuText?: string | undefined,
	business: Array<GraphQLTypes["Business"]>,
	lokalEnterprise: Array<GraphQLTypes["Business"]>,
	businessSectionTitle?: string | undefined,
	lokalSectionTitle?: string | undefined,
	businessLink?: GraphQLTypes["Link"] | undefined,
	lokalLink?: GraphQLTypes["Link"] | undefined,
	paginateBusiness: GraphQLTypes["BusinessConnection"],
	paginateLokalEnterprise: GraphQLTypes["BusinessConnection"]
};
	["HeaderLocaleMeta"]: {
	__typename: "HeaderLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	menuLabel?: GraphQLTypes["FieldMeta"] | undefined,
	menuTitle?: GraphQLTypes["FieldMeta"] | undefined,
	menuText?: GraphQLTypes["FieldMeta"] | undefined,
	business?: GraphQLTypes["FieldMeta"] | undefined,
	lokalEnterprise?: GraphQLTypes["FieldMeta"] | undefined,
	businessSectionTitle?: GraphQLTypes["FieldMeta"] | undefined,
	lokalSectionTitle?: GraphQLTypes["FieldMeta"] | undefined,
	businessLink?: GraphQLTypes["FieldMeta"] | undefined,
	lokalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Header"]: {
	__typename: "Header",
	_meta?: GraphQLTypes["HeaderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["HeaderLocale"]>,
	logo?: GraphQLTypes["Image"] | undefined,
	logoMenu?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	localesByBusinessLink?: GraphQLTypes["HeaderLocale"] | undefined,
	localesByLokalLink?: GraphQLTypes["HeaderLocale"] | undefined,
	paginateLocales: GraphQLTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
	__typename: "HeaderMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	logoMenu?: GraphQLTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["HeaderOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	menuLabel?: GraphQLTypes["OrderDirection"] | undefined,
	menuTitle?: GraphQLTypes["OrderDirection"] | undefined,
	menuText?: GraphQLTypes["OrderDirection"] | undefined,
	businessSectionTitle?: GraphQLTypes["OrderDirection"] | undefined,
	lokalSectionTitle?: GraphQLTypes["OrderDirection"] | undefined,
	businessLink?: GraphQLTypes["LinkOrderBy"] | undefined,
	lokalLink?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	logoMenu?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderLocalesByBusinessLinkUniqueWhere"]: {
		businessLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HeaderLocalesByLokalLinkUniqueWhere"]: {
		lokalLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
	__typename: "HeaderLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
	__typename: "HeaderLocaleEdge",
	node: GraphQLTypes["HeaderLocale"]
};
	["Business"]: {
	__typename: "Business",
	_meta?: GraphQLTypes["BusinessMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	logo?: GraphQLTypes["Image"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["BusinessMeta"]: {
	__typename: "BusinessMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["BusinessConnection"]: {
	__typename: "BusinessConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessEdge"]>
};
	["BusinessEdge"]: {
	__typename: "BusinessEdge",
	node: GraphQLTypes["Business"]
};
	["SeoDefaultLocale"]: {
	__typename: "SeoDefaultLocale",
	_meta?: GraphQLTypes["SeoDefaultLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["SeoDefault"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined
};
	["SeoDefaultLocaleMeta"]: {
	__typename: "SeoDefaultLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined
};
	["SeoDefault"]: {
	__typename: "SeoDefault",
	_meta?: GraphQLTypes["SeoDefaultMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["SeoDefaultLocale"]>,
	localesByLocale?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	localesBySeo?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	paginateLocales: GraphQLTypes["SeoDefaultLocaleConnection"]
};
	["SeoDefaultMeta"]: {
	__typename: "SeoDefaultMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["SeoDefaultLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["SeoDefaultOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["SeoDefaultOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoDefaultLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SeoDefaultLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["SeoDefaultLocaleConnection"]: {
	__typename: "SeoDefaultLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoDefaultLocaleEdge"]>
};
	["SeoDefaultLocaleEdge"]: {
	__typename: "SeoDefaultLocaleEdge",
	node: GraphQLTypes["SeoDefaultLocale"]
};
	["JobsFilterLocale"]: {
	__typename: "JobsFilterLocale",
	_meta?: GraphQLTypes["JobsFilterLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	jobPositionLabel?: string | undefined,
	jobContractLabel?: string | undefined,
	jobCityLabel?: string | undefined,
	root?: GraphQLTypes["JobsFilter"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	bannerTitle?: string | undefined,
	bannerFormLabel?: string | undefined,
	bannerJobsForm?: GraphQLTypes["JobsFormLocale"] | undefined,
	bannerImage?: GraphQLTypes["Image"] | undefined,
	noJobTitle?: string | undefined,
	noJobBtnLabel?: string | undefined,
	noJobImage?: GraphQLTypes["Image"] | undefined,
	noJobTitleForSpecificBusiness?: string | undefined,
	deactivatedJob?: string | undefined
};
	["JobsFilterLocaleMeta"]: {
	__typename: "JobsFilterLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	jobPositionLabel?: GraphQLTypes["FieldMeta"] | undefined,
	jobContractLabel?: GraphQLTypes["FieldMeta"] | undefined,
	jobCityLabel?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	bannerTitle?: GraphQLTypes["FieldMeta"] | undefined,
	bannerFormLabel?: GraphQLTypes["FieldMeta"] | undefined,
	bannerJobsForm?: GraphQLTypes["FieldMeta"] | undefined,
	bannerImage?: GraphQLTypes["FieldMeta"] | undefined,
	noJobTitle?: GraphQLTypes["FieldMeta"] | undefined,
	noJobBtnLabel?: GraphQLTypes["FieldMeta"] | undefined,
	noJobImage?: GraphQLTypes["FieldMeta"] | undefined,
	noJobTitleForSpecificBusiness?: GraphQLTypes["FieldMeta"] | undefined,
	deactivatedJob?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobsFilter"]: {
	__typename: "JobsFilter",
	_meta?: GraphQLTypes["JobsFilterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["JobsFilterLocale"]>,
	localesByLocale?: GraphQLTypes["JobsFilterLocale"] | undefined,
	localesByBannerJobsForm?: GraphQLTypes["JobsFilterLocale"] | undefined,
	localesByBannerImage?: GraphQLTypes["JobsFilterLocale"] | undefined,
	localesByNoJobImage?: GraphQLTypes["JobsFilterLocale"] | undefined,
	paginateLocales: GraphQLTypes["JobsFilterLocaleConnection"]
};
	["JobsFilterMeta"]: {
	__typename: "JobsFilterMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobsFilterLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	jobPositionLabel?: GraphQLTypes["OrderDirection"] | undefined,
	jobContractLabel?: GraphQLTypes["OrderDirection"] | undefined,
	jobCityLabel?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["JobsFilterOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	bannerTitle?: GraphQLTypes["OrderDirection"] | undefined,
	bannerFormLabel?: GraphQLTypes["OrderDirection"] | undefined,
	bannerJobsForm?: GraphQLTypes["JobsFormLocaleOrderBy"] | undefined,
	bannerImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	noJobTitle?: GraphQLTypes["OrderDirection"] | undefined,
	noJobBtnLabel?: GraphQLTypes["OrderDirection"] | undefined,
	noJobImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	noJobTitleForSpecificBusiness?: GraphQLTypes["OrderDirection"] | undefined,
	deactivatedJob?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobsFilterOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobsFilterLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["JobsFilterLocalesByBannerJobsFormUniqueWhere"]: {
		bannerJobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["JobsFilterLocalesByBannerImageUniqueWhere"]: {
		bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterLocalesByNoJobImageUniqueWhere"]: {
		noJobImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["JobsFilterLocaleConnection"]: {
	__typename: "JobsFilterLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobsFilterLocaleEdge"]>
};
	["JobsFilterLocaleEdge"]: {
	__typename: "JobsFilterLocaleEdge",
	node: GraphQLTypes["JobsFilterLocale"]
};
	["GeneralLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GeneralOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["GeneralOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
		root?: GraphQLTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByTermsAndConditionsUniqueWhere"]: {
		termsAndConditions?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByBannerImageUniqueWhere"]: {
		bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleFootersByBannerLinkUniqueWhere"]: {
		bannerLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByJobsFormUniqueWhere"]: {
		jobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["LocaleFootersByCopyrightLinkUniqueWhere"]: {
		copyrightLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByRecommendationFormUniqueWhere"]: {
		recommendationForm?: GraphQLTypes["RecommendationFormLocaleUniqueWhere"] | undefined
};
	["LocaleFootersByBannerSecondLinkUniqueWhere"]: {
		bannerSecondLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleFootersByInstagramLinkUniqueWhere"]: {
		instagramLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleGenericPagesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
		root?: GraphQLTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleHeadersByBusinessLinkUniqueWhere"]: {
		businessLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHeadersByLokalLinkUniqueWhere"]: {
		lokalLink?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleSeoDefaultsByRootUniqueWhere"]: {
		root?: GraphQLTypes["SeoDefaultUniqueWhere"] | undefined
};
	["LocaleSeoDefaultsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["BusinessPageUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBusinessPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBusinessPagesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["JobPageUniqueWhere"] | undefined
};
	["LocaleJobPagesByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobPagesByKeyInfoUniqueWhere"]: {
		keyInfo?: GraphQLTypes["KeyInfoListUniqueWhere"] | undefined
};
	["LocaleJobPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleJobPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleJobPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleJobPagesByMobileImageUniqueWhere"]: {
		mobileImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleContractTypesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ContractTypeUniqueWhere"] | undefined
};
	["LocaleContractTypesBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["LocaleJobCitiesByRootUniqueWhere"]: {
		root?: GraphQLTypes["JobCityUniqueWhere"] | undefined
};
	["LocaleJobCitiesBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["LocaleJobPositionsByRootUniqueWhere"]: {
		root?: GraphQLTypes["JobPositionUniqueWhere"] | undefined
};
	["LocaleJobPositionsBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["LocaleHomePagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["HomePageUniqueWhere"] | undefined
};
	["LocaleHomePagesByButtonUniqueWhere"]: {
		button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHomePagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleHomePagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHomePagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleHomePagesByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePagesBySiteSwitcherUniqueWhere"]: {
		siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["LocaleHomePagesByIlustrationUniqueWhere"]: {
		ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["LocaleBonusBannersByRootUniqueWhere"]: {
		root?: GraphQLTypes["BonusBannerUniqueWhere"] | undefined
};
	["LocaleBonusBannersByTilesUniqueWhere"]: {
		tiles?: GraphQLTypes["TileUniqueWhere"] | undefined
};
	["LocaleBonusBannersByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByRootUniqueWhere"]: {
		root?: GraphQLTypes["HomePageLokalUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByButtonUniqueWhere"]: {
		button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"]: {
		siteSwitcher?: GraphQLTypes["SiteSwitcherUniqueWhere"] | undefined
};
	["LocaleHomePageLokalsByIlustrationUniqueWhere"]: {
		ilustration?: GraphQLTypes["GalleryUniqueWhere"] | undefined
};
	["LocaleJobsFormsByRootUniqueWhere"]: {
		root?: GraphQLTypes["JobsFormUniqueWhere"] | undefined
};
	["LocaleJobsFormsByImageTextListUniqueWhere"]: {
		imageTextList?: GraphQLTypes["ImageTextListUniqueWhere"] | undefined
};
	["LocaleJobsFormsByLogoUniqueWhere"]: {
		logo?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFormsBySuccessImageUniqueWhere"]: {
		successImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByRootUniqueWhere"]: {
		root?: GraphQLTypes["JobsFilterUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByBannerJobsFormUniqueWhere"]: {
		bannerJobsForm?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByBannerImageUniqueWhere"]: {
		bannerImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleJobsFiltersByNoJobImageUniqueWhere"]: {
		noJobImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LocaleGeneralByRootUniqueWhere"]: {
		root?: GraphQLTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleRecommendationFormsByRootUniqueWhere"]: {
		root?: GraphQLTypes["RecommendationFormUniqueWhere"] | undefined
};
	["LocaleInfoBoxesByRootUniqueWhere"]: {
		root?: GraphQLTypes["InfoBoxUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
	__typename: "GeneralLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
	__typename: "GeneralLocaleEdge",
	node: GraphQLTypes["GeneralLocale"]
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GeneralConnection"]: {
	__typename: "GeneralConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
	__typename: "GeneralEdge",
	node: GraphQLTypes["General"]
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["GenericPageConnection"]: {
	__typename: "GenericPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
	__typename: "GenericPageEdge",
	node: GraphQLTypes["GenericPage"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["FooterConnection"]: {
	__typename: "FooterConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterEdge"]>
};
	["FooterEdge"]: {
	__typename: "FooterEdge",
	node: GraphQLTypes["Footer"]
};
	["FooterLinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["HeaderConnection"]: {
	__typename: "HeaderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
	__typename: "HeaderEdge",
	node: GraphQLTypes["Header"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["SeoDefaultConnection"]: {
	__typename: "SeoDefaultConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoDefaultEdge"]>
};
	["SeoDefaultEdge"]: {
	__typename: "SeoDefaultEdge",
	node: GraphQLTypes["SeoDefault"]
};
	["BusinessUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	logo?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["BusinessPageConnection"]: {
	__typename: "BusinessPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessPageEdge"]>
};
	["BusinessPageEdge"]: {
	__typename: "BusinessPageEdge",
	node: GraphQLTypes["BusinessPage"]
};
	["IconUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	icon?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["IconConnection"]: {
	__typename: "IconConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IconEdge"]>
};
	["IconEdge"]: {
	__typename: "IconEdge",
	node: GraphQLTypes["Icon"]
};
	["KeyInfoListConnection"]: {
	__typename: "KeyInfoListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["KeyInfoListEdge"]>
};
	["KeyInfoListEdge"]: {
	__typename: "KeyInfoListEdge",
	node: GraphQLTypes["KeyInfoList"]
};
	["ContractTypeConnection"]: {
	__typename: "ContractTypeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContractTypeEdge"]>
};
	["ContractTypeEdge"]: {
	__typename: "ContractTypeEdge",
	node: GraphQLTypes["ContractType"]
};
	["JobCityConnection"]: {
	__typename: "JobCityConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobCityEdge"]>
};
	["JobCityEdge"]: {
	__typename: "JobCityEdge",
	node: GraphQLTypes["JobCity"]
};
	["JobPositionConnection"]: {
	__typename: "JobPositionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobPositionEdge"]>
};
	["JobPositionEdge"]: {
	__typename: "JobPositionEdge",
	node: GraphQLTypes["JobPosition"]
};
	["GalleryConnection"]: {
	__typename: "GalleryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
	__typename: "GalleryEdge",
	node: GraphQLTypes["Gallery"]
};
	["HomePageConnection"]: {
	__typename: "HomePageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HomePageEdge"]>
};
	["HomePageEdge"]: {
	__typename: "HomePageEdge",
	node: GraphQLTypes["HomePage"]
};
	["MediumUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MediumConnection"]: {
	__typename: "MediumConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
	__typename: "MediumEdge",
	node: GraphQLTypes["Medium"]
};
	["MediumListConnection"]: {
	__typename: "MediumListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumListEdge"]>
};
	["MediumListEdge"]: {
	__typename: "MediumListEdge",
	node: GraphQLTypes["MediumList"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["TextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TextItemUniqueWhere"] | undefined
};
	["TextListConnection"]: {
	__typename: "TextListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
	__typename: "TextListEdge",
	node: GraphQLTypes["TextList"]
};
	["CarouselUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["CarouselItemUniqueWhere"] | undefined
};
	["CarouselItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	linkUrl?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["CarouselConnection"]: {
	__typename: "CarouselConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CarouselEdge"]>
};
	["CarouselEdge"]: {
	__typename: "CarouselEdge",
	node: GraphQLTypes["Carousel"]
};
	["ThemeConnection"]: {
	__typename: "ThemeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ThemeEdge"]>
};
	["ThemeEdge"]: {
	__typename: "ThemeEdge",
	node: GraphQLTypes["Theme"]
};
	["BonusBannerConnection"]: {
	__typename: "BonusBannerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BonusBannerEdge"]>
};
	["BonusBannerEdge"]: {
	__typename: "BonusBannerEdge",
	node: GraphQLTypes["BonusBanner"]
};
	["SideBySideUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["SideBySideItemUniqueWhere"] | undefined
};
	["SideBySideItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	media?: GraphQLTypes["MediumListUniqueWhere"] | undefined
};
	["SideBySideConnection"]: {
	__typename: "SideBySideConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SideBySideEdge"]>
};
	["SideBySideEdge"]: {
	__typename: "SideBySideEdge",
	node: GraphQLTypes["SideBySide"]
};
	["HomePageLokalConnection"]: {
	__typename: "HomePageLokalConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HomePageLokalEdge"]>
};
	["HomePageLokalEdge"]: {
	__typename: "HomePageLokalEdge",
	node: GraphQLTypes["HomePageLokal"]
};
	["SiteSwitcherConnection"]: {
	__typename: "SiteSwitcherConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SiteSwitcherEdge"]>
};
	["SiteSwitcherEdge"]: {
	__typename: "SiteSwitcherEdge",
	node: GraphQLTypes["SiteSwitcher"]
};
	["JobsFormConnection"]: {
	__typename: "JobsFormConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobsFormEdge"]>
};
	["JobsFormEdge"]: {
	__typename: "JobsFormEdge",
	node: GraphQLTypes["JobsForm"]
};
	["ImageTextListConnection"]: {
	__typename: "ImageTextListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageTextListEdge"]>
};
	["ImageTextListEdge"]: {
	__typename: "ImageTextListEdge",
	node: GraphQLTypes["ImageTextList"]
};
	["JobsFilterConnection"]: {
	__typename: "JobsFilterConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobsFilterEdge"]>
};
	["JobsFilterEdge"]: {
	__typename: "JobsFilterEdge",
	node: GraphQLTypes["JobsFilter"]
};
	["File"]: {
	__typename: "File",
	_meta?: GraphQLTypes["FileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	size?: number | undefined,
	type?: string | undefined
};
	["FileMeta"]: {
	__typename: "FileMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["FileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FileWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FileWhere"] | undefined
};
	["FileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["FileConnection"]: {
	__typename: "FileConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FileEdge"]>
};
	["FileEdge"]: {
	__typename: "FileEdge",
	node: GraphQLTypes["File"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
        	__typename:"_FieldPathFragment" | "_IndexPathFragment"
        	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["FileCreateInput"]: {
		url?: string | undefined,
	size?: number | undefined,
	type?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["JobFormSubscriptionCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	email?: string | undefined,
	message?: string | undefined,
	file?: GraphQLTypes["JobFormSubscriptionCreateFileEntityRelationInput"] | undefined,
	job?: GraphQLTypes["JobFormSubscriptionCreateJobEntityRelationInput"] | undefined,
	status?: GraphQLTypes["JobFormSubscriptionCreateStatusEntityRelationInput"] | undefined,
	isSyncedToTabidoo?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["JobFormSubscriptionCreateFileEntityRelationInput"]: {
		connect?: GraphQLTypes["FileUniqueWhere"] | undefined,
	create?: GraphQLTypes["FileCreateInput"] | undefined,
	connectOrCreate?: GraphQLTypes["JobFormSubscriptionConnectOrCreateFileRelationInput"] | undefined
};
	["JobFormSubscriptionConnectOrCreateFileRelationInput"]: {
		connect: GraphQLTypes["FileUniqueWhere"],
	create: GraphQLTypes["FileCreateInput"]
};
	["JobFormSubscriptionCreateJobEntityRelationInput"]: {
		connect?: GraphQLTypes["JobPageLocaleUniqueWhere"] | undefined
};
	["JobFormSubscriptionCreateStatusEntityRelationInput"]: {
		connect?: GraphQLTypes["JobFormSubscriptionStatusUniqueWhere"] | undefined
};
	["JobFormSubscriptionStatusUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["JobFormSubscriptionStatus"]: {
	__typename: "JobFormSubscriptionStatus",
	_meta?: GraphQLTypes["JobFormSubscriptionStatusMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	statusTitle?: string | undefined,
	statusColor?: string | undefined
};
	["JobFormSubscriptionStatusMeta"]: {
	__typename: "JobFormSubscriptionStatusMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	statusTitle?: GraphQLTypes["FieldMeta"] | undefined,
	statusColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["JobFormSubscriptionStatusWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	statusTitle?: GraphQLTypes["StringCondition"] | undefined,
	statusColor?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["JobFormSubscriptionStatusWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["JobFormSubscriptionStatusWhere"] | undefined> | undefined,
	not?: GraphQLTypes["JobFormSubscriptionStatusWhere"] | undefined
};
	["JobFormSubscriptionStatusOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	statusTitle?: GraphQLTypes["OrderDirection"] | undefined,
	statusColor?: GraphQLTypes["OrderDirection"] | undefined
};
	["JobFormSubscriptionStatusConnection"]: {
	__typename: "JobFormSubscriptionStatusConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["JobFormSubscriptionStatusEdge"]>
};
	["JobFormSubscriptionStatusEdge"]: {
	__typename: "JobFormSubscriptionStatusEdge",
	node: GraphQLTypes["JobFormSubscriptionStatus"]
};
	["StickyBarUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["StickyBarConnection"]: {
	__typename: "StickyBarConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StickyBarEdge"]>
};
	["StickyBarEdge"]: {
	__typename: "StickyBarEdge",
	node: GraphQLTypes["StickyBar"]
};
	["StickyBarCreateInput"]: {
		title?: string | undefined,
	link?: GraphQLTypes["StickyBarCreateLinkEntityRelationInput"] | undefined,
	formButtonLabel?: string | undefined,
	jobsForm?: GraphQLTypes["StickyBarCreateJobsFormEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["StickyBarCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["StickyBarCreateJobsFormEntityRelationInput"]: {
		connect?: GraphQLTypes["JobsFormLocaleUniqueWhere"] | undefined
};
	["RecommendationFormConnection"]: {
	__typename: "RecommendationFormConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RecommendationFormEdge"]>
};
	["RecommendationFormEdge"]: {
	__typename: "RecommendationFormEdge",
	node: GraphQLTypes["RecommendationForm"]
};
	["RecommendationFormSubscriptionCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	yourEmail?: string | undefined,
	theirEmail?: string | undefined,
	yourName?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["InfoBoxConnection"]: {
	__typename: "InfoBoxConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InfoBoxEdge"]>
};
	["InfoBoxEdge"]: {
	__typename: "InfoBoxEdge",
	node: GraphQLTypes["InfoBox"]
};
	["InfoBoxCreateInput"]: {
		logoSize?: GraphQLTypes["LogoSize"] | undefined,
	logo?: GraphQLTypes["InfoBoxCreateLogoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["InfoBoxCreateLogoEntityRelationInput"]: {
		connect?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	schema?: GraphQLTypes["_Schema"] | undefined,
	s3DummyQuery?: string | undefined,
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getContentReferenceLinkableItem?: GraphQLTypes["ContentReferenceLinkableItem"] | undefined,
	listContentReferenceLinkableItem: Array<GraphQLTypes["ContentReferenceLinkableItem"]>,
	paginateContentReferenceLinkableItem: GraphQLTypes["ContentReferenceLinkableItemConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterLink?: GraphQLTypes["FooterLink"] | undefined,
	listFooterLink: Array<GraphQLTypes["FooterLink"]>,
	paginateFooterLink: GraphQLTypes["FooterLinkConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getSeoDefault?: GraphQLTypes["SeoDefault"] | undefined,
	listSeoDefault: Array<GraphQLTypes["SeoDefault"]>,
	paginateSeoDefault: GraphQLTypes["SeoDefaultConnection"],
	getSeoDefaultLocale?: GraphQLTypes["SeoDefaultLocale"] | undefined,
	listSeoDefaultLocale: Array<GraphQLTypes["SeoDefaultLocale"]>,
	paginateSeoDefaultLocale: GraphQLTypes["SeoDefaultLocaleConnection"],
	getBusiness?: GraphQLTypes["Business"] | undefined,
	listBusiness: Array<GraphQLTypes["Business"]>,
	paginateBusiness: GraphQLTypes["BusinessConnection"],
	getBusinessPage?: GraphQLTypes["BusinessPage"] | undefined,
	listBusinessPage: Array<GraphQLTypes["BusinessPage"]>,
	paginateBusinessPage: GraphQLTypes["BusinessPageConnection"],
	getBusinessPageLocale?: GraphQLTypes["BusinessPageLocale"] | undefined,
	listBusinessPageLocale: Array<GraphQLTypes["BusinessPageLocale"]>,
	paginateBusinessPageLocale: GraphQLTypes["BusinessPageLocaleConnection"],
	getIcon?: GraphQLTypes["Icon"] | undefined,
	listIcon: Array<GraphQLTypes["Icon"]>,
	paginateIcon: GraphQLTypes["IconConnection"],
	getJobPage?: GraphQLTypes["JobPage"] | undefined,
	listJobPage: Array<GraphQLTypes["JobPage"]>,
	paginateJobPage: GraphQLTypes["JobPageConnection"],
	getJobPageLocale?: GraphQLTypes["JobPageLocale"] | undefined,
	listJobPageLocale: Array<GraphQLTypes["JobPageLocale"]>,
	paginateJobPageLocale: GraphQLTypes["JobPageLocaleConnection"],
	getKeyInfoItem?: GraphQLTypes["KeyInfoItem"] | undefined,
	listKeyInfoItem: Array<GraphQLTypes["KeyInfoItem"]>,
	paginateKeyInfoItem: GraphQLTypes["KeyInfoItemConnection"],
	getKeyInfoList?: GraphQLTypes["KeyInfoList"] | undefined,
	listKeyInfoList: Array<GraphQLTypes["KeyInfoList"]>,
	paginateKeyInfoList: GraphQLTypes["KeyInfoListConnection"],
	getContractType?: GraphQLTypes["ContractType"] | undefined,
	listContractType: Array<GraphQLTypes["ContractType"]>,
	paginateContractType: GraphQLTypes["ContractTypeConnection"],
	getContractTypeLocale?: GraphQLTypes["ContractTypeLocale"] | undefined,
	listContractTypeLocale: Array<GraphQLTypes["ContractTypeLocale"]>,
	paginateContractTypeLocale: GraphQLTypes["ContractTypeLocaleConnection"],
	getJobCity?: GraphQLTypes["JobCity"] | undefined,
	listJobCity: Array<GraphQLTypes["JobCity"]>,
	paginateJobCity: GraphQLTypes["JobCityConnection"],
	getJobCityLocale?: GraphQLTypes["JobCityLocale"] | undefined,
	listJobCityLocale: Array<GraphQLTypes["JobCityLocale"]>,
	paginateJobCityLocale: GraphQLTypes["JobCityLocaleConnection"],
	getJobPosition?: GraphQLTypes["JobPosition"] | undefined,
	listJobPosition: Array<GraphQLTypes["JobPosition"]>,
	paginateJobPosition: GraphQLTypes["JobPositionConnection"],
	getJobPositionLocale?: GraphQLTypes["JobPositionLocale"] | undefined,
	listJobPositionLocale: Array<GraphQLTypes["JobPositionLocale"]>,
	paginateJobPositionLocale: GraphQLTypes["JobPositionLocaleConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getHomePage?: GraphQLTypes["HomePage"] | undefined,
	listHomePage: Array<GraphQLTypes["HomePage"]>,
	paginateHomePage: GraphQLTypes["HomePageConnection"],
	getHomePageLocale?: GraphQLTypes["HomePageLocale"] | undefined,
	listHomePageLocale: Array<GraphQLTypes["HomePageLocale"]>,
	paginateHomePageLocale: GraphQLTypes["HomePageLocaleConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getMediumItem?: GraphQLTypes["MediumItem"] | undefined,
	listMediumItem: Array<GraphQLTypes["MediumItem"]>,
	paginateMediumItem: GraphQLTypes["MediumItemConnection"],
	getMediumList?: GraphQLTypes["MediumList"] | undefined,
	listMediumList: Array<GraphQLTypes["MediumList"]>,
	paginateMediumList: GraphQLTypes["MediumListConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getCarousel?: GraphQLTypes["Carousel"] | undefined,
	listCarousel: Array<GraphQLTypes["Carousel"]>,
	paginateCarousel: GraphQLTypes["CarouselConnection"],
	getCarouselItem?: GraphQLTypes["CarouselItem"] | undefined,
	listCarouselItem: Array<GraphQLTypes["CarouselItem"]>,
	paginateCarouselItem: GraphQLTypes["CarouselItemConnection"],
	getTheme?: GraphQLTypes["Theme"] | undefined,
	listTheme: Array<GraphQLTypes["Theme"]>,
	paginateTheme: GraphQLTypes["ThemeConnection"],
	getBonusBanner?: GraphQLTypes["BonusBanner"] | undefined,
	listBonusBanner: Array<GraphQLTypes["BonusBanner"]>,
	paginateBonusBanner: GraphQLTypes["BonusBannerConnection"],
	getBonusBannerLocale?: GraphQLTypes["BonusBannerLocale"] | undefined,
	listBonusBannerLocale: Array<GraphQLTypes["BonusBannerLocale"]>,
	paginateBonusBannerLocale: GraphQLTypes["BonusBannerLocaleConnection"],
	getTile?: GraphQLTypes["Tile"] | undefined,
	listTile: Array<GraphQLTypes["Tile"]>,
	paginateTile: GraphQLTypes["TileConnection"],
	getSideBySide?: GraphQLTypes["SideBySide"] | undefined,
	listSideBySide: Array<GraphQLTypes["SideBySide"]>,
	paginateSideBySide: GraphQLTypes["SideBySideConnection"],
	getSideBySideItem?: GraphQLTypes["SideBySideItem"] | undefined,
	listSideBySideItem: Array<GraphQLTypes["SideBySideItem"]>,
	paginateSideBySideItem: GraphQLTypes["SideBySideItemConnection"],
	getHomePageLokal?: GraphQLTypes["HomePageLokal"] | undefined,
	listHomePageLokal: Array<GraphQLTypes["HomePageLokal"]>,
	paginateHomePageLokal: GraphQLTypes["HomePageLokalConnection"],
	getHomePageLokalLocale?: GraphQLTypes["HomePageLokalLocale"] | undefined,
	listHomePageLokalLocale: Array<GraphQLTypes["HomePageLokalLocale"]>,
	paginateHomePageLokalLocale: GraphQLTypes["HomePageLokalLocaleConnection"],
	getSiteSwitcher?: GraphQLTypes["SiteSwitcher"] | undefined,
	listSiteSwitcher: Array<GraphQLTypes["SiteSwitcher"]>,
	paginateSiteSwitcher: GraphQLTypes["SiteSwitcherConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getJobsForm?: GraphQLTypes["JobsForm"] | undefined,
	listJobsForm: Array<GraphQLTypes["JobsForm"]>,
	paginateJobsForm: GraphQLTypes["JobsFormConnection"],
	getJobsFormLocale?: GraphQLTypes["JobsFormLocale"] | undefined,
	listJobsFormLocale: Array<GraphQLTypes["JobsFormLocale"]>,
	paginateJobsFormLocale: GraphQLTypes["JobsFormLocaleConnection"],
	getImageTextItem?: GraphQLTypes["ImageTextItem"] | undefined,
	listImageTextItem: Array<GraphQLTypes["ImageTextItem"]>,
	paginateImageTextItem: GraphQLTypes["ImageTextItemConnection"],
	getImageTextList?: GraphQLTypes["ImageTextList"] | undefined,
	listImageTextList: Array<GraphQLTypes["ImageTextList"]>,
	paginateImageTextList: GraphQLTypes["ImageTextListConnection"],
	getJobsFilter?: GraphQLTypes["JobsFilter"] | undefined,
	listJobsFilter: Array<GraphQLTypes["JobsFilter"]>,
	paginateJobsFilter: GraphQLTypes["JobsFilterConnection"],
	getJobsFilterLocale?: GraphQLTypes["JobsFilterLocale"] | undefined,
	listJobsFilterLocale: Array<GraphQLTypes["JobsFilterLocale"]>,
	paginateJobsFilterLocale: GraphQLTypes["JobsFilterLocaleConnection"],
	getFile?: GraphQLTypes["File"] | undefined,
	listFile: Array<GraphQLTypes["File"]>,
	paginateFile: GraphQLTypes["FileConnection"],
	validateCreateFile: GraphQLTypes["_ValidationResult"],
	validateCreateJobFormSubscription: GraphQLTypes["_ValidationResult"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getJobFormSubscriptionStatus?: GraphQLTypes["JobFormSubscriptionStatus"] | undefined,
	listJobFormSubscriptionStatus: Array<GraphQLTypes["JobFormSubscriptionStatus"]>,
	paginateJobFormSubscriptionStatus: GraphQLTypes["JobFormSubscriptionStatusConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getStickyBar?: GraphQLTypes["StickyBar"] | undefined,
	listStickyBar: Array<GraphQLTypes["StickyBar"]>,
	paginateStickyBar: GraphQLTypes["StickyBarConnection"],
	validateCreateStickyBar: GraphQLTypes["_ValidationResult"],
	getRecommendationForm?: GraphQLTypes["RecommendationForm"] | undefined,
	listRecommendationForm: Array<GraphQLTypes["RecommendationForm"]>,
	paginateRecommendationForm: GraphQLTypes["RecommendationFormConnection"],
	getRecommendationFormLocale?: GraphQLTypes["RecommendationFormLocale"] | undefined,
	listRecommendationFormLocale: Array<GraphQLTypes["RecommendationFormLocale"]>,
	paginateRecommendationFormLocale: GraphQLTypes["RecommendationFormLocaleConnection"],
	validateCreateRecommendationFormSubscription: GraphQLTypes["_ValidationResult"],
	getInfoBox?: GraphQLTypes["InfoBox"] | undefined,
	listInfoBox: Array<GraphQLTypes["InfoBox"]>,
	paginateInfoBox: GraphQLTypes["InfoBoxConnection"],
	validateCreateInfoBox: GraphQLTypes["_ValidationResult"],
	getInfoBoxLocale?: GraphQLTypes["InfoBoxLocale"] | undefined,
	listInfoBoxLocale: Array<GraphQLTypes["InfoBoxLocale"]>,
	paginateInfoBoxLocale: GraphQLTypes["InfoBoxLocaleConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	generateUploadUrl: GraphQLTypes["S3SignedUpload"],
	generateReadUrl: GraphQLTypes["S3SignedRead"],
	createFile: GraphQLTypes["FileCreateResult"],
	createJobFormSubscription: GraphQLTypes["JobFormSubscriptionCreateResult"],
	createStickyBar: GraphQLTypes["StickyBarCreateResult"],
	createRecommendationFormSubscription: GraphQLTypes["RecommendationFormSubscriptionCreateResult"],
	createInfoBox: GraphQLTypes["InfoBoxCreateResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["S3SignedUpload"]: {
	__typename: "S3SignedUpload",
	url: string,
	headers: Array<GraphQLTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string,
	publicUrl: string
};
	["S3Header"]: {
	__typename: "S3Header",
	key: string,
	value: string
};
	["S3GenerateSignedUploadInput"]: {
		contentType?: string | undefined,
	/** If not provided, extension is detected from a content-type. */
	extension?: string | undefined,
	/** Signed URL expiration. */
	expiration?: number | undefined,
	/** Uploaded file size. Required when you enable ACL size limits. */
	size?: number | undefined,
	/** Can be used as a "directory". */
	prefix?: string | undefined,
	/** Suffix after generated id and before the extension. */
	suffix?: string | undefined,
	/** This only affects Content-disposition header. Does not affect actual object key. */
	fileName?: string | undefined,
	contentDisposition?: GraphQLTypes["S33ContentDisposition"] | undefined,
	/** If not supported by S3 provider, an error is thrown. */
	acl?: GraphQLTypes["S3Acl"] | undefined
};
	["S33ContentDisposition"]: S33ContentDisposition;
	["S3Acl"]: S3Acl;
	["S3SignedRead"]: {
	__typename: "S3SignedRead",
	url: string,
	headers: Array<GraphQLTypes["S3Header"]>,
	method: string,
	objectKey: string,
	bucket: string
};
	["FileCreateResult"]: {
	__typename: "FileCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["File"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"FileCreateResult" | "JobFormSubscriptionCreateResult" | "StickyBarCreateResult" | "RecommendationFormSubscriptionCreateResult" | "InfoBoxCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on FileCreateResult']: '__union' & GraphQLTypes["FileCreateResult"];
	['...on JobFormSubscriptionCreateResult']: '__union' & GraphQLTypes["JobFormSubscriptionCreateResult"];
	['...on StickyBarCreateResult']: '__union' & GraphQLTypes["StickyBarCreateResult"];
	['...on RecommendationFormSubscriptionCreateResult']: '__union' & GraphQLTypes["RecommendationFormSubscriptionCreateResult"];
	['...on InfoBoxCreateResult']: '__union' & GraphQLTypes["InfoBoxCreateResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: _MutationErrorType;
	["JobFormSubscriptionCreateResult"]: {
	__typename: "JobFormSubscriptionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["StickyBarCreateResult"]: {
	__typename: "StickyBarCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["StickyBar"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["RecommendationFormSubscriptionCreateResult"]: {
	__typename: "RecommendationFormSubscriptionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["InfoBoxCreateResult"]: {
	__typename: "InfoBoxCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["InfoBox"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	generateUploadUrl: GraphQLTypes["S3SignedUpload"],
	generateReadUrl: GraphQLTypes["S3SignedRead"],
	createFile: GraphQLTypes["FileCreateResult"],
	createJobFormSubscription: GraphQLTypes["JobFormSubscriptionCreateResult"],
	createStickyBar: GraphQLTypes["StickyBarCreateResult"],
	createRecommendationFormSubscription: GraphQLTypes["RecommendationFormSubscriptionCreateResult"],
	createInfoBox: GraphQLTypes["InfoBoxCreateResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined
}
    }
export const enum _RelationSide {
	owning = "owning",
	inverse = "inverse"
}
export const enum _OnDeleteBehaviour {
	restrict = "restrict",
	cascade = "cascade",
	setNull = "setNull"
}
export const enum _OrderByDirection {
	asc = "asc",
	desc = "desc"
}
export const enum One {
	One = "One"
}
export const enum LinkType {
	internal = "internal",
	external = "external",
	anchor = "anchor"
}
export const enum ContentReferenceType {
	image = "image",
	video = "video",
	sideBySideContent = "sideBySideContent",
	linkables = "linkables",
	statistics = "statistics",
	collapsible = "collapsible",
	carousel = "carousel",
	bonusBanner = "bonusBanner",
	grid = "grid",
	gridGallery = "gridGallery",
	jobFilter = "jobFilter",
	anchor = "anchor",
	articlesCarousel = "articlesCarousel",
	gallery = "gallery"
}
export const enum MediumType {
	image = "image",
	video = "video"
}
export const enum Background {
	red = "red",
	lightGrey = "lightGrey",
	inherit = "inherit",
	lokal = "lokal"
}
export const enum SideBySideItemType {
	default = "default",
	jobsForm = "jobsForm"
}
export const enum ContentAlignment {
	left = "left",
	center = "center",
	right = "right"
}
export const enum MediumSize {
	small = "small",
	medium = "medium",
	big = "big"
}
export const enum LogoSize {
	small = "small",
	medium = "medium",
	big = "big"
}
export const enum OrderDirection {
	asc = "asc",
	desc = "desc",
	ascNullsFirst = "ascNullsFirst",
	descNullsLast = "descNullsLast"
}
export const enum S33ContentDisposition {
	ATTACHMENT = "ATTACHMENT",
	INLINE = "INLINE"
}
export const enum S3Acl {
	PUBLIC_READ = "PUBLIC_READ",
	PRIVATE = "PRIVATE",
	NONE = "NONE"
}
export const enum _MutationErrorType {
	NotNullConstraintViolation = "NotNullConstraintViolation",
	UniqueConstraintViolation = "UniqueConstraintViolation",
	ForeignKeyConstraintViolation = "ForeignKeyConstraintViolation",
	NotFoundOrDenied = "NotFoundOrDenied",
	NonUniqueWhereInput = "NonUniqueWhereInput",
	InvalidDataInput = "InvalidDataInput",
	SqlError = "SqlError"
}

type ZEUS_VARIABLES = {
	["Json"]: ValueTypes["Json"];
	["_RelationSide"]: ValueTypes["_RelationSide"];
	["_OnDeleteBehaviour"]: ValueTypes["_OnDeleteBehaviour"];
	["_OrderByDirection"]: ValueTypes["_OrderByDirection"];
	["UUID"]: ValueTypes["UUID"];
	["One"]: ValueTypes["One"];
	["GeneralWhere"]: ValueTypes["GeneralWhere"];
	["UUIDCondition"]: ValueTypes["UUIDCondition"];
	["OneCondition"]: ValueTypes["OneCondition"];
	["GeneralLocaleWhere"]: ValueTypes["GeneralLocaleWhere"];
	["LocaleWhere"]: ValueTypes["LocaleWhere"];
	["StringCondition"]: ValueTypes["StringCondition"];
	["FooterLocaleWhere"]: ValueTypes["FooterLocaleWhere"];
	["FooterWhere"]: ValueTypes["FooterWhere"];
	["FooterLinkWhere"]: ValueTypes["FooterLinkWhere"];
	["IntCondition"]: ValueTypes["IntCondition"];
	["LinkWhere"]: ValueTypes["LinkWhere"];
	["LinkTypeCondition"]: ValueTypes["LinkTypeCondition"];
	["LinkType"]: ValueTypes["LinkType"];
	["LinkableWhere"]: ValueTypes["LinkableWhere"];
	["RedirectWhere"]: ValueTypes["RedirectWhere"];
	["GenericPageLocaleWhere"]: ValueTypes["GenericPageLocaleWhere"];
	["GenericPageWhere"]: ValueTypes["GenericPageWhere"];
	["ThemeWhere"]: ValueTypes["ThemeWhere"];
	["ContentWhere"]: ValueTypes["ContentWhere"];
	["ContentBlockWhere"]: ValueTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: ValueTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: ValueTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: ValueTypes["ContentReferenceType"];
	["ImageWhere"]: ValueTypes["ImageWhere"];
	["FloatCondition"]: ValueTypes["FloatCondition"];
	["ContentReferenceLinkableItemWhere"]: ValueTypes["ContentReferenceLinkableItemWhere"];
	["MediumListWhere"]: ValueTypes["MediumListWhere"];
	["MediumItemWhere"]: ValueTypes["MediumItemWhere"];
	["MediumWhere"]: ValueTypes["MediumWhere"];
	["MediumTypeCondition"]: ValueTypes["MediumTypeCondition"];
	["MediumType"]: ValueTypes["MediumType"];
	["VideoWhere"]: ValueTypes["VideoWhere"];
	["TextListWhere"]: ValueTypes["TextListWhere"];
	["TextItemWhere"]: ValueTypes["TextItemWhere"];
	["CarouselWhere"]: ValueTypes["CarouselWhere"];
	["CarouselItemWhere"]: ValueTypes["CarouselItemWhere"];
	["BackgroundCondition"]: ValueTypes["BackgroundCondition"];
	["Background"]: ValueTypes["Background"];
	["BooleanCondition"]: ValueTypes["BooleanCondition"];
	["BonusBannerLocaleWhere"]: ValueTypes["BonusBannerLocaleWhere"];
	["BonusBannerWhere"]: ValueTypes["BonusBannerWhere"];
	["TileWhere"]: ValueTypes["TileWhere"];
	["JobsFormLocaleWhere"]: ValueTypes["JobsFormLocaleWhere"];
	["JobsFormWhere"]: ValueTypes["JobsFormWhere"];
	["ImageTextListWhere"]: ValueTypes["ImageTextListWhere"];
	["ImageTextItemWhere"]: ValueTypes["ImageTextItemWhere"];
	["SideBySideWhere"]: ValueTypes["SideBySideWhere"];
	["SideBySideItemWhere"]: ValueTypes["SideBySideItemWhere"];
	["SideBySideItemTypeCondition"]: ValueTypes["SideBySideItemTypeCondition"];
	["SideBySideItemType"]: ValueTypes["SideBySideItemType"];
	["ContentAlignmentCondition"]: ValueTypes["ContentAlignmentCondition"];
	["ContentAlignment"]: ValueTypes["ContentAlignment"];
	["MediumSizeCondition"]: ValueTypes["MediumSizeCondition"];
	["MediumSize"]: ValueTypes["MediumSize"];
	["GalleryWhere"]: ValueTypes["GalleryWhere"];
	["GalleryItemWhere"]: ValueTypes["GalleryItemWhere"];
	["SeoWhere"]: ValueTypes["SeoWhere"];
	["BusinessPageLocaleWhere"]: ValueTypes["BusinessPageLocaleWhere"];
	["BusinessPageWhere"]: ValueTypes["BusinessPageWhere"];
	["StickyBarWhere"]: ValueTypes["StickyBarWhere"];
	["InfoBoxWhere"]: ValueTypes["InfoBoxWhere"];
	["LogoSizeCondition"]: ValueTypes["LogoSizeCondition"];
	["LogoSize"]: ValueTypes["LogoSize"];
	["InfoBoxLocaleWhere"]: ValueTypes["InfoBoxLocaleWhere"];
	["JobPageLocaleWhere"]: ValueTypes["JobPageLocaleWhere"];
	["JobPageWhere"]: ValueTypes["JobPageWhere"];
	["ContractTypeWhere"]: ValueTypes["ContractTypeWhere"];
	["ContractTypeLocaleWhere"]: ValueTypes["ContractTypeLocaleWhere"];
	["JobCityWhere"]: ValueTypes["JobCityWhere"];
	["JobCityLocaleWhere"]: ValueTypes["JobCityLocaleWhere"];
	["JobPositionWhere"]: ValueTypes["JobPositionWhere"];
	["JobPositionLocaleWhere"]: ValueTypes["JobPositionLocaleWhere"];
	["DateTimeCondition"]: ValueTypes["DateTimeCondition"];
	["DateTime"]: ValueTypes["DateTime"];
	["KeyInfoListWhere"]: ValueTypes["KeyInfoListWhere"];
	["KeyInfoItemWhere"]: ValueTypes["KeyInfoItemWhere"];
	["IconWhere"]: ValueTypes["IconWhere"];
	["HomePageLocaleWhere"]: ValueTypes["HomePageLocaleWhere"];
	["HomePageWhere"]: ValueTypes["HomePageWhere"];
	["SiteSwitcherWhere"]: ValueTypes["SiteSwitcherWhere"];
	["HomePageLokalLocaleWhere"]: ValueTypes["HomePageLokalLocaleWhere"];
	["HomePageLokalWhere"]: ValueTypes["HomePageLokalWhere"];
	["RecommendationFormLocaleWhere"]: ValueTypes["RecommendationFormLocaleWhere"];
	["RecommendationFormWhere"]: ValueTypes["RecommendationFormWhere"];
	["HeaderLocaleWhere"]: ValueTypes["HeaderLocaleWhere"];
	["HeaderWhere"]: ValueTypes["HeaderWhere"];
	["BusinessWhere"]: ValueTypes["BusinessWhere"];
	["SeoDefaultLocaleWhere"]: ValueTypes["SeoDefaultLocaleWhere"];
	["SeoDefaultWhere"]: ValueTypes["SeoDefaultWhere"];
	["JobsFilterLocaleWhere"]: ValueTypes["JobsFilterLocaleWhere"];
	["JobsFilterWhere"]: ValueTypes["JobsFilterWhere"];
	["FooterLocaleOrderBy"]: ValueTypes["FooterLocaleOrderBy"];
	["OrderDirection"]: ValueTypes["OrderDirection"];
	["FooterOrderBy"]: ValueTypes["FooterOrderBy"];
	["LocaleOrderBy"]: ValueTypes["LocaleOrderBy"];
	["LinkOrderBy"]: ValueTypes["LinkOrderBy"];
	["LinkableOrderBy"]: ValueTypes["LinkableOrderBy"];
	["RedirectOrderBy"]: ValueTypes["RedirectOrderBy"];
	["GenericPageLocaleOrderBy"]: ValueTypes["GenericPageLocaleOrderBy"];
	["GenericPageOrderBy"]: ValueTypes["GenericPageOrderBy"];
	["ThemeOrderBy"]: ValueTypes["ThemeOrderBy"];
	["ContentOrderBy"]: ValueTypes["ContentOrderBy"];
	["SeoOrderBy"]: ValueTypes["SeoOrderBy"];
	["ImageOrderBy"]: ValueTypes["ImageOrderBy"];
	["BusinessPageLocaleOrderBy"]: ValueTypes["BusinessPageLocaleOrderBy"];
	["BusinessPageOrderBy"]: ValueTypes["BusinessPageOrderBy"];
	["StickyBarOrderBy"]: ValueTypes["StickyBarOrderBy"];
	["JobsFormLocaleOrderBy"]: ValueTypes["JobsFormLocaleOrderBy"];
	["JobsFormOrderBy"]: ValueTypes["JobsFormOrderBy"];
	["ImageTextListOrderBy"]: ValueTypes["ImageTextListOrderBy"];
	["InfoBoxOrderBy"]: ValueTypes["InfoBoxOrderBy"];
	["JobPageLocaleOrderBy"]: ValueTypes["JobPageLocaleOrderBy"];
	["JobPageOrderBy"]: ValueTypes["JobPageOrderBy"];
	["ContractTypeOrderBy"]: ValueTypes["ContractTypeOrderBy"];
	["JobCityOrderBy"]: ValueTypes["JobCityOrderBy"];
	["JobPositionOrderBy"]: ValueTypes["JobPositionOrderBy"];
	["KeyInfoListOrderBy"]: ValueTypes["KeyInfoListOrderBy"];
	["HomePageLocaleOrderBy"]: ValueTypes["HomePageLocaleOrderBy"];
	["HomePageOrderBy"]: ValueTypes["HomePageOrderBy"];
	["SiteSwitcherOrderBy"]: ValueTypes["SiteSwitcherOrderBy"];
	["GalleryOrderBy"]: ValueTypes["GalleryOrderBy"];
	["HomePageLokalLocaleOrderBy"]: ValueTypes["HomePageLokalLocaleOrderBy"];
	["HomePageLokalOrderBy"]: ValueTypes["HomePageLokalOrderBy"];
	["RecommendationFormLocaleOrderBy"]: ValueTypes["RecommendationFormLocaleOrderBy"];
	["RecommendationFormOrderBy"]: ValueTypes["RecommendationFormOrderBy"];
	["FooterLocalesByLocaleUniqueWhere"]: ValueTypes["FooterLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: ValueTypes["LocaleUniqueWhere"];
	["FooterLocaleUniqueWhere"]: ValueTypes["FooterLocaleUniqueWhere"];
	["FooterUniqueWhere"]: ValueTypes["FooterUniqueWhere"];
	["LinkUniqueWhere"]: ValueTypes["LinkUniqueWhere"];
	["ImageUniqueWhere"]: ValueTypes["ImageUniqueWhere"];
	["JobsFormLocaleUniqueWhere"]: ValueTypes["JobsFormLocaleUniqueWhere"];
	["JobsFormUniqueWhere"]: ValueTypes["JobsFormUniqueWhere"];
	["ImageTextListUniqueWhere"]: ValueTypes["ImageTextListUniqueWhere"];
	["ImageTextItemUniqueWhere"]: ValueTypes["ImageTextItemUniqueWhere"];
	["RecommendationFormLocaleUniqueWhere"]: ValueTypes["RecommendationFormLocaleUniqueWhere"];
	["RecommendationFormUniqueWhere"]: ValueTypes["RecommendationFormUniqueWhere"];
	["GenericPageLocaleUniqueWhere"]: ValueTypes["GenericPageLocaleUniqueWhere"];
	["GenericPageUniqueWhere"]: ValueTypes["GenericPageUniqueWhere"];
	["ContentUniqueWhere"]: ValueTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: ValueTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: ValueTypes["ContentReferenceUniqueWhere"];
	["ContentReferenceLinkableItemUniqueWhere"]: ValueTypes["ContentReferenceLinkableItemUniqueWhere"];
	["LinkableUniqueWhere"]: ValueTypes["LinkableUniqueWhere"];
	["RedirectUniqueWhere"]: ValueTypes["RedirectUniqueWhere"];
	["BusinessPageLocaleUniqueWhere"]: ValueTypes["BusinessPageLocaleUniqueWhere"];
	["BusinessPageUniqueWhere"]: ValueTypes["BusinessPageUniqueWhere"];
	["SeoUniqueWhere"]: ValueTypes["SeoUniqueWhere"];
	["JobPageLocaleUniqueWhere"]: ValueTypes["JobPageLocaleUniqueWhere"];
	["JobPageUniqueWhere"]: ValueTypes["JobPageUniqueWhere"];
	["KeyInfoListUniqueWhere"]: ValueTypes["KeyInfoListUniqueWhere"];
	["KeyInfoItemUniqueWhere"]: ValueTypes["KeyInfoItemUniqueWhere"];
	["HomePageLocaleUniqueWhere"]: ValueTypes["HomePageLocaleUniqueWhere"];
	["HomePageUniqueWhere"]: ValueTypes["HomePageUniqueWhere"];
	["ThemeUniqueWhere"]: ValueTypes["ThemeUniqueWhere"];
	["SiteSwitcherUniqueWhere"]: ValueTypes["SiteSwitcherUniqueWhere"];
	["GalleryUniqueWhere"]: ValueTypes["GalleryUniqueWhere"];
	["GalleryItemUniqueWhere"]: ValueTypes["GalleryItemUniqueWhere"];
	["HomePageLokalLocaleUniqueWhere"]: ValueTypes["HomePageLokalLocaleUniqueWhere"];
	["HomePageLokalUniqueWhere"]: ValueTypes["HomePageLokalUniqueWhere"];
	["MediumListUniqueWhere"]: ValueTypes["MediumListUniqueWhere"];
	["MediumItemUniqueWhere"]: ValueTypes["MediumItemUniqueWhere"];
	["HeaderLocaleUniqueWhere"]: ValueTypes["HeaderLocaleUniqueWhere"];
	["HeaderUniqueWhere"]: ValueTypes["HeaderUniqueWhere"];
	["SeoDefaultLocaleUniqueWhere"]: ValueTypes["SeoDefaultLocaleUniqueWhere"];
	["SeoDefaultUniqueWhere"]: ValueTypes["SeoDefaultUniqueWhere"];
	["ContractTypeLocaleUniqueWhere"]: ValueTypes["ContractTypeLocaleUniqueWhere"];
	["ContractTypeUniqueWhere"]: ValueTypes["ContractTypeUniqueWhere"];
	["JobCityLocaleUniqueWhere"]: ValueTypes["JobCityLocaleUniqueWhere"];
	["JobCityUniqueWhere"]: ValueTypes["JobCityUniqueWhere"];
	["JobPositionLocaleUniqueWhere"]: ValueTypes["JobPositionLocaleUniqueWhere"];
	["JobPositionUniqueWhere"]: ValueTypes["JobPositionUniqueWhere"];
	["BonusBannerLocaleUniqueWhere"]: ValueTypes["BonusBannerLocaleUniqueWhere"];
	["BonusBannerUniqueWhere"]: ValueTypes["BonusBannerUniqueWhere"];
	["TileUniqueWhere"]: ValueTypes["TileUniqueWhere"];
	["JobsFilterLocaleUniqueWhere"]: ValueTypes["JobsFilterLocaleUniqueWhere"];
	["JobsFilterUniqueWhere"]: ValueTypes["JobsFilterUniqueWhere"];
	["GeneralLocaleUniqueWhere"]: ValueTypes["GeneralLocaleUniqueWhere"];
	["GeneralUniqueWhere"]: ValueTypes["GeneralUniqueWhere"];
	["InfoBoxLocaleUniqueWhere"]: ValueTypes["InfoBoxLocaleUniqueWhere"];
	["InfoBoxUniqueWhere"]: ValueTypes["InfoBoxUniqueWhere"];
	["FooterLocalesByTermsAndConditionsUniqueWhere"]: ValueTypes["FooterLocalesByTermsAndConditionsUniqueWhere"];
	["FooterLocalesByBannerImageUniqueWhere"]: ValueTypes["FooterLocalesByBannerImageUniqueWhere"];
	["FooterLocalesByBannerLinkUniqueWhere"]: ValueTypes["FooterLocalesByBannerLinkUniqueWhere"];
	["FooterLocalesByJobsFormUniqueWhere"]: ValueTypes["FooterLocalesByJobsFormUniqueWhere"];
	["FooterLocalesByCopyrightLinkUniqueWhere"]: ValueTypes["FooterLocalesByCopyrightLinkUniqueWhere"];
	["FooterLocalesByRecommendationFormUniqueWhere"]: ValueTypes["FooterLocalesByRecommendationFormUniqueWhere"];
	["FooterLocalesByBannerSecondLinkUniqueWhere"]: ValueTypes["FooterLocalesByBannerSecondLinkUniqueWhere"];
	["FooterLocalesByInstagramLinkUniqueWhere"]: ValueTypes["FooterLocalesByInstagramLinkUniqueWhere"];
	["GenericPageLocalesByLocaleUniqueWhere"]: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"];
	["GenericPageLocalesByContentUniqueWhere"]: ValueTypes["GenericPageLocalesByContentUniqueWhere"];
	["GenericPageLocalesBySeoUniqueWhere"]: ValueTypes["GenericPageLocalesBySeoUniqueWhere"];
	["GenericPageLocalesByLinkUniqueWhere"]: ValueTypes["GenericPageLocalesByLinkUniqueWhere"];
	["GenericPageLocalesByLogoUniqueWhere"]: ValueTypes["GenericPageLocalesByLogoUniqueWhere"];
	["GenericPageLocalesByImageUniqueWhere"]: ValueTypes["GenericPageLocalesByImageUniqueWhere"];
	["ContentReferenceLinkableItemOrderBy"]: ValueTypes["ContentReferenceLinkableItemOrderBy"];
	["ContentReferenceOrderBy"]: ValueTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: ValueTypes["ContentBlockOrderBy"];
	["MediumListOrderBy"]: ValueTypes["MediumListOrderBy"];
	["TextListOrderBy"]: ValueTypes["TextListOrderBy"];
	["CarouselOrderBy"]: ValueTypes["CarouselOrderBy"];
	["BonusBannerLocaleOrderBy"]: ValueTypes["BonusBannerLocaleOrderBy"];
	["BonusBannerOrderBy"]: ValueTypes["BonusBannerOrderBy"];
	["SideBySideOrderBy"]: ValueTypes["SideBySideOrderBy"];
	["VideoOrderBy"]: ValueTypes["VideoOrderBy"];
	["MediumItemOrderBy"]: ValueTypes["MediumItemOrderBy"];
	["MediumOrderBy"]: ValueTypes["MediumOrderBy"];
	["TextItemOrderBy"]: ValueTypes["TextItemOrderBy"];
	["CarouselItemOrderBy"]: ValueTypes["CarouselItemOrderBy"];
	["CarouselItemsByImageUniqueWhere"]: ValueTypes["CarouselItemsByImageUniqueWhere"];
	["CarouselItemsByLinkUrlUniqueWhere"]: ValueTypes["CarouselItemsByLinkUrlUniqueWhere"];
	["BonusBannerLocalesByLocaleUniqueWhere"]: ValueTypes["BonusBannerLocalesByLocaleUniqueWhere"];
	["BonusBannerLocalesByTilesUniqueWhere"]: ValueTypes["BonusBannerLocalesByTilesUniqueWhere"];
	["BonusBannerLocalesByLogoUniqueWhere"]: ValueTypes["BonusBannerLocalesByLogoUniqueWhere"];
	["JobsFormLocalesByLocaleUniqueWhere"]: ValueTypes["JobsFormLocalesByLocaleUniqueWhere"];
	["JobsFormLocalesByImageTextListUniqueWhere"]: ValueTypes["JobsFormLocalesByImageTextListUniqueWhere"];
	["JobsFormLocalesByLogoUniqueWhere"]: ValueTypes["JobsFormLocalesByLogoUniqueWhere"];
	["JobsFormLocalesBySuccessImageUniqueWhere"]: ValueTypes["JobsFormLocalesBySuccessImageUniqueWhere"];
	["ImageTextItemOrderBy"]: ValueTypes["ImageTextItemOrderBy"];
	["ImageTextListItemsByImageUniqueWhere"]: ValueTypes["ImageTextListItemsByImageUniqueWhere"];
	["TileOrderBy"]: ValueTypes["TileOrderBy"];
	["BonusBannerLocaleTilesByImageUniqueWhere"]: ValueTypes["BonusBannerLocaleTilesByImageUniqueWhere"];
	["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"]: ValueTypes["BonusBannerLocaleTilesByLigthBoxMediaUniqueWhere"];
	["SideBySideItemOrderBy"]: ValueTypes["SideBySideItemOrderBy"];
	["SideBySideItemsByMediaUniqueWhere"]: ValueTypes["SideBySideItemsByMediaUniqueWhere"];
	["GalleryItemOrderBy"]: ValueTypes["GalleryItemOrderBy"];
	["GalleryItemsByImageUniqueWhere"]: ValueTypes["GalleryItemsByImageUniqueWhere"];
	["ContentReferenceLinkablesByItemUniqueWhere"]: ValueTypes["ContentReferenceLinkablesByItemUniqueWhere"];
	["ContentBlockReferencesByLinkablesUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinkablesUniqueWhere"];
	["ContentBlockReferencesByLinkUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"];
	["ContentBlockReferencesByMediaUniqueWhere"]: ValueTypes["ContentBlockReferencesByMediaUniqueWhere"];
	["ContentBlocksByReferencesUniqueWhere"]: ValueTypes["ContentBlocksByReferencesUniqueWhere"];
	["BusinessPageLocalesByLocaleUniqueWhere"]: ValueTypes["BusinessPageLocalesByLocaleUniqueWhere"];
	["BusinessPageLocalesByContentUniqueWhere"]: ValueTypes["BusinessPageLocalesByContentUniqueWhere"];
	["BusinessPageLocalesBySeoUniqueWhere"]: ValueTypes["BusinessPageLocalesBySeoUniqueWhere"];
	["BusinessPageLocalesByLinkUniqueWhere"]: ValueTypes["BusinessPageLocalesByLinkUniqueWhere"];
	["BusinessPageLocalesByImageUniqueWhere"]: ValueTypes["BusinessPageLocalesByImageUniqueWhere"];
	["InfoBoxLocaleOrderBy"]: ValueTypes["InfoBoxLocaleOrderBy"];
	["InfoBoxLocalesByLocaleUniqueWhere"]: ValueTypes["InfoBoxLocalesByLocaleUniqueWhere"];
	["ContractTypeLocaleOrderBy"]: ValueTypes["ContractTypeLocaleOrderBy"];
	["ContractTypeLocalesByLocaleUniqueWhere"]: ValueTypes["ContractTypeLocalesByLocaleUniqueWhere"];
	["JobCityLocaleOrderBy"]: ValueTypes["JobCityLocaleOrderBy"];
	["JobCityLocalesByLocaleUniqueWhere"]: ValueTypes["JobCityLocalesByLocaleUniqueWhere"];
	["JobPositionLocaleOrderBy"]: ValueTypes["JobPositionLocaleOrderBy"];
	["JobPositionLocalesByLocaleUniqueWhere"]: ValueTypes["JobPositionLocalesByLocaleUniqueWhere"];
	["JobPositionJobsByLocalesUniqueWhere"]: ValueTypes["JobPositionJobsByLocalesUniqueWhere"];
	["JobPageLocalesByLocaleUniqueWhere"]: ValueTypes["JobPageLocalesByLocaleUniqueWhere"];
	["JobPageLocalesByImageUniqueWhere"]: ValueTypes["JobPageLocalesByImageUniqueWhere"];
	["JobPageLocalesByKeyInfoUniqueWhere"]: ValueTypes["JobPageLocalesByKeyInfoUniqueWhere"];
	["JobPageLocalesByContentUniqueWhere"]: ValueTypes["JobPageLocalesByContentUniqueWhere"];
	["JobPageLocalesBySeoUniqueWhere"]: ValueTypes["JobPageLocalesBySeoUniqueWhere"];
	["JobPageLocalesByLinkUniqueWhere"]: ValueTypes["JobPageLocalesByLinkUniqueWhere"];
	["JobPageLocalesByMobileImageUniqueWhere"]: ValueTypes["JobPageLocalesByMobileImageUniqueWhere"];
	["KeyInfoItemOrderBy"]: ValueTypes["KeyInfoItemOrderBy"];
	["IconOrderBy"]: ValueTypes["IconOrderBy"];
	["HomePageLocalesByLocaleUniqueWhere"]: ValueTypes["HomePageLocalesByLocaleUniqueWhere"];
	["HomePageLocalesByButtonUniqueWhere"]: ValueTypes["HomePageLocalesByButtonUniqueWhere"];
	["HomePageLocalesByContentUniqueWhere"]: ValueTypes["HomePageLocalesByContentUniqueWhere"];
	["HomePageLocalesBySeoUniqueWhere"]: ValueTypes["HomePageLocalesBySeoUniqueWhere"];
	["HomePageLocalesByLinkUniqueWhere"]: ValueTypes["HomePageLocalesByLinkUniqueWhere"];
	["HomePageLocalesByLogoUniqueWhere"]: ValueTypes["HomePageLocalesByLogoUniqueWhere"];
	["HomePageLocalesBySiteSwitcherUniqueWhere"]: ValueTypes["HomePageLocalesBySiteSwitcherUniqueWhere"];
	["HomePageLocalesByIlustrationUniqueWhere"]: ValueTypes["HomePageLocalesByIlustrationUniqueWhere"];
	["HomePageLokalLocalesByLocaleUniqueWhere"]: ValueTypes["HomePageLokalLocalesByLocaleUniqueWhere"];
	["HomePageLokalLocalesByLogoUniqueWhere"]: ValueTypes["HomePageLokalLocalesByLogoUniqueWhere"];
	["HomePageLokalLocalesByButtonUniqueWhere"]: ValueTypes["HomePageLokalLocalesByButtonUniqueWhere"];
	["HomePageLokalLocalesByContentUniqueWhere"]: ValueTypes["HomePageLokalLocalesByContentUniqueWhere"];
	["HomePageLokalLocalesBySeoUniqueWhere"]: ValueTypes["HomePageLokalLocalesBySeoUniqueWhere"];
	["HomePageLokalLocalesByLinkUniqueWhere"]: ValueTypes["HomePageLokalLocalesByLinkUniqueWhere"];
	["HomePageLokalLocalesBySiteSwitcherUniqueWhere"]: ValueTypes["HomePageLokalLocalesBySiteSwitcherUniqueWhere"];
	["HomePageLokalLocalesByIlustrationUniqueWhere"]: ValueTypes["HomePageLokalLocalesByIlustrationUniqueWhere"];
	["FooterLinkOrderBy"]: ValueTypes["FooterLinkOrderBy"];
	["RecommendationFormLocalesByLocaleUniqueWhere"]: ValueTypes["RecommendationFormLocalesByLocaleUniqueWhere"];
	["HeaderLocaleOrderBy"]: ValueTypes["HeaderLocaleOrderBy"];
	["HeaderOrderBy"]: ValueTypes["HeaderOrderBy"];
	["HeaderLocalesByLocaleUniqueWhere"]: ValueTypes["HeaderLocalesByLocaleUniqueWhere"];
	["HeaderLocalesByBusinessLinkUniqueWhere"]: ValueTypes["HeaderLocalesByBusinessLinkUniqueWhere"];
	["HeaderLocalesByLokalLinkUniqueWhere"]: ValueTypes["HeaderLocalesByLokalLinkUniqueWhere"];
	["BusinessOrderBy"]: ValueTypes["BusinessOrderBy"];
	["SeoDefaultLocaleOrderBy"]: ValueTypes["SeoDefaultLocaleOrderBy"];
	["SeoDefaultOrderBy"]: ValueTypes["SeoDefaultOrderBy"];
	["SeoDefaultLocalesByLocaleUniqueWhere"]: ValueTypes["SeoDefaultLocalesByLocaleUniqueWhere"];
	["SeoDefaultLocalesBySeoUniqueWhere"]: ValueTypes["SeoDefaultLocalesBySeoUniqueWhere"];
	["JobsFilterLocaleOrderBy"]: ValueTypes["JobsFilterLocaleOrderBy"];
	["JobsFilterOrderBy"]: ValueTypes["JobsFilterOrderBy"];
	["JobsFilterLocalesByLocaleUniqueWhere"]: ValueTypes["JobsFilterLocalesByLocaleUniqueWhere"];
	["JobsFilterLocalesByBannerJobsFormUniqueWhere"]: ValueTypes["JobsFilterLocalesByBannerJobsFormUniqueWhere"];
	["JobsFilterLocalesByBannerImageUniqueWhere"]: ValueTypes["JobsFilterLocalesByBannerImageUniqueWhere"];
	["JobsFilterLocalesByNoJobImageUniqueWhere"]: ValueTypes["JobsFilterLocalesByNoJobImageUniqueWhere"];
	["GeneralLocaleOrderBy"]: ValueTypes["GeneralLocaleOrderBy"];
	["GeneralOrderBy"]: ValueTypes["GeneralOrderBy"];
	["LocaleFootersByRootUniqueWhere"]: ValueTypes["LocaleFootersByRootUniqueWhere"];
	["LocaleFootersByTermsAndConditionsUniqueWhere"]: ValueTypes["LocaleFootersByTermsAndConditionsUniqueWhere"];
	["LocaleFootersByBannerImageUniqueWhere"]: ValueTypes["LocaleFootersByBannerImageUniqueWhere"];
	["LocaleFootersByBannerLinkUniqueWhere"]: ValueTypes["LocaleFootersByBannerLinkUniqueWhere"];
	["LocaleFootersByJobsFormUniqueWhere"]: ValueTypes["LocaleFootersByJobsFormUniqueWhere"];
	["LocaleFootersByCopyrightLinkUniqueWhere"]: ValueTypes["LocaleFootersByCopyrightLinkUniqueWhere"];
	["LocaleFootersByRecommendationFormUniqueWhere"]: ValueTypes["LocaleFootersByRecommendationFormUniqueWhere"];
	["LocaleFootersByBannerSecondLinkUniqueWhere"]: ValueTypes["LocaleFootersByBannerSecondLinkUniqueWhere"];
	["LocaleFootersByInstagramLinkUniqueWhere"]: ValueTypes["LocaleFootersByInstagramLinkUniqueWhere"];
	["LocaleGenericPagesByRootUniqueWhere"]: ValueTypes["LocaleGenericPagesByRootUniqueWhere"];
	["LocaleGenericPagesByContentUniqueWhere"]: ValueTypes["LocaleGenericPagesByContentUniqueWhere"];
	["LocaleGenericPagesBySeoUniqueWhere"]: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"];
	["LocaleGenericPagesByLinkUniqueWhere"]: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"];
	["LocaleGenericPagesByLogoUniqueWhere"]: ValueTypes["LocaleGenericPagesByLogoUniqueWhere"];
	["LocaleGenericPagesByImageUniqueWhere"]: ValueTypes["LocaleGenericPagesByImageUniqueWhere"];
	["LocaleHeadersByRootUniqueWhere"]: ValueTypes["LocaleHeadersByRootUniqueWhere"];
	["LocaleHeadersByBusinessLinkUniqueWhere"]: ValueTypes["LocaleHeadersByBusinessLinkUniqueWhere"];
	["LocaleHeadersByLokalLinkUniqueWhere"]: ValueTypes["LocaleHeadersByLokalLinkUniqueWhere"];
	["LocaleSeoDefaultsByRootUniqueWhere"]: ValueTypes["LocaleSeoDefaultsByRootUniqueWhere"];
	["LocaleSeoDefaultsBySeoUniqueWhere"]: ValueTypes["LocaleSeoDefaultsBySeoUniqueWhere"];
	["LocaleBusinessPagesByRootUniqueWhere"]: ValueTypes["LocaleBusinessPagesByRootUniqueWhere"];
	["LocaleBusinessPagesByContentUniqueWhere"]: ValueTypes["LocaleBusinessPagesByContentUniqueWhere"];
	["LocaleBusinessPagesBySeoUniqueWhere"]: ValueTypes["LocaleBusinessPagesBySeoUniqueWhere"];
	["LocaleBusinessPagesByLinkUniqueWhere"]: ValueTypes["LocaleBusinessPagesByLinkUniqueWhere"];
	["LocaleBusinessPagesByImageUniqueWhere"]: ValueTypes["LocaleBusinessPagesByImageUniqueWhere"];
	["LocaleJobPagesByRootUniqueWhere"]: ValueTypes["LocaleJobPagesByRootUniqueWhere"];
	["LocaleJobPagesByImageUniqueWhere"]: ValueTypes["LocaleJobPagesByImageUniqueWhere"];
	["LocaleJobPagesByKeyInfoUniqueWhere"]: ValueTypes["LocaleJobPagesByKeyInfoUniqueWhere"];
	["LocaleJobPagesByContentUniqueWhere"]: ValueTypes["LocaleJobPagesByContentUniqueWhere"];
	["LocaleJobPagesBySeoUniqueWhere"]: ValueTypes["LocaleJobPagesBySeoUniqueWhere"];
	["LocaleJobPagesByLinkUniqueWhere"]: ValueTypes["LocaleJobPagesByLinkUniqueWhere"];
	["LocaleJobPagesByMobileImageUniqueWhere"]: ValueTypes["LocaleJobPagesByMobileImageUniqueWhere"];
	["LocaleContractTypesByRootUniqueWhere"]: ValueTypes["LocaleContractTypesByRootUniqueWhere"];
	["LocaleContractTypesBySlugUniqueWhere"]: ValueTypes["LocaleContractTypesBySlugUniqueWhere"];
	["LocaleJobCitiesByRootUniqueWhere"]: ValueTypes["LocaleJobCitiesByRootUniqueWhere"];
	["LocaleJobCitiesBySlugUniqueWhere"]: ValueTypes["LocaleJobCitiesBySlugUniqueWhere"];
	["LocaleJobPositionsByRootUniqueWhere"]: ValueTypes["LocaleJobPositionsByRootUniqueWhere"];
	["LocaleJobPositionsBySlugUniqueWhere"]: ValueTypes["LocaleJobPositionsBySlugUniqueWhere"];
	["LocaleHomePagesByRootUniqueWhere"]: ValueTypes["LocaleHomePagesByRootUniqueWhere"];
	["LocaleHomePagesByButtonUniqueWhere"]: ValueTypes["LocaleHomePagesByButtonUniqueWhere"];
	["LocaleHomePagesByContentUniqueWhere"]: ValueTypes["LocaleHomePagesByContentUniqueWhere"];
	["LocaleHomePagesBySeoUniqueWhere"]: ValueTypes["LocaleHomePagesBySeoUniqueWhere"];
	["LocaleHomePagesByLinkUniqueWhere"]: ValueTypes["LocaleHomePagesByLinkUniqueWhere"];
	["LocaleHomePagesByLogoUniqueWhere"]: ValueTypes["LocaleHomePagesByLogoUniqueWhere"];
	["LocaleHomePagesBySiteSwitcherUniqueWhere"]: ValueTypes["LocaleHomePagesBySiteSwitcherUniqueWhere"];
	["LocaleHomePagesByIlustrationUniqueWhere"]: ValueTypes["LocaleHomePagesByIlustrationUniqueWhere"];
	["LocaleBonusBannersByRootUniqueWhere"]: ValueTypes["LocaleBonusBannersByRootUniqueWhere"];
	["LocaleBonusBannersByTilesUniqueWhere"]: ValueTypes["LocaleBonusBannersByTilesUniqueWhere"];
	["LocaleBonusBannersByLogoUniqueWhere"]: ValueTypes["LocaleBonusBannersByLogoUniqueWhere"];
	["LocaleHomePageLokalsByRootUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByRootUniqueWhere"];
	["LocaleHomePageLokalsByLogoUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByLogoUniqueWhere"];
	["LocaleHomePageLokalsByButtonUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByButtonUniqueWhere"];
	["LocaleHomePageLokalsByContentUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByContentUniqueWhere"];
	["LocaleHomePageLokalsBySeoUniqueWhere"]: ValueTypes["LocaleHomePageLokalsBySeoUniqueWhere"];
	["LocaleHomePageLokalsByLinkUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByLinkUniqueWhere"];
	["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"]: ValueTypes["LocaleHomePageLokalsBySiteSwitcherUniqueWhere"];
	["LocaleHomePageLokalsByIlustrationUniqueWhere"]: ValueTypes["LocaleHomePageLokalsByIlustrationUniqueWhere"];
	["LocaleJobsFormsByRootUniqueWhere"]: ValueTypes["LocaleJobsFormsByRootUniqueWhere"];
	["LocaleJobsFormsByImageTextListUniqueWhere"]: ValueTypes["LocaleJobsFormsByImageTextListUniqueWhere"];
	["LocaleJobsFormsByLogoUniqueWhere"]: ValueTypes["LocaleJobsFormsByLogoUniqueWhere"];
	["LocaleJobsFormsBySuccessImageUniqueWhere"]: ValueTypes["LocaleJobsFormsBySuccessImageUniqueWhere"];
	["LocaleJobsFiltersByRootUniqueWhere"]: ValueTypes["LocaleJobsFiltersByRootUniqueWhere"];
	["LocaleJobsFiltersByBannerJobsFormUniqueWhere"]: ValueTypes["LocaleJobsFiltersByBannerJobsFormUniqueWhere"];
	["LocaleJobsFiltersByBannerImageUniqueWhere"]: ValueTypes["LocaleJobsFiltersByBannerImageUniqueWhere"];
	["LocaleJobsFiltersByNoJobImageUniqueWhere"]: ValueTypes["LocaleJobsFiltersByNoJobImageUniqueWhere"];
	["LocaleGeneralByRootUniqueWhere"]: ValueTypes["LocaleGeneralByRootUniqueWhere"];
	["LocaleGeneralBySeoUniqueWhere"]: ValueTypes["LocaleGeneralBySeoUniqueWhere"];
	["LocaleRecommendationFormsByRootUniqueWhere"]: ValueTypes["LocaleRecommendationFormsByRootUniqueWhere"];
	["LocaleInfoBoxesByRootUniqueWhere"]: ValueTypes["LocaleInfoBoxesByRootUniqueWhere"];
	["GeneralLocalesByLocaleUniqueWhere"]: ValueTypes["GeneralLocalesByLocaleUniqueWhere"];
	["GeneralLocalesBySeoUniqueWhere"]: ValueTypes["GeneralLocalesBySeoUniqueWhere"];
	["FooterLinkUniqueWhere"]: ValueTypes["FooterLinkUniqueWhere"];
	["BusinessUniqueWhere"]: ValueTypes["BusinessUniqueWhere"];
	["IconUniqueWhere"]: ValueTypes["IconUniqueWhere"];
	["MediumUniqueWhere"]: ValueTypes["MediumUniqueWhere"];
	["VideoUniqueWhere"]: ValueTypes["VideoUniqueWhere"];
	["TextItemUniqueWhere"]: ValueTypes["TextItemUniqueWhere"];
	["TextListUniqueWhere"]: ValueTypes["TextListUniqueWhere"];
	["CarouselUniqueWhere"]: ValueTypes["CarouselUniqueWhere"];
	["CarouselItemUniqueWhere"]: ValueTypes["CarouselItemUniqueWhere"];
	["SideBySideUniqueWhere"]: ValueTypes["SideBySideUniqueWhere"];
	["SideBySideItemUniqueWhere"]: ValueTypes["SideBySideItemUniqueWhere"];
	["FileUniqueWhere"]: ValueTypes["FileUniqueWhere"];
	["FileWhere"]: ValueTypes["FileWhere"];
	["FileOrderBy"]: ValueTypes["FileOrderBy"];
	["FileCreateInput"]: ValueTypes["FileCreateInput"];
	["JobFormSubscriptionCreateInput"]: ValueTypes["JobFormSubscriptionCreateInput"];
	["JobFormSubscriptionCreateFileEntityRelationInput"]: ValueTypes["JobFormSubscriptionCreateFileEntityRelationInput"];
	["JobFormSubscriptionConnectOrCreateFileRelationInput"]: ValueTypes["JobFormSubscriptionConnectOrCreateFileRelationInput"];
	["JobFormSubscriptionCreateJobEntityRelationInput"]: ValueTypes["JobFormSubscriptionCreateJobEntityRelationInput"];
	["JobFormSubscriptionCreateStatusEntityRelationInput"]: ValueTypes["JobFormSubscriptionCreateStatusEntityRelationInput"];
	["JobFormSubscriptionStatusUniqueWhere"]: ValueTypes["JobFormSubscriptionStatusUniqueWhere"];
	["ImageListWhere"]: ValueTypes["ImageListWhere"];
	["ImageListItemWhere"]: ValueTypes["ImageListItemWhere"];
	["ImageListItemOrderBy"]: ValueTypes["ImageListItemOrderBy"];
	["ImageListOrderBy"]: ValueTypes["ImageListOrderBy"];
	["ImageListItemsByImageUniqueWhere"]: ValueTypes["ImageListItemsByImageUniqueWhere"];
	["ImageListUniqueWhere"]: ValueTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: ValueTypes["ImageListItemUniqueWhere"];
	["JobFormSubscriptionStatusWhere"]: ValueTypes["JobFormSubscriptionStatusWhere"];
	["JobFormSubscriptionStatusOrderBy"]: ValueTypes["JobFormSubscriptionStatusOrderBy"];
	["StickyBarUniqueWhere"]: ValueTypes["StickyBarUniqueWhere"];
	["StickyBarCreateInput"]: ValueTypes["StickyBarCreateInput"];
	["StickyBarCreateLinkEntityRelationInput"]: ValueTypes["StickyBarCreateLinkEntityRelationInput"];
	["StickyBarCreateJobsFormEntityRelationInput"]: ValueTypes["StickyBarCreateJobsFormEntityRelationInput"];
	["RecommendationFormSubscriptionCreateInput"]: ValueTypes["RecommendationFormSubscriptionCreateInput"];
	["InfoBoxCreateInput"]: ValueTypes["InfoBoxCreateInput"];
	["InfoBoxCreateLogoEntityRelationInput"]: ValueTypes["InfoBoxCreateLogoEntityRelationInput"];
	["S3GenerateSignedUploadInput"]: ValueTypes["S3GenerateSignedUploadInput"];
	["S33ContentDisposition"]: ValueTypes["S33ContentDisposition"];
	["S3Acl"]: ValueTypes["S3Acl"];
	["_MutationErrorType"]: ValueTypes["_MutationErrorType"];
	["MutationTransactionOptions"]: ValueTypes["MutationTransactionOptions"];
}